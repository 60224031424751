import React, { Component } from 'react';
import MyAccount from './MyAccount';
import MyAddress from './MyAddress';
import MyOrder from './MyOrder';
import ChangePassword from './ChangePassword';
import LocalStorageService from '../../services/LocalStorageService';
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import MyAccumulatedPoints from './MyAccumulatedPoints';
import FavoriteProduct from './FavoriteProduct';

const localStorageService = LocalStorageService.getService();


class User extends Component {
    constructor(props){
        super(props);
        this.state = {
            type : "account"
        }
    }

    componentDidMount() {
        if(this.props.match.params.type !== "account" 
        && this.props.match.params.type !== "my-order" 
        && this.props.match.params.type !== "change-password" 
        && this.props.match.params.type !== "delivery-address"
        && this.props.match.params.type !== "accumulated-points"
        && this.props.match.params.type !== "favorite-product"){
            this.props.history.push('/not-found');
        }
        this.setState({type: this.props.match.params.type});
        if(localStorageService.getOrderInfo()
            && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
            && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
            this.props.clearListCart();
            localStorageService.clearCart();
            localStorageService.clearVoucher();
            localStorageService.clearOrderInfo();
            localStorageService.clearDeliveryInfo();
            localStorageService.clearVoucherPoint();
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.type !== this.props.match.params.type){
            this.setState({type: this.props.match.params.type})
        }
      }
    render() {
        const { type } = this.state;
        return (
            <React.Fragment>
                {type === "account" && (
                    <MyAccount />
                )}
                 {type === "my-order" && (
                    <MyOrder />
                )}
                 {type === "change-password" && (
                    <ChangePassword />
                )}
                 {type === "delivery-address" && (
                    <MyAddress />
                )}
                {type === "accumulated-points" && (
                    <MyAccumulatedPoints />
                )}
                {type === "favorite-product" && (
                    <FavoriteProduct />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
      clearListCart: (payload = null) => {
        dispatch({ type: "CLEAR_LIST_CART", payload });
      },
    };
  };
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default compose(
    withStyles(style),
    withConnect
  )(withRouter(injectIntl(User)));