import React, { Component } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import styled from "styled-components";
import LocalStorageService from "../../../services/LocalStorageService";
import { Helmet } from 'react-helmet';
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  Container,
  Grid
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import Breadcrumb from "../../../components/Breadcrumb";
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();

const Session = styled.section`
  padding: 12px 0px;
  text-align: center;
`;
const ThumbIcon = styled.div`
  padding: 8px 16px 0px;
`;
const IconCustom = styled(CloseIcon)`
  font-size: 60px;
  font-weight: bold;
  color: #ff0000;
`;
const Heading = styled.h1`
  font-size: 1.875rem;
  margin-bottom: 20px;
  @media (max-width: 768px){
    font-size: 1.675rem;
    margin-bottom: 15px;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 26px;
  margin-bottom: 0px;
  color: #000;
  @media (max-width: 768px){
    font-size: 0.875rem;
  }
`;
const Code = styled.span`
  color: #ff0000;
`;
const ButtonCustom = styled(Link)`
    width: 185px;
    height: 40px;
    margin-top: 45px;
    line-height: 40px;
    text-align: center;
    background: #f2c123;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    border-radius: 22px;
`;
class CheckoutFailed extends Component {
  constructor(props){
    super(props);
    this.state = {
      order_no: "",
      isAuth: false,
      order_id: ""
    }
  }

  componentDidMount = async () => {
    if(!this.props.location.state || 
        !this.props.location.state.order_str || 
        typeof this.props.location.state.order_str !== "string"){
          this.props.history.push('/not-found');
          return false;
    }
    
    let order = JSON.parse(this.props.location.state.order_str);
    await this.setState({
      order_no: order.order_no,
      order_id: order.order_id,
    });
    if(localStorageService.getAccessToken()){
      this.setState({isAuth: true})
    }
  }
  
  handleRollbackOrder = () => {
    let { order_id, order_no } = this.state;
    let order = {
      order_no: order_no,
      order_id: order_id
    }
    let order_str = JSON.stringify(order);
    localStorageService.setOrderInfo(order_str);
    setTimeout(() => {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearOrderInfo();
      localStorageService.clearVoucherPoint();
    },1800000)
    this.props.history.push("/checkout");

  }

  render() {
    const { order_no } = this.state;
    return (
     <React.Fragment>
       <Helmet>
          <title>Thanh toán thất bại</title>
          <meta property="og:title" content={"Thanh toán thất bại"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Thanh toán thất bại"}></meta>
          <meta name="description" content={"Thanh toán thất bại"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb
          breadcrumb={[
            { link: "/", name: "Thanh toán thất bại" },
          ]}
        />
      <Session>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xl={12} xs={12}>
              <ThumbIcon>
                <IconCustom />
              </ThumbIcon>
              <Heading>Thanh toán thất bại</Heading>
              <Paragraph>
                Đơn hàng số <Code>{order_no}</Code> thanh toán thất bại.
              </Paragraph>
                  <Paragraph>
                  Vui lòng thực hiện thanh toán lại trong vòng 30 phút. Đơn hàng sẽ bị hủy sau thời gian này.
                </Paragraph>
              <ButtonCustom onClick={() => this.handleRollbackOrder()}>Thanh toán lại</ButtonCustom>
            </Grid>
          </Grid>
        </Container>
      </Session>
     </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(CheckoutFailed)));
