// eslint-disable-next-line import/no-anonymous-default-export
export default {
	queryRouteList: '/routes',
	queryUserInfo: 'GET /user/:id token',
	updateUserInfo: 'PUT /user/:id token',
	changeUserPassword: 'POST /change-password token',
	logout: 'POST /auth/logout',
	login: 'POST /auth/login',
	forgetPassword: 'POST /forgot-password',
	facebookLogin: 'GET /hybridauth/login token',
	facebookPage: 'GET /facebook-page token',
	createUser: 'POST /user',
	checkTokenVaid: '/forgot-password',
	registerNewPassword: 'PUT /forgot-password/:id',
	queryCampaign: '/campaign/:id',
	queryCampaigns: '/campaign',
	updateCampaign: 'PUT /campaign/:id',
	createCampaign: 'POST /campaign',
	removeCampaign: 'DELETE /campaign/:id',
	removeCampaignList: 'POST /campaign/delete',
	activeUser: 'POST /active-user',

	queryPostList: 'GET /post',
	createPost: 'POST /post',
	viewPost: 'GET /post/:id',
	actionPost: 'PATCH /post/:id',
	updatePost: 'PUT /post/:id',
	queryDashboard: '/dashboard',

	queryProducts: 'GET /product',
	removeProduct: 'DELETE /product/:id',
	queryProduct: 'GET /product/:id',
	updateProduct: 'PUT /product/:id',
	createProduct: 'POST /product',

	queryCategory: 'GET /category',
	queryCollection: 'GET /collection',
	createCollection: 'POST /collection',
	queryWarehouse: 'GET /ware-house',
	getWarehouse: 'GET /ware-house/:id',
	createWarehouse: 'POST /ware-house',
	updateWarehouse: 'PUT /ware-house/:id',

	queryChatbots: 'GET /chatbot',
	removeChatbot: 'DELETE /chatbot/:id',
	queryChatbot: 'GET /chatbot/:id',
	updateChatbot: 'PUT /chatbot/:id',
	createChatbot: 'POST /chatbot',

	queryOrders: 'GET /order',
	createOrder: 'POST /order',
	updateOrder: 'PUT /order/:id',
	queryOrder: 'GET /order/:id',
	removeOrder: 'DELETE /order/:id',

	queryCities: 'GET /city',
	queryDistricts: 'GET /district',
	queryWards: 'GET /ward',

	queryCustomers: 'GET /customer',
	queryCustomer: 'GET /customer/:id',
	createCustomer: 'POST /customer',
  updateCustomer: 'PUT /customer/:id',
  
	getParentComment: 'GET /comment',
	getChildComment: 'GET /comment-reply',
	createComment: 'POST /comment',

	queryListPageAndGroup: 'GET /facebook-page-group',
	queryProfileUser: 'POST /webhook/facebook',
	queryContentConversation: 'POST /webhook/facebook',
	sendMessage: 'POST /webhook/facebook',
	queryYoutubeLink: 'GET /youtube-link',
	queryListConversation: 'POST /webhook/facebook',

	queryNotification: 'GET /notification',


	//Product
	viewProduct: 'GET /product/:id',
	getProductList: 'GET /product',

	//content
	getContent: 'GET /content',

	//banner
	getBanner: 'GET /banner',

	listCountry: 'GET /country',

	getListDeliveryAddress: 'GET /user-delivery-address',
	createDeliveryAddress: 'POST /user-delivery-address',
	updateDeliveryAddress: 'PUT /user-delivery-address/:id',
	getDeliveryAddress: 'GET /user-delivery-address/:id',
	deleteDeliveryAddress: 'DELETE /user-delivery-address/:id',

	// subscription email
	subMail: 'POST /subscription-email',

	//cart
	getListCart: 'GET /order',
	addCart: 'POST /order',
	updateCart: 'PUT /order/:id',

	//checkout
	getDeliveryService: 'POST /order',
	getListOrder: 'GET /order',
	getStatus: 'GET /status',

	//check product cart
	checkProductCart: 'POST /order',

	//get info voucher
	getVoucherInfo: 'GET /voucher',

	//check exchange-rate
	exchangeRate: 'GET /exchange-rate',

	//create user voucher
	createUserVoucher: 'POST /user-voucher',
	getUserVoucher: 'GET /user-voucher',

	//check exchange rate
	checkExchangeRate: 'GET /exchange-rate',

	//get voucher setting
	getVoucherSetting: 'GET /voucher-setting',

	// product-favorite
	productFavorite: 'GET /product-favorite',

	//ADD addProductFavorite
	addProductFavorite: 'POST /product-favorite'

};
