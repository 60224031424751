import * as types from '../constants';
var users = {
	user: {
		id: '',
		fullname: '',
		email: '',
		phone: '',
		avatar: '',
		birthday: '',
		need_set_password: '',
	},
	avatar: null,
};

export default function reducer(state = users, actions) {
	switch (actions.type) {
		case types.SET_USER_INFO:
			return {
				...state,
				user: actions.payload,
			};
		case types.SET_USER_AVATAR:
			return {
				...state,
				avatar: actions.payload,
			};
		default:
			return state;
	}
}
