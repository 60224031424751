import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { Skeleton } from "@material-ui/lab";
import {
  Container,
  Grid,
  withStyles,
} from "@material-ui/core";
import { apiDomain } from "../../../../utills/config";
class MiddleBanner extends Component {
  render() {
    const { classes, listBanner, isLoading } = this.props;
    return (
      <section id="middle-banner" className={classes.mb}>
        {
          isLoading ? (
            <Skeleton height={181} width={"100%"}>
              <Container maxWidth="xl" >
                <p className="doi-tac"><Skeleton width={200} /></p>
              </Container>
            </Skeleton>
          ) : (
              <div className="title-middle-banner">
                <Container maxWidth="xl" >
                  <p className="doi-tac">Đối Tác</p>
                </Container>
              </div>
          )
        }
        <Container maxWidth="xl" className="mbContainer">
          <Grid container spacing={8}>
            {isLoading ? (
              <React.Fragment>
                {[1, 2, 3, 4].map((value, index) => (
                  <Grid item xs={6} md={3} xl={3} lg={3} key={index}>
                    <div className="mbThumb">
                      <Skeleton variant="rect" width={330} height={145} />
                    </div>
                  </Grid>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {listBanner && Array.isArray(listBanner) && listBanner.length > 0 && listBanner.map((value, index) => (
                  <Grid item xs={6} md={3} xl={3} lg={3} key={index}>
                    <div className="mbThumb">
                       <div className="mbOverlay">
                          <a href={value.link ? value.link : apiDomain}><img src={value.image} alt={`Đối tác ${index+1}`} /></a>
                       </div>
                    </div>
                  </Grid>
                ))}
              </React.Fragment>
            )}
          </Grid>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(MiddleBanner)));
