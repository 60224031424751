import { Container, Grid } from "@material-ui/core";
import React, { Component } from "react";
import LazyImage from "../../../../components/LazyImage";
import { domain } from "../../../../utills/config";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from 'styled-components';

const BannerBottom = styled.section`
    padding: 60px 5px 40px;
    @media (max-width: 768px) {
      padding: 25px 5px;
    }
`;

const SkeletonLoading = styled(Skeleton)`
  height: 280px;
  @media (max-width: 768px) {
    height: 171px;
    }
`;

class BannerTopBlog extends Component {
  render() {
    let { listBanner, isLoading } = this.props;
    return (
      <BannerBottom>
        <Container>
          <Grid container>
            {isLoading ? (
              <React.Fragment>
                {[1, 2].map((value, index) => (
                  <Grid item xs={12} md={6} lg={6} key={index}>
                    <div style={{padding: '0px 5px', marginBottom: '10px'}}>
                        <SkeletonLoading variant="rect" width={"100%"} />
                    </div>
                  </Grid>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {listBanner &&
                  Array.isArray(listBanner) &&
                  listBanner.length > 0 &&
                  listBanner.map((banner, i) => (
                    <Grid item xl={6} md={6} xs={12} key={i}>
                        <div style={{padding: '0px 5px', marginBottom: '10px'}}>
                            <a href={banner.link ? banner.link : domain}>
                                <LazyImage heightSke={280} effect={"opacity"} src={banner.image} alt="Ảnh footer" />
                            </a>
                        </div>
                    </Grid>
                  ))}
              </React.Fragment>
            )}
          </Grid>
        </Container>
      </BannerBottom>
    );
  }
}

export default BannerTopBlog;
