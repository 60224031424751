import React, { Component } from "react";
import { Container, Grid, withStyles, Paper } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LocalStorageService from "../../../services/LocalStorageService";
import { Helmet } from 'react-helmet';
import HorizontalLinearStepper from "../../../components/HorizontalLinearStepper";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import Breadcrumb from "../../../components/Breadcrumb";
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();

const Session = styled.section`
  padding: 12px 0px;
  text-align: center;
`;
const ThumbIcon = styled.div`
  padding: 8px 16px;
`;
const IconCustom = styled(DoneIcon)`
  font-size: 50px;
  color: #f2c123;
`;
const Heading = styled.h1`
  font-size: 1.875rem;
  margin-bottom: 20px;
  @media (max-width: 768px){
    font-size: 1.675rem;
    margin-bottom: 15px;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 26px;
  margin-bottom: 0px;
  color: #000;
  @media (max-width: 768px){
    font-size: 0.875rem;
  }
`;
const Code = styled.span`
  color: #f2c123;
`;
const ButtonCustom = styled(Link)`
    width: 185px;
    height: 40px;
    margin-top: 45px;
    line-height: 40px;
    text-align: center;
    background: #f2c123;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    border-radius: 22px;
`;
const LinkCustom = styled(Link)`
  color: #f2c123;
  text-decoration: none;
`;
class CheckoutSuccess extends Component {
  constructor(props){
    super(props);
    this.state = {
      order_no: "",
      isAuth: false
    }
  }

  componentDidMount() {
    if(!this.props.location.state){
          this.props.history.push('/not-found');
          return false;
    }
    this.setState({order_no: this.props.location.state.order_no});
    if(localStorageService.getAccessToken()){
      this.setState({isAuth: true})
    }
  }
  
  render() {
    const { order_no, isAuth } = this.state;
    const { classes } = this.props;
    return (
     <React.Fragment>
       <Helmet>
          <title>Thanh toán thành công</title>
          <meta property="og:title" content={"Thanh toán thành công"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Thanh toán thành công"}></meta>
          <meta name="description" content={"Thanh toán thành công"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb
          breadcrumb={[
            { link: "/", name: "Thanh toán thành công" },
          ]}
        />
      <Session>
        <Paper style={{padding: '10px 0px 40px'}}>
        <div className={classes.stepper}>
            <Container>
              <HorizontalLinearStepper activeStep={4} />
            </Container>
        </div>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xl={12} xs={12}>
              <ThumbIcon>
                <IconCustom />
              </ThumbIcon>
              <Heading>Đặt hàng thành công</Heading>
              <Paragraph>
                Đơn hàng số <Code>{order_no}</Code> đã được xác nhận
              </Paragraph>
              {
                isAuth && (
                  <Paragraph>
                  Vui lòng xem chi tiết trong phần{" "}
                  <LinkCustom to="/user/account">Tài khoản</LinkCustom> &rsaquo;{" "}
                  <LinkCustom to="/user/my-order">Đơn hàng</LinkCustom>
                </Paragraph>
                )
              }
              <ButtonCustom to="/">Tiếp tục mua sắm</ButtonCustom>
            </Grid>
          </Grid>
        </Container>
        </Paper>
      </Session>
     </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(CheckoutSuccess)));