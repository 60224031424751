export const ROLE_TYPE = {
  ADMIN: 'admin',
  DEFAULT: 'admin',
  DEVELOPER: 'developer',
}

export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const DEFAULT_PAGE_SIZE = 20;
export const LOGIN_SOCIAl_NETWORK = '/hybridauth/callback?provider=';

export const CURRENCY = 'Kč';

export const TYPE_LANDING_PAGE = [
  { url: "/giao-nhan-hang", value: "delivery", name: "Giao nhận hàng" },
  { url: "/chinh-sach-doi-tra", value: "return", name: "Chính sách đổi trả" },
  { url: "/chinh-sach-dat-hang", value: "order", name: "Chính sách đặt hàng" },
  { url: "/chinh-sach-bao-mat", value: "security", name: "Chính sách bảo mật" },
  { url: "/khuyen-mai", value: "sale-off", name: "Khuyến mãi" },
  { url: "/thuc-don", value: "menu", name: "Thực đơn" },
];

export const SCHENGEN_AREA = [
  // "at",
  // "be",
  "cz",
  // "dk",
  // "ee",
  // "fi",
  // "fr",
  // "de",
  // "gr",
  // "hu",
  // "is",
  // "it",
  // "lv",
  // "li",
  // "lt",
  // "lu",
  // "mt",
  // "nl",
  // "no",
  // "pl",
  // "pt",
  // "sk",
  // "si",
  // "es",
  // "se",
  // "ch"
];

export const SLIDER_SKELETON = [1, 2, 3, 4, 5, 6, 7, 8];


export const convertSlugSeo = (title) => {
  let slug;
  //Đổi chữ hoa thành chữ thường
  slug = title ? title.toLowerCase() : "";
  //Đổi ký tự có dấu thành không dấu
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
  slug = slug.replace(/đ/gi, "d");
  //Xóa các ký tự đặt biệt
  slug = slug.replace(
    // /\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi,
    /\|\`|\~|\!|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\/|\?|\>|\<|\'|\"|\:|\;|\[|\]|_/gi, //@ .
    ""
  );
  //Đổi khoảng trắng thành ký tự gạch ngang
  slug = slug.replace(/ /gi, "-");
  //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
  //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
  slug = slug.replace(/\-\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-\-/gi, "-");
  slug = slug.replace(/\-\-\-/gi, "-");
  slug = slug.replace(/\-\-/gi, "-");
  //Xóa các ký tự gạch ngang ở đầu và cuối
  slug = "@" + slug + "@";
  slug = slug.replace(/\@\-|\-\@|\@/gi, "");
  return slug;
};


export const formatTwoDecimals = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
 });
 let result = Number(formatter.format(number).replace(/,/g,''));
 return result;
}

export const convertImageTobase64 = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}