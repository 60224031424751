import { CssBaseline, Paper as MuiPaper } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { spacing } from '@material-ui/system';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';
import { setBreadcrumb } from '../redux/actions/breadcrumbActions';
import ModalLogout from './../components/Modal/Logout';
import NotificationHeader from '../components/NotificationHeader';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
	max-width: 100vw;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`;


const AppContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.body.background};
	padding: 0px;
	max-width: 100vw;
  	overflow: hidden;
	border-radius: 0px;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
		margin-top: 46px;
	}
`;

class Dashboard extends React.Component {
	state = {
		mobileOpen: false,
	};

	handleDrawerToggle = () => {
		this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
	};

	componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.location.pathname !== prevProps.location.pathname){
            window.scrollTo(0, 0);
        }
    }

	render() {
		const { children, width } = this.props;

		return (
			<Root>
				<CssBaseline />
				<GlobalStyle />
				<AppContent>
					<NotificationHeader />
					<Header
						onDrawerToggle={this.handleDrawerToggle}
						mobileOpen={this.state.mobileOpen}
					/>
					<MainContent p={isWidthUp('lg', width) ? 10 : 8} className="main-content">
						{children}
					</MainContent>
					<Footer />
					<ModalLogout />
				</AppContent>
			</Root>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		breadcrumb: store.breadcrumbReducer,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		setBreadcrumb: (header) => {
			dispatch(setBreadcrumb(header));
		},
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
