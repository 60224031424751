import React, { Component } from "react";
import style from "../../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  TableCell,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";
import styled from "styled-components";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";

const SaveButton = styled(Button)`
  width: 106px;
  height: 42px;
  background-color: #f2c123 !important;
  border: 1px solid #f2c123 !important;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 22px;
  margin: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 500px;
`;

class DesktopTemplate extends Component {
  render() {
    const {
      isLoading,
      classes,
      delivery_person_name,
      intl,
      postcode,
      city,
      delivery_address,
      phone,
      handleInputChange,
      handleSave,
      listCountry1,
      handleCountryChange,
      country_selected,
      handlePlacesApiChange,
      handlePlacesApiSelect,
      colorError,
      delivery_prefix,
      listCountry
    } = this.props;

    return (
      <div className={classes.tableContainer}>
              <ValidatorForm
        ref="form"
        onSubmit={() => handleSave()}
        onError={() => {
          if(!this.props.country_selected){
            this.props.handleColorErrorChange();
          }
        }}
      >
        <Grid container spacing={4}>
          <GridItem item xs={8}>
            <FormControl
              margin="normal"
              fullWidth
              required
              className={classes.txtControl}
            >
              <label>
                Họ và tên <span className="txtDanger">*</span>
              </label>
              <TextValidator
                autoFocus
                variant="outlined"
                onChange={(e) => handleInputChange(e)}
                name="delivery_person_name"
                value={delivery_person_name}
                validators={["required"]}
                errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

              {
                window && window.google && (
            <FormControl
              margin="normal"
              fullWidth
              className={classes.txtControl}
            >
              <label>
                Địa chỉ <span className="txtDanger">*</span>
              </label>
              <PlacesAutocomplete
                value={delivery_address}
                onChange={(address) => handlePlacesApiChange(address)}
                onSelect={(address) => handlePlacesApiSelect(address)}
                searchOptions={{
                  location: new window.google.maps.LatLng(50, 14),
                  radius: 2000,
                  types: ["address"],
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextValidator
                       errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                       validators={["required"]}
                       variant="outlined"
                      {...getInputProps({
                        placeholder: "",
                        className: "location-search-input",
                      })}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#DEEBFF", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </FormControl>
                )
              }

            <FormControl
              margin="normal"
              fullWidth
              required
              className={classes.txtControl}
            >
              <label>
                Post code <span className="txtDanger">*</span>
              </label>
              <TextValidator
                variant="outlined"
                validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                onChange={(e) => handleInputChange(e)}
                name="postcode"
                value={postcode}
                inputProps={{
                  maxLength: 20,
                }}
              />
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              required
              className={classes.txtControl}
            >
              <label>
                Thành phố <span className="txtDanger">*</span>
              </label>
              <TextValidator
                variant="outlined"
                validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                onChange={(e) => handleInputChange(e)}
                name="city"
                value={city}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
              variant="outlined"
              className={classes.txtControl}
            >
              <label>
                Quốc gia <span className="txtDanger">*</span>
              </label>
                <Select
                className="basic-single"
                placeholder=""
                isClearable={true}
                isSearchable={true}
                name="listCountry1"
                options={listCountry1}
                onChange={(e) => handleCountryChange(e)}
                value={country_selected}
                styles={{
                  control: (provided, state) =>
                    colorError
                      ? {
                          ...provided,
                          borderColor: "#f44336 !important",
                          borderRadius: '0px!important'
                        }
                      : provided
                }}
              />
                  <p className={classes.helperTextError} style={{display: colorError ? 'block' : 'none'}}>Vui lòng nhập</p>
            </FormControl>

            <FormControl
              margin="normal"
              fullWidth
            >
              <label>
                Số điện thoại <span className="txtDanger">*</span>
              </label>
              <div className={classes.gridBox}>
                <FormControl
                  margin="normal"
                  style={{ width: "130px", marginLeft: "0px" }}
                  className={classes.txtControl}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <SelectValidator
                  variant="outlined"
                    disabled={true}
                    value={delivery_prefix}
                    validators={['required']}
								    errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {listCountry.map((value, index) => (
                      <MenuItem value={value.phone_code} key={index}>
                        {`+${value.phone_code}`}
                      </MenuItem>
                    ))}
                  </SelectValidator>
                </FormControl>
                <FormControl
                  margin="normal"
                  fullWidth
                  required
                  className={classes.txtControl}
                >
                  <TextValidator
                    variant="outlined"
                    fullWidth
                    maxLength={15}
                    validators={["required"]}
                    errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                    onChange={(e) => handleInputChange(e)}
                    name="phone"
                    value={phone}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </FormControl>
              </div>
            </FormControl>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <SaveButton type="submit">
                {isLoading ? <CircularProgress size={18} /> : <React.Fragment>{`Lưu`}</React.Fragment>}
              </SaveButton>
            </Box>
          </GridItem>
        </Grid>
      </ValidatorForm>
        </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DesktopTemplate)));
