import React, { Component } from 'react';

import { injectIntl } from 'react-intl';
import styled, { withTheme } from "styled-components";
import { connect } from 'react-redux';
import { compose } from 'redux';
import style from "./style";
import { Autocomplete } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import {
    CircularProgress,
    TextField,
    withStyles,
  } from "@material-ui/core";
import _ from 'lodash';

const BoxIconSearch = styled.div`
    position: absolute;
    background: #f2c123;
    right: 0px;
    width: 55px;
    top: 1px;
    height: 39px;
    border-radius: 0px 22px 22px 0px;
`;
class SearchMobile extends Component {
    render() {
        let { classes, options, value, loading, filterProduct, viewSearch } = this.props;
        return (
            <div style={{position: 'relative'}}>
                <Autocomplete
                    className={classes.inputSearchMobile}
                      freeSolo
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      filterOptions={(x) => x}
                      options={options}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={value}
                      size="small"
                      loading={loading}
                      onChange={(event, newValue) => {
                        this.setState({
                          options: newValue ? [newValue, ...options] : [],
                          value: newValue,
                        });
                        viewSearch(newValue);
                      }}
                      onInputChange={_.debounce((event, newInputValue) => filterProduct(newInputValue), 1000)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          className="border-none"
                          fullWidth
                          size="small"
                          placeholder="Tìm kiếm"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading && (
                                  <CircularProgress color="inherit" size={15} />
                                )}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    <BoxIconSearch><SearchIcon style={{color: '#fff', position: 'absolute', display: 'block', top: '8px', left: '14px'}} size={23} /></BoxIconSearch>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
    };
  };
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
    withConnect,
    withStyles(style)
  )(withTheme(withRouter(injectIntl(SearchMobile))));