import React, { Component } from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from 'styled-components';
import NumberFormat from "react-number-format";
import { CURRENCY } from '../../../../utills/constant';

const TableHeadCus = styled(TableHead)`
  background: #f1f8f2;
  font-family: "Tinos";
`;

const TableCellCus = styled(TableCell)`
  font-family: "Tinos";
`;

class DesktopPoint extends Component {
    render() {
        const {isSkeleton, listResult } = this.props;
        return (
            <TableContainer style={{ marginTop: "20px" }}>
                      <Table aria-label="simple table" >
                        {!isSkeleton ? (
                          <React.Fragment>
                            <TableHeadCus>
                              <TableRow>
                                <TableCellCus>Ngày cập nhật</TableCellCus>
                                <TableCellCus>Mã đơn hàng/Mã voucher</TableCellCus>
                                <TableCellCus>Giao dịch</TableCellCus>
                                <TableCellCus>
                                  Tổng tiền thanh toán
                                </TableCellCus>
                                <TableCellCus>Điểm thưởng</TableCellCus>
                              </TableRow>
                            </TableHeadCus>
                            <TableBody>
                              {listResult &&
                              Array.isArray(listResult) &&
                              listResult.length > 0 ? (
                                <React.Fragment>
                                  {listResult.map((row, i) => (
                                    <TableRow key={i}>
                                      <TableCellCus component="th" scope="row">
                                        <p>{`${row.created_at} (UTC +2)`}</p>
                                      </TableCellCus>
                                      <TableCellCus>
                                        <p>{row.code}</p>
                                      </TableCellCus>
                                      <TableCellCus>
                                        <p>{row.type === "exchange" ? `Đổi điểm` : `Mua hàng`}</p>
                                      </TableCellCus>
                                      <TableCellCus>
                                        <p><NumberFormat
                                          value={row.exchange_rate
                                            && row.type !== "exchange" ?
                                            `${Number(row.value_exchange)} ` : `${Number(row.value)}`}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={row.exchange_rate && row.type !== "exchange" ? `${row.symbol} ` : ``}
                                          suffix={!row.exchange_rate ? ` ${CURRENCY}` : ``}
                                        /></p>
                                      </TableCellCus>
                                      <TableCellCus>
                                        <p>
                                          {Number(row.point) > 0 && (
                                             <NumberFormat
                                             value={Number(row.point)}
                                             displayType={"text"}
                                             thousandSeparator={true}
                                             prefix={row.type === "exchange" ? `-` : `+`}
                                           />
                                          )}
                                         </p>
                                      </TableCellCus>
                                    </TableRow>
                                  ))}
                                </React.Fragment>
                              ) : (
                                <h3 style={{ marginTop: "10px" }}>
                                  Hiện chưa có giao dịch.
                                </h3>
                              )}
                            </TableBody>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableHeadCus>
                              <TableRow>
                                <TableCell>
                                  <Skeleton variant="text" width={80} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant="text" width={80} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant="text" width={80} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant="text" width={80} />
                                </TableCell>
                                <TableCell>
                                  <Skeleton variant="text" width={80} />
                                </TableCell>
                              </TableRow>
                            </TableHeadCus>
                            <TableBody>
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row) => (
                                <TableRow key={row}>
                                  <TableCell component="th" scope="row">
                                    <Skeleton variant="text" width={80} />
                                  </TableCell>
                                  <TableCell>
                                    <p>
                                      {" "}
                                      <Skeleton variant="text" width={80} />
                                    </p>
                                    <p>
                                      <Skeleton variant="text" width={80} />
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" width={80} />
                                  </TableCell>
                                  <TableCell>
                                    <p>
                                      <Skeleton variant="text" width={80} />
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    <Skeleton variant="text" width={80} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </React.Fragment>
                        )}
                      </Table>
                      
                    </TableContainer>
        );
    }
}

  
  export default DesktopPoint;