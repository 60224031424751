import { Breadcrumbs, Container, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import style from "./style";

class Breadcrumb extends Component {
  render() {
    const { classes, breadcrumb } = this.props;
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={6}>
            <Grid item>
              <div className={classes.breadcrumbs}>
              <Breadcrumbs aria-label="breadcrumb" >
                <Link className="linkBc" color="inherit" to="/">
                  Trang chủ
                </Link>
                {breadcrumb && Array.isArray(breadcrumb) && breadcrumb.length > 0 && breadcrumb.map((value, index) => {
                  if(index === breadcrumb.length - 1){
                    return (
                      <span style={{color: '#000', marginBottom: '5px', display: 'inline-block'}} key={index} color="inherit" aria-current="page">
                        {value.name}
                      </span>
                    )
                  }else{
                    return (
                      <Link className="linkBc" key={index} color="inherit" aria-current="page" to={value.link}>
                      {value.name}
                    </Link>
                    )
                  }
                })}
              </Breadcrumbs>
              </div>
            </Grid>
          </Grid>
        </Container>
        {/* <div className={classes.line} /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    //breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
  };
};

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(Breadcrumb);
