import store from 'store'

import APIFunction from './../services';
// import LocalStorageService from "./../services/LocalStorageService";

// get user store
export const getUserStore = () => {
    return store.get('auth');
}

// get user info
export const queryUserInfo = (params) => {
    return APIFunction.queryUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update user info
export const updateUserInfo = (params) => {
    return APIFunction.updateUserInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user info
export const changeUserPassword = (params) => {
    return APIFunction.changeUserPassword(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get user store
export const removeUserStore = () => {
    return store.remove('auth');
}

// const localStorageService = LocalStorageService.getService();



// query Collection
export const queryCollection = () => {
    return APIFunction.queryCollection().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// create collection info
export const createCollection = (params) => {
    return APIFunction.createCollection(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Post
export const queryPostList = (params) => {
    return APIFunction.queryPostList(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// create Post
export const createPost = (params) => {
    return APIFunction.createPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// view Post
export const viewPost = (params) => {
    return APIFunction.viewPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// view Post
export const actionPost = (params) => {
    return APIFunction.actionPost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// update Post
export const updatePost = (params) => {
    return APIFunction.updatePost(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// get Notification
export const queryNotification = (params) => {
    return APIFunction.queryNotification(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// query Warehouse
export const queryWarehouse = () => {
    return APIFunction.queryWarehouse().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get Warehouse
export const getWarehouse = (params) => {
    return APIFunction.getWarehouse(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query  List Page And Group
export const queryListPageAndGroup = () => {
    return APIFunction.queryListPageAndGroup().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// create Warehouse
export const createWarehouse = (data) => {
    return APIFunction.createWarehouse(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update Warehouse
export const updateWarehouse = (data) => {
    return APIFunction.updateWarehouse(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Cities
export const queryCities = () => {
    return APIFunction.queryCities().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Districts
export const queryDistricts = () => {
    return APIFunction.queryDistricts().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Wards
export const queryWards = () => {
    return APIFunction.queryWards().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// query Customers
export const queryCustomers = () => {
    return APIFunction.queryCustomers().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get Customers
export const getCustomer = (params) => {
    return APIFunction.queryCustomer(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// create Customer
export const createCustomer = (data) => {
    return APIFunction.createCustomer(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// update Customer
export const updateCustomer = (data) => {
    return APIFunction.updateCustomer(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get Parent Comments
export const getParentComment = (params) => {
    return APIFunction.getParentComment(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get Child Comments
export const getChildComment = (params) => {
    return APIFunction.getChildComment(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get Child Comments
export const createComment = (params) => {
    return APIFunction.createComment(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}


// get country
export const listCountry = (params) => {
    return APIFunction.listCountry(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// post subscription mail
// create Customer
export const subMail = (data) => {
    return APIFunction.subMail(data).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}
