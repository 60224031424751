import React, { Component } from "react";
import Slider from "react-slick";
import { Container, Grid, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./../style";
import { compose } from "redux";
import ProductTile from "../../../../components/ProductTile";
import styled from "styled-components";
import AlertMessage from "../../../../components/UI/AlertMessage";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


const NextBtn = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid #eaeaea;
    border-radius: 100%;
    right: -15px;
    background: #fff;
    z-index: 100;
    top: calc(50% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
    @media (max-width: 1024px) {
      right: 0px;
    }
    &:hover{
      cursor: pointer;
      background-color: #f8f8f8;
      box-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
    }
`;

const PrevBtn = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    border: 1px solid #eaeaea;
    border-radius: 100%;
    left: -10px;
    background: #fff;
    z-index: 100;
    top: calc(50% - 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 30%);
    @media (max-width: 1024px) {
      left: 0px;
    }
    &:hover{
      cursor: pointer;
      background-color: #f8f8f8;
      box-shadow: 2px 2px 4px rgb(0 0 0 / 40%);
    }
`;

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <NextBtn onClick={onClick}>
      <NavigateNextIcon />
    </NextBtn>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <PrevBtn onClick={onClick}>
      <NavigateBeforeIcon />
    </PrevBtn>
  );
}

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  speed: 200,
  // autoplaySpeed: 2000,
  cssEase: "linear",
  draggable: false,
  arrows: true,
  pauseOnHover: true,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        draggable: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        draggable: false,
      },
    },
  ],
};

class RelatedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isChangeCart: false,
      open: false,
      sbColor: 'error',
      sbMessage: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.products !== this.props.products) {
      this.setState({
        ...this.state,
        products: this.props.products,
      });
    }
  }

  reloadListProduct = () => {
    this.setState(prevState => ({
      isChangeCart: !prevState.isChangeCart
    }));
  }

  showMessage = (info) => {
    this.setState({open: info.open, sbMessage: info.sbMessage, sbColor: info.sbColor})
  }

  handleAlertClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { products, classes, handleToggleFavorite  } = this.props;
    const { isChangeCart, open, sbColor, sbMessage } = this.state;
    return (
      <div style={{margin: '20px 0px 55px'}}>
        <h2 className={classes.subTitle}>Sản phẩm cùng loại</h2>
        {products && Array.isArray(products) && products.length >= 6 ? (
          <Slider {...settings} className={classes.sliderRelated}>
            {products.map((value, index) => (
              <div className={classes.fpGrid} key={index}>
                <ProductTile 
                  value={value}
                  reloadListProduct={() => this.reloadListProduct()}
                  isChangeCart={isChangeCart}
                  showMessage={(info) => this.showMessage(info)}
                  handleToggleFavorite={(id, type) => handleToggleFavorite(id, type)}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <Container maxWidth="xl">
            <Grid container spacing={5}>
              {products && Array.isArray(products) && products.length > 0 && products.map((value, index) => (
                <Grid
                  item
                  xs={12}
                  xl={2}
                  className={classes.fpGrid}
                  key={index}
                >
                  <ProductTile 
                  value={value}
                  reloadListProduct={() => this.reloadListProduct()}
                  isChangeCart={isChangeCart}
                  showMessage={(info) => this.showMessage(info)}
                  handleToggleFavorite={(id, type) => handleToggleFavorite(id, type)}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
         <AlertMessage
          open={open}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    changeQuantityItemCart: (payload) => {
      dispatch({ type: "CHANGE_QUANTITY_ITEM", payload });
    },
    deleteItemCart: (payload) => {
      dispatch({ type: "DELETE_ITEM_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(injectIntl(RelatedProduct));
