import request from './../utills/request'
import { apiPrefix } from './../utills/config'
import api from './api'

const gen = params => {
  let url = apiPrefix + params
  let method = 'GET'
  let headers = null;
  const paramsArray = params.split(' ')
  if (paramsArray.length === 2) {
    method = paramsArray[0]
    url = apiPrefix + paramsArray[1]
  }else if(paramsArray.length === 3){
    method = paramsArray[0]
    url = apiPrefix + paramsArray[1]
    headers = 'token'
  }



  return function(data) {
    return request({
      url,
      data,
      method,
      headers
    })
  }
}

const APIFunction = {}
for (const key in api) {
  APIFunction[key] = gen(api[key])
}

export default APIFunction
