import React, { Component } from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import styled from 'styled-components';
import { domain } from '../../../../utills/config';
import DefaultImage from './../../../../assets/images/default.jpg';

const BannerThumb = styled.div`
    padding: 5px 0px 0px 5px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
`;

class BannerProduct extends Component {
    render() {
        let { banner, isSke, title } = this.props;
        return (
            <React.Fragment>
                {
                    isSke ? (
                        <BannerThumb>
                            <Skeleton variant="text"
                                style={{
                                    display: 'block',
                                    transform: 'scale(1)',
                                    position: 'absolute',
                                    top: '5px',
                                    bottom: '10px',
                                    left: '0px',
                                    borderRadius: '0px',
                                    right: '0px',
                                    width: '100%'}} />
                        </BannerThumb>
                    ) : (
                        <BannerThumb>
                            <a style={{display: 'block'}} href={banner.link ? banner.link : domain}>
                            <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={banner.image ? banner.image : DefaultImage} alt={title} />
                            </a>
                     </BannerThumb>
                    )
                }
            </React.Fragment>
        );
    }
}

export default BannerProduct;