import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
// import { loadCSS } from 'fg-loadcss';
import React, { Component } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, withRouter } from "react-router-dom";
import iconGG from "./../../assets/images/icon-google.jpg";
import CustomButton from "./../../components/UI/Button";
import { login } from "./../../models/login";
import LocalStorageService from "./../../services/LocalStorageService";
import style from "./style";
import { compose } from "redux";
import { connect } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb";
import { setAuthAction } from "./../../redux/actions/authActions";
import { addCart, getListCart } from "../../models/cart";
import { apiDomain } from "../../utills/config";
import { LOGIN_SOCIAl_NETWORK } from "../../utills/constant";
import { Helmet } from 'react-helmet';

const localStorageService = LocalStorageService.getService();
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isOpen: false,
      sbMessage: "",
      classes: "",
      loading: false,
    };
  }

  async componentDidMount() {
    // Load Font awesome
    // loadCSS(
    // 	'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
    // 	document.querySelector('#font-awesome-css')
    // );

    if (localStorageService.getAccessToken()) {
      this.props.history.push("/");
    }

    let location = this.props.location.state;
    typeof location !== "undefined" && typeof location.location === "string"
    ? localStorageService.setLocation(location.location)
    : localStorageService.setLocation("/");
  }

  onSignIn = () => {
    let that = this;
    let { username, password } = this.state;
    this.setState({ loading: true });
    login(username, password)
      .then((res) => {
        if (res.success) {
          this.props.setAuth(res.access_token);
          return res.access_token;
        } else {
          that.setState({
            isOpen: true,
            sbMessage: "Email hoặc mật khẩu không đúng",
            loading: false,
          });
        }
      })
      .then((token) => {
        if (typeof token === "string") {
          this.handleAddToCart();
        }
      });
  };

  handleAddToCart = async () => {
    if(localStorageService.getCart() && JSON.parse(localStorageService.getCart()).length > 0){
      await addCart({ type: 1, carts: JSON.parse(localStorageService.getCart()) }).then(async(res) => {
        await getListCart({ type: 1 }).then(async (res) => {
          if (res.success) {
            this.props.clearListCart();
            localStorageService.clearCart();
				    localStorageService.clearVoucher();
				    localStorageService.clearDeliveryInfo();
            localStorageService.clearVoucherPoint();
            if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
              res.cart.forEach((value) => {
                let item = {
                  attribute_name1: value.attribute_name1,
                  attribute_name2: value.attribute_name2,
                  attribute_name3: value.attribute_name3,
                  attribute_name4: value.attribute_name4,
                  attribute_name5: value.attribute_name5,
                  attribute_value1: value.attribute_value1,
                  attribute_value2: value.attribute_value2,
                  attribute_value3: value.attribute_value3,
                  attribute_value4: value.attribute_value4,
                  attribute_value5: value.attribute_value5,
                  cart_id: value.cart_id,
                  inventory: Number(value.inventory),
                  image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                  product_attribute_value_id1: value.product_attribute_value_id1,
                  product_attribute_value_id2: value.product_attribute_value_id2,
                  product_attribute_value_id3: value.product_attribute_value_id3,
                  product_attribute_value_id4: value.product_attribute_value_id4,
                  product_attribute_value_id5: value.product_attribute_value_id5,
                  product_code: value.product_code,
                  product_id: value.product_id,
                  product_name: value.product_name,
                  quantity: Number(value.quantity),
                  sku_code: value.sku_code,
                  discount_price: (value.old_discount_price &&
                    typeof Number(value.old_discount_price) === "number"
                    && !isNaN(value.old_discount_price)
                    && Number(value.old_discount_price) > 0) ?
                    Number(value.old_discount_price) : 0,
                  price: (value.old_price
                    && typeof Number(value.old_price) === "number"
                    && !isNaN(value.old_price)
                    && Number(value.old_price) > 0) ?
                    Number(value.old_price) : 0,
                }

                if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
                  let exchange = res.exchange_rate[0];
                  if(exchange) {
                    item.isExchange = true;
                    item.symbol = exchange.symbol;
                    item.margin = exchange.margin;
                    item.rate = exchange.rate;
                    item.margin_type = exchange.margin_type;
                    if(exchange.margin_type === "increase") {
                      item.discount_price_exchange = (value.old_discount_price &&
                        typeof Number(value.old_discount_price) === "number"
                        && !isNaN(value.old_discount_price)
                        && Number(value.old_discount_price) > 0) ?
                        Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      item.price_exchange = (value.old_price
                        && typeof Number(value.old_price) === "number"
                        && !isNaN(value.old_price)
                        && Number(value.old_price) > 0) ?
                        Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    }else{
                      item.discount_price_exchange = (value.old_discount_price &&
                        typeof Number(value.old_discount_price) === "number"
                        && !isNaN(value.old_discount_price)
                        && Number(value.old_discount_price) > 0) ?
                        Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      item.price_exchange = (value.old_price
                        && typeof Number(value.old_price) === "number"
                        && !isNaN(value.old_price)
                        && Number(value.old_price) > 0) ?
                        Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    }
                  }
                }

                if(Number(value.inventory) < Number(value.quantity)){
                  item.error_inventory = true;
                }
                this.props.addToCart(item);
              });
            }
          }
        });
      let that = this;
      that.setState({
        isOpen: false,
        sbMessage: "",
        loading: false,
      });
      that.props.history.push(localStorageService.getLocation());
    });
    }else{
      await getListCart({ type: 1 }).then((res) => {
        if (res.success) {
          this.props.clearListCart();
          localStorageService.clearCart();
				  localStorageService.clearVoucher();
				  localStorageService.clearDeliveryInfo();
          localStorageService.clearVoucherPoint();
          if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
            res.cart.forEach((value) => {
              let item = {
                attribute_name1: value.attribute_name1,
                attribute_name2: value.attribute_name2,
                attribute_name3: value.attribute_name3,
                attribute_name4: value.attribute_name4,
                attribute_name5: value.attribute_name5,
                attribute_value1: value.attribute_value1,
                attribute_value2: value.attribute_value2,
                attribute_value3: value.attribute_value3,
                attribute_value4: value.attribute_value4,
                attribute_value5: value.attribute_value5,
                cart_id: value.cart_id,
                inventory: Number(value.inventory),
                image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                product_attribute_value_id1: value.product_attribute_value_id1,
                product_attribute_value_id2: value.product_attribute_value_id2,
                product_attribute_value_id3: value.product_attribute_value_id3,
                product_attribute_value_id4: value.product_attribute_value_id4,
                product_attribute_value_id5: value.product_attribute_value_id5,
                product_code: value.product_code,
                product_id: value.product_id,
                product_name: value.product_name,
                quantity: Number(value.quantity),
                sku_code: value.sku_code,
                discount_price: (value.old_discount_price &&
                  typeof Number(value.old_discount_price) === "number"
                  && !isNaN(value.old_discount_price)
                  && Number(value.old_discount_price) > 0) ?
                  Number(value.old_discount_price) : 0,
                price: (value.old_price
                  && typeof Number(value.old_price) === "number"
                  && !isNaN(value.old_price)
                  && Number(value.old_price) > 0) ?
                  Number(value.old_price) : 0,
              }
              if(Number(value.inventory) < Number(value.quantity)){
                item.error_inventory = true;
              }

              if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
                let exchange = res.exchange_rate[0];
                if(exchange) {
                  item.isExchange = true;
                  item.symbol = exchange.symbol;
                  item.margin = exchange.margin;
                  item.rate = exchange.rate;
                  item.margin_type = exchange.margin_type;
                  if(exchange.margin_type === "increase") {
                    item.discount_price_exchange = (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    item.price_exchange = (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  }else{
                    item.discount_price_exchange = (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    item.price_exchange = (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  }
                }
              }

              this.props.addToCart(item);
            });
          }
        }
      });
    let that = this;
    that.setState({
      isOpen: false,
      sbMessage: "",
      loading: false,
    });
    that.props.history.push(localStorageService.getLocation());
    }
  };

  render() {
    let { username, password, isOpen, sbMessage, loading } = this.state;

    const { classes: clsName, intl } = this.props;
    return (
      <React.Fragment>
      <Helmet>
          <title>Đăng nhập</title>
          <meta property="og:title" content={"Đăng nhập"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đăng nhập"}></meta>
          <meta name="description" content={"Đăng nhập"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb breadcrumb={[{ link: "/auth/login", name: "Đăng nhập" }]} />
        <section className={clsName.authPage}>
          <Typography
            variant="inherit"
            align="center"
            gutterBottom
            display="block"
            className={clsName.headingAuth}
          >
            <FormattedMessage id="Login"></FormattedMessage>
          </Typography>

          {isOpen && sbMessage && (
            <Typography
              variant="inherit"
              align="center"
              gutterBottom
              display="block"
              className={clsName.txtError}
            >
              {sbMessage}
            </Typography>
          )}

          <ValidatorForm
            ref="form"
            onSubmit={this.onSignIn}
            onError={() => {
              this.setState({ isOpen: false, sbMessage: "" });
            }}
          >
            <FormControl
              margin="normal"
              required
              fullWidth
              className={clsName.txtControl}
            >
              <label>
                Email <span className="txtDanger">*</span>
              </label>
              <TextValidator
                variant="outlined"
                onChange={(e) => this.setState({ username: e.target.value })}
                name="username"
                value={username}
                validators={["required", "isEmail"]}
                errorMessages={[
                  intl.formatMessage({ id: "Error.Required" }),
                  intl.formatMessage({ id: "Error.ValidEmail" }),
                ]}
                autoFocus
              />
            </FormControl>

            <FormControl
              margin="normal"
              required
              fullWidth
              className={clsName.txtControl}
            >
              <label>
                Mật khẩu <span className="txtDanger">*</span>
              </label>
              <TextValidator
                variant="outlined"
                onChange={(e) => this.setState({ password: e.target.value })}
                name="password"
                value={password}
                validators={["required"]}
                errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                type={"password"}
              />
            </FormControl>
            <CustomButton
              size="large"
              fullWidth
              variant="contained"
              type="submit"
              btnType="success"
              loading={loading}
              customClass={clsName.btnSubmit}
            >
              <FormattedMessage id="Login"></FormattedMessage>
            </CustomButton>
            <Box
              style={{ padding: "18px 0px 16px" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Link to="/auth/reset-password" className={clsName.txtSuccess}>
                <FormattedMessage id="Forgot password"></FormattedMessage>
              </Link>
            </Box>
            <Typography align="center" style={{ fontSize: "15px" }}>
              <FormattedMessage id="Not a registered user yet?"></FormattedMessage>{" "}
              <Link to="/auth/sign-up" className={clsName.txtSuccess}>
                <FormattedMessage id="Sign Up"></FormattedMessage>
              </Link>
            </Typography>
          </ValidatorForm>
          <div className={clsName.divider}>
            <FormattedMessage id="Or" />
          </div>
          <Grid container spacing={4}>
            <Grid item xl={6} sm={6} xs={6}>
              <a
                className={clsx(
                  clsName.mB10,
                  clsName.btnFb,
                  clsName.btnIconLeft
                )}
                href={apiDomain + LOGIN_SOCIAl_NETWORK + "facebook"}
              >
                <FacebookIcon
                  style={{ fontSize: "28px" }}
                />
              </a>
            </Grid>
            <Grid item xl={6} sm={6} xs={6}>
              <a
                className={clsx(clsName.btnGG, clsName.btnIconLeft)}
                href={apiDomain + LOGIN_SOCIAl_NETWORK + "google"}
              >
                <img
                  src={iconGG}
                  alt="Icon google"
                  className={clsName.iconGG}
                />
              </a>
            </Grid>
          </Grid>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    token: store.authReducer.token,
    listCartStore: store.cartReducer.listProduct,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setAuth: (token) => {
      dispatch(setAuthAction(token));
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(SignIn)));
