import React, { Component } from "react";
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  withStyles,
  Typography
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./../style";
import { compose } from "redux";
import { Skeleton } from "@material-ui/lab";
import { CURRENCY } from "../../../../utills/constant";
import NumberFormat from "react-number-format";
import styled from "styled-components";

const QuantityText = styled(NumberFormat)`
   width: 120px;
    max-width: 140px;
    height: 42px;
    border: 1px solid rgb(231, 232, 224)!important;
    text-align: center;
    font-size: 16px;
    border-radius: 0px!important;
    outline: none!important;
    box-shadow: none;
`;

class InformationProduct extends Component {
  render() {
    const {
      isSkeleton,
      name,
      inventory,
      // rating,
      price,
      quantity,
      setQuantityPurchase,
      addToCart,
      classes,
      buyNow,
      attribute,
      discount_percent,
      discount_price,
      handleAttrChange,
      isLoading1,
      isLoading2,
      multi_price,
      multi_discount_price,
      multi_discount_percent,
      isChoosed,
      blurQuantityPurChase,
      isExchange,
      rate,
      margin,
      margin_type,
      symbol
    } = this.props;

    return (
      <div className={classes.pdMain}>
        {!isSkeleton ? (
          <React.Fragment>
            <h1 className="pdName">{name}</h1>
            {/* <div className="rating">
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              this.setRating(newValue);
            }}
          />
        </div> */}
            <div className="info">
              <p className="price">
                  {
                    isExchange ? (
                      <React.Fragment>
                        {
                          margin_type === "increase" ? (
                            <React.Fragment>
                           {!isChoosed && multi_price && multi_price.length > 0 ? (
                  <React.Fragment>
                    {multi_discount_price &&
                    multi_discount_price.length > 0 &&
                    !multi_discount_price.includes(0) ? (
                      <React.Fragment>
                        <Typography component={'span'} variant={'body2'} className="price-sale">
                          {" "}
                          <NumberFormat
                            className="sale-rice"
                            value={Number((Math.round((multi_discount_price[0]*rate + margin/100*(multi_discount_price[0]*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                          {` - `}{" "}
                          <NumberFormat
                            className="sale-rice"
                            value={Number((Math.round((multi_discount_price[1]*rate + margin/100*(multi_discount_price[1]*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                        </Typography>
                        <Typography  component={'span'} variant={'body2'} style={{fontSize: '18px', display: 'block' }}>
                          <del className="cost">
                            <NumberFormat
                              value={Number((Math.round((multi_price[0]*rate + margin/100*(multi_price[0]*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </del>
                          {` - `}
                          <del className="cost">
                            <NumberFormat
                              className="item-price"
                              value={Number((Math.round((multi_price[1]*rate + margin/100*(multi_price[1]*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </del>
                          <span className="percent-promotion">{`(-${multi_discount_percent[0]}% -${multi_discount_percent[1]}%)`}</span>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((multi_price[0]*rate + margin/100*(multi_price[0]*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                        {` - `}
                        <NumberFormat
                          value={Number((Math.round((multi_price[1]*rate + margin/100*(multi_price[1]*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {typeof discount_price === "number" &&
                    !isNaN(discount_price) &&
                    discount_price > 0 ? (
                      <React.Fragment>
                        <NumberFormat
                          className="item-price"
                          value={Number((Math.round((discount_price*rate + margin/100*(discount_price*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                        <del className="cost">
                          <NumberFormat
                          className="item-price"
                            value={Number((Math.round((price*rate + margin/100*(price*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </del>
                      </React.Fragment>
                    ) : (
                      <NumberFormat
                        value={Number((Math.round((price*rate + margin/100*(price*rate))* 100) /100)).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={`${symbol} `}
                      />
                    )}
                    {typeof discount_percent === "number" &&
                      !isNaN(discount_percent) &&
                      discount_percent > 0 && (
                        <span className="percent-promotion">{`-${discount_percent}%`}</span>
                      )}
                  </React.Fragment>
                )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                           {!isChoosed && multi_price && multi_price.length > 0 ? (
                  <React.Fragment>
                    {multi_discount_price &&
                    multi_discount_price.length > 0 &&
                    !multi_discount_price.includes(0) ? (
                      <React.Fragment>
                        <Typography component={'span'} variant={'body2'} className="price-sale">
                          {" "}
                          <NumberFormat
                            className="sale-rice"
                            value={Number((Math.round((multi_discount_price[0]*rate - margin/100*(multi_discount_price[0]*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                          {` - `}{" "}
                          <NumberFormat
                            className="sale-rice"
                            value={Number((Math.round((multi_discount_price[1]*rate - margin/100*(multi_discount_price[1]*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                        </Typography>
                        <Typography  component={'span'} variant={'body2'} style={{fontSize: '18px', display: 'block' }}>
                          <del className="cost">
                            <NumberFormat
                              value={Number((Math.round((multi_price[0]*rate - margin/100*(multi_price[0]*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </del>
                          {` - `}
                          <del className="cost">
                            <NumberFormat
                              className="item-price"
                              value={Number((Math.round((multi_price[1]*rate - margin/100*(multi_price[1]*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </del>
                          <span className="percent-promotion">{`(-${multi_discount_percent[0]}% -${multi_discount_percent[1]}%)`}</span>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((multi_price[0]*rate - margin/100*(multi_price[0]*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                        {` - `}
                        <NumberFormat
                          value={Number((Math.round((multi_price[1]*rate - margin/100*(multi_price[1]*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {typeof discount_price === "number" &&
                    !isNaN(discount_price) &&
                    discount_price > 0 ? (
                      <React.Fragment>
                        <NumberFormat
                          className="item-price"
                          value={Number((Math.round((discount_price*rate - margin/100*(discount_price*rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${symbol} `}
                        />
                        <del className="cost">
                          <NumberFormat
                          className="item-price"
                            value={Number((Math.round((price*rate - margin/100*(price*rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </del>
                      </React.Fragment>
                    ) : (
                      <NumberFormat
                        value={Number((Math.round((price*rate - margin/100*(price*rate))* 100) /100)).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={`${symbol} `}
                      />
                    )}
                    {typeof discount_percent === "number" &&
                      !isNaN(discount_percent) &&
                      discount_percent > 0 && (
                        <span className="percent-promotion">{`-${discount_percent}%`}</span>
                      )}
                  </React.Fragment>
                )}
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                      {!isChoosed && multi_price && multi_price.length > 0 ? (
                        <React.Fragment>
                          {multi_discount_price &&
                          multi_discount_price.length > 0 &&
                          !multi_discount_price.includes(0) ? (
                            <React.Fragment>
                              <Typography component={'span'} variant={'body2'} className="price-sale">
                                {" "}
                                <NumberFormat
                                  className="sale-rice"
                                  value={multi_discount_price[0]}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                                {` - `}{" "}
                                <NumberFormat
                                  className="sale-rice"
                                  value={multi_discount_price[1]}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                              </Typography>
                              <Typography  component={'span'} variant={'body2'} style={{fontSize: '18px', display: 'block' }}>
                                <del className="cost">
                                  <NumberFormat
                                    value={multi_price[0]}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </del>
                                {` - `}
                                <del className="cost">
                                  <NumberFormat
                                    className="item-price"
                                    value={multi_price[1]}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </del>
                                <span className="percent-promotion">{`(-${multi_discount_percent[0]}% -${multi_discount_percent[1]}%)`}</span>
                              </Typography>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <NumberFormat
                                value={multi_price[0]}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${CURRENCY}`}
                              />
                              {` - `}
                              <NumberFormat
                                value={multi_price[1]}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${CURRENCY}`}
                              />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {typeof discount_price === "number" &&
                          !isNaN(discount_price) &&
                          discount_price > 0 ? (
                            <React.Fragment>
                              <NumberFormat
                                className="item-price"
                                value={discount_price}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${CURRENCY}`}
                              />
                              <del className="cost">
                                <NumberFormat
                                className="item-price"
                                  value={price}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                              </del>
                            </React.Fragment>
                          ) : (
                            <NumberFormat
                              value={price}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          )}
                          {typeof discount_percent === "number" &&
                            !isNaN(discount_percent) &&
                            discount_percent > 0 && (
                              <span className="percent-promotion">{`-${discount_percent}%`}</span>
                            )}
                        </React.Fragment>
                      )}
                      </React.Fragment>
                    )
                  }
              </p>
              {/* <p className="allocation">{inventory  ? ('Còn hàng') : ('Hết hàng') }</p> */}
              {/* <div className="promotion">
            <p>
              <span>
                <CheckIcon />
              </span>{" "}
              Giảm ngay 10% khi mua 5 thùng mì bất kì
            </p>
            <p>
              <span>
                <CheckIcon />
              </span>{" "}
              Tặng ngay dụng cụ trụng bún khi mua từ 10 thùng
            </p>
          </div> */}

             
              <div className="fpVariation">
                {attribute && Array.isArray(attribute) && attribute.length > 0 &&
                  attribute.map((valueAttr, indexAttr) => (
                    <FormControl
                      key={indexAttr}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <label>{valueAttr.name}</label>
                      <Select
                        name={valueAttr.name}
                        value={valueAttr.value}
                        onChange={(e) => handleAttrChange(e, indexAttr)}
                      >
                        {valueAttr.values.map((subAttr, index) => (
                          <MenuItem
                            className={classes.menuItem}
                            key={index}
                            value={subAttr.id}
                          >
                            {subAttr.value}
                          </MenuItem>
                        ))}
                      </Select>
                      {valueAttr.error && (<span className="error">{valueAttr.error}</span>)}
                    </FormControl>
                  ))}
                <FormControl className={classes.formControl}>
                  <label>Số lượng</label>
                  <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                  <Button
                  className="btnQuantity"
            disabled={Number(quantity) <= 1 ? true : false}
            onClick={() => {setQuantityPurchase(1, 'minus')}}
          >
            -
          </Button>
                  <QuantityText
                    id="quantity"
                    value={quantity}
                    variant="outlined"
                    onChange={(e) => {
                      setQuantityPurchase(e.target.value, "other");
                    }}
                    onBlur={(e) => {
                      blurQuantityPurChase(e.target.value)
                    }
                    }
                  />
              <Button className="btnQuantity btnPlus" disabled={Number(quantity) >= Number(inventory) ? true : false} onClick={() => {setQuantityPurchase(1, 'plus')}}>+</Button>
                  </div>
                </FormControl>
              </div>
              <div className="btnGroup">
                <Button
                  onClick={() => addToCart()}
                  variant="contained"
                  fullWidth={true}
                  className="btnAddToCart"
                  disabled={
                    quantity <= 0 || isLoading1 || isLoading2 ? true : false
                  }
                >
                  {isLoading1 ? (
                    <CircularProgress size={18} color="secondary" />
                  ) : (
                    <React.Fragment>Thêm vào giỏ hàng</React.Fragment>
                  )}
                </Button>
                <Button
                  onClick={() => buyNow()}
                  variant="contained"
                  fullWidth={true}
                  className="btnCheckout"
                  disabled={
                    quantity <= 0 || isLoading1 || isLoading2 ? true : false
                  }
                >
                  {isLoading2 ? (
                    <CircularProgress size={18} color="secondary" />
                  ) : (
                    <React.Fragment>Mua ngay</React.Fragment>
                  )}
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton
              variant="rect"
              height={40}
              style={{ marginBottom: "30px" }}
            />
            <div className="info">
              <p className="price" style={{ display: "flex" }}>
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  style={{ marginRight: "10px" }}
                />
              </p>
              <div
                className="fpVariation"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  style={{ marginRight: "10px" }}
                />
                <Skeleton
                  variant="rect"
                  width={100}
                  height={40}
                  style={{ marginRight: "10px" }}
                />
              </div>
              <div className="btnGroup">
                <Skeleton
                  variant="rect"
                  height={48}
                  style={{ marginBottom: "12px" }}
                />
                <Skeleton
                  variant="rect"
                  height={48}
                  style={{ marginBottom: "10px" }}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(injectIntl(InformationProduct));
