import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Skeleton from "@material-ui/lab/Skeleton";


class SkeletonLoading extends Component {
  render() {
    const {
      classes,
    } = this.props;
    return (
        <div className={classes.mobileCart}>
          <div className="mobileCartItem">
            <div className="mobileCartHead">
              <div className="field-name">
                <div className="thumb">
                <Skeleton variant="rect" width={210} height={210} />
                </div>
                <div className="info-product">
                  <p className="name-product">
                  <Skeleton variant="text" width={180} />
                  </p>
                  <p className="attribute-product">
                  <Skeleton variant="text" width={180} />
                  </p>
                  <Skeleton variant="rect" width={120} height={48} />
                </div>
              </div>
            </div>
            <div className="mobileCartBody">
              <div className="mobileCartInfo">
                <p><Skeleton variant="text"  width={80} /></p>
                <Skeleton variant="text"  width={80} />
              </div>
              <div className="mobileCartInfo">
                <p><Skeleton variant="text"  width={80} /></p>
                <Skeleton variant="text"  width={80} />
              </div>
              <div className="mobileCartInfo">
              <p><Skeleton variant="text"  width={80} /></p>
                <Skeleton variant="text"  width={80} />
              </div>
            </div>
          </div>
        <p className="totalPrice">
          <span
          >
           <Skeleton variant="text" />
          </span>
          <Skeleton variant="text" />
        </p>
        <div className="boxFooterTable">
        <Skeleton variant="rect" height={48} style={{width: '100%', marginBottom: '15px'}} />
        <Skeleton variant="rect" height={48} style={{width: '100%'}} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownDispatch) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(SkeletonLoading)));
