const style = (theme) => ({
  banner: {
    width: "100%",
    overflow: "hidden",
    "& .banner-desktop": {
        display: 'block',
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      }
    },
    "& .banner-mobile": {
      display: 'none',
      [theme.breakpoints.down("sm")]: {
        display: 'block'
      }
    },
    "& .bannerItem": {
      width: "100%",
      overflow: "hidden",
      "& .bannerLink": {
        display: "block",
        width: "100%",
        overflow: "hidden",
        "& img": {
          maxWidth: "100%",
          width: '100%',
          display: "block",
          margin: "0px auto",
        },
      },
    },
  },
  fp: {
    background: "#fff",
    padding: '25px 0px',
    "& .fpGrid": {
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        padding: '5px'
      }
    },
    [theme.breakpoints.down("sm")]: {
      background: '#f6f6f6',
      padding: '0px',
      paddingBottom: "35px",
    }
  },

  titleHomeMobile: {
    display: 'none',
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      marginBottom: '15px',
    },
    "& h2": {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      "& span": {
        display: 'inline-block',
        marginLeft: '7px'
      }
    }
  },
  titleHome: {
    marginBottom: "20px",
    overflow: "hidden",
    position: 'relative',
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
    "& h2": {
        width: '385px',
        fontSize: "24px",
        position: "relative",
        display: "inline-block",
        fontWeight: "400",
        paddingLeft: '50px',
        textTransform: "capitalize",
        letterSpacing: "3px",
        margin: "0px -30px",
        zIndex: '10',
        background: '#f2c123',
        height: '45px',
        color: '#fff',
        transform: 'skew(50deg, 0deg)',
        [theme.breakpoints.down("md")]: {
          fontSize: "15px",
          width: '305px',
        },
        '& span': {
          transform: 'skew(-50deg, 0deg)',
          display: 'block',
          lineHeight: '45px'
        },
      "&::before": {
        width: "60px",
        content: '""',
        height: "45px",
        background: "#fff",
        position: "absolute",
        top: "0",
        left: "-25px",
        display: 'block',
        transform: 'skew(-50deg, 0deg)',
      }
    },
      '& .title-sp': {
        background: '#f8f9fa',
        width: '100%',
        position: 'absolute',
        right: '5px',
        bottom: '0px',
        height: '35px',
        zIndex: '2',
      }
  },
  bannerSkeleton: {
    height: "543px",
    [theme.breakpoints.down("xl")]: {
      height: "434px",
    },
    [theme.breakpoints.down("md")]: {
      height: "360px",
    },
  },
  bannerSkeletonMobile: {
    height: "543px",
    [theme.breakpoints.down("sm")]: {
      height: "264px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "148px",
    },
  },
  mb: {
    margin: "25px 0px 35px",
    height: "235px",
    background: '#fff',
    [theme.breakpoints.down("sm")]: {
      margin: '0px',
      height: "435px",
      background: '#f6f6f6'
    },
    "& .title-middle-banner": {
      height: '181px',
      background: "#cc2d26",
      [theme.breakpoints.down("sm")]: {
        borderRadius: '30px 30px 0px 0px',
        height: "120px",
      },
    },
    "& .doi-tac": {
      color: "#fff",
      position: 'relative',
      top: '35px',
      fontSize: '24px',
      left: '5px',
      [theme.breakpoints.down("sm")]: {
        top: '20px',
        left: '0px',
        right: '0px',
        textAlign: 'center'
      },
    },
    "& .mbContainer": {
      display: "flex",
      gap: "15px",
      "& .mbThumb": {
        width: "100%",
        overflow: "hidden",
        position: "relative",
        padding: '0px 5px',
        top: '-90px',
        [theme.breakpoints.down("sm")]: {
          top: '-35px',
        },
        "& .mbOverlay": {
          border: '1px solid #e8e8e8',
          background: '#fff',
          height: '145px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          "& a": {
            display: 'flex'
          }
        },
        "& .mbTitle": {
          position: "absolute",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          textTransform: "uppercase",
          // fontFamily: "Aleo",
          fontWeight: "500",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& span": {
            display: "inline-block",
          },
        },
        "& .mbLink": {
          width: "100%",
          display: "block",
        },
      },
    },
  },
  infoProduct: {
    marginBottom: "50px",
  },
  btnViewAll : {
    background: "#fff",
    color: "#f2c123",
    textTransform: "uppercase",
    border: "1px solid #f2c123",
    margin: '15px auto',
    marginBottom: "0px",
    borderRadius: "30px",
    height: "48px",
    width: '180px',
    fontSize: "0.875rem",
    fontWeight: "500",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fpMain: {
    "& .fpItemProduct": {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column'
      }
    },
    "& .banner-product": {
      width: '20%',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
      },
    },
    "& .for-mobile": {
      display: 'none'
    },
    [theme.breakpoints.down("sm")]: {
      "& .for-mobile": {
        display: 'block'
      },
      "& .for-desktop": {
        display: 'none'
      }
    },
    "& .list-product": {
      width: '80%',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        position: 'relative',
        top: '-23px'
      }
    }
  }
});

export default style;
