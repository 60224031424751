import React, { Component } from 'react';
import { Skeleton } from "@material-ui/lab";
import styled from 'styled-components';

const SkeletonImage = styled(Skeleton)`
  height: 231px;
  @media (max-width: 1024px){
    height: 180px;
  }
  @media (max-width: 768px){
    height: 239px;
  }
  @media (max-width: 414px){
    height: 191px;
  }
  @media (max-width: 375px){
    height: 178px;
  }
  @media (max-width: 360px){
    height: 170px;
  }
  @media (max-width: 320px){
    height: 150px;
  }

`;
class ProductTileLoading extends Component {
    render() {
        return (
            <div className="fpItem" style={{border: '1px solid #e8e8e8'}}>
                          <SkeletonImage
                            animation="wave"
                            variant="rect"
                            style={{ marginBottom: 6 }}
                          />
                          <Skeleton
                            animation="wave"
                            height={20}
                            width={120}
                            style={{margin: '10px auto'}}
                          />
                          <Skeleton
                            animation="wave"
                            height={20}
                            width={80}
                            style={{margin: '10px auto 20px' }}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={36}
                            style={{ marginBottom: '20px' }}
                          />
                        </div>
        );
    }
}

export default ProductTileLoading;