const styles = (theme) => ({
    root: {
        borderRadius: '0px'
    },
    blogTileItem: {
        position: 'relative',
        overflow: 'hidden',
        fontFamily: 'Roboto',
        marginBottom: '30px',
        "& .blogTile_thumb": {
            overflow: 'hidden',
            width: '100%',
            display: 'block',
            "& img": {
                objectFit: 'cover!important'
            }
        },
        "&:hover": {
            boxShadow: 'rgb(153 153 153 / 60%) 0px 0px 10px 0px',
            cursor: 'pointer'
        },
        "& .blogTile_viewBlog": {
            position: 'absolute',
            display: 'block',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px'
        },
        "& .blogTile_info": {
            padding: ' 10px 15px',
            [theme.breakpoints.down("md")]: {
                padding: "10px 5px",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "10px 0px",
            },
            "& .blogTile_title": {
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '10px',
                lineHeight: '25px',
                height: '50px',
                overflow: 'hidden',
                textTransform: 'capitalize'
            },
            "& .descriptionShort": {
                maxHeight: '37px',
                minHeight: '37px',
                marginBottom: '15px'
            }
        }
    }
});

export default styles;