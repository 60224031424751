module.exports = {
  // apiDomain: 'https://api-eshop.fourwals.com',
  apiDomain: 'https://api.taphoa.cz',
  // apiDomain: 'http://localhost/shopbox_backend/public',
  // apiDomain: 'http://local.shopbox-backend.vn',
  dateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
  dateFormat: 'dd/MM/yyyy',
  apiPrefix: '',
  dateTimeHHMMFormat: 'DD/MM/YYYY HH:mm',
  reCaptchaKey: '6LfTbtsUAAAAAO4w46Z75QUh7fsQPJ24CYYSCcPA'
}
