import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { withStyles, Container, Grid, CircularProgress } from "@material-ui/core";
import Breadcrumb from "./../../../components/Breadcrumb";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from 'react-helmet';
import styled from "styled-components";
import CategoryProduct from "../../../components/CategoryProduct";
import AlertMessage from "../../../components/UI/AlertMessage";
import ProductTileLoading from "../../../components/UI/ProductTileLoading";
import LocalStorageService from "../../../services/LocalStorageService";
import { apiDomain } from "../../../utills/config";
import { queryPostList } from "../../../models";
import BlogTile from "./components/BlogTile";
import NotFoundPost from "../../../components/NotFoundPost";
import PostTileLoading from "../../../components/UI/PostTileLoading";

const localStorageService = LocalStorageService.getService();

const Progress = styled(CircularProgress)`
  color: #c7c7c7 !important;
`;

class ListBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "",
      listBlog: [],
      page: 1,
      page_size: 12,
      page_count: 0,
      breadcrumb: [],
      hasMore: true,
      isFetch: false,
      current_datetime: '',
      isChangeCart: false,
      open: false,
      sbColor: 'error',
      sbMessage: ''
    };
  }

  componentDidMount() {
    this.setState({
      breadcrumb: [{ link: '/blog', name: "Blog" }],
    });
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }
    const { page, page_size } = this.state;
    let params = {
      page,
      page_size,
    };
    this.handleGetListPost(params);
  }

  handleGetListPost(params) {
    this.setState({ isFetch: false });
    const { page, page_count, sort, listBlog, current_datetime } = this.state;
    if (page > page_count && page_count !== 0) {
      this.setState({ hasMore: false });
      return;
    } else {
      let sortProducts = sort.split(" ");
      if (sortProducts.length > 0) {
        params.sort_name = sortProducts[0];
        params.sort_type = sortProducts[1];
      }
      if(current_datetime){
        params.created_at = current_datetime;
      }else{
        params.is_first = 1;
      }
      queryPostList(params).then((res) => {
        if (res.success) {
          if(res.current_datetime){
            this.setState({current_datetime: res.current_datetime});
          }
          const products = res._embedded.post;
          const list_products = [...listBlog];
          const result = products.map((value) => {
            if (value.created_at) {
              let created_at = value.created_at.split(" ");
              let created_at_date = created_at[0].split("/");
              let created_at_format = `${created_at_date[2]}-${created_at_date[1]}-${created_at_date[0]}`;
              if (moment().diff(created_at_format, "days") === 0) {
                value.isNew = true;
              }
            }
            return value;
          });
          let products_tmp = [...list_products, ...result];
          this.setState({
            ...this.state,
            listBlog: products_tmp,
            page_count: res.page_count,
            page: page <= res.page_count ? page + 1 : page,
          });
          if (res.total_items === 0 || res._embedded.post.length === res.total_items  ||
            products_tmp.length === res.total_items) {
            this.setState({ hasMore: false });
          }
          this.setState({ isFetch: true });
        }
      });
    }
  }

  showLoading = () => {
    const { page_size } = this.state;
    let xhtml = [];
    for (let i = 0; i < page_size; i++) {
      xhtml.push(
        <Grid item md={4} lg={4} xs={12} sm={6} key={i} className="fpGrid">
          <PostTileLoading />
        </Grid>
      );
    }
    return xhtml;
  };

  nextData = () => {
    const { page, page_size } = this.state;
    let params = {
      page,
      page_size,
    };
    this.handleGetListPost(params);
  };

  showMessage = (info) => {
    this.setState({open: info.open, sbMessage: info.sbMessage, sbColor: info.sbColor})
  }

  handleAlertClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      listBlog,
      isFetch,
      breadcrumb,
      open,
      hasMore,
      sbColor,
      sbMessage
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
       <Helmet>
          <title>Blog</title>
          <meta property="og:title" content="Blog"></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content="Blog"></meta>
          <meta name="description" content="Blog"></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <CategoryProduct />
        <Breadcrumb breadcrumb={breadcrumb} />
        <section id="list-product-page" className={classes.listProductPage}>
          <Container maxWidth="xl">
            <div className={classes.fp}>
              <div className={classes.fpMain}>
                <InfiniteScroll
                  dataLength={listBlog.length}
                  next={this.nextData}
                  hasMore={hasMore}
                  className={classes.infinite}
                  loader={
                    <React.Fragment>
                      <Grid container spacing={2}>
                        {this.showLoading()}
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          style={{ textAlign: "center" }}
                        >
                          <h3>
                            <Progress size={30} color="primary" />
                          </h3>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  scrollThreshold={0.5}
                >
                  <Grid container spacing={2}>
                    {listBlog.length > 0 ? (
                      <React.Fragment>
                        {listBlog.map((value, index) => (
                          <Grid
                            item
                            md={4}
                            lg={4}
                            xs={12}
                            sm={6}
                            key={index}
                            className="fpGrid"
                          >
                            <BlogTile
                              value={value}
                              key={index}
                              showMessage={(info) => this.showMessage(info)}
                              />
                          </Grid>
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {isFetch && (
                          <Grid item xl={12} sm={12}>
                            <NotFoundPost />
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                </InfiniteScroll>
              </div>
            </div>
          </Container>
        </section>
        <AlertMessage
          open={open}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ListBlog)));