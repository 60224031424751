
import APIFunction from '../services';


export const getListCart = (params) => {
    return APIFunction.getListCart(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const addCart = (params) => {
    return APIFunction.addCart(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const updateCart = (params) => {
    return APIFunction.updateCart(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const getDeliveryService = (params) => {
    return APIFunction.getDeliveryService(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const createOrder = (params) => {
    return APIFunction.createOrder(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const getListOrder = (params) => {
    return APIFunction.getListOrder(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const queryOrder = (params) => {
    return APIFunction.queryOrder(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const getStatus = (params) => {
    return APIFunction.getStatus(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const checkProductCart = (params) => {
    return APIFunction.checkProductCart(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const getVoucherInfo = (params) => {
    return APIFunction.getVoucherInfo(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const checkExchangeRate = (params) => {
    return APIFunction.checkExchangeRate(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}