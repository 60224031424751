import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FormControl,
  Typography,
  Box,
  withStyles,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import LocalStorageService from "./../../services/LocalStorageService";
import { FormattedMessage, injectIntl } from 'react-intl';
import style from "./style";
import { checkTokenVaid, registerNewPassword } from "./../../models/user";
import CustomButton from './../../components/UI/Button';
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../utills/config";

const localStorageService = LocalStorageService.getService();
class RegisterNewPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirm_password: '',
      isOpen: false,
      sbColor: 'error',
      sbMessage: '',
      classes: '',
      loading: false,
      isValid: false,
      token: ''
    };
  }

  componentWillMount() {
    // custom rule will have name ''
    ValidatorForm.addValidationRule('isNotEqual', (value) => {
      if (this.state.password && this.state.confirm_password && (this.state.password !== this.state.confirm_password)) {
        return false;
      }
      return true;
    });
  }

  componentDidMount() {
    if (localStorageService.getAccessToken()) {
      this.props.history.push('/')
    }

    const { intl } = this.props;

    this.setState({ loading: true });

    if (this.props.location.search && this.props.location.search.includes('?token=')) {
      let temp = this.props.location.search.replace('?token=', '');
      this.setState({ token: temp });
      checkTokenVaid(temp).then((res) => {
        if (res.success) {
          this.setState({ isValid: true, loading: false });
        } else {
          this.setState({ 
            isOpen: true, 
            sbColor: 'error', 
            sbMessage: intl.formatMessage({ id: 'Sorry, this change password link is not valid' }), 
            isValid: false,
            loading: false 
          });
        }
      })

    } else {
      this.setState({ 
        isOpen: true, 
        sbColor: 'error', 
        sbMessage: intl.formatMessage({ id: 'Sorry, this change password link is not valid' }), 
        loading: false 
      });
    }

  }

  onUpdatePassword = () => {
    let that = this;
    const { password, token } = this.state;
    const { intl } = this.props;

    this.setState({
      loading: true
    })

    registerNewPassword(token, password).then((res) => {
      if (res.success) {
        that.setState({
          isOpen: true,
          sbColor: 'success',
          sbMessage: intl.formatMessage({ id: 'Your password has been created successfully' }),
          loading: false
        });
      } else {
        that.setState({
          isOpen: true,
          sbColor: 'error',
          sbMessage: intl.formatMessage({ id: 'Your password has been created unsuccessfully'}),
          loading: false
        });
      }
    });

  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpen: false });
  };

  render() {
    let { isOpen, sbMessage, sbColor, password, confirm_password, loading, isValid } = this.state;
    const { classes: clsName, intl } = this.props;

    return (
      <React.Fragment>
         <Helmet>
          <title>Đổi mật khẩu</title>
          <meta property="og:title" content={"Đổi mật khẩu"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đổi mật khẩu"}></meta>
          <meta name="description" content={"Đổi mật khẩu"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
      <section className={clsName.authPage}>
        <Typography
          component="h1"
          variant="h1"
          align="center"
          gutterBottom
          className={clsName.headingAuth}
        >
         <FormattedMessage id="Register new password" ></FormattedMessage>
        </Typography>

        {isOpen && sbMessage && (
            <Typography
              variant="inherit"
              align="center"
              gutterBottom
              display="block"
              className={sbColor === "error" ? clsName.txtError  : clsName.txtSuccess}
            >
              {sbMessage}
            </Typography>
          )}

        <ValidatorForm
          ref="form"
          onSubmit={this.onUpdatePassword}
          onError={() => { this.setState({ isOpen: false, sbMessage: '' }) }}
        >
            <FormControl
              margin="normal"
              required
              fullWidth
              className={clsName.txtControl}
            >
              <label>Mật khẩu</label>
              <TextValidator
                onChange={(e) => this.setState({ password: e.target.value })}
                name="password"
                value={password}
                validators={["required"]}
                errorMessages={[intl.formatMessage({ id: 'Error.Required' })]}
                type={"password"}
                variant="outlined"
                inputProps={{
                  maxLength: 255,
                }}
                disabled = {!isValid}
              />
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              className={clsName.txtControl}
            >
              <label>Xác nhận mật khẩu</label>
              <TextValidator
                onChange={(e) => this.setState({ confirm_password: e.target.value })}
                name="confirm_password"
                value={confirm_password}
                validators={["required", "isNotEqual"]}
                errorMessages={[intl.formatMessage({ id: 'Error.Required' }), intl.formatMessage({ id: 'Error.ConfirmPassword' })]}
                type={"password"}
                variant="outlined"
                inputProps={{
                  maxLength: 255
                }}
                disabled = {!isValid}
              />
            </FormControl>
            <CustomButton
              size='large'
              fullWidth
              variant='contained'
              type='submit'
              btnType='success'
              loading={loading}
              customClass={clsName.btnSubmit}>
              Xác nhận
					  </CustomButton>
            <Box
              style={{ padding: "18px 0px 16px" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
            <Typography align="center">
              <Link to="/auth/sign-in" className={clsName.txtSuccess}>
                <FormattedMessage id="Back to Login" ></FormattedMessage>
              </Link>
            </Typography>
            </Box>

        </ValidatorForm>

      </section >
      </React.Fragment>
    );
  }
}

export default withStyles(style)(injectIntl(RegisterNewPassword));
