const style = (theme) => ({
    fpItem: {
        border: "1px solid #e8e8e8",
        position: 'relative',
        background: '#fff',
      "&:hover": {
        boxShadow: 'rgb(153 153 153 / 60%) 0px 0px 10px 0px',
        // transition: 'all 1s',
        cursor: "pointer",
        "& .fpThumb .fpBoxBtn .fpBtn": {
          display: "flex",
        },
        "& .fpOverlay.fpBoxBtn": {
            display: 'flex'
        },
        "& .fpBtn": {
          display: 'flex'
        }
      },

      "& .fpThumb": {
        position: "relative",
        width: "100%",
        overflow: "hidden",
        textAlign: "center",
        marginBottom: "8px",
        aspectRatio: "1",
        "& .label-sale-off":{
          fontSize: '13px',
          position: 'absolute',
          color: '#fff',
          width: '38px',
          height: '38px',
          lineHeight: '38px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '4px',
          left: '3px',
          zIndex: '10'
        },
        "& a": {
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down'
          }
        },
      },
      "& .fpInfo": {
        textAlign: "center",
        padding: '10px 10px 20px 10px',
        "& .fpName": {
          fontSize: "15px",
          lineHeight: "20px",
          marginBottom: "7px",
          maxHeight: '40px',
          minHeight: '40px',
          overflow: 'hidden',
          "& a": {
            color: "#333333",
            "&:hover": {
              color: "#333333",
              fontWeight: "500",
            },
          },
        },
        "& .fpPrice": {
          color: "#333333",
          fontSize: "15px",
          marginBottom: '15px'
        },
        "& .fpBtn": {
          display: "block",
          background: "#f2c123",
          zIndex: '10',
          transition: "all 0.1s",
          height: '36px',
          transform: 'scale(1)!important',
          borderRadius: '22px',
          color: '#fff',
          border: '1px solid #f2c123',
          position: 'relative',
          width: '150px',
          [theme.breakpoints.down("sm")]: {
            width: '120px'
          },
          "& span.textButtonCart": {
            color: "#fff",
            position: 'absolute',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            fontSize: '15px',
            lineHeight: '36px',
            fontWeight: '600',
            textTransform: 'capitalize',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "& .label-btn-buy": {
              marginLeft: '15px',
              display: 'inline-block',
              [theme.breakpoints.down("sm")]: {
                marginLeft: '5px'
              }
            }
          },
          "&:hover": {
            background: "#fff",
            '& span': {
              color: '#f2c123',
            }
          },
          "&:disabled": {
            cursor: 'auto',
            opacity: '60%'
          }
        },
      },
    },
    bottomTile: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // '& p': {
      //   width: '150px',
      //   [theme.breakpoints.down("sm")]: {
      //     width: '120px'
      //   }
      // }
      "& .span-favorite": {
        display: 'flex',
        zIndex: '10',
        padding: '10px'
      }
    }
});

export default style;
