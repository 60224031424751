import * as types from '../constants';
import LocalStorageService from "./../../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();
export function setLanguageAction(value) {
  // set language to localstorage
  localStorageService.setLanguage(value);
  return {
    type: types.SET_LANGUAGE,
    payload: value
  }

}
