import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  FormControl,
  Typography,
  withStyles,
  Grid,
  Box,
  MenuItem,
  TextField,
  FormControlLabel,
  Select as SelectMui,
  Checkbox,
} from "@material-ui/core";
import LocalStorageService from "./../../services/LocalStorageService";
import style from "./style";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { apiDomain, reCaptchaKey } from "./../../utills/config";
import ReCAPTCHA from "react-google-recaptcha";
import { createUser } from "./../../models/user";
import CustomButton from "./../../components/UI/Button";
import { listCountry } from "../../models";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
} from "react-places-autocomplete";
import { Helmet } from 'react-helmet';

const localStorageService = LocalStorageService.getService();

const GreenCheckbox = styled(Checkbox)`
  color: #f2c123;
`;

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: "",
      email: "",
      password: "",
      phone: "",
      confirm_password: "",
      isOpen: false,
      classes: "",
      sbColor: "",
      sbMessage: "",
      loading: false,
      isCheck: false,
      delivery_person_name: "",
      delivery_address: "",
      delivery_postcode: "",
      delivery_city: "",
      delivery_country_id: 0,
      delivery_prefix: "",
      delivery_phone: "",
      listCountry1: [],
      listCountry2: [],
      listCountry: [],
      country_selected1: null,
      country_selected2: null,
      isAcceptPolicy: false,
      phone_code: "",
      colorError: false
    };
  }

  componentWillMount() {
    // custom rule will have name ''
    ValidatorForm.addValidationRule("isNotEqual", (value) => {
      if (
        this.state.password &&
        this.state.confirm_password &&
        this.state.password !== this.state.confirm_password
      ) {
        return false;
      }
      return true;
    });
  }

  componentDidMount() {
    if (localStorageService.getAccessToken()) {
      this.props.history.push("/");
    }
    this.fetchListCountry();
  }

  fetchListCountry() {
    listCountry({}).then((res) => {
      if (res.success) {
        let result1 = [];
        let result2 = [];
        const country = res._embedded.country;
        country && Array.isArray(country) && country.length > 0 && country.forEach((value) => {
          let item1 = {
            value: value.phone_code,
            label: `+${value.phone_code} (${value.name})`,
          };
          result1.push(item1);

          let item2 = {
            value: value.id,
            label: value.name,
          };
          result2.push(item2);
        });

        this.setState({
          ...this.state,
          listCountry1: result1,
          listCountry2: result2,
          listCountry: country && Array.isArray(country) && country.length > 0 ? country : [],
        });
      }
    });
  }

  handleAlertClose = () => {
    this.setState({ isOpen: false });
  };

  onSignUp = () => {
    let that = this;
    const { intl } = this.props;

    const {
      fullname,
      email,
      password,
      phone,
      isCheck,
      isAcceptPolicy,
      delivery_person_name,
      delivery_address,
      delivery_postcode,
      delivery_city,
      delivery_country_id,
      delivery_prefix,
      delivery_phone,
      phone_code,
      country_selected1
    } = this.state;

    if (!country_selected1) {
        this.setState({colorError: true});
        return;
    }

    if (isCheck && isAcceptPolicy) {
      this.setState({ loading: true });

      let params = {
        fullname,
        email,
        phone: phone_code + " " + phone,
        password,
      };

      if (
        delivery_person_name &&
        delivery_postcode &&
        delivery_address &&
        delivery_city &&
        delivery_country_id &&
        delivery_prefix &&
        delivery_phone
      ) {
        params.delivery_person_name = delivery_person_name;
        params.delivery_postcode = delivery_postcode;
        params.delivery_address = delivery_address;
        params.delivery_country_id = delivery_country_id;
        params.delivery_city = delivery_city;
        params.delivery_prefix = delivery_prefix;
        params.delivery_phone = delivery_phone;
      }

      createUser(params).then((res) => {
        if (res.success) {
          setTimeout(() => {
            that.props.history.push("/auth/sign-up-success");
          }, 1500);
        } else {
          that.setState({
            isOpen: true,
            sbColor: "error",
            sbMessage: intl.formatMessage({
              id: "Email address is already in use",
            }),
            loading: false,
          });
          window.scrollTo(0, 0);
        }
      });
    } else {
      that.setState({
        isOpen: true,
        sbColor: "error",
        sbMessage: !isCheck
          ? "Vui lòng chọn captcha để chứng minh bạn không phải người máy"
          : "Vui lòng nhấn chấp nhận các điều khoản của chúng tôi",
      });
      window.scrollTo(0, 0);
    }
  };

  handleChange = (e) => {
    if (e) {
      this.setState({ isCheck: true });
      if (
        this.state.sbMessage ===
        "Vui lòng chọn captcha để chứng minh bạn không phải người máy"
      ) {
        this.setState({ isOpen: false, sbColor: "success", sbMessage: "" });
      }
    } else {
      this.setState({ isCheck: false });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpen: false });
  };

  handlePolicyChange = (e) => {
    let isEChecked = e.target.checked;
    if (
      isEChecked &&
      this.state.sbMessage ===
        "Vui lòng nhấn chấp nhận các điều khoản của chúng tôi"
    ) {
      this.setState({ isOpen: false, sbColor: "success", sbMessage: "" });
    }
    this.setState({ isAcceptPolicy: isEChecked });
  };

  handleCountry1Change = (e) => {
    if (e) {
      this.setState({
        phone_code: e.value,
        country_selected1: { value: e.value, label: e.label },
        colorError: false
      });
    } else {
      this.setState({ phone_code: "", country_selected1: e });
    }
  };

  handleCountry2Change = (e) => {
    if (e) {
      let countrySelected = this.state.listCountry.find(
        (value) => Number(e.value) === Number(value.id)
      );
      this.setState({
        delivery_country_id: countrySelected.id,
        delivery_prefix: countrySelected.phone_code,
        country_selected2: { value: e.value, label: e.label },
      });
    } else {
      this.setState({
        delivery_country_id: 0,
        delivery_prefix: "",
        country_selected2: e,
      });
    }
  };

  handlePlacesApiChange = (address) => {
    this.setState({ delivery_address: address });
  };

  handlePlacesApiSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      if (results && results[0]) {
        let postal_code = "";
        let city = "";
        let country = "";
        let prefix = "";
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              if (results[0].address_components[i].types[j] === "postal_code") {
                postal_code = results[0].address_components[i].long_name;
              }
              if (
                results[0].address_components[i].types[j] ===
                "administrative_area_level_1"
              ) {
                city = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] === "country") {
                country = results[0].address_components[i].long_name;
              }
            }
          }
        }

        let countryFind = this.state.listCountry.find(
          (value) => value.name === country
        );

        let country_selected = this.state.country_selected;
        if (countryFind) {
          country_selected = { value: countryFind.id, label: countryFind.name };
          prefix = countryFind.phone_code;
        }

        this.setState({
          delivery_address: results[0].formatted_address,
          delivery_postcode: postal_code,
          delivery_city: city,
          country_selected2: country_selected,
          delivery_prefix: prefix
        });
      }
    });
  };

  render() {
    const {
      fullname,
      email,
      phone,
      password,
      confirm_password,
      isOpen,
      sbColor,
      sbMessage,
      loading,
      delivery_person_name,
      delivery_postcode,
      delivery_city,
      delivery_prefix,
      delivery_phone,
      listCountry1,
      listCountry2,
      listCountry,
      isAcceptPolicy,
      country_selected1,
      country_selected2,
      colorError
    } = this.state;

    const { classes: clsName, intl } = this.props;

    return (
        <React.Fragment>
          <Helmet>
          <title>Đăng ký</title>
          <meta property="og:title" content={"Đăng ký"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đăng ký"}></meta>
          <meta name="description" content={"Đăng ký"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
      <section className={clsName.signUpPage}>
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          className={clsName.headingAuth}
        >
          <FormattedMessage id="Sign Up"></FormattedMessage>
        </Typography>

        <Box style={{ marginBottom: "40px" }}>
          <Typography
            variant="inherit"
            align="center"
            gutterBottom
            display="block"
          >
            Đã có tài khoản?{" "}
            <Link to="/auth/sign-in" className={clsName.txtSuccess}>
              <FormattedMessage id="Login"></FormattedMessage>
            </Link>
          </Typography>
        </Box>

        {isOpen && sbMessage && (
          <Typography
            variant="inherit"
            align="center"
            gutterBottom
            display="block"
            className={
              sbColor === "error" ? clsName.txtError : clsName.txtSuccess
            }
          >
            {sbMessage}
          </Typography>
        )}

        <ValidatorForm
          ref="form"
          onSubmit={this.onSignUp}
          onError={() => {
              if(!country_selected1){
                this.setState({colorError: true});
              }
          }}
        >
          <Grid container spacing={10}>
            <Grid item xl={6} md={12} sm={12} xs={12}>
              <h2>Thông tin cá nhân</h2>

              <FormControl
                margin="normal"
                required
                fullWidth
                className={clsName.txtControl}
              >
                <label>
                  Họ và tên <span className="txtDanger">*</span>
                </label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => this.setState({ fullname: e.target.value })}
                  name="fullname"
                  value={fullname}
                  validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  autoFocus
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>

              <FormControl
                margin="normal"
                required
                fullWidth
                className={clsName.txtControl}
              >
                <label>
                  Email <span className="txtDanger">*</span>
                </label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  name="email"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    intl.formatMessage({ id: "Error.Required" }),
                    intl.formatMessage({ id: "Error.ValidEmail" }),
                  ]}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>

              <label>
                Số điện thoại <span className="txtDanger">*</span>
              </label>
              <FormControl
                className={clsName.gridBox}
                style={{ gridTemplateColumns: "175px 1fr" }}
                required
                fullWidth
              >
                <FormControl
                  margin="normal"
                  style={{ width: "165px" }}
                  className={clsName.txtControl}
                  variant="outlined"
                >
                  <Select
                    className="basic-single"
                    placeholder=""
                    isClearable={true}
                    isSearchable={true}
                    name="listCountry1"
                    options={listCountry1}
                    value={country_selected1}
                    onChange={(e) => this.handleCountry1Change(e)}
                    styles={{
                      control: (provided, state) =>
                        colorError
                          ? {
                              ...provided,
                              borderColor: "#f44336 !important",
                              borderRadius: '0px!important',
                              padding: '2.5px!important',
                            }
                          : provided
                    }}
                  />
                  <p className={clsName.helperTextError} style={{display: colorError ? 'block' : 'none'}}>Vui lòng nhập</p>
                </FormControl>
                <FormControl
                  margin="normal"
                  className={clsName.txtControl}
                  required
                  fullWidth
                >
                  <TextValidator
                    variant="outlined"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    name="phone"
                    value={phone}
                    validators={["required"]}
                    errorMessages={[
                      intl.formatMessage({ id: "Error.Required" }),
                    ]}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </FormControl>
              </FormControl>

              <h2>Mật khẩu</h2>
              <FormControl
                margin="normal"
                required
                fullWidth
                className={clsName.txtControl}
              >
                <label>
                  Mật khẩu <span className="txtDanger">*</span>
                </label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  name="password"
                  value={password}
                  validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  type={"password"}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
              <FormControl
                margin="normal"
                required
                fullWidth
                className={clsName.txtControl}
              >
                <label>
                  Nhập lại mật khẩu <span className="txtDanger">*</span>
                </label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ confirm_password: e.target.value })
                  }
                  name="confirm_password"
                  value={confirm_password}
                  validators={["required", "isNotEqual"]}
                  errorMessages={[
                    intl.formatMessage({ id: "Error.Required" }),
                    intl.formatMessage({ id: "Error.ConfirmPassword" }),
                  ]}
                  type={"password"}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xl={6} md={12} sm={12} xs={12}>
              <h2>Thông tin giao hàng</h2>
              <FormControl
                margin="normal"
                fullWidth
                className={clsName.txtControl}
              >
                <label>Họ và tên</label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ delivery_person_name: e.target.value })
                  }
                  name="delivery_person_name"
                  value={delivery_person_name}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>

              {
                window && window.google && (
              <FormControl
                margin="normal"
                fullWidth
                className={clsName.txtControl}
              >
                <label>Địa chỉ</label>
                <PlacesAutocomplete
                  value={this.state.delivery_address}
                  onChange={this.handlePlacesApiChange}
                  onSelect={this.handlePlacesApiSelect}
                  searchOptions={{
                    location: new window.google.maps.LatLng(50, 14),
                    radius: 2000,
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <TextField
                        variant="outlined"
                        {...getInputProps({
                          placeholder: "",
                          className: "location-search-input",
                        })}
                        inputProps={{
                          maxLength: 255,
                        }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#DEEBFF", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </FormControl>
                )
              }
              <FormControl
                margin="normal"
                fullWidth
                className={clsName.txtControl}
              >
                <label>Post code</label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ delivery_postcode: e.target.value })
                  }
                  name="delivery_postcode"
                  value={delivery_postcode}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </FormControl>

              <FormControl
                margin="normal"
                fullWidth
                className={clsName.txtControl}
              >
                <label>Thành phố</label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ delivery_city: e.target.value })
                  }
                  name="delivery_city"
                  value={delivery_city}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
              <FormControl
                margin="normal"
                fullWidth
                variant="outlined"
                className={clsName.txtControl}
              >
                <label>Quốc gia</label>
                <Select
                  className="basic-single"
                  placeholder=""
                  isClearable={true}
                  isSearchable={true}
                  name="listCountry2"
                  options={listCountry2}
                  onChange={(e) => this.handleCountry2Change(e)}
                  value={country_selected2}
                />
              </FormControl>
              <label>Số điện thoại</label>
              <div className={clsName.gridBox}>
                <FormControl
                  margin="normal"
                  style={{ width: "100px" }}
                  className={clsName.txtControl}
                  variant="outlined"
                >
                  <SelectMui
                    disabled={true}
                    value={delivery_prefix}
                    onChange={(e) =>
                      this.setState({ delivery_prefix: e.target.value })
                    }
                  >
                    {listCountry && Array.isArray(listCountry) && listCountry.length > 0 && listCountry.map((value, index) => (
                      <MenuItem value={value.phone_code} key={index}>
                        {`+${value.phone_code}`}
                      </MenuItem>
                    ))}
                  </SelectMui>
                </FormControl>
                <FormControl margin="normal" className={clsName.txtControl}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      this.setState({ delivery_phone: e.target.value })
                    }
                    name="delivery_phone"
                    value={delivery_phone}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <ReCAPTCHA sitekey={reCaptchaKey} onChange={this.handleChange} />
          </div>

          <Box
            style={{
              padding: "4px 0px 16px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <GreenCheckbox
                  style={{ color: "#f2c123" }}
                  checked={isAcceptPolicy}
                  onChange={(e) => this.handlePolicyChange(e)}
                  name="isAcceptPolicy"
                />
              }
              label={
                <React.Fragment>
                  Tôi đã đọc và đồng ý với điều khoản{" "}
                  <Link to="/chinh-sach-bao-mat" className={clsName.txtSuccess}>
                    Chính sách bảo mật
                  </Link>
                  ,{" "}
                  <Link to="/giao-nhan-hang" className={clsName.txtSuccess}>
                    Điều khoản giao nhận
                  </Link>
                  ,{" "}
                  <Link to="/chinh-sach-doi-tra" className={clsName.txtSuccess}>
                    Chính sách đổi trả
                  </Link>
                  ,{" "}
                  <Link
                    to="/chinh-sach-dat-hang"
                    className={clsName.txtSuccess}
                  >
                    Chính sách đặt hàng
                  </Link>
                  .
                </React.Fragment>
              }
            />
            <CustomButton
              size="large"
              variant="contained"
              type="submit"
              btnType="success"
              loading={loading}
              customClass={clsName.btnSignUp}
            >
              <FormattedMessage id="Sign Up"></FormattedMessage>
            </CustomButton>
          </Box>
        </ValidatorForm>
      </section>
        </React.Fragment>
    );
  }
}

export default withStyles(style)(injectIntl(SignUp));
