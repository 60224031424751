import React, { Component } from "react";
import { viewPost } from "../../../models";
import LocalStorageService from "../../../services/LocalStorageService";
import { Helmet } from "react-helmet";
import CategoryProduct from "../../../components/CategoryProduct";
import Breadcrumb from "../../../components/Breadcrumb";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { withStyles, Container, Grid, Typography } from "@material-ui/core";
import { EventAvailable } from "@material-ui/icons";
import { Person } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import NotFoundPost from "../../../components/NotFoundPost";
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();
class ViewBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      created_at: "",
      description: "",
      fullName: "",
      image: "",
      isSkeleton: false,
      isNotFound: false,
      breadcrumb: [],
    };
  }

  async componentDidMount() {
    this.setState({ isSkeleton: true });
    if (
      localStorageService.getOrderInfo() &&
      typeof JSON.parse(localStorageService.getOrderInfo()) === "object" &&
      Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0
    ) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }
    let id =
      this.props && this.props.match.params && this.props.match.params.slug
        ? this.props.match.params.slug
        : 0;
    this.getBlogInformation(id);
  }

  getBlogInformation = (id) => {
    viewPost({ id: id }).then(async (res) => {
      if (res.success) {
        if (!res.title) {
          await this.setState({
            isSkeleton: false,
            isNotFound: true,
            breadcrumb: [
              {
                link: "/",
                name: "Không tìm thấy bài viết",
              },
            ],
            title: "Không tìm thấy bài viết",
          });
          return;
        }
        await this.setState({
          title: res.title,
          description: res.description ? res.description : "",
          created_at: res.created_at ? res.created_at : "",
          fullName: res.fullName ? res.fullName : "",
          image:
            res.img && Array.isArray(res.img) && res.img.length > 0
              ? res.img[0].url
              : `${apiDomain}/upload/og-image-default2.png`,
          isSkeleton: false,
          breadcrumb: [
            {
              link: "/blog",
              name: "Blog",
            },
            {
              link: "/blog",
              name: res.title,
            },
          ],
        });
      }
    });
  };

  render() {
    let {
      title,
      description,
      created_at,
      fullName,
      image,
      breadcrumb,
      isNotFound,
      isSkeleton,
    } = this.state;
    let { classes } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
          <meta content={title} property="og:title"></meta>
          <meta
            property="og:image"
            content={image ? image : `${apiDomain}/upload/og-image-default2.png`}
          ></meta>
          <meta
            property="og:description"
            content={
              description
                ? description
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .replace(/&nbsp;/g, " ").length > 200
                  ? `${description
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, " ")
                      .substr(0, 197)}...`
                  : description
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, " ")
                : `${title}`
            }
          ></meta>
          <meta
            name="description"
            content={
              description
                ? description
                    .replace(/<\/?[^>]+(>|$)/g, "")
                    .replace(/&nbsp;/g, " ").length > 200
                  ? `${description
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, " ")
                      .substr(0, 197)}...`
                  : description
                      .replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, " ")
                : `${title}`
            }
          ></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <CategoryProduct />
        <Breadcrumb breadcrumb={breadcrumb} />
        <section className={classes.viewPost_detail}>
            <Grid container>
                <Grid item xs={12} xl={12}>
                {!isSkeleton ? (
            <React.Fragment>
              {isNotFound ? (
                <NotFoundPost />
              ) : (
                <Container>
                  <div className="viewPost_title">
                    <h1>{title}</h1>
                  </div>
                  <div className="viewPost_info">
                    <p className="viewPost_createdAt">
                      <span>
                        <EventAvailable style={{ marginRight: "5px" }} />
                        {created_at}
                      </span>
                    </p>
                    <p className="viewPost_fullName">
                      <span>
                        <Person style={{ marginRight: "5px" }} />
                        {fullName}
                      </span>
                    </p>
                  </div>
                  <div className="viewPost_description">
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                    ></div>
                  </div>
                </Container>
              )}
            </React.Fragment>
          ) : (
            <Container>
              <div className="viewPost_title">
                <h1>
                  <Typography component="div" key={"h1"} variant={"h1"}>
                    <Skeleton />
                  </Typography>
                </h1>
              </div>
              <div className="viewPost_info">
                <div className="viewPost_createdAt">
                <Typography component="div" key={"body1"} variant={"body1"}>
                    <Skeleton width={115} />
                </Typography>
                </div>
                <div className="viewPost_fullName">
                    <Typography component="div" key={"body2"} variant={"body1"}>
                        <Skeleton width={115} />
                    </Typography>
                </div>
              </div>
              <div className="viewPost_description">
                    {[1,2,3,4,5,6,7,8,9,10,11,12].map((x, i) => (
                        <Typography component="div" key={`body${3+i}`} variant={"body1"}>
                            <Skeleton />
                        </Typography>
                    ))}
              </div>
            </Container>
          )}
                </Grid>
            </Grid>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ViewBlog)));
