import {
    Container,
    Grid,
    Snackbar,
    CircularProgress
  } from "@material-ui/core";
  import { withStyles } from "@material-ui/core/styles";
  import React, { Component } from "react";
  import { injectIntl } from "react-intl";
  import { connect } from "react-redux";
  import { compose } from "redux";
  import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
  import LeftSidebar from "../components/LeftSidebar";
  import style from "./style";
  import { setUserInfoAction } from "../../../redux/actions/userActions";
  import { Helmet } from 'react-helmet';
  import { apiDomain } from "../../../utills/config";
  import InfiniteScroll from "react-infinite-scroll-component";
  import styled from "styled-components";
  import NotFoundProduct from "../../../components/NotFoundProduct";
  import ProductTile from "../../../components/ProductTile";
  import { addProductFavorite, productFavorite } from "../../../models/product";
  import ProductTileLoading from "../../../components/UI/ProductTileLoading";
  import moment from "moment";
  import LocalStorageService from "../../../services/LocalStorageService";
  
  const localStorageService = LocalStorageService.getService();

  const Progress = styled(CircularProgress)`
    color: #c7c7c7 !important;
  `;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

  class FavoriteProduct extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sort: "",
        listProducts: [],
        page: 1,
        page_size: 8,
        page_count: 0,
        breadcrumb: [],
        hasMore: true,
        isFetch: false,
        current_datetime: '',
        isChangeCart: false,
        open: false,
        sbColor: 'error',
        sbMessage: '',
        isAuth: false,
      };
    }

    async componentDidMount() {
        this.setState({
          breadcrumb: [{ link: '/san-pham-khuyen-mai', name: "Sản phẩm khuyến mãi" }],
        });
        if(localStorageService.getOrderInfo()
        && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
        && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
          this.props.clearListCart();
          localStorageService.clearCart();
          localStorageService.clearVoucher();
          localStorageService.clearOrderInfo();
          localStorageService.clearDeliveryInfo();
          localStorageService.clearVoucherPoint();
        }
    
        let token = localStorageService.getAccessToken();
        if(typeof token === "string") {
          await this.setState({isAuth: true});
        }
    
        const { page, page_size } = this.state;
        let params = {
          page,
          page_size,
        };
        this.handleGetListProduct(params);
      }
    
      handleGetListProduct(params) {
        this.setState({ isFetch: false });
        const { page, page_count, sort, listProducts, current_datetime } = this.state;
        if (page > page_count && page_count !== 0) {
          this.setState({ hasMore: false });
          return;
        } else {
          let sortProducts = sort.split(" ");
          if (sortProducts.length > 0) {
            params.sort_name = sortProducts[0];
            params.sort_type = sortProducts[1];
          }
          if(current_datetime){
            params.created_at = current_datetime;
          }else{
            params.is_first = 1;
          }
          params.bestdiscount = 1;
          productFavorite(params).then((res) => {
            if (res.success) {
              if(res.current_datetime){
                this.setState({current_datetime: res.current_datetime});
              }
    
              const products = res.products;
              const exchange = res.exchange_rate;
              const list_products = [...listProducts];
              const result = products.map((value) => {
                if (value.created_at) {
                  let created_at = value.created_at.split(" ");
                  let created_at_date = created_at[0].split("/");
                  let created_at_format = `${created_at_date[2]}-${created_at_date[1]}-${created_at_date[0]}`;
                  if (moment().diff(created_at_format, "days") === 0) {
                    value.isNew = true;
                  }
                }
    
                value.isFavorite = true;
    
                if(exchange && typeof exchange === "object" && Object.keys(exchange).length > 0) {
                  value.isExchange = true;
                  value.symbol = exchange.symbol;
                  value.margin = exchange.margin;
                  value.margin_type = exchange.margin_type;
                  value.rate = exchange.rate;
                }
    
                if (value && value.attribute_detail.length > 0) {
                  value.attribute_detail.sort(function (a, b) {
                    return Number(a.price) - Number(b.price);
                  });
                  let lengthAttr = value.attribute_detail.length;
                  value.multiPrice = [
                    Number(value.attribute_detail[0].price),
                    Number(value.attribute_detail[lengthAttr - 1].price),
                  ];
                  value.multiDiscountPrice = [
                    Number(value.attribute_detail[0].discount_price),
                    Number(value.attribute_detail[lengthAttr - 1].discount_price),
                  ];
                }
                return value;
              });
              let products_tmp = [...list_products, ...result];
              this.setState({
                ...this.state,
                listProducts: products_tmp,
                page_count: res.page_count,
                page: page <= res.page_count ? page + 1 : page,
              });
              if (res.total_items === 0 || res.products.length === res.total_items  ||
                products_tmp.length === res.total_items) {
                this.setState({ hasMore: false });
              }
              this.setState({ isFetch: true });
            }
          });
        }
      }
    
      handleFilterChange = async (event) => {
        const { page_size } = this.state;
        await this.setState({
          listProducts: [],
          page: 1,
          page_count: 0,
          sort: event.target.value,
          hasMore: true,
          current_datetime: ''
        });
        this.getListFavorite();
        this.handleGetListProduct({ page: 1, page_size});
      };
    
      showLoading = () => {
        let { page_size } = this.state;
        let xhtml = [];
        for (let i = 0; i < page_size; i++) {
          xhtml.push(
            <div key={i} className="fpGrid">
              <ProductTileLoading />
            </div>
          );
        }
        return xhtml;
      };
    
      nextData = () => {
        const { page, page_size } = this.state;
        let params = {
          page,
          page_size,
        };
        this.handleGetListProduct(params);
      };
    
      reloadListProduct = () => {
        this.setState(prevState => ({
          isChangeCart: !prevState.isChangeCart
        }));
      }
    
      showMessage = (info) => {
        this.setState({open: info.open, sbMessage: info.sbMessage, sbColor: info.sbColor})
      }
    
      handleAlertClose = () => {
        this.setState({ open: false });
      };
    
      handleToggleFavorite = (id, type) => {
        if(!this.state.isAuth){
          this.props.history.push('/auth/sign-in');
          return;
        }
        let listProducts = [...this.state.listProducts];
    
        let newList = listProducts.map((value) => {
          if(Number(value.id) === Number(id)){
            value.isFavorite = type === "remove" ? false : true;
          }
          return value;
        });
        addProductFavorite({product_id: id, favorite: type === "remove" ? 0 : 1}).then((res) => {
          if(res.success){
            this.setState({
              open: true,
              sbColor: 'success',
              sbMessage: type === "remove" ? 'Đã xoá khỏi danh sách sản phẩm yêu thích' : 'Đã thêm vào danh sách sản phẩm yêu thích'})
          }
        });
        this.setState({
          listProducts: newList,
        });
      }
  
    render() {
      const { classes } = this.props;
      const {
        listProducts,
        isFetch,
        open,
        isChangeCart,
        hasMore,
        sbColor,
        sbMessage
      } = this.state;
      return (
        <React.Fragment>
          <Helmet>
            <title>Sản phẩm yêu thích</title>
            <meta property="og:title" content={"Sản phẩm yêu thích"}></meta>
            <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
            <meta property="og:description" content={"Sản phẩm yêu thích"}></meta>
            <meta name="description" content={"Sản phẩm yêu thích"}></meta>
            <meta property="og:url" content={window.location.href}></meta>
          </Helmet>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={3000}
            open={open}
            onClose={this.handleAlertClose}
          >
            <MySnackbarContentWrapper
              onClose={this.handleAlertClose}
              variant={sbColor}
              message={sbMessage}
            />
          </Snackbar>
          <section id="my-account-page" className={classes.myAccountPage}>
            <Container>
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                    <LeftSidebar />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={10}>
                    <div className={classes.fp}>
              <div className={classes.fpMain}>
                <InfiniteScroll
                  dataLength={listProducts.length}
                  next={this.nextData}
                  hasMore={hasMore}
                  className={classes.infinite}
                  loader={
                    <React.Fragment>
                      <GridContainer>
                        {this.showLoading()}
                      </GridContainer>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          style={{ textAlign: "center" }}
                        >
                          <h3>
                            <Progress size={30} color="primary" />
                          </h3>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  scrollThreshold={0.5}
                >
                    {listProducts.length > 0 && (
                      <React.Fragment>
                        <GridContainer>
                        {listProducts.map((value, index) => (
                          <div key={index} className="fpGrid">
                            <ProductTile
                              value={value}
                              key={index}
                              reloadListProduct={() => this.reloadListProduct()}
                              isChangeCart={isChangeCart}
                              showMessage={(info) => this.showMessage(info)}
                              handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
                              />
                          </div>
                        ))}
                        </GridContainer>
                      </React.Fragment>
                    )}
                </InfiniteScroll>
                <React.Fragment>
                  {isFetch && listProducts.length === 0 && (
                    <Container>
                      <Grid container>
                        <Grid item xs={12} xl={12}>
                           <div style={{marginBottom: '20px'}}>
                           <NotFoundProduct />
                           </div>
                        </Grid>
                      </Grid>
                    </Container>
                  )}
                </React.Fragment>
              </div>
            </div>
                    </Grid>
                </Grid>
            </Container>
          </section>
        </React.Fragment>
      );
    }
  }
  
  const mapStateToProps = (store) => {
    return {
      language: store.languageReducer.language,
      avatar: store.userReducer.avatar,
    };
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
      setUserInfo: (user) => {
        dispatch(setUserInfoAction(user));
      },
    };
  };
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default compose(withStyles(style), withConnect)(injectIntl(FavoriteProduct));
  