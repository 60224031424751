import React, { Component } from "react";
import style from "../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  Grid,
  Button,
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { green } from "@material-ui/core/colors";
import Skeleton from '@material-ui/lab/Skeleton';

const GreenCheckbox = styled(Checkbox)`
  color: ${green[400]};
  background-color: #fff0 !important;
`;

class ListAddress extends Component {
  render() {
    const {
      isAuth,
      isService,
      listAddress,
      delivery_address_id,
      isLoading,
      idxLoading,
      checked,
      handleChooseAddress,
      handleModalOpen,
      handleCheckboxChange,
      isSkeleton
    } = this.props;
    return (
        <div className="list-address">
           {!isSkeleton ? (
             <Grid container>
             <Grid item xl={12} xs={12}>
               {listAddress && Array.isArray(listAddress) && listAddress.length > 0 && listAddress.map((value, index) => (
                 <div
                   className={
                     delivery_address_id === value.delivery_address_id &&
                     isService
                       ? "item-address item-choosed"
                       : "item-address"
                   }
                   key={index}
                 >
                   <div
                     onClick={() => handleChooseAddress(value)}
                     className={"overlay"}
                   ></div>
                   <div className="contact-info">
                     <p className="contact-name">{value.name}</p>
                     <p className="address-phone">{value.address && (<React.Fragment>{`${value.address},`}</React.Fragment>)} {value.city}, {value.country_name}, {value.postcode}</p>
                     <p className="address-phone">{value.delivery_prefix ? (<React.Fragment>{`+${value.delivery_prefix} ${value.phone}`}</React.Fragment>) : (<React.Fragment>{value.phone}</React.Fragment>)}</p>
                     {!isAuth && value.email && (<React.Fragment>{value.email}</React.Fragment>)}
                   </div>
                   <div className="options">
                     <button
                       className="btn-edit"
                       onClick={() =>
                         handleModalOpen(value.delivery_address_id)
                       }
                     >
                       {isLoading &&
                       idxLoading === value.delivery_address_id ? (
                         <React.Fragment>
                           <CircularProgress size={18} />
                         </React.Fragment>
                       ) : (
                         <React.Fragment>{"Chỉnh sửa"}</React.Fragment>
                       )}
                     </button>
                     <FormControlLabel
                       className="checkbox"
                       control={
                         <GreenCheckbox
                           disabled={true}
                           checked={checked}
                           onChange={(e) => handleCheckboxChange(e)}
                           name="checked"
                         />
                       }
                     />
                   </div>
                 </div>
               ))}
               {
                 !isAuth && listAddress.length > 0 ? (<React.Fragment></React.Fragment>) : (
                  <div className="addnew-address">
                  <Button
                    className="btn-addnew"
                    onClick={() => handleModalOpen()}
                  >
                    THÊM ĐỊA CHỈ MỚI
                  </Button>
                </div>
                 )
               }
             </Grid>
           </Grid>
           ) : (
            <Grid container>
            <Grid item xl={12} xs={12}>
              {[1,2].map((value, index) => (
                <div
                  className={
                    delivery_address_id === value.delivery_address_id &&
                    isService
                      ? "item-address item-choosed"
                      : "item-address"
                  }
                  key={index}
                >
                  <div
                    className={"overlay"}
                  ></div>
                  <div className="contact-info">
                    <p className="contact-name"><Skeleton variant="text" width={196} /></p>
                    <p className="address-phone"><Skeleton variant="text" width={196} /></p>
                    <p className="address-phone"><Skeleton variant="text" width={196} /></p>
                  </div>
                  <div className="options">
                    <Skeleton variant="text" />
                  </div>
                </div>
              ))}
              <div className="addnew-address" style={{textAlign: 'center'}}>
              <Skeleton variant="rect" width={186} height={48} style={{margin: '0px auto'}} />
              </div>
            </Grid>
          </Grid>
              )}
        
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ListAddress)));
