import React, { Component } from "react";
import style from "../../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  Grid,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Select as SelectMui,
  MenuItem
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import Select from "react-select";
import { TextValidator, ValidatorForm, SelectValidator } from "react-material-ui-form-validator";
import styled from "styled-components";
import PlacesAutocomplete from "react-places-autocomplete";

const SaveButton = styled(Button)`
  width: 106px;
  height: 42px;
  background-color: #f2c123!important;
  border: 1px solid #f2c123!important;
  text-transform: uppercase;
  color: #fff!important;
  border-radius: 22px;
`;

class NotAuthMobile extends Component {
  render() {
    const {
      isLoading,
      classes,
      delivery_person_name,
      intl,
      postcode,
      city,
      delivery_address,
      phone,
      handleInputChange,
      handleSave,
      listCountry1,
      country_selected,
      handleCountryChange,
      handlePlacesApiChange,
      handlePlacesApiSelect,
      delivery_prefix,
      listCountry,
      email,
      colorError
    } = this.props;
    return (
      <Box className={classes.boxMobile}>
        <Grid container>
          <Grid item xl={12} xs={12}>
            <ValidatorForm
              ref="form"
              onSubmit={() => handleSave()}
              onError={() => {
                if(!this.props.country_selected){
                  this.props.handleColorErrorChange();
                }
              }}
            >
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Tên người nhận <span className="txtDanger">*</span></label>
                <TextValidator
                  autoFocus
                  placeholder="Họ và tên"
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  name="delivery_person_name"
                  value={delivery_person_name}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  validators={["required"]}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Địa chỉ nhận hàng <span className="txtDanger">*</span></label>
                {
                   window && window.google && (
                <PlacesAutocomplete
                  value={delivery_address}
                  onChange={handlePlacesApiChange}
                  onSelect={handlePlacesApiSelect}
                  searchOptions={{
                    location: new window.google.maps.LatLng(50, 14),
                    radius: 2000,
                    types: ["address"],
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                    <TextValidator
                       errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                       validators={["required"]}
                       variant="outlined"
                        {...getInputProps({
                          placeholder: "Địa chỉ",
                          className: "location-search-input",
                        })}
                        inputProps={{
                          maxLength: 255,
                        }}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, i) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#DEEBFF", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                   )
                }
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Post code <span className="txtDanger">*</span></label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  name="postcode"
                  value={postcode}
                  validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  placeholder="Post code"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Thành phố <span className="txtDanger">*</span></label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  name="city"
                  value={city}
                  validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  placeholder="Thành phố"
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
              <FormControl
                className={classes.formControlMobile}
                variant="outlined"
                margin="normal"
                fullWidth
              >
                <label>Quốc gia <span className="txtDanger">*</span></label>
                <div className={classes.groupCountry}>
                <Select
                    className="basic-single"
                    placeholder="Quốc gia"
                    isClearable={true}
                    isSearchable={true}
                    name="listCountry1"
                    options={listCountry1}
                    value={country_selected}
                    onChange={(e) => handleCountryChange(e)}
                    styles={{
                      control: (provided, state) =>
                        colorError
                          ? {
                              ...provided,
                              borderColor: "#f44336 !important",
                            }
                          : provided
                    }}
                  />
                  <p className={classes.helperTextError} style={{display: colorError ? 'block' : 'none'}}>Vui lòng nhập</p>
                    </div>
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
                variant="outlined"
                fullWidth
              >
                <label>Prefix <span className="txtDanger">*</span></label>
                <SelectValidator
                 variant="outlined"
                 fullWidth
                    disabled={true}
                    value={delivery_prefix}
                    onChange={(e) => handleInputChange(e)}
                    validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  >
                    {listCountry.map((value, index) => (
                      <MenuItem value={value.phone_code} key={index}>
                        {`+${value.phone_code}`}
                      </MenuItem>
                    ))}
                  </SelectValidator>
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Số điện thoại <span className="txtDanger">*</span></label>
                <TextValidator
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  name="phone"
                  value={phone}
                  validators={["required"]}
                  errorMessages={[intl.formatMessage({ id: "Error.Required" })]}
                  placeholder="Số điện thoại"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </FormControl>
              <FormControl
                margin="normal"
                className={classes.formControlMobile}
              >
                <label>Email</label>
                <TextValidator
                  placeholder="Email"
                  variant="outlined"
                  onChange={(e) => handleInputChange(e)}
                  name="email"
                  value={email}
                  validators={["isEmail"]}
                  errorMessages={[intl.formatMessage({ id: "Error.ValidEmail" })]}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </FormControl>
              <Box>
                <SaveButton
                  style={{ width: "100%" }}
                  type="submit"
                >
                  {isLoading ? <CircularProgress size={18} /> : <React.Fragment>{`Lưu`}</React.Fragment>}
                </SaveButton>
              </Box>
            </ValidatorForm>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(NotAuthMobile)));
