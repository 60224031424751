
import APIFunction from '../services';


export const queryCategory = () => {
    return APIFunction.queryCategory().then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}