const style = (theme) => ({
	myAccountPage: {
		paddingTop: '40px',
		paddingBottom: '40px',
		borderTop: '1px solid #f3f3ef'
	},
	sectionTitle: {
		fontWeight: 400,
		color: '#212529',
		textAlign: 'center',
		marginBottom: theme.spacing(5),
	},
	validateForm: {
		maxWidth: '440px',
		margin: '0px auto'
	},
	txtControl: {
		marginTop: '0',
		marginBottom: '30px',
		'& label': {
			marginBottom: '12px'
		},
		'& input': {
			maxWidth: '440px',
			width: '440px',
			padding: '13px 15px',
			fontSize: '15px'
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderRadius: '0px'
		},
	},
	btnSubmit: {
		backgroundColor: '#f2c123!important',
		borderRadius: '30px',
		border: '1px solid #f2c123!important',
		textTransform: 'uppercase',
		marginTop: '10px',

	},
});

export default style;
