import React, { Component } from 'react';
import { Skeleton } from "@material-ui/lab";
import { withStyles, Container, Grid, Typography } from "@material-ui/core";
class PostTileLoading extends Component {
    render() {
        return (
            <div className="fpItem">
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            height={190}
                            style={{ marginBottom: 6 }}
                          />
                            <div style={{marginBottom: '30px'}}>
                            <Typography component="div" key={"h1"} variant={"h1"}>
                                <Skeleton />
                            </Typography>
                            </div>
                            <div>
                            <Typography component="div" key={"body1"} variant={"body1"}>
                                <Skeleton />
                            </Typography>
                            </div>
                            <div>
                            <Typography component="div" key={"body2"} variant={"body1"}>
                                <Skeleton />
                            </Typography>
                            </div>
                        </div>
        );
    }
}

export default PostTileLoading;