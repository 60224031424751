import React, { Component } from "react";
import {
  Container,
  FormControl,
  Grid,
  Button,
  CircularProgress,
  List,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FacebookIcon from "./../assets/images/facebook-icon.png";
import InstagramIcon from "./../assets/images/instagram-icon.png";
import YoutubeIcon from "./../assets/images/youtube-icon.png";
import { subMail } from "../models";
import AlertMessage from "./UI/AlertMessage";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { injectIntl } from "react-intl";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Commit from "./Commit";
const Wrapper = styled.div`
  padding: 35px 0px 10px;
  background: #fff;
  position: relative;
`;
const Title = styled.p`
  text-transform: uppercase;
  margin: 20px 0px;
`;

const Information = styled.p`
  margin-bottom: 12px;
`;

const Suggestions = styled.p`
  margin-bottom: 32px;
`;

const FormSubMail = styled(FormControl)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonSendSubMail = styled(Button)`
  display: inline-block;
  height: 32px;
  width: 32px;
  padding: 0px;
  position: absolute;
  right: 0px;
  top: 1px;
  &:hover {
    background: #fdebd2;
    color: #f2c123;
  }
`;

const SocialNetWork = styled.a`
  display: inline-block;
  margin-right: 15px;
  &:hover{
    cursor: pointer;
  }
`;

const ListItem = styled.li`
  display: block;
  line-height: 15px;
  margin-bottom: 12px;
  & a:hover {
    color: #f2c123;
  }
`;

const SubMail = styled(TextValidator)`
    width: 100%;
    & input{
    font-size: 16px;
    width: 100%;
    padding-right: 64px;
    }
    & .MuiInput-underline:after{
      border-bottom: 2px solid #000;
    }
`;
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      open: false,
      loading: false,
      severity: "success",
      message: "",
    };
  }

  handleSubscription = () => {
    this.setState({
      loading: true,
    });

    subMail({ email: this.state.email }).then((res) => {
      if (res.success) {
        this.setState({
          email: "",
          open: true,
          severity: "success",
          message: "Đăng ký nhận bản tin thành công.",
          loading: false,
        });
      } else {
        this.setState({
          email: "",
          open: true,
          severity: "error",
          message: "Email này đã đăng ký nhận bản tin.",
          loading: false,
        });
      }
    });
  };

  handleAlertClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { intl } = this.props;
    const { open, email, severity, message, loading } = this.state;
    return (
        <React.Fragment>
          <Commit />
          <Wrapper>
            <Container maxWidth="xl">
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <Title>Liên hệ</Title>
                  <Information>
                    SAPA, Libušská 319/126, 142 00z Praha-Libuš
                  </Information>
                  <Information>lienhe@taphoa.cz</Information>
                  <Information>+420 732886666</Information>
                  <div className="social-network">
                    <SocialNetWork href="https://www.instagram.com/tiemtaphoacz/?hl=vi">
                      <img src={InstagramIcon} alt="Instagram" />
                    </SocialNetWork>
                    <SocialNetWork href="https://www.facebook.com/taphoa.cz">
                      <img src={FacebookIcon} alt="Facebook" />
                    </SocialNetWork>
                    <SocialNetWork href="https://youtube.com">
                      <img src={YoutubeIcon} alt="Youtube" />
                    </SocialNetWork>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Title>Chính sách</Title>
                  <List style={{ paddingTop: "0px" }}>
                    <ListItem>
                      <Link to="/giao-nhan-hang">Giao nhận hàng</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/chinh-sach-doi-tra">Chính sách đổi trả</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/chinh-sach-dat-hang">Chính sách đặt hàng</Link>
                    </ListItem>
                    <ListItem>
                      <Link to="/chinh-sach-bao-mat">Chính sách bảo mật</Link>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Title>Đăng ký nhận bản tin</Title>
                  <Suggestions>
                    Nhận gợi ý cho thực đơn và thông tin sản phẩm mới nhất
                  </Suggestions>
                  <ValidatorForm
                    ref="form"
                    onSubmit={() => this.handleSubscription()}
                    onError={() => {
                      this.setState({ isOpen: false, errorMessage: "" });
                    }}
                  >
                    <FormSubMail margin="normal" required fullWidth>
                      <SubMail
                        onChange={(e) => this.setState({ email: e.target.value })}
                        name="email"
                        value={email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          intl.formatMessage({ id: "Error.Required" }),
                          intl.formatMessage({ id: "Error.ValidEmail" }),
                        ]}
                        placeholder={intl.formatMessage({ id: "Email Address" })}
                        inputProps={{
                          maxLength: 255,
                        }}
                      />
                      <ButtonSendSubMail type="submit">
                        {loading ? <CircularProgress size={32} /> : <MailOutlineIcon style={{fontSize: '1.75rem', height: '32px'}}/>}
                      </ButtonSendSubMail>
                    </FormSubMail>
                  </ValidatorForm>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <div className="copyRight__wrapper">
                    <div  className="copyRight__container">
                      <p>Powered by <a href="https://www.crexp.tech/">Crexp Tech</a></p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <AlertMessage
              open={open}
              severity={severity}
              handleAlertClose={() => this.handleAlertClose()}
              message={message}
            />
          </Wrapper>
        </React.Fragment>
    );
  }
}

export default injectIntl(Footer);
