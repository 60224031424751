import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import style from "./../../style";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
  withStyles,
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import styled from "styled-components";

const TableHeadCus = styled(TableHead)`
  background: #f1f8f2;
  font-family: 'Tinos';
`;

const TableCellCus = styled(TableCell)`
font-family: 'Tinos';
`;

const ButtonTable = styled.span`
    background-color: #fff!important;
    color: #f2c123!important;
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    &:nth-child(3){
        margin-right: 0px;
    }
    &:hover{
        cursor: pointer;
    }
`;

const ButtonAddNew = styled.span`
color: #f2c123!important;
font-size: 0.875rem;
line-height: 1.5rem;
font-weight: 600;
display: inline-block;
&:hover{
    cursor: pointer;
}
`;

class DesktopTemplate extends Component {
  render() {
    const {
      classes,
      listAddress,
      handleRemove,
      handleModalOpen,
      isSkeleton
    } = this.props;
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          {!isSkeleton ? (
            <React.Fragment>
               <TableHeadCus>
            <TableRow>
              <TableCellCus>Tên người nhận</TableCellCus>
              <TableCellCus>Địa chỉ nhận hàng</TableCellCus>
              <TableCellCus>Post code</TableCellCus>
              <TableCellCus>Thành phố</TableCellCus>
              <TableCellCus>Quốc gia</TableCellCus>
              <TableCellCus>Prefix</TableCellCus>
              <TableCellCus>Số điện thoại</TableCellCus>
              <TableCellCus className="cell-note"><ButtonAddNew onClick={() => handleModalOpen()}>Thêm mới</ButtonAddNew></TableCellCus>
            </TableRow>
          </TableHeadCus>
          <TableBody>
              {listAddress && Array.isArray(listAddress) && listAddress.length > 0 ? (
                <React.Fragment>
                   {listAddress.map((row, i) => (
              <TableRow key={i}>
                <TableCellCus component="th" scope="row">
                <p>{row.name}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.address}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.postcode}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.city}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.country_name}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.prefix}</p>
                </TableCellCus>
                <TableCellCus>
                <p>{row.phone}</p>
                </TableCellCus>
                <TableCellCus className="cell-note">
                <ButtonTable onClick={() => handleModalOpen(row.id)}>Sửa</ButtonTable>
                <ButtonTable onClick={() => handleRemove(row.id)}>Xóa</ButtonTable>
                </TableCellCus>
              </TableRow>
            ))}
                </React.Fragment>
              ) : (
                  <h3 style={{marginTop: '10px'}}>Hiện chưa có địa chỉ.</h3>
              )}
          </TableBody>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TableHeadCus>
                <TableRow>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                </TableRow>
              </TableHeadCus>
              <TableBody>
                {[1,2,3,4,5,6,7,8,9,10].map((row) => (
                  <TableRow key={row}>
                    <TableCell component="th" scope="row">
                    <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell >
                      <p> <Skeleton variant="text" width={80} /></p>
                    <p><Skeleton variant="text" width={80} /></p>
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                    <TableCell >
                      <p><Skeleton variant="text" width={80} /></p>
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                    <TableCell >
                    <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DesktopTemplate)));
