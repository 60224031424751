const style = (theme) => ({
    notFoundProduct: {
        textAlign: 'center',
        '& p': {
            fontSize: '1.125rem',
            margin: '35px 15px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        '& a': {
            padding: '8px 15px',
            border: '1px solid #f2c123',
            color: '#f2c123',
            borderRadius: '22px',
            textTransform: 'uppercase',
            '&:hover':{
                color: '#fff',
                background: '#f2c123'
            }
        }
    }
});

export default style;
