import APIFunction from './../services';

// forgetPassword
export const forgetPassword = (email) => {
    return APIFunction.forgetPassword({ email: email }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// createUser
export const createUser = (params) => {
    return APIFunction.createUser(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const checkTokenVaid = (token) => {
    return APIFunction.checkTokenVaid({ token: token }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const registerNewPassword = (token, password) => {
    return APIFunction.registerNewPassword({ id: token, password: password }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const activeUser = (token) => {
    return APIFunction.activeUser({ token: token }).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const createUserVoucher = (params) => {
    return APIFunction.createUserVoucher(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

export const getUserVoucher = (params) => {
    return APIFunction.getUserVoucher(params).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}

// get voucher setting
export const getVoucherSetting = (param) => {
    return APIFunction.getVoucherSetting(param).then((result) => {
        return result;
    }).catch(error => {
        return error;
    })
}