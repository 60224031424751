
import APIFunction from './../services';

//get a user delivery address
export const getDeliveryAddress = (params) => {
    return APIFunction.getDeliveryAddress(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

//get list delivery address
export const getListDeliveryAddress = (params) => {
    return APIFunction.getListDeliveryAddress(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

//create new delivery address
export const createDeliveryAddress = (params) => {
    return APIFunction.createDeliveryAddress(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

//update delivery address
export const updateDeliveryAddress = (params) => {
    return APIFunction.updateDeliveryAddress(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

//delete delivery address
export const deleteDeliveryAddress = (params) => {
    return APIFunction.deleteDeliveryAddress(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}
