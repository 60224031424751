import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./../style";
import { compose } from "redux";
import Slider from "react-slick";
import DefaultImage from './../../../../assets/images/default.jpg';
import ReactDOM from "react-dom";
import styled from "styled-components";

const settings1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
      },
    },
  ],
};
const settings2 = {
  dots: false,
  infinite: true,
  vertical: true,
  speed: 500,
  slidesToScroll: 1,
  focusOnSelect: true,
  arrows: false,
  verticalSwiping: true,
};

const SoldOut = styled.div`
    width: 80px;
    height: 80px;
    background: #000000a1;
    border-radius: 50%;
    color: #fff;
    line-height: 80px;
    font-size: 17px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    text-align: center;
`;

class SliderMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
    this.accordionContent2 = [];
  }

  componentDidMount() {
    let name1 = ReactDOM.findDOMNode(this.refs.cpThumbSlider1);
    if(name1){
      this.setHeightEqualWidth(name1);
    }

    this.accordionContent2.forEach((x,i) => {
      if(ReactDOM.findDOMNode(this.accordionContent2[i])){
        if(this.accordionContent2.length === 1){
          this.setHeightEqualWidth(ReactDOM.findDOMNode(this.accordionContent2[i]));
        }
      }
    })
   

    let name3 = ReactDOM.findDOMNode(this.refs.cpThumbSlider3);
    if(name3){
      this.setHeightEqualWidth(name3);
    }

    let name4 = ReactDOM.findDOMNode(this.refs.cpThumbSlider4);
    if(name4){
      this.setHeightEqualWidth(name4);
    }

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  setHeightEqualWidth = (name) => {
    name.style.height = `${name.clientWidth}px`;
  }

  render() {
    const { classes, name, image, inventory } = this.props;
    const { nav1, nav2 } = this.state;
    return (
      <React.Fragment>
        <div className={classes.boxSlider}  >
            {image && Array.isArray(image) && image.length > 0 ? (
                <React.Fragment>
                   <Slider
               className={classes.sliderMain}
               {...settings1}
               asNavFor={nav2}
               ref={(slider) => (this.slider1 = slider)}
             >
               {image.map((value, index) => (
                 <div className="container-thumb" key={index} ref="cpThumbSlider1" >
                   <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={value} alt={name} />
                {Number(inventory) === 0 && (
                  <SoldOut><span>Hết hàng</span></SoldOut>
                )}
                 </div>
               ))}
             </Slider>
             <Slider
               className={classes.sliderThumb}
               {...settings2}
               slidesToShow={image.length < 3 ? image.length : 3}
               asNavFor={nav1}
               ref={(slider) => (this.slider2 = slider)}
             >
               {image.map((value, index) => (
                 <div className="container-thumb" key={index} ref={ref => this.accordionContent2[index] = ref}>
                   <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={value} alt={name} />
                 </div>
               ))}
             </Slider>
                </React.Fragment>
            ) : (
              <React.Fragment>
                 <Slider
               className={classes.sliderMain}
               {...settings1}
               asNavFor={nav2}
               ref={(slider) => (this.slider1 = slider)}
             >
                 <div className="container-thumb" ref="cpThumbSlider3">
                   <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={DefaultImage} alt={name} />
                {Number(inventory) === 0 && (
                  <SoldOut><span>Hết hàng</span></SoldOut>
                )}
                 </div>
             </Slider>
             <Slider
               className={classes.sliderThumb}
               {...settings2}
               slidesToShow={1}
               asNavFor={nav1}
               ref={(slider) => (this.slider2 = slider)}
             >
                 <div className="container-thumb" ref="cpThumbSlider4">
                   <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={DefaultImage} alt={name} />
                 </div>
             </Slider>
              </React.Fragment>
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(injectIntl(SliderMain));
