import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container, Grid, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Skeleton from "@material-ui/lab/Skeleton";
import style from "./../../style";
import PostTileLoading from "./../../../../components/UI/PostTileLoading";
import BlogTile from "./components/BlogTile";
import IconTitle from './../../../../assets/images/icon-title.png';

class ListPost extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { title, total_blogs, listBlog, isLoading, classes } = this.props;
    return (
      <React.Fragment>
        {!isLoading ? (
          <React.Fragment>
            {listBlog && Array.isArray(listBlog) && listBlog.length > 0 ? (
              <section id="fp" className={classes.fp} style={{background: '#fff'}}>
              <Container maxWidth="xl">
                <Grid container>
                  <Grid xl={12} xs={12} item>
                    <div className={classes.titleHome}>
                      <h2><span>{title}</span></h2>
                      <div className="title-sp"></div>
                    </div>
                    <div className={classes.titleHomeMobile}>
                      <h2><img src={IconTitle} alt="Blog" /><span>{title}</span></h2>
                    </div>
                  </Grid>
                </Grid>
                </Container>
                <div className={classes.fpMain}>
                  <Container maxWidth="xl">
                    <Grid container spacing={2}>
                      {listBlog.map((value, index) => (
                        <Grid
                          item
                          md={4}
                          lg={4}
                          xs={12}
                          sm={12}
                          key={index}
                          className="fpGrid"
                        >
                          <BlogTile
                            value={value}
                            key={index}
                            showMessage={(info) => this.showMessage(info)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {total_blogs && total_blogs > 3 && (
                      <Grid item xs={12}>
                        <Link to="/blog" className={classes.btnViewAll} style={{marginTop: '0px'}}>
                          Xem thêm
                        </Link>
                      </Grid>
                    )}
                  </Container>
                </div>
              </section>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <section id="fp" className={classes.fp}>
           <Container maxWidth="xl">
                <Grid container>
                  <Grid xl={12} xs={12} item>
                    <div className={classes.titleHome}>
                      <h2><span>{title}</span></h2>
                      <div className="title-sp"></div>
                    </div>
                  </Grid>
                </Grid>
                </Container>
            <div className={classes.fpMain}>
              <Container maxWidth="xl">
                <Grid container spacing={2}>
                  {[1,2,3].map((value, index) => (
                    <Grid
                      item
                      md={4}
                      lg={4}
                      xs={12}
                      sm={12}
                      className="fpGrid"
                      key={index}
                    >
                      <PostTileLoading />
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ListPost)));
