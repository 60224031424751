import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import style from "./style";
import { compose } from "redux";
import { withStyles, Container } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { queryCategory } from "../../models/category";
import Skeleton from "@material-ui/lab/Skeleton";
import { convertSlugSeo } from "../../utills/constant";
import DefaultImage from './../../assets/images/default.jpg';
import LazyImage from "../LazyImage";

class CategoryProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      sbMessage: "",
      isOpen: false,
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchCategories();
  }

  fetchCategories = () => {
    let that = this;
    queryCategory().then((res) => {
      if (res.success) {
        that.setState({
          isOpen: false,
          sbMessage: "",
          categories: res.category,
          isLoading: false
        });
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.message,
          sbColor: "error",
          isLoading: false,
        });
      }
    });
  };
  render() {
    let { classes } = this.props;
    let { categories, isLoading } = this.state;
    return (
      <section id="category-product" className={classes.cp}>
        <Container maxWidth="xl">
          <div className={classes.titleHome}>
            <h2>Danh Mục Sản Phẩm</h2>
          </div>
          <div className="cpMain">
            <ul className="cpList">
              {!isLoading ? (
                <React.Fragment>
                  {categories && Array.isArray(categories) && categories.length > 0 && categories.map((value, index) => (
                    <li className={`cpItem cpItem${index + 1}`} key={index}>
                      <div className="cpThumb">
                        <Link to={value.id && value.name && `/categories/${convertSlugSeo(value.name)}.id=${value.id}`}>
                          <LazyImage 
                            width={`100%`}
                            height={`auto`}
                            effect={"opacity"}
                            src={ value.image ? value.image : DefaultImage }
                            alt={value.name}
                            heightSke={130}
                          />
                        </Link>
                      </div>
                      <Link
                        to={value.id && value.name && `/categories/${convertSlugSeo(value.name)}.id=${value.id}`}
                        className="cpParentName"
                      >
                        {value.name}
                      </Link>
                      {value.sub_category && value.sub_category.length > 0 && (
                        <ul className="cpSubCats">
                          {value.sub_category.map((subValue, subIndex) => (
                            <li key={"subCats" + subIndex}>
                              <Link
                                to={subValue.parent_id && subValue.id && subValue.name && `/categories/${convertSlugSeo(subValue.name)}.id=${subValue.parent_id}_${subValue.id}`}
                              >
                                {subValue.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((value, index) => (
                    <li className={`cpItem cpItem${index + 1}`} key={index}>
                      <div className="cpThumb">
                        <Skeleton variant="rect" width={130} height={130} />
                      </div>
                      <Skeleton variant="text"  width="60%" style={{margin: '0px auto'}} />
                    </li>
                  ))}
                </React.Fragment>
              )}
            </ul>
          </div>
        </Container>
      </section>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  withStyles(style)
)(withTheme(withRouter(injectIntl(CategoryProduct))));
