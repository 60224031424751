export const SET_THEME = 'SET_THEME';
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_AUTH = 'SET_AUTH';
export const SET_MODAL_LOGOUT = 'SET_MODAL_LOGOUT';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const FETCH_POSTS = 'FETCH_POSTS';

//Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const CLEAR_LIST_CART = 'CLEAR_LIST_CART';
export const DELETE_ITEM_CART = 'DELETE_ITEM_CART';
export const CHANGE_QUANTITY_ITEM = 'CHANGE_QUANTITY_ITEM';
export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';