const style = (theme) => ({
  myOrder: {
    paddingTop: '40px',
		paddingBottom: '40px',
		borderTop: '1px solid #f3f3ef',
      '& h2': {
        fontSize: '1.75rem',
        marginBottom: '18px',
        fontWeight: '500',
        paddingTop: '8px'
      },
    "& .list-order-mobile": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
      '& .item-order-mobile': {
          padding: '10px 0px 15px',
          borderBottom: '1px solid #eee',
          '& p': {
            marginBottom: '5px',
            display: 'flex',
            '& .label': {
                minWidth: '155px',
                display: 'inline-block',
                fontWeight: 'bold'
            },
            '& .list-name-product': {
                display: 'flex',
                flexDirection: 'column'
            }
          }
      }
    },
    "& .list-order-desktop": {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  detailOrder: {
    "& .contact-info": {
      marginBottom: "20px",
      "& .info-item": {
        border: "1px solid #eee",
        padding: "10px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: "5px",
        },
        "& h3": {
          marginBottom: "10px",
          // textAlign: 'center'
        },
      },
      "& .contact-info:first-child": {
        borderRight: "none",
      },
    },
    "& .detail-product": {
      marginBottom: "20px",
    },
    "& .total-price": {
      textAlign: "right",
      "& p": {
        marginBottom: "10px",
        fontWeight: "bold",
      },
    },
    "& .table-mobile": {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      "& .item-detail-mb": {
        paddingBottom: "10px",
        "& p": {
          fontWeight: "bold",
          marginBottom: "5px",
          "& span": {
            fontWeight: "500",
          },
        },
      },
      '& .MuiDialogContent-dividers':{
        padding: '14px 12px'
      }
    },
    "& .table-desktop": {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  cellTableDetail: {
    minWidth: "120px",
  },
  btnCloseModal: {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  reCheckout: {
    background: '#fff',
    padding: '2px',
    color: '#f2c123',
    textDecoration: 'underline',
    fontSize: '16px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default style;
