
import APIFunction from '../services';

//get banner list
export const getBanner = (params) => {
    return APIFunction.getBanner(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}


