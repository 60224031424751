import React, { Component } from 'react';
import { withStyles, Box, Stepper, Step, StepLabel } from "@material-ui/core";
import { injectIntl } from 'react-intl';
import { withTheme } from "styled-components";
import { connect } from 'react-redux';
import { compose } from 'redux';
import style from "./style";
import { withRouter } from "react-router-dom";
const steps = ['Chọn địa chỉ giao hàng', 'Xác nhận Thông tin đơn hàng và Chọn Phương thức thanh toán', 'Đặt hàng thành công'];

class HorizontalLinearStepper extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    let {activeStep, classes} = this.props;
    return (
        <Box sx={{ width: '100%' }}>
          <Stepper className={classes.stepperCustom} activeStep={activeStep} orientation={window.innerWidth < 769 ? "vertical" : "horizontal"}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    );
  }
}

const mapStateToProps = (store) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  withStyles(style)
)(withTheme(withRouter(injectIntl(HorizontalLinearStepper))));