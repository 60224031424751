const LocalStorageService = (function () {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  
  function _setLocation(params) {
    localStorage.setItem('location',  params ? params : '');
  }

  function _getLocation() {
    return localStorage.getItem('location');
  }

  function _clearLocation() {
    localStorage.removeItem('location');
  }

  function _setOrderInfo(params) {
    localStorage.setItem('orderinfo',  params ? params : '');
  }

  function _getOrderInfo() {
    return localStorage.getItem('orderinfo');
  }

  function _clearOrderInfo() {
    localStorage.removeItem('orderinfo');
  }

  function _setDeliveryInfo(params) {
    localStorage.setItem('info',  params ? params : '');
  }

  function _getDeliveryInfo() {
    return localStorage.getItem('info');
  }

  function _clearDeliveryInfo() {
    localStorage.removeItem('info');
  }

  function _setCart(cart) {
    localStorage.setItem('cart',  cart ? cart : '');
  }

  function _getCart() {
    return localStorage.getItem('cart');
  }

  function _clearCart() {
    localStorage.removeItem('cart');
  }
  
  function _setToken(tokenObj) {
    localStorage.setItem('access_token',  tokenObj ? tokenObj.access_token : '');
    localStorage.setItem('refresh_token', tokenObj ? tokenObj.refresh_token: '');
  }

  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }

  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  function _setUserInfor(userInfor) {
    localStorage.setItem('user_infor',  userInfor);
    localStorage.setItem('user_infor', userInfor);
  }

  function _getUserInfor() {
    return localStorage.getItem('user_infor');
  }

  function _clearUserInfor() {
    localStorage.removeItem('user_infor');
  }

  function _setLanguage(language) {
    localStorage.setItem('language',  language);
  }

  function _getLanguage() {
    return localStorage.getItem('language');
  }

  function _setVoucher(params) {
    localStorage.setItem('voucher',  params ? params : '');
  }

  function _getVoucher() {
    return localStorage.getItem('voucher');
  }

  function _clearVoucher() {
    localStorage.removeItem('voucher');
  }

  function _setVoucherPoint(params) {
    localStorage.setItem('voucher_point',  params ? params : '');
  }

  function _getVoucherPoint() {
    return localStorage.getItem('voucher_point');
  }

  function _clearVoucherPoint() {
    localStorage.removeItem('voucher_point');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setUserInfor: _setUserInfor,
    getUserInfor: _getUserInfor,
    clearUserInfor: _clearUserInfor,
    setLanguage: _setLanguage,
    getLanguage: _getLanguage,
    setCart: _setCart,
    getCart: _getCart,
    clearCart: _clearCart,
    setDeliveryInfo: _setDeliveryInfo,
    getDeliveryInfo: _getDeliveryInfo,
    clearDeliveryInfo: _clearDeliveryInfo,
    setLocation: _setLocation,
    getLocation: _getLocation,
    clearLocation: _clearLocation,
    setVoucher: _setVoucher,
    getVoucher: _getVoucher,
    clearVoucher: _clearVoucher,
    setOrderInfo: _setOrderInfo,
    getOrderInfo: _getOrderInfo,
    clearOrderInfo: _clearOrderInfo,
    setVoucherPoint: _setVoucherPoint,
    getVoucherPoint: _getVoucherPoint,
    clearVoucherPoint: _clearVoucherPoint,
  }
})();
export default LocalStorageService;