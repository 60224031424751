import * as types from '../constants';

export function setLogoutAction(value) {
  return {
    type: types.SET_LOGOUT,
    payload: value
  }
}

export function setAuthAction(value) {
  return {
    type: types.SET_AUTH,
    payload: value
  }
}