import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import {withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import style from './style';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";

const MenuListItem = styled.li`
  font-size: 16px;
  color: #000;
  padding: 5px;
  &:hover{
    cursor: pointer;
    background-color: #e8e8e8
  }
`;

class InputSelect extends Component {
  render() {
    const { classes, label , options, sort, handleFilterChange } = this.props;
    return (
      <div className={classes.productFilter}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <label>{label}</label>
                <Select
                  variant="outlined"
                  value={sort}
                  onChange={handleFilterChange}
                  label={label}
                >
                  <MenuListItem value=""></MenuListItem>
                  {options && Array.isArray(options) && options.length > 0 && options.map((value, i) => (
                    <MenuListItem key={i} value={value.value}>
                      {value.label}
                    </MenuListItem>
                  ))}
                </Select>
              </FormControl>
            </div>
    );
  }
}


const mapStateToProps = (store) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch, ownDispatch) => {
    return {
    };
  };
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default compose(
    withStyles(style),
    withConnect
  )(withRouter(injectIntl(InputSelect)));
  
