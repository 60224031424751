import { withStyles, Grid } from "@material-ui/core";
import React, { Component } from "react";
import style from "./../style";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { CURRENCY } from "../../../utills/constant";
class DetailCart extends Component {
  render() {
    const {
      detailOrder,
      classes,
      totalPayment,
      shipment_fee,
      totalCart,
      infoVoucher,
      isExchange,
      symbol,
      totalPaymentExchange,
      totalCartExchange,
      shipment_fee_exchange,
      isAuth,
      rate,
      rank,
      voucherPoint,
      shipment_discount
    } = this.props;
    return (
      <div className={classes.detailOrder}>
        <div className="detail-product">
          <div className="table-desktop">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellTableDetail}>
                      Tên sản phẩm
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      SKU
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Số lượng
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Giá
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Tạm tính
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailOrder &&
                    Array.isArray(detailOrder) &&
                    detailOrder.length > 0 &&
                    detailOrder.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className={classes.cellTableDetail}
                          component="th"
                          scope="row"
                        >
                          <p>{row.product_name}</p>
                          {row.attribute_name1 && row.attribute_value1 && (
                            <p className="attribute-product">
                              ({row.attribute_name1 +
                                ": " +
                                row.attribute_value1})
                            </p>
                          )}
                          {row.attribute_name2 && row.attribute_value2 && (
                            <p className="attribute-product">
                              ({row.attribute_name2 +
                                ": " +
                                row.attribute_value2})
                            </p>
                          )}
                          {row.attribute_name3 && row.attribute_value3 && (
                            <p className="attribute-product">
                              ({row.attribute_name3 +
                                ": " +
                                row.attribute_value3})
                            </p>
                          )}
                          {row.attribute_name4 && row.attribute_value4 && (
                            <p className="attribute-product">
                              ({row.attribute_name4 +
                                ": " +
                                row.attribute_value4})
                            </p>
                          )}
                          {row.attribute_name5 && row.attribute_value5 && (
                            <p className="attribute-product">
                             ({row.attribute_name5 +
                                ": " +
                                row.attribute_value5})
                            </p>
                          )}
                        </TableCell>
                        <TableCell className={classes.cellTableDetail}>
                          {row.sku_code ? (
                            <React.Fragment>{row.sku_code}</React.Fragment>
                          ) : (
                            <React.Fragment>{row.product_code}</React.Fragment>
                          )}
                        </TableCell>
                        <TableCell className={classes.cellTableDetail}>
                          <NumberFormat
                            value={row.quantity}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` `}
                          />
                        </TableCell>
                        <TableCell className={classes.cellTableDetail}>
                          {row.discount &&
                          typeof Number(row.discount) === "number" &&
                          !isNaN(row.discount) &&
                          Number(row.discount) > 0 ? (
                            <React.Fragment>
                              <NumberFormat
                                value={
                                  isExchange
                                    ? Number(row.discount_exchange)
                                    : Number(row.discount)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={!isExchange ? ` ${CURRENCY}` : ""}
                                prefix={isExchange ? `${symbol} ` : ""}
                              />
                              <del
                                className="txtDanger"
                                style={{ marginLeft: "10px" }}
                              >
                                <NumberFormat
                                  value={
                                    isExchange
                                      ? Number(row.price_exchange)
                                      : Number(row.price)
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={!isExchange ? ` ${CURRENCY}` : ""}
                                  prefix={isExchange ? `${symbol} ` : ""}
                                />
                              </del>
                            </React.Fragment>
                          ) : (
                            <NumberFormat
                              value={
                                isExchange
                                  ? Number(row.price_exchange)
                                  : Number(row.price)
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={!isExchange ? ` ${CURRENCY}` : ""}
                              prefix={isExchange ? `${symbol} ` : ""}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.cellTableDetail}>
                          <NumberFormat
                            value={
                              isExchange
                                ? Number(row.provisional_exchange)
                                : Number(row.provisional)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={!isExchange ? ` ${CURRENCY}` : ""}
                            prefix={isExchange ? `${symbol} ` : ""}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="table-mobile">
            <Grid container>
              <Grid item xl={12} xs={12}>
                {detailOrder &&
                  Array.isArray(detailOrder) &&
                  detailOrder.length > 0 &&
                  detailOrder.map((row, index) => (
                    <div className="item-detail-mb" key={index}>
                      <p>
                        Tên sản phẩm: <span>{row.product_name}</span>
                      </p>
                      {row.attribute_name1 && row.attribute_value1 && (
                            <p className="attribute-product">
                              ({row.attribute_name1 +
                                ": " +
                                row.attribute_value1})
                            </p>
                          )}
                          {row.attribute_name2 && row.attribute_value2 && (
                            <p className="attribute-product">
                              ({row.attribute_name2 +
                                ": " +
                                row.attribute_value2})
                            </p>
                          )}
                          {row.attribute_name3 && row.attribute_value3 && (
                            <p className="attribute-product">
                              ({row.attribute_name3 +
                                ": " +
                                row.attribute_value3})
                            </p>
                          )}
                          {row.attribute_name4 && row.attribute_value4 && (
                            <p className="attribute-product">
                              ({row.attribute_name4 +
                                ": " +
                                row.attribute_value4})
                            </p>
                          )}
                          {row.attribute_name5 && row.attribute_value5 && (
                            <p className="attribute-product">
                             ({row.attribute_name5 +
                                ": " +
                                row.attribute_value5})
                            </p>
                          )}
                      <p>
                        SKU:{" "}
                        <span>
                          {row.sku_code ? (
                            <React.Fragment>{row.sku_code}</React.Fragment>
                          ) : (
                            <React.Fragment>{row.product_code}</React.Fragment>
                          )}
                        </span>
                      </p>
                      <p>
                        Số lượng:{" "}
                        <span>
                          {" "}
                          <NumberFormat
                            value={row.quantity}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` `}
                          />
                        </span>
                      </p>
                      <p>
                        Đơn giá:{" "}
                        {row.discount &&
                        typeof Number(row.discount) === "number" &&
                        !isNaN(row.discount) &&
                        Number(row.discount) > 0 ? (
                          <React.Fragment>
                             <NumberFormat
                          value={
                            isExchange
                              ? Number(row.discount_exchange)
                              : Number(row.discount)
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!isExchange ? ` ${CURRENCY}` : ""}
                          prefix={isExchange ? `${symbol} ` : ""}
                        />
                        <del
                          className="txtDanger"
                          style={{ marginLeft: "10px" }}
                        >
                          <NumberFormat
                            value={
                              isExchange
                                ? Number(row.price_exchange)
                                : Number(row.price)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={!isExchange ? ` ${CURRENCY}` : ""}
                            prefix={isExchange ? `${symbol} ` : ""}
                          />
                        </del>
                          </React.Fragment>
                        ) : (
                          <NumberFormat
                            value={isExchange ? Number(row.price_exchange) : Number(row.price)}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={!isExchange ? ` ${CURRENCY}` : ""}
                            prefix={isExchange ? `${symbol} ` : ""}
                          />
                        )}
                      </p>
                      <p>
                        Tạm tính:{" "}
                        <NumberFormat
                          value={isExchange ? Number(row.provisional_exchange) : Number(row.provisional)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!isExchange ? ` ${CURRENCY}` : ""}
                            prefix={isExchange ? `${symbol} ` : ""}
                        />
                      </p>
                    </div>
                  ))}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="total-price">
          <p>
            Tổng tạm tính:{" "}
            <NumberFormat
              value={isExchange ?  Number(totalCartExchange) : Number(totalCart)}
              displayType={"text"}
              thousandSeparator={true}
              suffix={!isExchange ? ` ${CURRENCY}` : ""}
              prefix={isExchange ? `${symbol} ` : ""}
            />
          </p>
          {infoVoucher && typeof infoVoucher === "object" && Object.keys(infoVoucher).length > 0 && (
                  <React.Fragment>
                    {
                      isExchange ? (
                          <React.Fragment>
                            {
                             infoVoucher.value_type === "fixed" ?
                             (
                             <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                               {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                               {` ${symbol} ${Number(infoVoucher.value_exchange)}`}</span></p>
                             ) : (
                              <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                                {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                                {` ${symbol} ${Number((Math.round(((Number(infoVoucher.value) / 100) * Number(totalCartExchange)) *100) / 100).toFixed(2))}`}
                                {` (${Number(infoVoucher.value)}%)`}</span></p>
                             )
                            }
                          </React.Fragment>
                      ) : (
                        <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                          {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                            {
                              infoVoucher.value_type === "fixed" ? (`${Number(infoVoucher.value)} ${CURRENCY}`) : (
                                <React.Fragment>
                                  {`${Number((Math.round(((Number(infoVoucher.value) / 100) * Number(totalCart)) *100) / 100).toFixed(2))} ${CURRENCY}`}
                                  {` (${Number(infoVoucher.value)}%)`}
                                </React.Fragment>
                              )
                            }
                          </span></p>
                      )
                    }
                  </React.Fragment>
                )}
            {rank && rank.price_discount && Number(rank.price_discount) > 0 && (
                  <React.Fragment>
                     {
                        isExchange ? (
                          <React.Fragment>
                              {
                             rank.price_discount_type === "fixed" ?
                             (
                             <p className="priceVoucher">Khuyến mãi thành viên: <span style={{color: '#ff0000'}}>
                               {` ${symbol} ${Number(rank.price_discount_exchange)}`}</span></p>
                             ) : (
                              <p className="priceVoucher">Khuyến mãi thành viên: <span style={{color: '#ff0000'}}>
                                {` ${symbol} ${Number((Math.round(((Number(rank.price_discount) / 100) * Number(totalCartExchange)) *100) / 100).toFixed(2))}`}
                                {` (${Number(rank.price_discount)}%)`}</span></p>
                             )
                            }
                          </React.Fragment>
                        ) : (<React.Fragment>
                         <p className="priceVoucher">Khuyến mãi thành viên:
                          <span style={{color: '#ff0000'}}>
                           {rank.price_discount_type === "fixed" ? (
                            ` ${Number(rank.price_discount)} ${CURRENCY}`
                           ) : (<React.Fragment>
                            {` ${Number((Math.round(((Number(rank.price_discount) / 100) * Number(totalCart)) *100) / 100).toFixed(2))} ${CURRENCY}`}
                            {` (${Number(rank.price_discount)}%)`}
                           </React.Fragment>)}
                          </span></p>
                        </React.Fragment>)
                      }
                  </React.Fragment>
                )}
          <p>
            Phí vận chuyển:{" "}
            {isExchange ? (
                                  <React.Fragment>
                                      {shipment_discount === 0 ? (
                                        <React.Fragment>
                                          {`${symbol} ${Number(shipment_discount)} (${symbol} -${Number(shipment_fee_exchange)})`}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                        {
                                          (rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0) ? (
                                            <React.Fragment>
                                            {rank.shipment_fee_discount_type === "percent" ? 
                                              (` ${symbol} ${Number((Math.round((Number(shipment_fee_exchange) - ((Number(rank.shipment_fee_discount) / 100) * Number(shipment_fee_exchange))) *100) / 100).toFixed(2))}`) : 
                                              (<React.Fragment>
                                                {
                                                  Number(Number(shipment_fee_exchange) - Number(rank.shipment_fee_discount*rate)) > 0 ?
                                                  `${symbol} ${Number((Math.round(Number(Number(shipment_fee_exchange) - Number(rank.shipment_fee_discount*rate)) * 100) / 100).toFixed(2))}` :
                                                  `${symbol} 0`
                                                }
                                              </React.Fragment>)}
                                            </React.Fragment>
                                            ) : (<React.Fragment> {` ${symbol} ${Number(shipment_fee_exchange)}`}</React.Fragment>)}
                                          {
                                            rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0
                                            ? (
                                              <React.Fragment>
                                                {
                                                  rank.shipment_fee_discount_type === "percent" ?
                                                  (` (-${Number(rank.shipment_fee_discount)}%)`) :
                                                  (` (${symbol} -${Number((Math.round((Number(rank.shipment_fee_discount)*Number(rate))* 100) /100).toFixed(2))})`)
                                                }
                                              </React.Fragment>
                                            ) : (<React.Fragment></React.Fragment>)
                                          }
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                      {
                                        shipment_discount === 0 ? (
                                          <React.Fragment>
                                            {`${Number(shipment_discount)} ${CURRENCY} (-${shipment_fee} ${CURRENCY})`}
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                          {
                                            rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0 ? (
                                              <React.Fragment>
                                              {
                                                rank.shipment_fee_discount_type === "percent" ?
                                                (`${
                                                  Number((Math.round((Number(shipment_fee)
                                                  - ((Number(rank.shipment_fee_discount) / 100) * Number(shipment_fee))) *100) / 100).toFixed(2))
                                                }
                                                ${CURRENCY}`) : (`${
                                                  Number(Number(shipment_fee) - Number(rank.shipment_fee_discount)) > 0 ?
                                                  Number((Math.round((Number(shipment_fee) - Number(rank.shipment_fee_discount)) * 100) / 100).toFixed(2)) : 0
                                                } ${CURRENCY}`)
                                              }
                                              </React.Fragment>
                                            ) : (<React.Fragment>{`${Number(shipment_fee)} ${CURRENCY}`}</React.Fragment>)
                                          }
                                          {
                                            rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0
                                            ? (
                                              <React.Fragment>
                                                {
                                                  rank.shipment_fee_discount_type === "percent" ? 
                                                  (` (-${rank.shipment_fee_discount}%)`) : (` (-${rank.shipment_fee_discount} ${CURRENCY})`)
                                                }
                                              </React.Fragment>
                                            ) : (<React.Fragment></React.Fragment>)
                                          }
                                          </React.Fragment>
                                        )
                                      }
                                  </React.Fragment>
                                )
                                }
          </p>
          <p>
            Tổng cộng:{" "}
            <NumberFormat
              value={isExchange ? Number(totalPaymentExchange) : Number(totalPayment)}
              displayType={"text"}
              thousandSeparator={true}
              suffix={!isExchange ? ` ${CURRENCY}` : " "}
              prefix={isExchange ? `${symbol} ` : ""}
            />
            {
              totalPaymentExchange && Number(totalPaymentExchange) > 0 ? (
                  <NumberFormat value={Number((Math.round((totalPaymentExchange/rate) * 100) / 100).toFixed(2))}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={` (`}
                  suffix={` ${CURRENCY})`}
                  />
              ) : (<React.Fragment></React.Fragment>)
            }
          </p>
          {isAuth && (<p className="priceVoucherPoint">
                  Điểm tích lũy thành viên: {
                    infoVoucher &&
                    typeof infoVoucher === "object"
                    && Object.keys(infoVoucher).length > 0
                    && rank && rank.price_discount
                    && Number(rank.price_discount) > 0 ? (
                        <React.Fragment>
                          {
                            isExchange ? (
                          <React.Fragment>
                                 {
                            infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor((Math.round((Number(totalCartExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor((Math.round((Number(totalCartExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                 infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1 )) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1 )) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />) : (<React.Fragment>
                              {infoVoucher.value_type === "percent" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`} />) : (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - Number(infoVoucher.value_exchange)
                                - (Number(rank.price_discount) / 100) * Number(totalCartExchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCartExchange)
                                - Number(infoVoucher.value_exchange)
                                - (Number(rank.price_discount) / 100) * Number(totalCartExchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                          </React.Fragment>
                            ) : (<React.Fragment>{ 
                              infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor(Number((Math.round((Number(totalCart)
                                - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Number((Math.round((Number(totalCart)
                                - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                  infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                                     <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCart))
                                        - ((Number(rank.price_discount) / 100) * Number(totalCart))) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />) : (<React.Fragment>
                                    {
                                     infoVoucher.value_type === "fixed" && rank.price_discount_type === "percent" ? ( <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCart))) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCart))) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />) : (
                                      <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCart))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCart)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCart))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />
                                    )
                                    }
                                  </React.Fragment>)
                                }
                              </React.Fragment>
                            )}
                              </React.Fragment>
                            )
                          }
                        </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                         (!infoVoucher ||
                         typeof infoVoucher !== "object"||
                         Object.keys(infoVoucher).length === 0)
                         && (!rank || !rank.price_discount ||
                         Number(rank.price_discount) === 0) ? (<React.Fragment>
                            {
                            isExchange ? (
                              <NumberFormat
                              value={
                                Number((Math.floor(Math.floor(Math.floor(Number(totalCartExchange)/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Math.floor(Math.floor(Number(totalCartExchange)/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <NumberFormat
                              value={
                                Number((Math.floor(Math.floor(Number(totalCart))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Math.floor(Number(totalCart))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            )
                          }
                        </React.Fragment>) : (<React.Fragment>
                            {
                             (infoVoucher ||
                              typeof infoVoucher === "object"||
                              Object.keys(infoVoucher).length > 0)
                              && (!rank || !rank.price_discount ||
                              Number(rank.price_discount) === 0) ? (
                                <React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       infoVoucher.value_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.floor((Number(Math.floor(Number((Math.round((Number(totalCartExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2)))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor((Number(Math.floor(Number((Math.round((Number(totalCartExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2)))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />) : (<NumberFormat
                                    value={
                                      Number((Math.floor((Number(Math.floor((Number((Math.round((Number(totalCartExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2))))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor((Number(Math.floor((Number((Math.round((Number(totalCartExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2))))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        infoVoucher.value_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCart))) *100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCart))) *100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />) : (<NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                              ) : (<React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       rank.price_discount_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.floor(Number(Math.floor(Number((Math.round((Number(totalCartExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor(Number(Math.floor(Number((Math.round((Number(totalCartExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCartExchange))) *100) / 100).toFixed(2))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />) : (<NumberFormat
                                    value={
                                      Number((Math.floor(Number(Math.floor(Number(Number((Math.round((Number(totalCartExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor(Number(Math.floor(Number(Number((Math.round((Number(totalCartExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        rank.price_discount_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.floor((Number((Math.round((Number(totalCart)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCart))) *100) / 100).toFixed(2)))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor((Number((Math.round((Number(totalCart)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCart))) *100) / 100).toFixed(2)))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />) : (<NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCart)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>)
                            }
                        </React.Fragment>)
                        }
                      </React.Fragment>
                    )
                  }
                </p>)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DetailCart)));
