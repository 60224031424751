import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/core/styles';
import enLocale from 'date-fns/locale/en-US';
import viLocale from 'date-fns/locale/vi';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import messages_en from './locales/en/messages.json';
import messages_vi from './locales/vi-vn/messages.json';
import Routes from './routes/Routes';
import maTheme from './theme';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const messages = {
	en: messages_en,
	vi: messages_vi,
};

Sentry.init({
	dsn: "https://6f204eb37db7449d9ca6ef32eb6e286a@o1009215.ingest.sentry.io/5973245",
	integrations: [new Integrations.BrowserTracing()],
  
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
  });

function App({ theme, language }) {
	return (
		<StylesProvider injectFirst>
			<MuiPickersUtilsProvider
				utils={DateFnsUtils}
				locale={language.language === 'en' ? enLocale : viLocale}>
				<MuiThemeProvider theme={maTheme[theme.currentTheme]}>
					<ThemeProvider theme={maTheme[theme.currentTheme]}>
						<IntlProvider
							locale={language.language}
							messages={messages[language.language]}>
							<Routes />
						</IntlProvider>
					</ThemeProvider>
				</MuiThemeProvider>
			</MuiPickersUtilsProvider>
		</StylesProvider>
	);
}

export default connect((store) => ({
	theme: store.themeReducer,
	language: store.languageReducer,
}))(App);
