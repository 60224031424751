const userMenu = [
	{
		path: '/user/account',
		name: 'User Account',
	},
	{
		path: '/user/accumulated-points',
		name: 'Accumulated Points',
	},
	{
		path: '/user/favorite-product',
		name: 'My Favorite Product',
	},
	{
		path: '/user/my-order',
		name: 'My Order',
	},
	{
		path: '/user/change-password',
		name: 'Change Password',
	},
	{
		path: '/user/delivery-address',
		name: 'Delivery Address',
	},
	// {
	// 	path: '/user/logout',
	// 	name: 'Logout',
	// },
];

export default userMenu;
