import { darken } from "@material-ui/core";

const style = (theme) => ({
  authPage: {
    maxWidth: "465px",
    margin: "0px auto",
    padding: "60px 15px",
    "& *": {
      // fontFamily: 'Aleo'
    },
    "& label": {
      marginBottom: "12px",
    },
  },
  signUpPage: {
    maxWidth: "960px",
    margin: "0px auto",
    padding: "60px 15px",
    "& *": {
      // fontFamily: 'Aleo'
    },
    "& h2": {
      fontSize: "1.5rem",
      fontWeight: "500",
      textTransform: "capitalize",
      marginBottom: "15px",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(0, 2px) scale(0.85)",
    },
    "& label": {
      marginBottom: "12px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px",
    },
    "& .css-yk16xz-control": {
      borderRadius: "0px",
      padding: "2.5px",
    },
    "& .css-buk575-control": {
      padding: "2.5px",
      borderColor: '#f44336!important',
      boxShadow: '0 0 0 1px #f44336'
    },
    "& .css-1pahdxg-control": {
      borderRadius: "0px!important",
      padding: "2.5px",
      borderColor: '#1565c0!important',
      boxShadow: '0 0 0 1px #1565c0'
    },
    '& .location-search-input': {
      width: '100%',
      borderRadius: '0px',
      '&:hover': {
        borderColor: '#000'
      },
      '&:focus':{
        outline: '2px solid #1565c0',
        border: '1px solid #f000'
      }
    },
    '& .autocomplete-dropdown-container':{
      position: 'absolute',
      zIndex: '10',
      width: '100%',
      left: '0px',
      '& *': {
        padding: '10px 8px',
      }
    }

  },
  txtControl: {
    marginTop: "0px",
    marginBottom: "30px",
    "& input": {
      padding: "13px 15px",
      fontSize: "15px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "0px",
    },
    "& .MuiInputAdornment-root": {
      color: "#c8c8c8",
    },

    "& .MuiInput-underline": {
      "&::before, &::after, &:hover": {
        borderBottomColor: "#c8c8c8",
      },
      "&:hover::before": {
        borderBottomColor: "#c8c8c8",
      },
    },

    "& .MuiInput-underline.Mui-error": {
      "&::before, &::after, &:hover": {
        borderBottomColor: "#f44336",
      },
    },
  
  },
  selectControl: {
    width: "100%",
  },

  mB10: {
    marginBottom: "10px",
  },

  mb50: {
    marginBottom: "50px",
  },

  mt50: {
    marginTop: "50px",
  },
  gridBox: {
    display: "grid",
    gridTemplateColumns: "110px 1fr",
    marginTop: "12px",
  },

  divider: {
    color: "#c8c8c8",
    textAlign: "center",
    position: "relative",
    margin: "10px 0",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      width: "50px",
      height: "1px",
      backgroundColor: "#e8e8e8",
    },
    "&::before": {
      left: "calc((100% /2) - 70px)",
    },
    "&::after": {
      right: "calc((100% /2) - 70px)",
    },
  },

  txtSuccess: {
    color: "#f2c123",
    fontSize: "15px",
  },

  txtError: {
    color: "#d02e2e",
    fontSize: "15px",
    textAlign: "left",
    marginBottom: "15px",
  },

  textTransform: {
    textTransform: "uppercase",
  },

  iconGG: {
    width: "24px",
    height: "24px",
    objectFit: "contain",
    borderRadius: "4px",
  },

  btnFb: {
    backgroundColor: "#3b5997",
    color: "#fff",
    height: "54px",
    width: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    margin: '0px 0px 0px auto',
    "&:hover": {
      backgroundColor: darken("#3b5997", 0.2),
      color: "#fff  !important",
    },

  },

  btnGG: {
    backgroundColor: "#4485f2",
    color: "#fff",
    height: "54px",
    width: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    margin: '0px auto 0px 0px',
    "&:hover": {
      backgroundColor: darken("#4485f2", 0.2),
      color: "#fff  !important",
    },
  },

  btnIconLeft: {
    "& .MuiButton-label": {
      position: "relative",
      fontWeight: "500",
      "& .MuiButton-startIcon": {
        position: "absolute",
        left: "-8px",
      },
    },
  },

  headingAuth: {
    fontSize: "2.1875rem",
    lineHeight: "38px",
    fontWeight: "500",
    marginBottom: "35px",
  },
  btnSubmit: {
    height: "48px",
    borderRadius: "30px",
    backgroundColor: "#f2c123!important",
    border: "1px solid #f2c123!important",
    textTransform: "uppercase",
    fontSize: "0.875rem",
    marginTop: "4px",
    color: "#fff",
  },
  btnSignUp: {
    height: "48px",
    width: "100%",
    maxWidth: "465px",
    borderRadius: "30px",
    backgroundColor: "#f2c123!important",
    border: "1px solid #f2c123!important",
    textTransform: "uppercase",
    fontSize: "0.875rem",
    margin: "25px auto",
    color: "#fff",
  },
  autoAddress: {
    "&:focus": {
      outline: "none",
      border: "2px solid #1565c0",
    },
  },
  helperTextError: {
    bottom: '-22px',
    left: '0px',
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  }
});

export default style;
