import * as types from '../constants';
import LocalStorageService from "./../../services/LocalStorageService";

let language = 'vi';
const localStorageService = LocalStorageService.getService();

if (localStorageService.getLanguage()) {
  language = localStorageService.getLanguage();
}

export default function reducer(state = { language }, actions) {
  switch (actions.type) {

    case types.SET_LANGUAGE:
      return {
        ...state,
        language: actions.payload
      }

    default:
      return state
  }
}
