import React from "react";
import DefaultImage from "./../../assets/images/default.jpg";
import { Skeleton } from "@material-ui/lab";

function elementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export default class LazyImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.handleScroll();

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.state.loaded && elementInViewport(this.imgElm)) {
      // Load real image
      const imgLoader = new Image();
      imgLoader.src = this.props ? this.props.src : DefaultImage;
      imgLoader.onload = () => {
        const ratioWH = imgLoader.width / imgLoader.height;

        this.imgElm &&
          this.imgElm.setAttribute(
            `src`,
            `${this.props ? this.props.src : DefaultImage}`
          );

        this.props &&
          this.props.keepRatio &&
          this.imgElm &&
          this.imgElm.setAttribute(
            `height`,
            `${this.props.width ? this.props.width / ratioWH : 100 / ratioWH}`
          );

        this.imgElm &&
          this.imgElm.classList.add(
            `${this.props ? this.props.effect : "effect"}`
          );

        this.setState({
          loaded: true,
        });
      };

      imgLoader.onerror = () => {
        const ratioWH = imgLoader.width / imgLoader.height;

        this.imgElm && this.imgElm.setAttribute(`src`, `${DefaultImage}`);

        this.props &&
          this.props.keepRatio &&
          this.imgElm &&
          this.imgElm.setAttribute(
            `height`,
            `${this.props.width ? this.props.width / ratioWH : 100 / ratioWH}`
          );

        this.imgElm &&
          this.imgElm.classList.add(
            `${this.props ? this.props.effect : "effect"}`
          );

        this.setState({
          loaded: true,
        });
      };
    }
  }

  render() {
    const width = this.props.width || "100%";
    const height = this.props.height || "100%";

    const { loaded } = this.state;
    const { heightSke } = this.props;

    return (
      <React.Fragment>
        <img
          width={width}
          height={height}
          ref={(imgElm) => (this.imgElm = imgElm)}
          className="lazy-image"
          alt={this.props.alt}
          style={!loaded ? { display: "none" } : { display: "block" }}
        />
        {!loaded && (
          <Skeleton
            animation="wave"
            variant="rect"
            height={heightSke ? heightSke : 190}
            style={{ marginBottom: 6 }}
          />
        )}
      </React.Fragment>
    );
  }
}
