import React from "react";
import { Users } from "react-feather";
// import async from "../components/Async";
import Cart from "../pages/cart";
import Home from "../pages/home";
import ProductDetail from "../pages/product/ProductDetail";
import ListProduct from "../pages/product/ListProduct";
import Content from "../pages/content";
import Search from "../pages/search";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import RegisterNewPassword from "../pages/auth/RegisterNewPassword";
import HybridSignIn from "../pages/auth/HybridSignIn";
import ActiveAccount from "../pages/auth/ActiveAccount";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import SignUpSuccess from "../pages/auth/SignUpSuccess";
import User from "../pages/user";
import CheckoutSuccess from "../pages/checkout/CheckoutSuccess";
import DeliveryAddress from "../pages/checkout/DeliveryAddress";
import Checkout from "../pages/checkout";
import ListTypeProduct from "../pages/product/ListTypeProduct";
import CheckoutFailed from "../pages/checkout/CheckoutFailed";
import ListBlog from "../pages/blog/ListBlog";
import ViewBlog from "../pages/blog/ViewBlog";

// // Auth components
// const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
// const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
// const RegisterNewPassword = async(() =>
//   import("../pages/auth/RegisterNewPassword")
// );
// const HybridSignIn = async(() => import("../pages/auth/HybridSignIn"));
// const ActiveAccount = async(() => import("../pages/auth/ActiveAccount"));
// const Page404 = async(() => import("../pages/auth/Page404"));
// const Page500 = async(() => import("../pages/auth/Page500"));
// const SignUpSuccess = async(() => import("../pages/auth/SignUpSuccess"));

// // // Dashboards components
// const Home = async(() => import("../pages/home"));
// const User = async(() => import("./../pages/user"));

// // Product components
// const ProductDetail = async(() => import("../pages/product/ProductDetail"));
// const ListProduct = async(() => import("../pages/product/ListProduct"));
// const Search = async(() => import("../pages/search"));

// //Conten components
// const Content = async(() => import("../pages/content/index"));

// //Cart - Checkout
// const CheckoutSuccess = async(() => import("../pages/checkout/CheckoutSuccess"));
// const DeliveryAddress = async(() => import("../pages/checkout/DeliveryAddress"));
// const Checkout = async(() => import("../pages/checkout"));

const productRoutes = {
  id: "Product",
  path: "/product/:slug.id=:id",
  component: ProductDetail,
};

const listProductRoutes = {
  id: "List Product",
  path: "/categories/:slug.id=:id",
  component: ListProduct,
};

const cartRoutes = {
  id: "Cart",
  path: "/cart",
  component: Cart,
};

const contentRoutes = {
  id: "Content",
  path: "/trang/:type",
  component: Content,
};

const dashboardsRoutes = {
  id: "Trang chủ",
  path: "/",
  exact: true,
  component: Home,
};

const searchRoutes = {
  id: "Tìm kiếm",
  path: "/search/search=:name",
  exact: true,
  component: Search,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/register-new-password",
      name: "Reset New Password",
      component: RegisterNewPassword,
    },
    {
      path: "/auth/hybrid-sign-in",
      name: "Reset Password",
      component: HybridSignIn,
    },
    {
      path: "/auth/active-account",
      name: "ActiveAccount",
      component: ActiveAccount,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/sign-up-success",
      name: "Sign Up Success",
      component: SignUpSuccess,
    },
  ],
};

const userRoutes = {
  id: "User",
  path: "/user/:type",
  name: "User Account",
  icon: <Users />,
  component: User,
};

const orderLPRoutes = {
  id: "orderLPRoutes",
  path: "/chinh-sach-dat-hang",
  component: Content,
};
const promotionLPRoutes = {
  id: "promotionLPRoutes",
  path: "/khuyen-mai",
  component: Content,
};
const menuLPRoutes = {
	id: 'menuLPRoutes',
	path: '/thuc-don',
	component: Content
}
const policyReturnLPRoutes = {
	id: 'policyReturnLPRoutes',
	path: '/chinh-sach-doi-tra',
	component: Content
}
const deliveryLPRoutes = {
	id: 'deliveryLPRoutes',
	path: '/giao-nhan-hang',
	component: Content
}
const policySecurityLPRoutes = {
	id: 'policySecurityLPRoutes',
	path: '/chinh-sach-bao-mat',
	component: Content
}
const listBlogRoutes = {
  id: 'listBlog',
  path: '/blog',
  component: ListBlog
}

//cart - checkout
const checkoutSuccessRoutes = {
  id: 'checkoutSuccess',
  path: '/checkout-success',
  component: CheckoutSuccess
}

const checkoutFailedRoutes = {
  id: 'checkoutFailed',
  path: '/checkout-failed',
  component: CheckoutFailed
}

const deliveryAddressRoutes = {
  id: 'deliveryAddress',
  path: '/delivery-address',
  component: DeliveryAddress
}

const checkoutRoutes = {
  id: 'checkoutRoutes',
  path: '/checkout',
  component: Checkout
}

//list product discount
const productDiscountRoutes = {
  id: 'listProductDiscount',
  path: '/san-pham-khuyen-mai',
  component: ListTypeProduct
}

//list blogs
const viewBlogRoutes = {
  id: 'viewBlog',
  path: '/blog/:slug',
  component: ViewBlog
}

export const protectedRoutes = [ ];

export const userManagerRoutes = [userRoutes];

export const publicRoutes = [
  dashboardsRoutes,
  productRoutes,
  cartRoutes,
  listProductRoutes,
  contentRoutes,
  searchRoutes,
  orderLPRoutes,
  promotionLPRoutes,
  menuLPRoutes,
  policyReturnLPRoutes,
  deliveryLPRoutes,
  policySecurityLPRoutes,
  checkoutSuccessRoutes,
  authRoutes,
  deliveryAddressRoutes,
  checkoutRoutes,
  productDiscountRoutes,
  checkoutFailedRoutes,
  listBlogRoutes,
  viewBlogRoutes
];
