const style = (theme) => ({
    titleHome: {
      margin: "20px auto",
      textAlign: "center",
      overflow: "hidden",
      "& h2": {
        fontSize: "1.875rem",
        position: "relative",
        display: "inline-block",
        fontWeight: "400",
        letterSpacing: "5px",
        margin: "0px",
        "&::after": {
          width: "40px",
          content: '""',
          height: "1px",
          background: "#000",
          position: "absolute",
          top: "50%",
          right: "-50px",
        },
        "&::before": {
          width: "40px",
          content: '""',
          height: "1px",
          background: "#000",
          position: "absolute",
          top: "50%",
          left: "-50px",
        },
      },
    },
    cp: {
      padding: '30px 0px',
      [theme.breakpoints.down("sm")]: {
        padding: '10px 0px'
      },
      "& .cpList": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        gap: "10px",
        "& .cpItem": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          textAlign: "center",
          alignItems: 'center',
          border: '1px solid #fff0',
          padding: '5px',
          position: 'relative',
          "& .cpSubCats": {
            display: 'none',
            textAlign: 'left',
            alignsSelf: 'flex-start'
          },
          "& .cpThumb": {
            borderRadius: "50%!important",
            overflow: "hidden",
            marginBottom: "10px",
            width: "130px",
            height: "130px",
            "& a":{
              display: 'block',
              height: '100%'
            },
            "& img": {
              width: "100%",
              height: "100%",
            },
          },
          "& a": {
            display: "block",
            fontSize: "1.0625rem",
            color: "#26292e",
            minHeight: '52px',
            "&:hover": {
                color: "#f2c123",
            },
            [theme.breakpoints.down("xs")]: {
              fontSize: "0.8125rem",
            },
          },
          "&:hover": {
            border: '1px solid #f2c123',
            cursor: 'pointer',
            "& .cpParentName":{
              fontWeight: 'bold',
              fontSize: '1.125rem',
              color: "#f2c123",
            },
            "& .cpSubCats": {
              display: 'block',
              position: 'absolute',
              width: 'calc(100% + 2px)',
              top: '100%',
              zIndex: '100',
              background: '#fff',
              border: '1px solid #f2c123',
              borderTop: '0px',
              padding: '10px',
              paddingTop: '0px',
              left: '-1px',
              '& li a': {
                minHeight: '0px',
                marginBottom: '10px'
              }
            }
          }
        },
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
        },
        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
          "& .cpItem1 , & .cpItem4": {
              gridColumnStart: 1,
              gridColumnEnd: 3,
            },
            "& .cpItem2 , & .cpItem5 ": {
              gridColumnStart: 3,
              gridColumnEnd: 5,
            },
            "& .cpItem3 , & .cpItem6": {
              gridColumnStart: 5,
              gridColumnEnd: 7,
            },
          "& .cpItem7": {
            gridColumnStart: 2,
            gridColumnEnd: 4,
          },
          "& .cpItem8": {
            gridColumnStart: 4,
            gridColumnEnd: 6,
          },
          "& .cpItem": {
              '& .cpThumb': {
                  borderRadius: "50%!important",
                  overflow: "hidden",
                  marginBottom: "30px",
                  width: "70px!important",
                  height: "70px!important",
                  "& img": {
                    width: "100%",
                    height: "100%",
                  },
                },
          }
        },
      },
    },
  });
  
  export default style;
  