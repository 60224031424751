import {
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";
import { setModalLogoutAction } from "../../redux/actions/modalActions";
import style from "./style";
import UserIcon from './../../assets/images/user-icon.png';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const StyledMenu = withStyles({})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorMenu: null,
      open: false,
      loading: false,
    };
    this.anchorRef = React.createRef();
  }

  toggleMenu = (event) => {
    this.setState({ anchorMenu: event.currentTarget });
  };

  closeMenu = (action) => {
    this.setState({ anchorMenu: null });
    if (action === "signOut") {
      this.props.setModalLogout(true);
    } else if (action === "profile") {
      this.props.history.push("/user/account");
    } else if (action === "mypage") {
      this.props.history.push("/user/my-page");
    } else if (action === "login") {
      this.props.history.push("/auth/sign-in");
    }
  };

  handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  render() {
    const { anchorMenu } = this.state;
    const { classes, intl } = this.props;
    const open = Boolean(anchorMenu);

    return (
      <React.Fragment>
        <IconButton
          aria-owns={open ? "menu-appbar" : undefined}
          aria-haspopup={true}
          ref={this.anchorRef}
          onClick={this.toggleMenu}
          color="inherit"
          className={classes.btnUser}
        >
         <img src={UserIcon} alt="" />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorMenu}
          keepMounted
          open={Boolean(anchorMenu)}
          onClose={() => {
            this.closeMenu("");
          }}
        >
          <MenuItem
            onClick={() => {
              this.closeMenu("profile");
            }}
          >
            {intl.formatMessage({
              id: "Nav.Profile",
            })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.closeMenu("signOut");
            }}
          >
            {intl.formatMessage({
              id: "Signout",
            })}
          </MenuItem>
        </StyledMenu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    logout: store.authReducer.logout,
    user: store.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setModalLogout: (logout) => {
      dispatch(setModalLogoutAction(logout));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withStyles(style)
)(withRouter(withTheme(injectIntl(UserMenu))));
