
import APIFunction from '../services';

//get content detail
export const getContent = (params) => {
    return APIFunction.getContent(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}


