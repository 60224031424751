import iconUpload from './../../../../assets/images/upload_icon.png';

const style = (theme) => ({
	avatar: {
		position: 'relative',
		width: '150px',
		height: '150px',
		display: 'flex',
		'-webkit-box-pack': 'center',
		'-webkit-justify-content': 'center',
		'-ms-flex-pack': 'center',
		justifyContent: 'center',
		border: '1px dashed #9b9b9b',
		borderRadius: '50%',
		cursor: 'pointer',
		backgroundColor: '#f7f7f7',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		overflow: 'hidden',
		backgroundSize: '60% 60%',
		backgroundImage: `url(${iconUpload})`,
		marginBottom: theme.spacing(5),
		[theme.breakpoints.up('sm')]: {
			width: '200px',
			height: '200px',
		},
	},
	imgageLoading: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		zIndex: 4,
		opacity: 0.6,
	},
	imgagePreview: {
		height: 'auto',
		width: '100%',
		borderRadius: '50%',
		zIndex: 1,
		objectFit: 'cover',
	},
	inputUpload: {
		display: 'block',
		opacity: 0,
		borderRadius: '50%',
		position: 'absolute',
		width: '150px',
		height: '150px',
		cursor: 'pointer',
		zIndex: 2,
		[theme.breakpoints.up('sm')]: {
			width: '200px',
			height: '200px',
		},
	},
	boxListNav: {
		borderRight: '1px solid #f3f3ef',
		height: '100%'
	},
	listNav: {
		// paddingLeft: '0px',
		'& .MuiListItemIcon-root': {
			minWidth: '40px',
		},
		'& .MuiListItemText-root': {
			'& a': {
				textDecoration: 'none',
				color: '#4c5152',
				fontSize: '16px',
			},
		},
		'& .active': {
			'& .MuiListItemText-root': {
				'& a': {
					color: '#f2c123',
				},
			},
		},
		'& .logout': {
			'& .MuiTypography-root': {
				textDecoration: 'none',
				color: '#4c5152',
				fontSize: '16px',
				cursor: 'pointer',
			},
		},
	},
	userName: {
		color: '#212529',
		fontWeight: 500,
	},
	userTitle: {
		color: '#212529',
		fontWeight: 400,
		marginBottom: '50px',
	},
});

export default style;
