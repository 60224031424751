import { Box, List, ListItem, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import { setModalLogoutAction } from "../../../../redux/actions/modalActions";
import {
  setUserAvatarAction,
  setUserInfoAction,
} from "../../../../redux/actions/userActions";
import userMenu from "./../../../../routes/menu";
import style from "./style";
import { queryUserInfo } from "../../../../models";
import { parse } from "date-fns";
import { dateFormat } from "./../../../../utills/config";
import styled from "styled-components";

const ListItemCus = styled(ListItem)`
  padding-left: 0px;
`;

const NavigationItem = ({ label, to, activeOnlyWhenExact, icon }) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <ListItemCus className={match ? "active" : ""}>
      <ListItemText primary={<Link to={to}>{label}</Link>} />
    </ListItemCus>
  );
};

const NavigationItemLogout = ({ label, to, activeOnlyWhenExact, onClick }) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <ListItemCus className={match ? "logout active" : "logout"}>
      <ListItemText primary={label} onClick={onClick} />
    </ListItemCus>
  );
};

class LeftSidebar extends Component {
  state = {
    avatar: "",
    imagePreview: "",
    loading: false,
    sbColor: "error",
    sbMessage: "",
    isOpen: false,
  };

  onLogout = () => {
    this.props.setModalLogout(true);
  };

  fetchUserInfo = () => {
    let that = this;
    queryUserInfo({ id: 1 }).then((res) => {
      if (res.success) {
        const user = {
          id: res.id,
          fullname: res.fullName ? res.fullName : "",
          email: res.email ? res.email : "",
          phone: res.phone ? res.phone : "",
          avatar: res.avatar ? res.avatar : "",
          birthday: res.birthday
            ? parse(res.birthday, dateFormat, new Date())
            : null,
        };
        that.setState({
          isOpen: false,
          sbMessage: "",
        });
        that.props.setUserInfo(user);
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.message,
          sbColor: "error",
        });
      }
    });
  };

  render() {
    const { classes, intl } = this.props;

    return (
      <div className={classes.boxListNav}>
        <Box>
          <List
            className={classes.listNav}
            component="nav"
            aria-label="navgation"
          >
            {userMenu && Array.isArray(userMenu) && userMenu.length > 0 && userMenu.map((item) => {
              if (item.name === "Logout") {
                return (
                  <NavigationItemLogout
                    to={item.path}
                    label={intl.formatMessage({
                      id: `${item.name}`,
                    })}
                    key={item.name}
                    onClick={this.onLogout}
                  />
                );
              } else {
                return (
                  <NavigationItem
                    to={item.path}
                    label={intl.formatMessage({
                      id: `${item.name}`,
                    })}
                    icon={item.icon}
                    key={item.name}
                  />
                );
              }
            })}
          </List>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.userReducer.user,
    language: store.languageReducer.language,
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setModalLogout: (logout) => {
      dispatch(setModalLogoutAction(logout));
    },
    setUserAvatar: (avatar) => {
      dispatch(setUserAvatarAction(avatar));
    },
    setUserInfo: (user) => {
      dispatch(setUserInfoAction(user));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(injectIntl(LeftSidebar));
