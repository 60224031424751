const style = (theme) => ({
    productFilter: {
        width: '165px',
        marginLeft: 'auto',
        marginRight: '10px'
      },
      formControl: {
        marginTop: '0',
        marginBottom: '30px',
        '& label': {
          marginBottom: '12px'
        },
        '& input': {
          padding: '13px 15px',
          fontSize: '15px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '0px',
          border: '1px solid #f3f3ef'
        },
        '& .MuiSelect-outlined.MuiSelect-outlined':{
          width: '135px',
          padding: '14.5px 14px',
          fontFamily: 'Tinos'
        },
        '& .PrivateNotchedOutline-legendNotched-199':{
          display: 'none'
        },
        '& .MuiInputBase-input:focus': {
          background: '#fff'
        }
      },
});

export default style;
