import { Box, Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { setModalLogoutAction } from "../../../../redux/actions/modalActions";
import {
  setUserAvatarAction,
  setUserInfoAction,
} from "../../../../redux/actions/userActions";
import style from "./style";
import { updateUserInfo, queryUserInfo } from "../../../../models";
import { parse } from "date-fns";
import { dateFormat } from "./../../../../utills/config";
import { MySnackbarContentWrapper } from "../../../../components/MySnackbarContentWrapper";
import iconloading from "./../../../../assets/images/loading.gif";
import CreateIcon from '@material-ui/icons/Create';
import styled from "styled-components";


const EditAvatar = styled(Box)`
    width: 26px;
    height: 26px;
    z-index: 99;
    position: absolute;
    top: 0px;
    background: #353535;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    font-size: 10px;
    color: #fff;
    text-align: center;
    right: 0px;
    &:hover{
      cursor: pointer;
    }
`;
class Avatar extends Component {
  state = {
    avatar: "",
    imagePreview: "",
    loading: false,
    sbColor: "error",
    sbMessage: "",
    isOpen: false,
  };

  onLogout = () => {
    this.props.setModalLogout(true);
  };

  onChange = (e) => {
    const imageToUpload = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (avatar) => {
      const image = avatar.target.result;
      this.setState({ imagePreview: image });
      this.onSubmit(image);
      this.props.setUserAvatar(image);
    };
    if (imageToUpload && imageToUpload.type.match("image.*")) {
      reader.readAsDataURL(imageToUpload);
    }
  };

  fetchUserInfo = () => {
    let that = this;
    queryUserInfo({ id: 1 }).then((res) => {
      if (res.success) {
        const user = {
          id: res.id,
          fullname: res.fullName ? res.fullName : "",
          email: res.email ? res.email : "",
          phone: res.phone ? res.phone : "",
          avatar: res.avatar ? res.avatar : "",
          birthday: res.birthday
            ? parse(res.birthday, dateFormat, new Date())
            : null,
        };
        that.setState({
          isOpen: false,
          sbMessage: "",
        });
        that.props.setUserInfo(user);
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.message,
          sbColor: "error",
        });
      }
    });
  };

  onSubmit = (image) => {
    const { user } = this.props;
    this.setState({ loading: true });
    const that = this;
    const req = {
      id: user.id,
      image,
      update_flg: "img",
    };
    updateUserInfo(req).then((res) => {
      if (res.success) {
        that.setState({
          isOpen: true,
          sbMessage: <FormattedMessage id={res.title} />,
          sbColor: "success",
          loading: false,
        });
        this.fetchUserInfo();
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.message,
          sbColor: "error",
          loading: false,
        });
      }
    });
  };

  render() {
    const { classes, user } = this.props;
    const { imagePreview, isOpen, sbColor, sbMessage, loading } = this.state;

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          open={isOpen}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box alignItems="center" style={{position: 'relative'}}>
            <div className={classes.avatar}>
              {imagePreview || user.avatar ? (
                <img
                  src={imagePreview || user.avatar}
                  alt={user.fullname}
                  className={classes.imgagePreview}
                />
              ) : null}
              {loading ? (
                <img
                  src={iconloading}
                  alt="loading"
                  className={classes.imgageLoading}
                />
              ) : null}

              <input
                type="file"
                accept="image/*"
                className={classes.inputUpload}
                onChange={this.onChange}
              />
              <EditAvatar>
            <CreateIcon fontSize="small" />
            </EditAvatar>
            </div>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.userReducer.user,
    language: store.languageReducer.language,
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setModalLogout: (logout) => {
      dispatch(setModalLogoutAction(logout));
    },
    setUserAvatar: (avatar) => {
      dispatch(setUserAvatarAction(avatar));
    },
    setUserInfo: (user) => {
      dispatch(setUserInfoAction(user));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(injectIntl(Avatar));
