import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { publicRoutes, protectedRoutes, userManagerRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { connect } from "react-redux";

const getUserConfirmation = (dialogKey, callback) => {
  // use "message" as Symbol-based key
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }

  // Fallback to allowing navigation
  callback(true);
};

const childRoutes = (Layout, routes, isAuthed, token) => {
  return routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) =>
            isAuthed ? (
              token ? (
                <Layout>
                  <Component {...props} />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth/sign-in",
                    state: { from: props.location },
                  }}
                />
              )
            ) : (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          }
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) =>
          isAuthed ? (
            token ? (
              <Layout>
                <Component {...props} />
              </Layout>
            ) : (
              <Redirect
                to={{
                  pathname: "/auth/sign-in",
                  state: { from: props.location },
                }}
              />
            )
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
      />
    )
  );
};

const Routes = (props) => (
  <Router getUserConfirmation={getUserConfirmation}>
    <Switch>
      {childRoutes(DashboardLayout, publicRoutes, false, props.token)}
      {childRoutes(DashboardLayout, protectedRoutes, true, props.token)}
      {childRoutes(AuthLayout, userManagerRoutes, true, props.token)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default connect((store) => ({
	token: store.authReducer.token,
}))(Routes);
