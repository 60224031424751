import React, { Component } from "react";
import { Container, Grid, withStyles } from "@material-ui/core";
import NotFoundImage from '../../assets/images/cart-empty-img.png';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import style from './style';

class NotFoundPost extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.notFoundProduct}>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xl={12} sm={12}>
                <div className="thumb">
                    <img src={NotFoundImage} alt="Hiện tại không có bài viết" />
                </div>
                <p>Hiện tại không có bài viết</p>
                <Link to="/" >Tiếp tục mua sắm</Link>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}


const mapStateToProps = (store) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch, ownDispatch) => {
    return {
    };
  };
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default compose(
    withStyles(style),
    withConnect
  )(withRouter(injectIntl(NotFoundPost)));
  
