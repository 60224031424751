import * as types from '../constants';

export default function reducer(state={ currentBreadcrumb: 'Dashboard'}, actions) {
  switch (actions.type) {

    case types.SET_BREADCRUMB:
      return {
        ...state,
        currentBreadcrumb: actions.payload
      }

    default:
      return state
  }
}
