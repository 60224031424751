import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { injectIntl } from "react-intl";
import { withStyles, Container, Grid } from "@material-ui/core";
import { getListCart, getListOrder, queryOrder, updateCart } from "../../../models/cart";
import styled from "styled-components";
import Detail from "./component/Detail";
import PaginationUI from "../../../components/UI/PaginationUI";
import MobileTemplate from "./component/MobileTemplate";
import DesktopTemplate from "./component/DesktopTemplate";
import LeftSidebar from "../components/LeftSidebar";
import Skeleton from '@material-ui/lab/Skeleton';
import { Helmet } from 'react-helmet';
import LocalStorageService from "../../../services/LocalStorageService";
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();


const DialogTitle = styled(MuiDialogTitle)`
  position: relative;
  text-align: center;
`;

const DialogDetailOrder = styled(Dialog)`
  & .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-start;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 1170px;
  }
`;

class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSkeleton: false,
      listOrder: [],
      sort_name: "order_no",
      sort_type: "desc",
      page: 1,
      page_size: 10,
      pageCount: 0,
      detailOrder: {},
      isOpenModal: false,
      isExchange: false,
      symbol: "",
      rate: 0,
      margin: 0,
      margin_type: "",
    };
  }

  async componentDidMount() {
    this.setState({isSkeleton : true})
    this.getListOrder();
  }

  getListOrder = (status) => {
    const { page, page_size, sort_name, sort_type} = this.state;
    let params = {
      type: 2,
      page,
      page_size,
      sort_name,
      sort_type,
    };
    getListOrder(params).then((res) => {
      if (res.success) {
        let orders = res.orders;
        let listOrder = [];
        orders.forEach((el) => {
          let total_products = 0;
          el.detail.forEach((dex) => {
            total_products += dex.quantity;
          })
          let orderItem = {
            id: el.id,
            order_no: el.order_no,
            receiver_name: el.receiver_name,
            delivery_address: el.delivery_address,
            postcode: el.postcode,
            city: el.city,
            country_name: el.country_name,
            receiver_phone: el.receiver_phone,
            total_payment: el.total_payment,
            total_payment_eur: el.total_payment_eur,
            delivery_date: el.delivery_date,
            delivery_time: el.delivery_time,
            created_at: el.created_at,
            image: el.image,
            detail: el.detail,
            status: el.status,
            status_name: el.status_name,
            delivery_service: el.delivery_service,
            exchange_rate: el.exchange_rate,
            symbol: el.symbol,
            rate: el.rate,
            margin: el.margin,
            margin_type: el.margin_type,
            total_products: total_products,
            price_discount_percent: el.price_discount_percent,
            price_discount: el.price_discount,
            shipment_fee: el.shipment_fee,
            shipment_fee_discount: el.shipment_fee_discount,
            shipment_fee_discount_percent: el.shipment_fee_discount_percent
          };

          listOrder.push(orderItem);
        });
        this.setState({ listOrder, pageCount: res.page_count, isSkeleton: false });
      }
    });
  };

  getDetailOrder = async (row, isDetail = false) => {
    await queryOrder({ id: row.id })
      .then(async(res) => {
        if (res.success) {
          let exchange = null;
          if(res.exchange_rate && typeof res.exchange_rate === "object" && Object.keys(res.exchange_rate).length > 0 ) {
            exchange = res.exchange_rate;
            await this.setState({
              isExchange: true,
              rate: exchange.rate,
              symbol: exchange.symbol,
              margin: exchange.margin,
              margin_type: exchange.margin_type
            });
          }else{
            await this.setState({
              isExchange: false,
              rate: 0,
              symbol: "",
              margin: 0,
              margin_type: ""
            });
          }
          let item = {
            id: res.data.id,
            delivery_address: res.data.delivery_address,
            receiver_name: res.data.receiver_name,
            invoice_address: res.data.invoice_address,
            invoice_name: res.data.invoice_name,
            detail: res.data.detail,
            invoice_city: res.data.invoice_city,
            invoice_country: res.data.invoice_country,
            delivery_city: res.data.delivery_city,
            delivery_country: res.data.delivery_country,
            invoice_postcode: res.data.invoice_postcode,
            invoice_phone: res.data.invoice_phone,
            delivery_postcode: res.data.delivery_postcode,
            receiver_phone: res.data.receiver_phone,
            total_payment: res.data.total_payment,
            shipment_fee: row.shipment_fee,
            voucher_code: res.data.voucher_code,
            voucher_value: res.data.voucher_value,
            voucher_value_type: res.data.voucher_value_type,
            total_payment_eur: row.total_payment_eur,
            symbol: row.symbol,
            margin: row.margin,
            margin_type: row.margin_type,
            rate: row.rate,
            exchange_rate: row.exchange_rate,
            shipment_fee_discount: row.shipment_fee_discount,
            shipment_fee_discount_percent: row.shipment_fee_discount_percent,
            price_discount: row.price_discount,
            price_discount_percent: row.price_discount_percent,
          };

          item.shipment_fee_exchange = 0;
          item.shipment_fee_discount_exchange = 0;
          if(this.state.isExchange) {
            if(this.state.margin_type === "increase") {
            //  item.shipment_fee_exchange =  Number((Math.round((Number(row.shipment_fee)*Number(this.state.rate)
              //+ Number(this.state.margin)/100*(Number(row.shipment_fee)*Number(this.state.rate)))* 100) /100).toFixed(2));
                item.shipment_fee_exchange = (Number(row.shipment_fee)*Number(this.state.rate)
                + Number(this.state.margin)/100*(Number(row.shipment_fee)*Number(this.state.rate)));
            }else{
             // item.shipment_fee_exchange = Number((Math.round((Number(row.shipment_fee)*Number(this.state.rate)
              //- Number(this.state.margin)/100*(Number(row.shipment_fee)*Number(this.state.rate)))* 100) /100).toFixed(2));
              item.shipment_fee_exchange = (Number(row.shipment_fee)*Number(this.state.rate)
              - Number(this.state.margin)/100*(Number(row.shipment_fee)*Number(this.state.rate)));
            }

            item.shipment_fee_discount_exchange =  Number(row.shipment_fee_discount)*Number(this.state.rate);
          }

          let provisional = 0;
          let provisional_exchange = 0;
          res.data.detail && Array.isArray(res.data.detail) && res.data.detail.length > 0 && res.data.detail.map((el) => {
            if(typeof el.discount === "number" && !isNaN(el.discount) && el.discount > 0){
              el.provisional = Number((Math.round((el.quantity * el.discount) * 100) / 100).toFixed(2));
            }else{
              el.provisional = Number((Math.round((el.quantity * el.price) * 100) / 100).toFixed(2));
            }
            provisional += el.provisional;
            return el;
          });

          if(row.exchange_rate && Object.keys(row.exchange_rate).length > 0) {
            if(res.data.voucher_value_type === "fixed") {
              item.voucher_value_exchange =
              Number((Math.round((Number(res.data.voucher_value)*Number(row.exchange_rate.rate))* 100) /100)).toFixed(2);
            }

            res.data.detail && Array.isArray(res.data.detail) && res.data.detail.length > 0 && res.data.detail.map((el) => {
              if(row.exchange_rate.margin_type === "increase") {
                el.discount_exchange = Number((Math.round((Number(el.discount)*row.exchange_rate.rate + row.exchange_rate.margin/100*(Number(el.discount)*row.exchange_rate.rate))* 100) /100)).toFixed(2);
              }else{
                el.discount_exchange = Number((Math.round((Number(el.discount)*row.exchange_rate.rate - row.exchange_rate.margin/100*(Number(el.discount)*row.exchange_rate.rate))* 100) /100)).toFixed(2);
              }

              if(row.exchange_rate.margin_type === "increase") {
                el.price_exchange = Number((Math.round((Number(el.price)*row.exchange_rate.rate + row.exchange_rate.margin/100*(Number(el.price)*row.exchange_rate.rate))* 100) /100)).toFixed(2);
              }else{
                el.price_exchange = Number((Math.round((Number(el.price)*row.exchange_rate.rate - row.exchange_rate.margin/100*(Number(el.price)*row.exchange_rate.rate))* 100) /100)).toFixed(2);
              }

              if(typeof el.discount === "number" && !isNaN(el.discount) && el.discount > 0){
                el.provisional_exchange = Number((Math.round((el.quantity * el.discount_exchange) * 100) / 100).toFixed(2));
              }else{
                el.provisional_exchange = Number((Math.round((el.quantity * el.price_exchange) * 100) / 100).toFixed(2));
              }
              provisional_exchange += el.provisional_exchange;
              return el;
            });
          }

          item.provisional = Number((Math.round(provisional * 100) / 100).toFixed(2));
          item.provisional_exchange = item.exchange_rate && provisional_exchange && Number(provisional_exchange) > 0 ? Number((Math.round(provisional_exchange * 100) / 100).toFixed(2)) : 0;

          await this.setState({ detailOrder: item });
          return item;
        }
      })
      .then((item) => {
        if (typeof item !== "undefined" && isDetail) {
          this.setState({ isOpenModal: true });
        }
      });
  };

  handleModalDetailClose = () => {
    this.setState({ isOpenModal: false });
  };

  handlePaginationChange = (event, value) => {
    this.setState({ page: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      let status = this.state.status;
      this.getListOrder(status);
    }
  }

  handleReCheckout = async (data) => {
    this.props.clearListCart();
    localStorageService.clearCart();
    localStorageService.clearVoucher();
    localStorageService.clearOrderInfo();
    localStorageService.clearDeliveryInfo();
    localStorageService.clearVoucherPoint();
    await getListCart({type: 1}).then(async(res) => {
        if(res.success) {
          if(res.cart && Array.isArray(res.cart) && res.cart.length > 0) {
            res.cart.forEach(async(value) => {
              let params = {
                id: value.cart_id,
                type: 1,
                quantity: 0,
              };
              await updateCart(params).then(() => {});
            });
          }
        }
    });
    await this.getDetailOrder(data);
    let {detailOrder} = this.state;
    if(detailOrder && typeof detailOrder === "object" && Object.keys(detailOrder).length > 0 && detailOrder.id === data.id) {
      let order_info = {order_no: data.order_no, order_id: data.id};
      let cart_info = [];
      let address_info = {
        delivery_address: data.delivery_address,
        delivery_person_name: data.receiver_name,
        postcode: data.postcode,
        city: data.city,
        country_name: data.country_name,
        phone: data.receiver_phone,
        delivery_time: data.delivery_time,
        delivery_service: data.delivery_service.toString(),
        shipment_fee: detailOrder.shipment_fee,
        shipment_fee_exchange: Number(detailOrder.shipment_fee_exchange),
        payment: detailOrder.provisional,
        payment_exchange: detailOrder.provisional_exchange,
      };

      if(data.delivery_date && typeof data.delivery_date === "string" && data.delivery_date.length > 0) {
        let delivery_date = data.delivery_date.split("/");
        let delivery_date_format = delivery_date[2] + '-' + delivery_date[1] + '-' + delivery_date[0];
        address_info.delivery_date = delivery_date_format;
      }

      detailOrder.detail && Array.isArray(detailOrder.detail) && detailOrder.detail.forEach((de) => {
        let item  = {
          attribute_name1: de.attribute_name1,
          attribute_name2: de.attribute_name2,
          attribute_name3: de.attribute_name3,
          attribute_name4: de.attribute_name4,
          attribute_name5: de.attribute_name5,
          attribute_value1: de.attribute_value1,
          attribute_value2: de.attribute_value2,
          attribute_value3: de.attribute_value3,
          attribute_value4: de.attribute_value4,
          attribute_value5: de.attribute_value5,
          product_code: de.product_code,
          sku_code: de.sku_code,
          product_name: de.product_name,
          product_id: de.product_id,
          price: Number(de.price),
          discount_price: Number(de.discount),
          inventory: Number(de.inventory),
          quantity: Number(de.quantity),
          payment: Number(de.provisional),
          payment_exchange: Number(de.provisional_exchange),
          price_exchange: Number(de.price_exchange),
          discount_price_exchange: Number(de.discount_exchange),
        }
        cart_info.push(item);
      });

      if(detailOrder.voucher_code && detailOrder.voucher_value && detailOrder.voucher_value_type) {
        let voucher_info = {
          code: detailOrder.voucher_code,
          value: detailOrder.voucher_value,
          value_type: detailOrder.voucher_value_type,
          value_exchange: detailOrder.voucher_value_exchange
        };
        let voucherInfo = JSON.stringify(voucher_info);
        localStorageService.setVoucher(voucherInfo);
      }

      let cartInfo = JSON.stringify(cart_info);
      localStorageService.setCart(cartInfo);

      let order_str = JSON.stringify(order_info);
      localStorageService.setOrderInfo(order_str);

      let deliveryInfo = JSON.stringify(address_info);
      localStorageService.setDeliveryInfo(deliveryInfo);

      this.props.history.push("/checkout");
    }

  }

  render() {
    const {
      isSkeleton,
      listOrder,
      isOpenModal,
      detailOrder,
      page,
      pageCount,
      isExchange,
      symbol,
      rate,
      margin,
      margin_type
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
      <Helmet>
          <title>Đơn hàng của tôi</title>
          <meta property="og:title" content={"Đơn hàng của tôi"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đơn hàng của tôi"}></meta>
          <meta name="description" content={"Đơn hàng của tôi"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <section id="my-order-page" className={classes.myOrder}>
          <Container>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <LeftSidebar />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={10}>
                <h2>Đơn hàng</h2>
                <div className="list-order-desktop">
                  <DesktopTemplate
                  isSkeleton={isSkeleton}
                  listOrder={listOrder}
                  getDetailOrder={(data) => this.getDetailOrder(data, true)}
                  handleReCheckout={(data) => this.handleReCheckout(data)}
                  />
                </div>
                <div className="list-order-mobile">
                  <MobileTemplate
                  isSkeleton={isSkeleton}
                  listOrder={listOrder}
                  getDetailOrder={(data) => this.getDetailOrder(data, true)}
                  handleReCheckout={(data) => this.handleReCheckout(data)}
                  />
                </div>
                {!isSkeleton ? (
                  <React.Fragment>
                    {
                      pageCount > 0 ? (
                        <PaginationUI
                        currentPage={page}
                        pageCount={pageCount}
                        handlePaginationChange={(e, number) =>
                          this.handlePaginationChange(e, number)
                        }
                      />
                      ) : (<React.Fragment></React.Fragment>)
                    }
                  </React.Fragment>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', margin: '20px auto'}}>
                    {[1,2,3,4,5].map((val) => (
                      <Skeleton key={val} variant="rect" width={30} height={30} style={{marginRight: '10px'}} />
                    ))}
                    </div>
                )}
              </Grid>
            </Grid>
          </Container>
          <DialogDetailOrder
            onClose={() => this.handleModalDetailClose()}
            aria-labelledby="customized-dialog-title"
            open={isOpenModal}
          >
            <DialogTitle disableTypography>
              <h2>Thông tin đơn hàng</h2>
              <IconButton
                className={classes.btnCloseModal}
                aria-label="close"
                onClick={() => this.handleModalDetailClose()}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <MuiDialogContent dividers>
              <Detail
                detailOrder={detailOrder}
                isExchange={isExchange}
                symbol={symbol}
                rate={rate}
                margin={margin}
                margin_type={margin_type}
                />
            </MuiDialogContent>
          </DialogDetailOrder>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(MyOrder)));
