import React, { Component } from "react";
import style from "../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MobileTemplate from "./components/MobileTemplate";
import DesktopTemplate from "./components/DesktopTemplate";
import NotAuthDesktop from "./components/NotAuthDesktop";
import NotAuthMobile from "./components/NotAuthMobile";

const DialogContent = styled(MuiDialogContent)`
  padding: 30px 15px;
`;

const DialogTitle = styled(MuiDialogTitle)`
  position: relative;
`;

const DialogActions = styled(MuiDialogActions)`
  padding: "5px";
`;

const DialogDelivery = styled(Dialog)`
  & .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-start;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 1170px;
  }
`;

const DialogCustom = styled(DialogDelivery)`
  & .MuiDialog-paper {
    margin: 32px 0px !important;
  }
`;

class EditAddress extends Component {
  render() {
    const {
      isModalOpen,
      isLoading,
      classes,
      delivery_person_name,
      postcode,
      city,
      country_id,
      listCountry,
      listCountry1,
      delivery_address,
      phone,
      handleModalClose,
      handleInputChange,
      handleCountryChange,
      country_selected,
      handleSave,
      handlePlacesApiChange,
      handlePlacesApiSelect,
      isAuth,
      email,
      delivery_prefix,
      colorError,
      handleColorErrorChange
    } = this.props;
    return (
      <DialogCustom
        onClose={() => handleModalClose()}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        <DialogTitle disableTypography>
          <Typography variant="h6">Chỉnh sửa địa chỉ giao hàng</Typography>
          <IconButton
            className={classes.btnCloseModal}
            aria-label="close"
            onClick={() => handleModalClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{paddingTop: '20px'}}>
          {
            isAuth ? (
              <React.Fragment>
                <DesktopTemplate  
            isLoading={isLoading}
            classes={classes}
            delivery_person_name={delivery_person_name}
            delivery_address={delivery_address}
            postcode={postcode}
            city={city}
            country_id={country_id}
            listCountry1={listCountry1}
            phone={phone}
            country_selected={country_selected}
            colorError={colorError}
            handleInputChange={handleInputChange}
            handleCountryChange={handleCountryChange}
            handleColorErrorChange={handleColorErrorChange}
            handlePlacesApiChange={handlePlacesApiChange}
            handlePlacesApiSelect={handlePlacesApiSelect}
            handleSave={handleSave}
            delivery_prefix={delivery_prefix}
            listCountry={listCountry}
            />
          <MobileTemplate
            isLoading={isLoading}
            classes={classes}
            delivery_person_name={delivery_person_name}
            delivery_address={delivery_address}
            postcode={postcode}
            city={city}
            country_id={country_id}
            listCountry1={listCountry1}
            country_selected={country_selected}
            colorError={colorError}
            phone={phone}
            handleColorErrorChange={handleColorErrorChange}
            handleInputChange={handleInputChange}
            handleCountryChange={handleCountryChange}
            handlePlacesApiChange={handlePlacesApiChange}
            handlePlacesApiSelect={handlePlacesApiSelect}
            handleSave={handleSave}
            delivery_prefix={delivery_prefix}
            listCountry={listCountry}
          />
              </React.Fragment>
            ) : (
              <React.Fragment>
              <NotAuthDesktop
              listCountry={listCountry}
            email={email}
            delivery_prefix={delivery_prefix}
            isLoading={isLoading}
            classes={classes}
            delivery_person_name={delivery_person_name}
            delivery_address={delivery_address}
            postcode={postcode}
            city={city}
            country_id={country_id}
            listCountry1={listCountry1}
            country_selected={country_selected}
            colorError={colorError}
            phone={phone}
            handleColorErrorChange={handleColorErrorChange}
            handleInputChange={handleInputChange}
            handleCountryChange={handleCountryChange}
            handlePlacesApiChange={handlePlacesApiChange}
            handlePlacesApiSelect={handlePlacesApiSelect}
            handleSave={handleSave} />
          <NotAuthMobile
            isLoading={isLoading}
            classes={classes}
            delivery_person_name={delivery_person_name}
            delivery_address={delivery_address}
            postcode={postcode}
            city={city}
            country_id={country_id}
            listCountry={listCountry}
            listCountry1={listCountry1}
            country_selected={country_selected}
            colorError={colorError}
            phone={phone}
            delivery_prefix={delivery_prefix}
            email={email}
            handleColorErrorChange={handleColorErrorChange}
            handleInputChange={handleInputChange}
            handleCountryChange={handleCountryChange}
            handlePlacesApiChange={handlePlacesApiChange}
            handlePlacesApiSelect={handlePlacesApiSelect}
            handleSave={handleSave}
          />
              </React.Fragment>
            )
          }
        </DialogContent>
        <DialogActions></DialogActions>
      </DialogCustom>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(EditAddress)));
