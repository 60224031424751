import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";
import Skeleton from '@material-ui/lab/Skeleton';

const ButtonSpan = styled.span`
  background-color: #fff !important;
  color: #f2c123 !important;
  font-weight: 500;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
`;

class MobileTemplate extends Component {
  render() {
    const { classes, listAddress, handleModalOpen, handleRemove, isSkeleton } = this.props;
    return (
      <div className={classes.listMobile}>
        {!isSkeleton ? (<React.Fragment>
         
              { listAddress && Array.isArray(listAddress) && listAddress.length > 0 ? (
                <React.Fragment>
                 <ButtonSpan onClick={() => handleModalOpen()}>
                Thêm mới
              </ButtonSpan>
                   {listAddress.map((row) => (
          <div className="item-order-mobile" key={row.id}>
            <p>
              <span className="label">Tên người nhận:</span>{" "}
              <span>{row.name}</span>
            </p>
            <p>
              <span className="label">Địa chỉ nhận hàng:</span>{" "}
              <span>{row.address}</span>
            </p>
            <p>
              <span className="label">Post code: </span>
              <span>{row.postcode}</span>
            </p>
            <p>
              <span className="label">Thành phố: </span>
              <span>{row.city}</span>
            </p>
            <p>
              <span className="label">Quốc gia: </span>
              <span>{row.country_name}</span>
            </p>
            <p>
              <span className="label">Prefix:</span>{" "}
              <span>{row.prefix}</span>
            </p>
            <p>
              <span className="label">Số điện thoại:</span>{" "}
              <span>{row.phone}</span>
            </p>
            <p>
                <span className="label">Tùy chỉnh: </span>
              <ButtonSpan onClick={() => handleModalOpen(row.id)}>
                Sửa
              </ButtonSpan>{" "}
              <ButtonSpan onClick={() => handleRemove(row.id)}>Xóa</ButtonSpan>
            </p>
          </div>
        ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                   <h3 style={{marginBottom: '15px'}}>Hiện chưa có địa chỉ.</h3>
                <ButtonSpan onClick={() => handleModalOpen()}>
                Thêm mới
              </ButtonSpan>
                </React.Fragment>
              )}
        
        </React.Fragment>) : (<React.Fragment>
                   {[1,2,3,4,5,6,7,8,9,10].map((row) => (
                  <div className="item-order-mobile" key={row}>
                    {[1,2,3,4,5,6].map((value) => (
                       <p key={value}>
                       <Skeleton className="label" variant="text" width={155} style={{marginRight: '20px'}} />
                       <Skeleton variant="text" width={180} />
                     </p>
                    ))}
                  </div>
                ))}
        </React.Fragment>)}
        
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(MobileTemplate)));
