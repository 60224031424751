const styles = (theme) => ({
    root: {
        borderRadius: '0px'
    },
    blogTileItem: {
        position: 'relative',
        overflow: 'hidden',
        fontFamily: 'Roboto',
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '12px',
            border: '1px solid #e8e8e8',
            marginBottom: '6px'
        },
        "& .blogTile_thumb": {
            overflow: 'hidden',
            width: '100%',
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                width: '35%',
            },
            "& img": {
                objectFit: 'cover!important',
                width: '100%',
                height: '100%'
            }
        },
        "&:hover": {
            boxShadow: 'rgb(153 153 153 / 60%) 0px 0px 10px 0px',
            cursor: 'pointer'
        },
        "& .blogTile_viewBlog": {
            position: 'absolute',
            display: 'block',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px'
        },
        "& .blogTile_info": {
            padding: ' 10px 15px',
            [theme.breakpoints.down("sm")]: {
                width: '70%',
                padding: '0px 0px 0px 10px'
            },
            "& .blogTile_title": {
                fontWeight: '500',
                fontSize: '18px',
                marginBottom: '10px',
                lineHeight: '25px',
                height: '50px',
                overflow: 'hidden',
                textTransform: 'capitalize',
                [theme.breakpoints.down("sm")]: {
                    height: '40px',
                    fontSize: '17px',
                    lineHeight: '22px',
                    marginBottom: '9px',
                }
            },
            "& .descriptionShort": {
                maxHeight: '37px',
                minHeight: '37px',
                marginBottom: '15px',
                [theme.breakpoints.down("sm")]: {
                    maxHeight: '45px',
                    minHeight: '44px',
                    marginBottom: '0px',
                    overflow: 'hidden'
                }
            }
        }
    }
});

export default styles;