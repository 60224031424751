import React, { Component } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import style from "./style";
class PaginationUI extends Component {
  render() {
    const { handlePaginationChange, currentPage, pageCount, classes } = this.props;
    return (
        <Pagination
          className={classes.pagination}
          onChange={handlePaginationChange}
          page={currentPage}
          count={pageCount}
          variant="outlined"
          shape="rounded"
          renderItem={(item)=> <PaginationItem {...item} 
                           classes={{selected:classes.selected}}
                            />}
        />
    );
  }
}

const mapStateToProps = (store) => {
  return {
    //breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
  };
};

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(PaginationUI);
