import React from "react";
import { Prompt } from "react-router";
import { uid } from "uid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomButton from "./../UI/Button"

class PreventTransitionPrompt extends React.Component {
  /**
   * Dialog state
   */

  constructor() {
    super();
    this.state = {
        open: false,
        location: ''
    }

    // Define a unique global symbol to store
    // a dialog trigger reference accessible via
    // a string key. Use uid for unique ID.
    this.__trigger = Symbol.for(`__PreventTransitionPrompt_${uid()}`);
  }

  /**
   * Attach global dialog trigger for this component
   * instance to our Symbol trigger
   */
  componentDidMount() {
    window[this.__trigger] = this.show;
  }

   /**
   * Show the dialog. Invoked primarily from React Router transition
   * handler getUserConfirmation.
   *
   * @param allowTransitionCallback A function that accepts a flag whether or not to allow the route transition
   */
    show = async (allowTransitionCallback) => {
      await this.setState({ open: true }, () => allowTransitionCallback(false));
    };

  /**
   * Ensure we clean up and remove the reference
   * from the global object
   */
  componentWillUnmount() {
    delete window[this.__trigger];
  }

  successTransitionRoute = async () => {
    await this.setState({ open: false });
    this.props.successTransitionRoute(this.state.location)
  }

  render() {
    const { when, title, message } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        {/* React Router prompt, callback will return true to allow transition or dialog key to prevent */}
        <Prompt when={when} message={(location) => {
            this.setState({location: location.pathname});
            return Symbol.keyFor(this.__trigger);
        }} />

        {/* Example MUI dialog to show when open. You could make this
            totally customizable or a complete one-off. */}
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
          <CustomButton onClick={() => this.handleClose()} btnType="success">Không</CustomButton>
          <CustomButton
            customStyle={{width: '80px', height: '38px'}}
            onClick={() =>this.successTransitionRoute()}
            btnType="danger"
            autoFocus
          >Có</CustomButton>
        </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  /**
   * Closes the dialog
   */
  handleClose = () => {
    this.setState({ open: false });
  };

  /**
   * Handles the Router transition. Returns true if allowed
   * or the dialog trigger key to enable the dialog.
   *
   * This would be a good candidate to allow optionally
   * being passed as a callback prop to let
   * caller decide if transition is allowed.
   */
  
}

export default PreventTransitionPrompt;
