import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './style.js';
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';
import LazyImage from '../../../../../components/LazyImage/index.js';
import { apiDomain } from '../../../../../utills/config.js';

class BlogTile extends Component {

    componentDidMount() {
        let name = ReactDOM.findDOMNode(this.refs.cpDev1);
        if(name){
          this.setHeightEqualWidth(name);
        }
    }

    setHeightEqualWidth = (name) => {
        name.style.height = `${ Number(name.clientWidth) > 179 ? (Number(name.clientWidth)/1.91) : (179/1.91) }px`;
      }
    
    render() {
        const { classes, value } = this.props;
        return (
            <div className={classes.blogTileItem}>
                <Link to={`blog/${value.slug}`} className="blogTile_viewBlog"></Link>
                <div className="blogTile_thumb" ref="cpDev1">
                    <LazyImage
                        width={`100%`}
                        height={`auto`}
                        effect={"opacity"}
                        src={ value.url ? value.url : `${apiDomain}/upload/og-image-default2.png` }
                        alt={value.title}
                        heightSke={250}
                    />
                </div>
                <div className="blogTile_info">
                    <p className="blogTile_title">{value.title}</p>
                    <div className="descriptionShort">
                        { value.description ? (value.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").length > 100
                            ? `${value.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").substr(0, 97)}...`
                            : value.description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ")) : ``}
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(BlogTile);