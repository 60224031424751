const style = (theme) => ({
  snackbar: {
    '& .MuiSnackbarContent-message': {
      [theme.breakpoints.down("md")]: {
        fontSize: "11px",
      },
    },
  },
  deliveryAddress: {
    padding: "30px 0px",
    "& *": {
      fontFamily: 'Tinos',
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#f2c123",
    },
    "& .item-address": {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 24px",
      alignItems: "flex-start",
      borderTop: '1px solid #f3f3ef',
      position: "relative",
      marginBottom: "28px",
      minHeight: '127px',
      [theme.breakpoints.down("md")]: {
        padding: "16px 5px",
      },
      "& .overlay": {
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        zIndex: 1,
      },
      "&:hover": {
        cursor: "pointer",
        background: "#f1f8f2",
      },
      "& .contact-info": {
        "& .contact-name": {
          fontSize: "18px",
          lineHeight: "20px",
          fontWeight: "400",
          marginBottom: "10px",
          marginTop: "8px",
        },
        "& .address-phone": {
          fontSize: "0.9375rem",
          lineHeight: "16px",
          marginBottom: "10px",
        },
      },
      "& .options": {
        minWidth: "80px",
        "& .checkbox": {
          position: "absolute",
          display: "none",
          bottom: "16px",
          right: "0px",
          background: "#fff0",
        },
        "& .btn-edit": {
          fontSize: "0.9375rem",
          lineHeight: "16px",
          position: "relative",
          zIndex: 10,
          textDecoration: "underline",
          color: "#f2c123",
          background: "#fff0",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
      "&.item-choosed .checkbox": {
        display: "block!important",
      },
    },

    "& .addnew-address": {
      padding: "14px 35px 25px",
      marginBottom: "25px",
      textAlign: "center",
      "& .btn-addnew": {
        border: "1px solid #f2c123",
        color: "#f2c123",
        background: "#fff",
        textTransform: "uppercase",
        fontSize: "0.875rem",
        width: "186px",
        borderRadius: "0px",
        height: "48px",
      },
    },
    "& .service-delivery": {
      "& h2": {
        fontSize: "1.375rem",
        lineHeight: "24px",
        marginBottom: "18px",
      },
      "& p": {
        fontSize: "0.9375rem",
        lineHeight: "20px",
        marginBottom: "10px",
      },
      "& .btn-checkout": {
        width: "182px",
        height: "46px",
        borderRadius: "30px",
        border: "1px solid #f2c123",
        fontSize: "0.875rem",
        marginTop: '20px',
        textTransform: "uppercase",
        textAlign: "center",
        color: "#fff",
        background: "#f2c123",
        [theme.breakpoints.down("sm")]: {
          width: '100%'
        },
        "&:hover": {
          color: "#f2c123",
          background: "#fff",
        },
      },
    },
  },
  phoneNumber: {
    "& input": {
      border: "1px solid #d4d4d4!important",
      padding: "17px 5px",
      borderRadius: "4px",
      "&:hover": {
        border: "1px solid #000",
      },
    },
    "& .MuiInput-underline.Mui-focused:after": {
      display: "none!important",
    },
    "& .MuiInput-underline:before": {
      display: "none!important",
    },
  },
  table: {
    "& input": {
      fontSize: "0.875rem",
      fontFamily: 'Tinos',
      outline: "none",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
      fontSize: "15px",
      fontFamily: 'Tinos',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)!important",
      borderRadius: '0px'
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid hsl(0, 0%, 80%)!important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid hsl(0, 0%, 80%)!important",
      },
    },
    "& .css-1wa3eu0-placeholder": {
      fontSize: "15px",
      fontFamily: 'Tinos',
      fontWeight: "400",
      color: "hsl(0deg 0% 50% / 82%)",
    },
    "& .css-1uccc91-singleValue": {
      fontSize: "15px",
      fontFamily: 'Tinos',
      fontWeight: "500",
    },
 
  },
  btnCloseModal: {
    position: "absolute",
    top: "5px",
    right: "5px",
    padding: "5px"
  },
  "& .service-item": {
    padding: "15px",
    "& .service-options": {
      display: "flex",
      flexDirection: "column",
    },
  },
  serviceSchedule: {
    display: "flex",
    justifyContent: "space-between",
  },
  serviceItem: {
    width: '100%',
    height: '100%',
    borderRight: '1px solid #f3f3ef',
    padding: "15px",
    [theme.breakpoints.down("sm")]: {
      borderBottom: '1px solid #f3f3ef',
      borderRight: 'none'
    },
    "& h3": {
      fontSize: "1.125rem",
      lineHeight: "22px",
      marginBottom: "20px",
    },
    "& p": {
      margin: "10px 0px",
    },
    "& .field-note": {
      display: "block",
      padding: "10px",
      border: "1px solid #b9b9b9",
      fontSize: "14px",
      width: '100%',
      borderRadius: "4px",
      "&:focus": {
        outline: "none",
      },
    },
  },
  tableCell: {
    padding: "5px 5px 250px",
    minWidth: "200px",
    '& .MuiFormHelperText-contained':{
      position: 'absolute',
      bottom: '-22px',
      left: '0px',
      color: '#f44336',
      marginLeft: '14px',
      marginRight: '14px'
    },
    '& .autocomplete-dropdown-container':{
      position: 'absolute',
      zIndex: '10',
      width: '100%',
      left: '0px',
      '& *': {
        padding: '10px 8px',
      }
    },
  },
  boxMobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    '& .css-1wa3eu0-placeholder': {
      fontSize: '14px'
    }
  },
  tableContainer: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& .css-yk16xz-control": {
      borderRadius: "0px!important",
      borderColor: 'hsl(0, 0%, 80%)!important',
      "&:focus": {
        outline: 'none'
      }
    },
    "& .css-1pahdxg-control": {
      borderRadius: "0px!important",
      borderColor: 'hsl(0, 0%, 80%)!important',
      outline: 'none!important',
      boxShadow: 'none!important'
    }
  },
  formControlMobile: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginTop: '8px',
    marginBottom : '16px',
    "& label": {
      fontFamily:'Tinos',
      fontSize: "15px",
      width: "130px",
      overflow: 'hidden',
      minWidth: '130px',
    },
    "& .css-2b097c-container": {
      width: "100%",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
      fontSize: "14px",
      fontFamily: 'Tinos',
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
    },
    "& .css-1wa3eu0-placeholder": {
      fontSize: "15px",
      fontFamily: 'Tinos',
      fontWeight: "100",
    },
    "& .css-1uccc91-singleValue": {
      fontSize: "14px",
      fontFamily: 'Tinos',
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: "24px",
      marginBottom: "20px",
    },
    '& .autocomplete-dropdown-container':{
      position: 'absolute',
      zIndex: '10',
      width: '100%',
      left: '0px',
      '& *': {
        padding: '10px 8px',
      }
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336!important'
    }
  },
  serviceMain: {
    borderTop: '1px solid #f3f3ef',
    borderBottom: '1px solid #f3f3ef',
    paddingTop: '15px',
    paddingBottom: '15px',
    [theme.breakpoints.down("sm")]: {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  fullWidthMobile:{
    [theme.breakpoints.down("sm")]: {
      width: '100%',
    },
  },
  txtControl: {
    margin: '0px auto 15px',
    '& label': {
      marginBottom: '7px'
    },
    "& input": {
      padding: "10px 12px",
      fontSize: "15px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "0px",
    },
    "& .MuiInputAdornment-root": {
      color: "#c8c8c8",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "9px",
    },
    "& .css-yk16xz-control": {
      borderRadius: "0px!important",
      padding: "0px",
    },
    "& .css-1pahdxg-control": {
      borderRadius: "0px!important",
      padding: "0px",
    },
    "& .css-1peopny-control": {
      borderColor: '#f44336',
      boxShadow: '0 0 0 1px #f44336'
    },
    "& .MuiInput-underline": {
      "&::before, &::after, &:hover": {
        borderBottomColor: "#c8c8c8",
      },
      "&:hover::before": {
        borderBottomColor: "#c8c8c8",
      },
    },

    "& .MuiInput-underline.Mui-error": {
      "&::before, &::after, &:hover": {
        borderBottomColor: "#f44336",
      },
    },
    '& .autocomplete-dropdown-container':{
      position: 'absolute',
      zIndex: '10',
      width: '100%',
      left: '0px',
      '& *': {
        padding: '10px 8px',
      }
    },
    '& .location-search-input': {
      minWidth: '100%'
    }
  
  },
  textContainer:{
    '& label': {
      display: 'block'
    }
  },
  gridBox: {
    display: "grid",
    gridTemplateColumns: "140px 1fr",
    marginTop: "12px",
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#f44336!important'
    }
   
  },
  groupCountry: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
  },
  helperTextError: {
    bottom: '-22px',
    left: '0px',
    color: '#f44336',
    marginLeft: '14px',
    marginRight: '14px',
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    marginTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  stepper: {
    marginBottom: '40px',
    '& .MuiPaper-root' : {
      marginTop: '0px!important'
    }
  }
});

export default style;
