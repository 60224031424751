import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setLogoutAction, setAuthAction } from '../../../redux/actions/authActions';
import { setModalLogoutAction } from '../../../redux/actions/modalActions';
import LocalStorageService from '../../../services/LocalStorageService';
import { logout } from './../../../models/login';
import style from './style';
import CustomButton from './../../UI/Button';
import { withRouter } from 'react-router-dom';
import {
	setUserAvatarAction,
	setUserInfoAction,
} from '../../../redux/actions/userActions';

const localStorageService = LocalStorageService.getService();

class ModalLogout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	handleLogout = () => {
		const req = {
			token: localStorageService.getAccessToken(),
		};
		this.setState({ loading: true });
		logout(req).then((res) => {
			if (res.revoked) {
				this.setState({ loading: false });
				this.props.setLogout(true);
				this.props.clearListCart();
				this.props.setModalLogout(false);
				this.props.setUserAvatar(null);
				localStorageService.clearCart();
				localStorageService.clearVoucher();
				localStorageService.clearDeliveryInfo();
				localStorageService.clearLocation();
				localStorageService.clearOrderInfo();
				localStorageService.clearUserInfor();
            	localStorageService.clearVoucherPoint();
				this.props.setUserInfo({
					id: '',
					fullname: '',
					email: '',
					phone: '',
					avatar: '',
					birthday: '',
				});
				this.props.setAuth(null);
				this.props.history.push('/auth/sign-in');
			} else {
				this.props.setModalLogout(false);
			}
		});
	};

	onClose = () => {
		this.props.setModalLogout(false);
	};

	render() {
		const { loading } = this.state;
		const { modalLogout } = this.props;
		return (
			<Dialog
				maxWidth='xs'
				open={modalLogout}
				onClose={this.onClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>
					<FormattedMessage id='Logout' />
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<FormattedMessage id='Are you sure you want to logout now?' />
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CustomButton onClick={this.onClose} btnType='success'>
						<FormattedMessage id='No' />
					</CustomButton>
					<CustomButton
						customStyle={{width: '80px', height: '38px'}}
						onClick={this.handleLogout}
						btnType='danger'
						loading={loading}
						autoFocus>
						<FormattedMessage id='Yes' />
					</CustomButton>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		logout: store.authReducer.logout,
		modalLogout: store.modalReducer.logout,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		setLogout: (logout) => {
			dispatch(setLogoutAction(logout));
		},
		setModalLogout: (logout) => {
			dispatch(setModalLogoutAction(logout));
		},
		setUserAvatar: (avatar) => {
			dispatch(setUserAvatarAction(avatar));
		},
		setUserInfo: (user) => {
			dispatch(setUserInfoAction(user));
		},
		setAuth: (token) => {
			dispatch(setAuthAction(token));
		},
		clearListCart: (payload = null) => {
			dispatch({ type: "CLEAR_LIST_CART", payload });
		  },
	};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
	withConnect,
	withStyles(style)
)(withRouter(injectIntl(ModalLogout)));
