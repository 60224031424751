
import APIFunction from './../services';

//get product list
export const getProductList = (params) => {
    return APIFunction.getProductList(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}
// view product (detail)
export const viewProduct = (params) => {
    return APIFunction.viewProduct(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// product-favorite
export const productFavorite = (params) => {
    return APIFunction.productFavorite(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}

// add - remove product-favorite
export const addProductFavorite = (params) => {
    return APIFunction.addProductFavorite(params).then((result) => { 
        return result;
    }).catch(error => {
        return error;
    })
}