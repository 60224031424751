import {
  Container,
  FormControl,
  Grid,
  DialogContent,
  Dialog,
  Snackbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { compose } from "redux";
import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
import LeftSidebar from "../components/LeftSidebar";
import Button from "./../../../components/UI/Button";
import { queryUserInfo, updateUserInfo } from "./../../../models";
import style from "./style";
import { setUserInfoAction } from "../../../redux/actions/userActions";
import Avatar from "./../components/Avatar";
import { Helmet } from 'react-helmet';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CURRENCY } from "./../../../utills/constant";
import styled from "styled-components";
import { apiDomain } from "../../../utills/config";


const ButtonRank = styled.button`
    color: #f2c123;
    font-weight: bold;
    background-color: #fff;
    text-align: center;
    font-size: 16px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
`;

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      fullname: "",
      email: "",
      phone: "",
      sbColor: "error",
      sbMessage: "",
      isOpen: false,
      isOpenDate: false,
      loading: false,
      rank: {},
      isRankOpen: false
    };
  }

  componentDidMount() {
	this.fetchUserInfo();
  }

  fetchUserInfo = () => {
    let that = this;
    queryUserInfo({ id: 1 }).then((res) => {
      if (res.success) {
        const user = {
          id: res.id,
          fullname: res.fullName ? res.fullName : "",
          email: res.email ? res.email : "",
          phone: res.phone ? res.phone : "",
          avatar: res.avatar ? res.avatar : "",
          rank: res.rank ? res.rank : {}
        };
        that.setState({
          ...user,
          isOpen: false,
          sbMessage: "",
        });
        that.props.setUserInfo(user);
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.message,
          sbColor: "error",
        });
      }
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpen: false });
  };

  onSubmit = () => {
    const { id, fullname, phone } = this.state;
    this.setState({ loading: true });
    const that = this;
    const req = {
      id,
      fullname,
      phone,
    };
    updateUserInfo(req).then((res) => {
      if (res.success) {
        that.setState({
          isOpen: true,
          sbMessage: <FormattedMessage id={res.title} />,
          sbColor: "success",
          loading: false,
        });
        this.fetchUserInfo();
      } else {
        that.setState({
          isOpen: true,
          sbMessage: res.title,
          sbColor: "error",
          loading: false,
        });
      }
    });
  };

  showRank = () => {
    this.setState({isRankOpen: true});
  }

  handleRankClose = () => {
    this.setState({ isRankOpen: false });
  }

  render() {
    const { classes, intl } = this.props;
    const {
      isOpen,
      fullname,
      email,
      sbMessage,
      phone,
      sbColor,
      loading,
      rank,
      isRankOpen
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Thông tin người dùng</title>
          <meta property="og:title" content={"Thông tin người dùng"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Thông tin người dùng"}></meta>
          <meta name="description" content={"Thông tin người dùng"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          open={isOpen}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <section id="my-account-page" className={classes.myAccountPage}>
          <Container>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <LeftSidebar />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={10}>
                <Avatar />
                { rank && rank.name && (<div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                  <ButtonRank onClick={() => this.showRank()}>{`Thành viên ${rank.name}`}</ButtonRank>
                </div>)}
                <ValidatorForm
                  className={classes.validateForm}
                  ref="form"
                  onSubmit={this.onSubmit}
                  onError={() => {
                    this.setState({
                      isOpen: false,
                      sbMessage: "",
                    });
                  }}
                >
                  <FormControl
                    margin="normal"
                    fullWidth
                    required
                    className={classes.txtControl}
                  >
                    <label>
                      Tên <span className="txtDanger">*</span>
                    </label>
                    <TextValidator
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({
                          fullname: e.target.value,
                        })
                      }
                      name="fullname"
                      value={fullname}
                      validators={["required"]}
                      errorMessages={[
                        intl.formatMessage({
                          id: "Error.Required",
                        }),
                      ]}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    className={classes.txtControl}
                  >
                    <label>
                      Email <span className="txtDanger">*</span>
                    </label>
                    <TextValidator
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({
                          email: e.target.value,
                        })
                      }
                      name="email"
                      disabled
                      value={email}
                      validators={[
                        "required",
                        "isEmail",
                      ]}
                      errorMessages={[
                        intl.formatMessage({
                          id: "Error.Required",
                        }),
                        intl.formatMessage({
                          id: "Error.ValidEmail",
                        }),
                      ]}
                      type={"email"}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                  </FormControl>

                  <FormControl
                    margin="normal"
                    fullWidth
                    className={classes.txtControl}
                  >
                  <label>Số điện thoại <span className="txtDanger">*</span></label>
                    <TextValidator
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({
                          phone: e.target.value,
                        })
                      }
                      name="phone"
                      value={phone}
                      validators={["required"]}
                      errorMessages={[
                        intl.formatMessage({
                          id: "Error.Required",
                        }),
                      ]}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </FormControl>

                  <Button
                    customClass={classes.btnSubmit}
                    size="large"
                    variant="contained"
                    type="submit"
                    btnType="success"
                    loading={loading}
					          fullWidth
                  >
                    CẬP NHẬT
                  </Button>
                </ValidatorForm>
              </Grid>
            </Grid>
          </Container>
          <Dialog maxWidth="sm" fullWidth={true} onClose={this.handleRankClose} aria-labelledby="customized-dialog-title" open={isRankOpen}>
                  <MuiDialogTitle  id="customized-dialog-title" onClose={this.handleRankClose}>
                    <span style={{margin: '0px', fontSize: '1.125rem', fontWeight: '600'}}>Ưu đãi thành viên</span>
                    <IconButton aria-label="close" style={{position: 'absolute',
                          right: '10px',
                          top: '10px'}}
                          onClick={this.handleRankClose}>
                      <CloseIcon />
                    </IconButton>
                  </MuiDialogTitle>
                  <DialogContent dividers style={{paddingTop: '0px'}}>
                    <div style={{textAlign: 'center'}}>
                    <h2 style={{margin: '20px 0px'}}>Thông tin ưu đãi</h2>
                    <p style={{marginBottom: '20px', fontWeight: 'bold'}}>Thành viên {rank.name}</p>
                    {rank.price_discount && (
                      <React.Fragment>
                        <p style={{marginBottom: '20px'}}>Chiết khấu đơn hàng: {rank.price_discount}{rank.price_discount_type === "percent" ? '%' : ` ${CURRENCY}`}</p>
                      </React.Fragment>
                    )}
                    {rank.shipment_fee_discount && (
                      <React.Fragment>
                        <p style={{marginBottom: '20px'}}>Giảm phí vận chuyển: {rank.shipment_fee_discount}{rank.shipment_fee_discount_type === "percent" ? '%' : ` ${CURRENCY}`}</p>
                      </React.Fragment>
                    )}
                    </div>

                </DialogContent>
              </Dialog>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    language: store.languageReducer.language,
    avatar: store.userReducer.avatar,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setUserInfo: (user) => {
      dispatch(setUserInfoAction(user));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style), withConnect)(injectIntl(MyAccount));
