import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import { TYPE_LANDING_PAGE } from "../../utills/constant";
import { getContent } from "./../../models/content";
import Breadcrumb from "../../components/Breadcrumb";
import CategoryProduct from "../../components/CategoryProduct";
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import { apiDomain } from "./../../utills/config";

const Section = styled.section`
  padding: 30px 0px;
`;
class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      url: "",
      name: "",
      content: "",
    };
  }
  componentDidMount() {
    let typeLandingPage = this.props.location.pathname;
    let paramType = TYPE_LANDING_PAGE.find((value) => {
      return value.url === typeLandingPage;
    });
    if (!paramType) {
      this.props.history.push("/not-found");
      return;
    }
    let typePage = paramType.value;
    this.setState({
      ...this.state,
      type: typePage,
      name: paramType.name,
      url: paramType.url,
    });
    this.handleGetLadingPage(typePage);
  }

  handleGetLadingPage(type) {
    let params = {
      type: type,
    };

    getContent(params).then((res) => {
      if (res._embedded && res._embedded.content.length > 0) {
        this.setState({
          ...this.state,
          content: res._embedded.content[0].description,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let typeLandingPage = this.props.location.pathname;
      let paramType = TYPE_LANDING_PAGE.find((value) => {
        return value.url === typeLandingPage;
      });
      if (!paramType) {
        this.props.history.push("/not-found");
        return;
      }
      let typePage = paramType.value;
      this.setState({
        ...this.state,
        type: paramType.value,
        name: paramType.name,
        url: paramType.url,
      });
      this.handleGetLadingPage(typePage);
    }
  }

  render() {
    const { url, content, name } = this.state;
    return (
      <React.Fragment>
       <Helmet>
          <title>{name}</title>
          <meta content={name} property="og:title"></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={name} ></meta>
          <meta name="description" content={name} ></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <CategoryProduct />
        <Breadcrumb breadcrumb={[{ link: url, name: name }]} />
        <Section>
          {content.length > 0 && (
            <Container maxWidth="xl">
              <Grid container>
                <Grid item xl={12} sm={12}>
                  <div
                    style={{ padding: "15px", background: "#fff" }}
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></div>
                </Grid>
              </Grid>
            </Container>
          )}
        </Section>
      </React.Fragment>
    );
  }
}

export default Content;
