import React, { Component } from "react";
import { compose } from "redux";
import { Container, Grid, withStyles } from "@material-ui/core";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./style";
import RelatedProduct from "./components/RelatedProduct";
import SliderMain from "./components/SliderMain";
import InformationProduct from "./components/InformationProduct";
import { viewProduct, getProductList, addProductFavorite, productFavorite } from "../../../models/product";
import Breadcrumb from "./../../../components/Breadcrumb";
import LocalStorageService from "../../../services/LocalStorageService";
import { addCart } from "../../../models/cart";
import CategoryProduct from "./../../../components/CategoryProduct";
import LoadingSlider from "./components/LoadingSlider";
import Skeleton from "@material-ui/lab/Skeleton";
import AlertMessage from "../../../components/UI/AlertMessage";
import moment from "moment";
import { convertSlugSeo } from "../../../utills/constant";
import { Helmet } from 'react-helmet';
import NotFoundProduct from "../../../components/NotFoundProduct";
import DefaultImage from "./../../../assets/images/default.jpg"
const localStorageService = LocalStorageService.getService();

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: "",
      image: [],
      price: 0,
      inventory: 0,
      description: "",
      weight: 0,
      rating: 4,
      sku_code: "",
      product_code: "",
      category_id1: null,
      category_id2: null,
      relatedProduct: [],
      isSkeleton: false,
      quantity: 1,
      breadcrumb: [],
      discount_percent: 0,
      discount_price: 0,
      attribute: [],
      attribute_detail: [],
      listAttrSelected: {},
      isLoading1: false,
      isLoading2: false,
      isAlert: false,
      sbColor: "error",
      sbMessage: "",
      multi_price: 0,
      multi_discount_price: 0,
      multi_discount_percent: 0,
      multi_price_exchange: 0,
      multi_discount_price_exchange: 0,
      isChoosed: false,
      isExchange: false,
      rate: 0,
      symbol: "",
      margin: 0,
      margin_type: "",
      price_exchange: 0,
      discount_price_exchange: 0,
      isNodata: false,
      isAuth: false
    };
  }

  async componentDidMount() {
    this.setState({ isSkeleton: true });
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }

    let token = localStorageService.getAccessToken();
    if(typeof token === "string") {
      await this.setState({isAuth: true});
    }

    let id = this.props && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
    this.getProductInformation(id);
  }

  getProductInformation(id) {
    let { isExchange, rate, margin, margin_type } = this.state;
    viewProduct({ id }).then(async (res) => {
      if (!res.success) {
        this.setState({
          isNodata: true,
          isSkeleton: false,
          breadcrumb: [{
            link: '/',
            name: 'Không tìm thấy sản phẩm'
          }],
          name: 'Không tìm thấy sản phẩm'
        })
        return;
      }

      if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
        let exchange = res.exchange_rate[0];
        await this.setState({
          isExchange: true,
          symbol: exchange.symbol,
          margin: exchange.margin,
          rate: exchange.rate,
          margin_type: exchange.margin_type
        });
      }

      let product = res.product;
      let listImages = product.image;

      let breadcrumb = [
        {
          link: product.category_name1 && product.category_id1 && `/categories/${convertSlugSeo(product.category_name1)}.id=${product.category_id1}`,
          name: product.category_name1,
        },
        {
          link: product.name && product.id && `/product/${convertSlugSeo(product.name)}.id=${product.id}`,
          name: product.name,
        },
      ];
      let listAttrSelected = {};
     if (product.attribute.length > 0 && product.attribute_detail.length > 0) {
        product.attribute.forEach((element) => {
          element.value = 0;
          element.error = "";
        });
        listAttrSelected = {
          attribute_value_id1:
            product.attribute[0] && product.attribute[0].value
              ? product.attribute[0].value
              : null,
          attribute_value_id2:
            product.attribute[1] && product.attribute[1].value
              ? product.attribute[1].value
              : null,
          attribute_value_id3:
            product.attribute[2] && product.attribute[2].value
              ? product.attribute[2].value
              : null,
          attribute_value_id4:
            product.attribute[3] && product.attribute[3].value
              ? product.attribute[3].value
              : null,
          attribute_value_id5:
            product.attribute[4] && product.attribute[4].value
              ? product.attribute[4].value
              : null,
        };
     }

     if(product.attribute_detail && Array.isArray(product.attribute_detail) && product.attribute_detail.length > 0){
      product.attribute_detail.sort(function(a,b){
        return Number(a.price) - Number(b.price);
      });
      let lengthAttr = product.attribute_detail.length;
      product.multiPrice = [
        Number(product.attribute_detail[0].price), 
        Number(product.attribute_detail[lengthAttr - 1].price),
      ];
      product.multiDiscountPrice = [
        Number(product.attribute_detail[0].discount_price),
        Number(product.attribute_detail[lengthAttr - 1].discount_price),
      ];
      product.multiDiscountPercent = [
        Number(product.attribute_detail[0].discount_percent),
        Number(product.attribute_detail[lengthAttr - 1].discount_percent),
      ];

      if(isExchange) {
          if(margin_type === "increase") {
            product.multiPriceExchange = [
              Number((Math.round((Number(product.attribute_detail[0].price)*rate + margin/100*(Number(product.attribute_detail[0].price)*rate))* 100) /100)).toFixed(2)
              ,
              Number((Math.round((Number(product.attribute_detail[lengthAttr - 1].price)*rate + margin/100*(Number(product.attribute_detail[lengthAttr - 1].price)*rate))* 100) /100)).toFixed(2)
            ];
            product.multiDiscountPriceExchange = [
              Number((Math.round((Number(product.attribute_detail[0].discount_price)*rate + margin/100*(Number(product.attribute_detail[0].discount_price)*rate))* 100) /100)).toFixed(2)
              ,
              Number((Math.round((Number(product.attribute_detail[lengthAttr - 1].discount_price)*rate + margin/100*(Number(product.attribute_detail[lengthAttr - 1].discount_price)*rate))* 100) /100)).toFixed(2)
            ];
          }else{
            product.multiPriceExchange = [
              Number((Math.round((Number(product.attribute_detail[0].price)*rate - margin/100*(Number(product.attribute_detail[0].price)*rate))* 100) /100)).toFixed(2)
              ,
              Number((Math.round((Number(product.attribute_detail[lengthAttr - 1].price)*rate - margin/100*(Number(product.attribute_detail[lengthAttr - 1].price)*rate))* 100) /100)).toFixed(2)
            ];
            product.multiDiscountPriceExchange = [
              Number((Math.round((Number(product.attribute_detail[0].discount_price)*rate - margin/100*(Number(product.attribute_detail[0].discount_price)*rate))* 100) /100)).toFixed(2)
              ,
              Number((Math.round((Number(product.attribute_detail[lengthAttr - 1].discount_price)*rate - margin/100*(Number(product.attribute_detail[lengthAttr - 1].discount_price)*rate))* 100) /100)).toFixed(2)
            ];
          }
      }
    }

    if(isExchange) {
      if(margin_type === "increase") {
        product.price_exchange = Number((Math.round((product.price*rate + margin/100*(product.price*rate))* 100) /100)).toFixed(2);
        product.discount_price_exchange = Number((Math.round((product.discount_price*rate + margin/100*(product.discount_price*rate))* 100) /100)).toFixed(2);
      }else{
        product.price_exchange = Number((Math.round((product.price*rate - margin/100*(product.price*rate))* 100) /100)).toFixed(2);
        product.discount_price_exchange = Number((Math.round((product.discount_price*rate - margin/100*(product.discount_price*rate))* 100) /100)).toFixed(2);
      }
    }

      this.setState({
        id: id,
        name: product.name,
        price: product.price,
        inventory: product.inventory ? product.inventory : 0,
        description: product.description ? product.description : "",
        weight: product.weight ? product.weight : 0,
        image: listImages,
        breadcrumb: breadcrumb,
        attribute: product.attribute,
        attribute_detail: product.attribute_detail,
        discount_percent: product.discount_percent,
        discount_price: product.discount_price,
        listAttrSelected: listAttrSelected,
        sku_code: product.sku_code,
        product_code: product.product_code,
        multi_price: product.multiPrice,
        multi_discount_price: product.multiDiscountPrice,
        multi_discount_percent: product.multiDiscountPercent,
        multi_price_exchange: product.multiPriceExchange,
        multi_discount_price_exchange: product.multiDiscountPriceExchange,
        isSkeleton: false,
        price_exchange: product.price_exchange,
        discount_price_exchange: product.discount_price_exchange,
        quantity: Number(product.inventory) === 0 ? 0 : 1,
        isNodata: false
      });
      let categoryInfo = {
        product_id: id,
        related: 1,
      };
      if (
        typeof product.category_id1 === "number" &&
        product.category_id1 > 0
      ) {
        categoryInfo.category_id1 = product.category_id1;
      } else {
        categoryInfo.category_id2 = product.category_id2;
      }
      this.getRelatedProductList(categoryInfo);
    });
  }

  getRelatedProductList(categoryInfo) {
    let params = categoryInfo;
    params.page = 1;
    params.page_size = 12;
    getProductList(params).then(async (res) => {
      let favorite = [];
      if(this.state.isAuth){
        await productFavorite({}).then((rsFavorite) => {
          if(rsFavorite.success) {
            if(rsFavorite.products) {
              favorite = rsFavorite.products
            }
          }
        })
      }
      if (res.success) {
        const products = res.products;
        const exchange = res.exchange_rate;
        const result = [];
        products && Array.isArray(products) && products.length > 0 && products.forEach((value) => {
          let isFavorite = false;
          favorite.forEach((f) => {
            if(f.id === value.id){
              isFavorite = true;
            }
          });
          value.isFavorite = isFavorite;
          if (value.created_at) {
            let created_at = value.created_at.split(" ");
            let created_at_date = created_at[0].split("/");
            let created_at_format = `${created_at_date[2]}-${created_at_date[1]}-${created_at_date[0]}`;
            if (moment().diff(created_at_format, "days") === 0) {
              value.isNew = true;
            }
          }

          if(exchange && typeof exchange === "object" && Object.keys(exchange).length > 0) {
            value.isExchange = true;
            value.symbol = exchange.symbol;
            value.margin = exchange.margin;
            value.margin_type = exchange.margin_type;
            value.rate = exchange.rate;
          }

          if (value.attribute_detail && Array.isArray(value.attribute_detail) && value.attribute_detail.length > 0) {
            value.attribute_detail.sort(function (a, b) {
              return Number(a.price) - Number(b.price);
            });
            let lengthAttr = value.attribute_detail.length;
            value.multiPrice = [
              Number(value.attribute_detail[0].price),
              Number(value.attribute_detail[lengthAttr - 1].price),
            ];
            value.multiDiscountPrice = [
              Number(value.attribute_detail[0].discount_price),
              Number(value.attribute_detail[lengthAttr - 1].discount_price),
            ];
          }
          result.push(value);
        });

        this.setState({ ...this.state, relatedProduct: result });
      }
    });
  }

  buyProduct(){
    this.setState({isLoading1: true});
    this.setAddToCart();
  }
  setAddToCart(isRedirect) {

    if(!this.state.isChoosed && this.state.multi_price && this.state.multi_price.length > 0){
      let attr = this.state.attribute.map((element) => {
        let check = element.values.every((x) => (
              x.id !== element.value
        ));
        if(check){
          element.error = `Vui lòng chọn ${element.name.toLowerCase()}`;
        }
        return element;
      });
      this.setState(
        {
          attribute: attr,
          isLoading1: false,
          isLoading2: false
        },
      );
      return;
    }
    if(Number(this.state.inventory) === 0){
      this.setState({
        isAlert: true,
        sbColor: "error",
        sbMessage: "Sản phẩm tạm thời đã hết hàng.",
        isLoading1: false,
        isLoading2: false
      });
      return;
    }
    let object = {};
    object.product_id = Number(this.state.id);
    object.product_name = this.state.name;
    object.image = this.state.image && Array.isArray(this.state.image) && this.state.image.length > 0 && this.state.image[0];
    object.price = Number(this.state.price);
    object.discount_price = Number(this.state.discount_price);
    object.discount_percent = Number(this.state.discount_percent);
    object.quantity = Number(this.state.quantity);
    object.sku_code = this.state.sku_code;
    object.product_code = this.state.product_code;
    object.inventory = Number(this.state.inventory);

    if(this.state.isExchange){
      object.isExchange = this.state.isExchange;
      object.rate = this.state.rate;
      object.margin = this.state.margin;
      object.margin_type = this.state.margin_type;
      object.symbol = this.state.symbol;
      object.price_exchange = Number(this.state.price_exchange);
      object.discount_price_exchange = Number(this.state.discount_price_exchange);
      if(this.state.margin_type === "increase") {
        object.discount_price_exchange = (Number(this.state.discount_price) &&
          typeof Number(Number(this.state.discount_price)) === "number"
          && !isNaN(Number(this.state.discount_price))
          && Number(Number(this.state.discount_price)) > 0) ?
          Number((Math.round((Number(this.state.discount_price)*Number(this.state.rate) + Number(this.state.margin)/100*(Number(this.state.discount_price)*Number(this.state.rate)))* 100) /100)).toFixed(2) : 0;
          object.price_exchange = (Number(this.state.price)
          && typeof Number(Number(this.state.price)) === "number"
          && !isNaN(Number(this.state.price))
          && Number(Number(this.state.price)) > 0) ?
          Number((Math.round((Number(this.state.price)*Number(this.state.rate) + Number(this.state.margin)/100*(Number(this.state.price)*Number(this.state.rate)))* 100) /100)).toFixed(2) : 0;
      }else{
        object.discount_price_exchange = (Number(this.state.discount_price) &&
          typeof Number(Number(this.state.discount_price)) === "number"
          && !isNaN(Number(this.state.discount_price))
          && Number(Number(this.state.discount_price)) > 0) ?
          Number((Math.round((Number(this.state.discount_price)*Number(this.state.rate) - Number(this.state.margin)/100*(Number(this.state.discount_price)*Number(this.state.rate)))* 100) /100)).toFixed(2) : 0;
          object.price_exchange = (Number(this.state.price)
          && typeof Number(Number(this.state.price)) === "number"
          && !isNaN(Number(this.state.price))
          && Number(Number(this.state.price)) > 0) ?
          Number((Math.round((Number(this.state.price)*Number(this.state.rate) - Number(this.state.margin)/100*(Number(this.state.price)*Number(this.state.rate)))* 100) /100)).toFixed(2) : 0;
      }
    }

    if (Object.keys(this.state.listAttrSelected).length > 0) {
      if (this.state.attribute[0]) {
        let attr1 = this.state.attribute[0].values.find(
          (value) => value.id === this.state.attribute[0].value
        );
        object.product_attribute_value_id1 = attr1.id;
        object.attribute_name1 = this.state.attribute[0].name;
        object.attribute_value1 = attr1.value;
      }
      if (this.state.attribute[1]) {
        let attr2 = this.state.attribute[1].values.find(
          (value) => value.id === this.state.attribute[1].value
        );
        object.product_attribute_value_id2 = attr2.id;
        object.attribute_name2 = this.state.attribute[1].name;
        object.attribute_value2 = attr2.value;
      } else {
        object.product_attribute_value_id2 = null;
        object.attribute_name2 = null;
        object.attribute_value2 = null;
      }
      if (this.state.attribute[2]) {
        let attr3 = this.state.attribute[2].values.find(
          (value) => value.id === this.state.attribute[2].value
        );
        object.product_attribute_value_id3 = attr3.id;
        object.attribute_name3 = this.state.attribute[2].name;
        object.attribute_value3 = attr3.value;
      } else {
        object.product_attribute_value_id3 = null;
        object.attribute_name3 = null;
        object.attribute_value3 = null;
      }
      if (this.state.attribute[3]) {
        let attr4 = this.state.attribute[3].values.find(
          (value) => value.id === this.state.attribute[3].value
        );
        object.product_attribute_value_id4 = attr4.id;
        object.attribute_name4 = this.state.attribute[3].name;
        object.attribute_value4 = attr4.value;
      } else {
        object.product_attribute_value_id4 = null;
        object.attribute_name4 = null;
        object.attribute_value4 = null;
      }
      if (this.state.attribute[4]) {
        let attr5 = this.state.attribute[4].values.find(
          (value) => value.id === this.state.attribute[4].value
        );
        object.product_attribute_value_id5 = attr5.id;
        object.attribute_name5 = this.state.attribute[4].name;
        object.attribute_value5 = attr5.value;
      } else {
        object.product_attribute_value_id5 = null;
        object.attribute_name5 = null;
        object.attribute_value5 = null;
      }
    }
    let Jproducts = localStorageService.getCart();
    let products = JSON.parse(Jproducts);
    let checked = false;
    if(products && Array.isArray(products) && products.length > 0){
      products.forEach((x) => {
        if(x.product_name === object.product_name && x.sku_code == object.sku_code && x.product_code == object.product_code){
          if(Number(object.inventory) <= Number(x.quantity)){
            checked = true;
          }
        }
      })
    }
    if(checked){
      this.setState({  isLoading1: false, isLoading2: false, isAlert: true, isLoading: false, sbMessage: 'Đã hết hàng tồn kho, vui lòng thử lại sau', sbColor: 'error' });
      return;
    }
    let token = LocalStorageService.getAccessToken();
    if (token) {
      addCart({ type: 1, carts: [object] }).then(() => {});
    }
    this.props.addToCart(object);
    setTimeout(() => {
      this.setState(
        {
          ...this.state,
          isAlert: true,
          sbColor: "success",
          sbMessage: "Thêm vào giỏ hàng thành công",
          isLoading1: false,
          isLoading2: false
        },
        () => {
          if (isRedirect) {
            this.props.history.push("/cart");
          }
        }
      );
    }, 1000);
   
  }

  buyNow() {
    this.setState({isLoading2: true});
    this.setAddToCart(true);
  }

  setRating(value) {
    this.state({ ...this.state, rating: value });
  }

  setQuantityPurchase(value, type) {
    if (type === "plus") {
      this.setState({ quantity: Number(this.state.quantity) + Number(value) });
    } else if (type === "minus") {
      this.setState({ quantity: Number(this.state.quantity) - Number(value) });
    } else {
     if (typeof Number(value) === "number" && !isNaN(value) && Number(value) > 0) {
        if (Number(value) > this.state.inventory) {
          this.setState({
            ...this.state,
            quantity: this.state.inventory,
            isAlert: true,
            sbMessage: "Bạn đã chọn hết số lượng tồn kho hiện có",
            sbColor: "error",
          });
        } else {
          this.setState({ quantity: Number(value) });
        }
      }
        else if(value === ""){
         this.setState({ quantity: "" });
      }
    }
  }

  handleAlertClose = () => {
    this.setState({ isAlert: false });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      await this.setState({isSkeleton: true, quantity: 1});
      let id = this.props.match.params.id;
      this.getProductInformation(id);
    }
    if (prevState.listAttrSelected !== this.state.listAttrSelected) {
      this.handleUpdateInfoProduct();
    }
  }

  handleUpdateInfoProduct = () => {
    let listAttrSelected = this.state.listAttrSelected;
    let attrDetails = this.state.attribute_detail;

    if (attrDetails.length > 0) {
      let attrDetail = attrDetails.find(
        (element) =>
          element.attribute_value_id1 ===
            listAttrSelected.attribute_value_id1 &&
          element.attribute_value_id2 ===
            listAttrSelected.attribute_value_id2 &&
          element.attribute_value_id3 ===
            listAttrSelected.attribute_value_id3 &&
          element.attribute_value_id4 ===
            listAttrSelected.attribute_value_id4 &&
          element.attribute_value_id5 === listAttrSelected.attribute_value_id5
      );

      if (attrDetail) {
        this.setState({
          ...this.state,
          price: attrDetail.price,
          inventory: Number(attrDetail.inventory) > 0 ? Number(attrDetail.inventory) : 0,
          quantity: Number(attrDetail.inventory) > 0 ? 1 : 0,
          discount_percent: attrDetail.discount_percent,
          discount_price: attrDetail.discount_price,
          sku_code: attrDetail.sku_code,
          isChoosed: true
        });
      }
    }
  };

  handleAttrChange = (e, index) => {
    let value = e.target.value;
    let attr = this.state.attribute;
    attr[index].value = value;
    attr[index].error = "";
    this.setState({ ...this.state, attribute: attr });
    switch (index) {
      case 0:
        this.setState({
          ...this.state,
          listAttrSelected: {
            ...this.state.listAttrSelected,
            attribute_value_id1: value,
          },
        });
        break;
      case 1:
        this.setState({
          ...this.state,
          listAttrSelected: {
            ...this.state.listAttrSelected,
            attribute_value_id2: value,
          },
        });
        break;
      case 2:
        this.setState({
          ...this.state,
          listAttrSelected: {
            ...this.state.listAttrSelected,
            attribute_value_id3: value,
          },
        });
        break;
      case 3:
        this.setState({
          ...this.state,
          listAttrSelected: {
            ...this.state.listAttrSelected,
            attribute_value_id4: value,
          },
        });
        break;
      case 4:
        this.setState({
          ...this.state,
          listAttrSelected: {
            ...this.state.listAttrSelected,
            attribute_value_id5: value,
          },
        });
        break;
      default:
        break;
    }
  };

  blurQuantityPurChase = (value) => {
    this.setState({quantity: typeof Number(value) === "number" && !isNaN(value) && Number(value) > 0 ? Number(value) : 1})
  }

  handleToggleFavorite = (id, type) => {
    if(!this.state.isAuth){
      this.props.history.push('/auth/sign-in');
      return;
    }
    let relatedProduct = [...this.state.relatedProduct];
    let newRelatedProduct = relatedProduct.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
   
    addProductFavorite({product_id: id, favorite: type === "remove" ? 0 : 1}).then((res) => {
      if(res.success){
        this.setState({
          isAlert: true,
          sbColor: 'success',
          sbMessage: type === "remove" ? 'Đã xoá khỏi danh sách sản phẩm yêu thích' : 'Đã thêm vào danh sách sản phẩm yêu thích'})
      }
    });
    this.setState({
      relatedProduct: newRelatedProduct,
    });
  }

  render() {
    const {
      id,
      name,
      image,
      price,
      inventory,
      quantity,
      description,
      weight,
      rating,
      relatedProduct,
      breadcrumb,
      attribute,
      attribute_detail,
      discount_price,
      discount_percent,
      isSkeleton,
      isLoading1,
      isLoading2,
      isAlert,
      sbMessage,
      sbColor,
      multi_price,
      multi_discount_price,
      multi_discount_percent,
      isChoosed,
      isExchange,
      rate,
      margin,
      margin_type,
      symbol,
      isNodata
    } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
      <Helmet>
          <title>{name}</title>
          <meta content={name} property="og:title"></meta>
          <meta property="og:image" content={image && image[0] ? image[0] : DefaultImage} ></meta>
          <meta property="og:description" content={description
            ? (description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").length > 200
              ? `${description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").substr(0, 197)}...`
              : description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ")) : `${name}` }></meta>
          <meta name="description" content={description
            ? (description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").length > 200
              ? `${description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ").substr(0, 197)}...`
              : description.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g," ")) : `${name}` } ></meta>
              <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <CategoryProduct />
        <Breadcrumb breadcrumb={breadcrumb} />
        <section id="product-detail" className={classes.productDetail}>
          <Container maxWidth="xl">
              {
                !isSkeleton && isNodata ? (
                  <React.Fragment>
                    <NotFoundProduct />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                  <div className={classes.infoProduct}>
                  <Grid container spacing={10}>
                    <Grid item xs={12} md={7}>
                      {isSkeleton && <LoadingSlider />}
                      {!isSkeleton && <SliderMain inventory={inventory} image={image} name={name} />}
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InformationProduct
                        isSkeleton={isSkeleton}
                        isLoading1={isLoading1}
                        isLoading2={isLoading2}
                        id={id}
                        price={price}
                        quantity={quantity}
                        inventory={inventory}
                        name={name}
                        rating={rating}
                        weight={weight}
                        attribute={attribute}
                        attribute_detail={attribute_detail}
                        discount_price={discount_price}
                        discount_percent={discount_percent}
                        multi_price={multi_price}
                        multi_discount_price={multi_discount_price}
                        multi_discount_percent={multi_discount_percent}
                        isChoosed={isChoosed}
                        handleAttrChange={(e, index) =>
                          this.handleAttrChange(e, index)
                        }
                        setQuantityPurchase={(value, type) =>
                          this.setQuantityPurchase(value, type)
                        }
                        blurQuantityPurChase={value => 
                          this.blurQuantityPurChase(value)
                        }
                        addToCart={() => this.buyProduct()}
                        buyNow={() => this.buyNow()}
                        isExchange={isExchange}
                        rate={rate}
                        margin={margin}
                        margin_type={margin_type}
                        symbol={symbol}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.descriptionProduct}>
                  <h2 className={classes.subTitle}>Mô tả sản phẩm</h2>
                  {!isSkeleton ? (
                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                  ) : (
                    <React.Fragment>
                      {[1, 2, 3, 4, 5].map((value, index) => (
                        <div key={index}>
                          <Skeleton />
                          <Skeleton animation={false} />
                          <Skeleton animation="wave" />
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </div>
                {relatedProduct.length > 0 && (
                  <div className={classes.fp}>
                    <RelatedProduct
                      products={relatedProduct}
                      handleAddToCart={(value) => this.setAddToCart(value)}
                      handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
                    />
                  </div>
                )}
                  </React.Fragment>
                )
              }
          </Container>
        </section>
        <AlertMessage
          open={isAlert}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    language: store.languageReducer.language,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(injectIntl(ProductDetail));
