import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  withStyles,
  Container,
  Grid,
} from "@material-ui/core";
import LocalStorageService from "../../services/LocalStorageService";
import { injectIntl } from "react-intl";
import style from "./style";
import { activeUser } from "../../models/user";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from '@material-ui/icons/Error';
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../utills/config";

const queryString = require("query-string");

const Session = styled.section`
  padding: 12px 0px;
  text-align: center;
`;
const ThumbIcon = styled.div`
  padding: 8px 16px;
`;
const IconSuccess = styled(DoneIcon)`
  font-size: 50px;
  color: #f2c123;
`;
const IconError = styled(ErrorIcon)`
  font-size: 50px;
  color: #ff242e;
`;
const Heading = styled.h1`
  font-size: 1.875rem;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 1.675rem;
    margin-bottom: 15px;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 26px;
  margin-bottom: 0px;
  color: #000;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ButtonCustom = styled(Link)`
  width: 235px;
  height: 45px;
  margin-top: 40px;
  line-height: 45px;
  text-align: center;
  background: #f2c123;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
`;

const localStorageService = LocalStorageService.getService();

class ActiveAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sbMessage: "",
      sbColor: "",
    };
  }

  componentDidMount() {
    if (localStorageService.getAccessToken()) {
      this.props.history.push("/");
    }

    const { intl } = this.props;

    this.setState({ loading: true });

    const dataToken = queryString.parse(this.props.location.search);

    if (this.props.location.search && dataToken.token) {
      activeUser(dataToken.token).then((res) => {
        if (res.success) {
          this.setState({
            sbMessage: intl.formatMessage({ id: "ActiveSuccess" }),
            sbColor: "success",
            loading: false,
          });
        } else {
          this.setState({
            sbMessage: intl.formatMessage({ id: "ActiveLinkFail" }),
            sbColor: "error",
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        sbMessage: intl.formatMessage({ id: "ActiveLinkFail" }),
        sbColor: "error",
        loading: false,
      });
    }
  }

  render() {
    let { sbMessage, sbColor, loading } = this.state;

    return (
      <React.Fragment>
      <Helmet>
          <title>Kích hoạt tài khoản</title>
          <meta property="og:title" content={"Kích hoạt tài khoản"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Kích hoạt tài khoản"}></meta>
          <meta name="description" content={"Kích hoạt tài khoản"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        {!loading && (
          <Session>
            <Container maxWidth="xl">
              <Grid container>
                <Grid item xl={12} xs={12}>
                  <ThumbIcon>
                    {sbColor === "success" ? (<IconSuccess />) : (<IconError />)}
                  </ThumbIcon>
                  {sbMessage && sbColor && (
                    <React.Fragment>
                      {sbColor === "success" ? (
                        <Heading>Kích hoạt tài khoản thành công</Heading>
                      ) : (
                        <Heading>Kích hoạt tài khoản thất bại</Heading>
                      )}
                      <Paragraph>
                        {sbMessage}
                      </Paragraph>
                    </React.Fragment>
                  )}
                  <ButtonCustom to="/auth/sign-in">
                    Trở về trang đăng nhập
                  </ButtonCustom>
                </Grid>
              </Grid>
            </Container>
          </Session>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(style)(injectIntl(ActiveAccount));
