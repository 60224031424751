import React, { Component } from "react";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import {
  Collapse,
  Hidden,
  MenuItem,
  Drawer,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import style from "./style";
import { setModalLogoutAction } from "../../redux/actions/modalActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { convertSlugSeo } from "../../utills/constant";
import LogoImage from './../../assets/images/logo2.png';

const NavigationItem = ({ label, to, trick , activeOnlyWhenExact, clsName }) => {
  let match = useRouteMatch({
    path: trick ? trick : to,
    exact: activeOnlyWhenExact,
  });

  return (
    <li className={match ? "active" : ""}>
      <Link to={to}>{label}</Link>
    </li>
  );
};

let CloseBtn = styled(Button)`
  position: absolute;
  right: 0px;
  top: 0px;
`;

const ListItemSpecial = styled(ListItem)`
  &::before {
    content: "";
    height: 1px;
    background: #f2c123;
    width: 100%;
    position: absolute;
    top: -16px;
    left: 0px;
  }
`;

class NavMobile extends Component {
  handleLogout = () => {
    this.props.setModalLogout(true);
    this.props.onDrawerToggle();
  };

  render() {
    let {
      intl,
      classes,
      mobileOpen,
      isAuth,
      categories,
      isOpenSubCat,
      handleToggleSubCat,
      idOpenSubCat
    } = this.props;
    return (
      <Hidden mdUp>
        <Drawer
          open={mobileOpen}
          onClose={() => this.props.onDrawerToggle()}
          className={classes.drawerNav}
        >
          <List>
            <ListItem className="navListItem" button>
              {/* <Typography variant="h1" className="logoText" onClick={() => this.props.onDrawerToggle()}> */}
              <Link to="/"><img src={LogoImage} alt="logo" /></Link>
              {/* </Typography> */}
              <CloseBtn onClick={() => this.props.onDrawerToggle()}>
                <CloseIcon />
              </CloseBtn>
            </ListItem>
            {categories && Array.isArray(categories) && categories.length > 0 && (
              <React.Fragment>
                {categories.map((value, index) => (
                  <ListItem className="navListItem" key={index}>
                    <div onClick={() => this.props.onDrawerToggle()}>
                    <NavigationItem
                      label={value.name}
                      to={value.id && value.name && `/categories/${convertSlugSeo(value.name)}.id=${
                        value.id
                      }`}
                    />
                    </div>
                    {value.sub_category && Array.isArray(value.sub_category) && value.sub_category.length > 0 && (
                      <React.Fragment>
                        {isOpenSubCat && idOpenSubCat === value.id ? (
                          <ExpandLessIcon
                            onClick={() => handleToggleSubCat(false, value.id)}
                            className={classes.toggleIcon}
                          />
                        ) : (
                          <ExpandMoreIcon
                            onClick={() => handleToggleSubCat(true, value.id)}
                            className={classes.toggleIcon}
                          />
                        )}

                        <ul className={classes.subCat}>
                          <Collapse
                            in={isOpenSubCat && idOpenSubCat === value.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {value.sub_category.map((sub_cat, sub_index) => (
                                <ListItem
                                  className="navListItem"
                                  style={{
                                    borderBottom: "none",
                                    padding: "8px 40px 2px 16px",
                                  }}
                                  key={sub_index}
                                >
                                <div  onClick={() => this.props.onDrawerToggle()}>
                                <NavigationItem
                                    className={classes.subItemCat}
                                    label={sub_cat.name}
                                    to={sub_cat.parent_id && sub_cat.name && sub_cat.id && `/categories/${convertSlugSeo(sub_cat.name)}.id=${sub_cat.parent_id}_${sub_cat.id}`}
                                  />
                                </div>
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        </ul>
                      </React.Fragment>
                    )}
                  </ListItem>
                ))}
              </React.Fragment>
            )}
            <ListItemSpecial className="navListItemSpecial" button>
              <NavigationItem
                label={intl.formatMessage({
                  id: "Nav.Purchase",
                })}
                to="/chinh-sach-dat-hang"
              />
            </ListItemSpecial>
            <ListItem className="navListItem" button>
              <NavigationItem
                label={intl.formatMessage({
                  id: "Nav.Promotion",
                })}
                to="/khuyen-mai"
              />
            </ListItem>
            <ListItem className="navListItem" button>
              <NavigationItem
                label={intl.formatMessage({
                  id: "Nav.Menu",
                })}
                to="/thuc-don"
              />
            </ListItem>
            <ListItem className="navListItem" button>
              <NavigationItem
                label={"Blog"}
                to="/blog"
              />
            </ListItem>

            {!isAuth ? (
              <ListItem className="navListItem" button>
                <NavigationItem
                  label={intl.formatMessage({
                    id: "Login",
                  })}
                  to="/user/account"
                  trick="/auth/sign-in"
                />
              </ListItem>
            ) : (
              <React.Fragment>
                <ListItem className="navListItem" button>
                  <NavigationItem
                    label={intl.formatMessage({
                      id: "Nav.Profile",
                    })}
                    to="/user/account"
                  />
                </ListItem>
                <ListItem className="navListItem" button>
                  <MenuItem
                    className={classes.btnLogout}
                    onClick={() => this.handleLogout()}
                  >
                    {intl.formatMessage({
                      id: "Signout",
                    })}
                  </MenuItem>
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </Drawer>
      </Hidden>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setModalLogout: (logout) => {
      dispatch(setModalLogoutAction(logout));
    },
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  withStyles(style)
)(withTheme(withRouter(injectIntl(NavMobile))));
