import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container, Grid, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { Skeleton } from "@material-ui/lab";
import Slider from "react-slick";

const settings = {
  dots: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: dots => (
    <div
      style={{
        bottom: '10px'
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
};

class Banner extends Component {
  render() {
    const { classes, listBanner, listBannerMobile, isLoading } = this.props;
    return (
      <section className={classes.banner}>
          <div className="banner-desktop">
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {isLoading ? (
               <div className="bannerItem">
                 <Skeleton variant="rect" className={classes.bannerSkeleton} />
               </div>
              ) : (
                <Slider {...settings}>
                  {listBanner && Array.isArray(listBanner) && listBanner.length > 0 && listBanner.map((value, index) => (
                    <div className="bannerItem" key={index}>
                      <a href={value.link} className="bannerLink">
                        <img src={value.image} alt={`Banner ${index+1}`} />
                      </a>
                    </div>
                  ))}
                </Slider>
              )}
            </Grid>
          </Grid>
          </div>
          <div  className="banner-mobile">
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {isLoading ? (
               <div className="bannerItem">
                 <Skeleton variant="rect" className={classes.bannerSkeletonMobile} />
               </div>
              ) : (
                <Slider {...settings}>
                  {listBannerMobile && Array.isArray(listBannerMobile) && listBannerMobile.length > 0 && listBannerMobile.map((value, index) => (
                    <div className="bannerItem" key={index}>
                      <a href={value.link} className="bannerLink">
                        <img src={value.image} alt="" />
                      </a>
                    </div>
                  ))}
                </Slider>
              )}
            </Grid>
          </Grid>
          </div>
      </section>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(Banner)));
