import React, { Component } from 'react';

import { injectIntl } from 'react-intl';
import styled, { withTheme } from "styled-components";
import { connect } from 'react-redux';
import { compose } from 'redux';
import style from "./style";
import { Autocomplete } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import { Search as SearchIcon } from "react-feather";
import {
    CircularProgress,
    Hidden,
    TextField,
    withStyles,
  } from "@material-ui/core";
import _ from 'lodash';

const ButtonSearchIcon = styled.div`
    position: absolute;
    background: #f2c123;
    right: -1px;
    width: 55px;
    top: -1px;
    height: 40px;
    border-radius: 0px 22px 22px 0px;
`;

class Search extends Component {

    constructor(props){
      super(props);
      this.state = {
        isOpen: false
      }
    }

    handleChangeOpenInput = () => {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }));
    }
    render() {
        const { isOpen } = this.state;
        let { classes, isMobile, options, value, loading, filterProduct, viewSearch } = this.props;
        return (
            <Hidden smDown={true}>
                  <div className={classes.search}  style={{marginRight: isOpen || isMobile ? '0px' : '50px'}}>
                    <Autocomplete
                    className={classes.inputSearchDesktop}
                      freeSolo
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.name
                      }
                      filterOptions={(x) => x}
                      options={options}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      value={value}
                      size="small"
                      loading={loading}
                      onChange={(event, newValue) => {
                        this.setState({
                          options: newValue ? [newValue, ...options] : [],
                          value: newValue,
                        });
                        viewSearch(newValue);
                      }}
                      onInputChange={_.debounce((event, newInputValue) => filterProduct(newInputValue), 1000)}
                      renderInput={(params) => (
                       <React.Fragment>
                         <TextField
                          {...params}
                          variant="outlined"
                          className="border-none"
                          fullWidth
                          size="small"
                          placeholder="Tìm kiếm"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={15} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                        <ButtonSearchIcon>
                          <SearchIcon style={{color: '#fff', position: 'absolute', display: 'block', top: '8px', left: '14px'}} size={23} />
                        </ButtonSearchIcon>
                       </React.Fragment>
                      )}
                    />
                  </div>
                </Hidden>
        )
    }
}

const mapStateToProps = (store) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
    };
  };
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
    withConnect,
    withStyles(style)
  )(withTheme(withRouter(injectIntl(Search))));