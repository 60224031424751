import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import CustomButton from "../../UI/Button";
import { withRouter } from "react-router-dom";

class DeleteCart extends Component {
  render() {
    const { loadingBtnDelete, cart_id, product_name, isOpenModal, onClose, isAuth } = this.props;
    return (
      <Dialog
        maxWidth="xs"
        open={isOpenModal}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Xóa sản phẩm
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Bạn muốn xoá sản phẩm {product_name} khỏi giỏ hàng?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton btnType="success" onClick={() => onClose()}>
            <FormattedMessage id="No" />
          </CustomButton>
          <CustomButton
            customStyle={{width: '80px', height: '38px'}}
            onClick={() => this.props.handleDeleteItemCart(cart_id, isAuth)}
            btnType="danger"
            loading={loadingBtnDelete}
            autoFocus
          >
            <FormattedMessage id="Yes" />
          </CustomButton>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
	deleteItemCart: (payload) => {
		dispatch({ type: "DELETE_ITEM_CART", payload });
	  },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(withRouter(injectIntl(DeleteCart)));
