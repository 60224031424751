import {
  Container,
  FormControl,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { compose } from "redux";
import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
import LeftSidebar from "../components/LeftSidebar";
import Button from "./../../../components/UI/Button";
import { changeUserPassword } from "./../../../models";
import { setBreadcrumb } from "./../../../redux/actions/breadcrumbActions";
import style from "./style";
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../../utills/config";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      old_password: "",
      new_password: "",
      confirm_new_password: "",
      sbColor: "error",
      sbMessage: "",
      isOpen: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb("Change Password");
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.new_password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpen: false });
  };

  onSubmit = () => {
    const { old_password, new_password, confirm_new_password } = this.state;
    const req = {
      old_password,
      new_password,
      confirm_new_password,
    };
    this.setState({ loading: true });
    const that = this;
    changeUserPassword(req)
      .then((res) => {
        if (res.success) {
          that.setState({
            isOpen: true,
            sbMessage: <FormattedMessage id={res.title} />,
            sbColor: "success",
            loading: false,
          });
          this.resetForm();
        } else {
          that.setState({
            isOpen: true,
            sbMessage: <FormattedMessage id="Old password does not match" />,
            sbColor: "error",
            loading: false,
          });
        }
      })
      .catch((res) => console.log(res));
  };

  resetForm = () => {
    this.setState({
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });
  };

  render() {
    const { classes, intl } = this.props;
    const {
      isOpen,
      sbColor,
      loading,
      sbMessage,
      old_password,
      new_password,
      confirm_new_password,
    } = this.state;
    return (
      <React.Fragment>
         <Helmet>
          <title>Đổi mật khẩu</title>
          <meta property="og:title" content={"Đổi mật khẩu"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đổi mật khẩu"}></meta>
          <meta name="description" content={"Đổi mật khẩu"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          open={isOpen}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <section className={classes.changePassword}>
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={5} md={2}>
              <LeftSidebar />
            </Grid>
            <Grid item xs={12} sm={7} md={10}>
            <h2>Đổi mật khẩu</h2>
              <ValidatorForm
                className={classes.validateForm}
                ref="form"
                onSubmit={this.onSubmit}
                onError={() => {
                  this.setState({
                    isOpen: false,
                    sbMessage: "",
                  });
                }}
              >
                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  className={classes.txtControl}
                >
                    <label>
                      Mật khẩu hiện tại <span className="txtDanger">*</span>
                    </label>
                  <TextValidator
                  variant="outlined"
                    onChange={(e) =>
                      this.setState({
                        old_password: e.target.value,
                      })
                    }
                    type="password"
                    name="old_password"
                    value={old_password}
                    validators={["required"]}
                    errorMessages={[
                      intl.formatMessage({
                        id: "Error.Required",
                      }),
                    ]}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                </FormControl>

                <FormControl
                  margin="normal"
                  required
                  fullWidth
                  className={classes.txtControl}
                >
                   <label>
                      Mật khẩu mới <span className="txtDanger">*</span>
                    </label>
                  <TextValidator
                  variant="outlined"
                    onChange={(e) =>
                      this.setState({
                        new_password: e.target.value,
                      })
                    }
                    type="password"
                    name="new_password"
                    value={new_password}
                    validators={["required"]}
                    errorMessages={[
                      intl.formatMessage({
                        id: "Error.Required",
                      }),
                    ]}
                    inputProps={{
                      maxLength: 255,
                    }}
                  />
                </FormControl>

                <FormControl
                  margin="normal"
                  fullWidth
                  className={classes.txtControl}
                >
                   <label>
                      Xác nhận mật khẩu mới <span className="txtDanger">*</span>
                    </label>
                  <TextValidator
                  variant="outlined"
                    onChange={(e) =>
                      this.setState({
                        confirm_new_password: e.target.value,
                      })
                    }
                    type="password"
                    name="confirm_new_password"
                    value={confirm_new_password}
                    validators={["required", "isPasswordMatch"]}
                    errorMessages={[
                      intl.formatMessage({
                        id: "Error.Required",
                      }),
                      intl.formatMessage({
                        id: "Error.Confirm New Password Incorrect",
                      }),
                    ]}
                  />
                </FormControl>

                <Button
                  customClass={classes.btnSubmit}
                  size="large"
                  fullWidth
                  variant="contained"
                  type="submit"
                  btnType="success"
                  loading={loading}
                >
                  <FormattedMessage id="Change Password" />
                </Button>
              </ValidatorForm>
            </Grid>
          </Grid>
        </Container>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    language: store.languageReducer.language,
    user: store.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setBreadcrumb: (header) => {
      dispatch(setBreadcrumb(header));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(injectIntl(ChangePassword));
