import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FormControl,
  Typography,
  withStyles,
  Box,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import LocalStorageService from "./../../services/LocalStorageService";
import { FormattedMessage, injectIntl } from 'react-intl';
import style from "./style";
import { apiDomain, reCaptchaKey } from './../../utills/config'
import ReCAPTCHA from "react-google-recaptcha";
import { forgetPassword } from "./../../models/user";
import CustomButton from './../../components/UI/Button';
import { Helmet } from 'react-helmet';

const localStorageService = LocalStorageService.getService();
class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isOpen: false,
      sbColor: 'error',
      sbMessage: '',
      isCheck: false,
      loading: false
    };
  }

  componentDidMount() {
    if (localStorageService.getAccessToken()) {
      this.props.history.push('/')
    }
  }

  onUpdatePassword = () => {
    let that = this;
    const { email, isCheck } = this.state;
    const { intl } = this.props;

    if (isCheck) {
      this.setState({
        loading: true
      })

      forgetPassword(email).then((res) => {
        if (res.success) {
          that.setState({
            isOpen: true,
            sbColor: 'success',
            sbMessage: intl.formatMessage({id: 'Password reset email sent successfully'}) ,
            loading: false,
            email: ''
          });
        } else {
          that.setState({
            isOpen: true,
            sbColor: 'error',
            sbMessage: intl.formatMessage({id: 'Email address not registered'}) ,
            loading: false
          });
        }
      });
    } else {
      that.setState({
        isOpen: true,
        sbColor: 'error',
        sbMessage: intl.formatMessage({id: 'Please check input the captcha'})
      });
    }

  }

  handleChange = (e) => {
    if (e) {
      this.setState({ isCheck: true })
    } else {
      this.setState({ isCheck: false })
    }
  }

  handleAlertClose = () => {
    this.setState({ ...this.state, open: false });
  };

  render() {
    let {  isOpen, sbMessage, sbColor, email, loading } = this.state;
    const { classes: clsName, intl } = this.props;

    return (
      <React.Fragment>
          <Helmet>
          <title>Khôi phục mật khẩu</title>
          <meta property="og:title" content={"Khôi phục mật khẩu"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Khôi phục mật khẩu"}></meta>
          <meta name="description" content={"Khôi phục mật khẩu"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
      <section className={clsName.authPage}>
        <Typography
          variant="inherit"
          align="center"
          gutterBottom
          display="block"
          className={clsName.headingAuth}
        >
          <FormattedMessage id="Reset password" ></FormattedMessage>
        </Typography>

        {isOpen && sbMessage && (
            <Typography
              variant="inherit"
              align="center"
              gutterBottom
              display="block"
              className={sbColor === "error" ? clsName.txtError  : clsName.txtSuccess }
            >
             {sbMessage}
            </Typography>
          )}

        <ValidatorForm
          ref="form"
          onSubmit={this.onUpdatePassword}
          onError={() => { this.setState({ isOpen: false, errorMessage: '' }) }}
        >
          <FormControl
            margin="normal"
            required
            fullWidth
            className={clsName.txtControl}
            style={{marginBottom: '8px'}}
          >
             <label>
                      Email <span className="txtDanger">*</span>
                    </label>
            <TextValidator
            variant="outlined"
              onChange={(e) => this.setState({ email: e.target.value })}
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[intl.formatMessage({ id: 'Error.Required' }), intl.formatMessage({ id: 'Error.ValidEmail' })]}
              autoFocus
              inputProps={{
                maxLength: 255
              }}
            />
          </FormControl>

          <div style={{
            textAlign: "center",
            paddingTop: 10,
            paddingBottom: 10
          }}>
            <ReCAPTCHA
              sitekey={reCaptchaKey}
              onChange={this.handleChange}
            />
          </div>
          <CustomButton
						size='large'
						fullWidth
						variant='contained'
						type='submit'
						btnType='success'
            loading={loading}
						customClass={clsName.btnSubmit}>
						<FormattedMessage id='Reset password'></FormattedMessage>
					</CustomButton>
          <Box
              style={{ padding: "18px 0px 16px" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
          <Typography align="center">
            <Link to="/auth/sign-in" className={clsName.txtSuccess}>
              <FormattedMessage id="Back to Login" ></FormattedMessage>
            </Link>
          </Typography>
          </Box>
        </ValidatorForm>

      </section >
      </React.Fragment>
    );
  }
}

export default withStyles(style)(injectIntl(ResetPassword));
