import { withStyles, Grid } from "@material-ui/core";
import React, { Component } from "react";
// import { compose } from 'redux';
import style from "./../../style";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { CURRENCY } from "../../../../../utills/constant";
class Detail extends Component {
  render() {
    const { detailOrder, classes, isExchange, symbol, rate, margin, margin_type } = this.props;
    return (
      <div className={classes.detailOrder}>
        <div className="contact-info">
          <Grid container>
            <Grid item xl={6} md={12} sm={12} xs={12}>
              <div className="info-item">
                <h3>Địa chỉ thanh toán</h3>
                <p>{detailOrder.invoice_name}</p>
                <p>{detailOrder.invoice_address}</p>
                <p>{detailOrder.invoice_postcode}</p>
                <p>{detailOrder.invoice_city}</p>
                <p>{detailOrder.invoice_country}</p>
                <p>{detailOrder.invoice_phone}</p>
              </div>
            </Grid>
            <Grid item xl={6} md={12} sm={12} xs={12}>
              <div className="info-item">
                <h3>Địa chỉ giao hàng</h3>
                <p>{detailOrder.receiver_name}</p>
                <p>{detailOrder.delivery_address}</p>
                <p>{detailOrder.delivery_postcode}</p>
                <p>{detailOrder.delivery_city}</p>
                <p>{detailOrder.delivery_country}</p>
                <p>{detailOrder.receiver_phone}</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="detail-product">
          <div className="table-desktop">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.cellTableDetail}>
                      Tên sản phẩm
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      SKU
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Số lượng
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Giá
                    </TableCell>
                    <TableCell className={classes.cellTableDetail}>
                      Tạm tính
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailOrder.detail && Array.isArray(detailOrder.detail) && detailOrder.detail.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.cellTableDetail}
                        component="th"
                        scope="row"
                      >
                        {row.product_name}
                      </TableCell>
                      <TableCell className={classes.cellTableDetail}>
                      {row.sku_code ? (<React.Fragment>{row.sku_code}</React.Fragment>) : (<React.Fragment>{row.product_code}</React.Fragment>)}
                      </TableCell>
                      <TableCell className={classes.cellTableDetail}>
                        <NumberFormat
                          value={row.quantity}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` `}
                        />
                      </TableCell>
                      <TableCell className={classes.cellTableDetail}>
                        {" "}
                        { row.discount ? (<React.Fragment>
                          <NumberFormat
                          value={detailOrder.total_payment_eur ? row.discount_exchange : row.discount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />
                        <del className="txtDanger" style={{marginLeft: '10px'}}>
                        <NumberFormat
                          value={detailOrder.total_payment_eur ? row.price_exchange : row.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />
                        </del>
                        </React.Fragment>) : (
                          <NumberFormat
                          value={detailOrder.total_payment_eur ? row.price_exchange : row.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />
                        )}
                      </TableCell>
                      <TableCell className={classes.cellTableDetail}>
                        {" "}
                        <NumberFormat
                          value={detailOrder.total_payment_eur ? row.provisional_exchange : row.provisional}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="table-mobile">
            <Grid container>
              <Grid item xl={12} xs={12}>
                {detailOrder.detail && Array.isArray(detailOrder.detail) && detailOrder.detail.map((row, index) => (
                  <div className="item-detail-mb" key={index}>
                    <p>
                      Tên sản phẩm: <span>{row.product_name}</span>
                    </p>
                    <p>
                      SKU: <span>{row.sku_code ? (<React.Fragment>{row.sku_code}</React.Fragment>) : (<React.Fragment>{row.product_code}</React.Fragment>)}</span>
                    </p>
                    <p>
                      Số lượng:{" "}
                      <span>
                        {" "}
                        <NumberFormat
                          value={row.quantity}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` `}
                        />
                      </span>
                    </p>
                    <p>
                      Đơn giá:{" "}
                      { row.discount ? (<NumberFormat
                          value={detailOrder.total_payment_eur ? Number(row.discount_exchange) : Number(row.discount)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />) : (
                          <NumberFormat
                          value={detailOrder.total_payment_eur ? Number(row.price_exchange) : Number(row.price)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                          prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                        />
                        )}
                    </p>
                    <p>
                      Tạm tính:{" "}
                      <NumberFormat
                        value={detailOrder.total_payment_eur ? Number(row.provisional_exchange) : Number(row.provisional)}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
                        prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
                      />
                    </p>
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="total-price">
          <p>
            Tổng tạm tính:{" "}
            <NumberFormat
              value={
                detailOrder.total_payment_eur ?
                Number(detailOrder.provisional_exchange) :
                Number(detailOrder.provisional)}
              displayType={"text"}
              thousandSeparator={true}
              suffix={!detailOrder.symbol ? ` ${CURRENCY}` : ""}
              prefix={detailOrder.symbol ? `${detailOrder.symbol} `: ""}
            />
          </p>
            {
              detailOrder.voucher_code && detailOrder.voucher_value && detailOrder.voucher_value_type ? (
                <p>
                Mã khuyến mãi {detailOrder.voucher_code}:{" "}
                <span style={{color: '#ff0000'}}>
                    {
                      isExchange ? (
                        <React.Fragment>
                        {
                          detailOrder.voucher_value_type === "percent" ? (
                            <span>
                              {`${symbol} ${Number((Math.round((Number(Number(detailOrder.provisional_exchange)
                                * (Number(detailOrder.voucher_value)/100))) * 100) / 100).toFixed(2))}`}
                              {` (${detailOrder.voucher_value}%)`}
                            </span>
                          ) : (
                            <NumberFormat
                              value={Number(detailOrder.voucher_value_exchange)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                              />
                          )
                        }
                      </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {
                            detailOrder.voucher_value_type === "percent" ? (
                              <span>
                                {`${Number((Math.round((Number(Number(detailOrder.provisional)
                                  * (Number(detailOrder.voucher_value)/100))) * 100) / 100).toFixed(2))} ${CURRENCY}`}
                                {` (${detailOrder.voucher_value}%)`}
                              </span>
                            ) : (
                              <NumberFormat
                                value={Number(detailOrder.voucher_value)}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${CURRENCY}`}
                                />
                            )
                          }
                        </React.Fragment>
                      )
                    }
                </span>
              </p>
              ) : (<React.Fragment></React.Fragment>)
            }
            {
              detailOrder.price_discount ? (
                <p>
                  Khuyến mãi thành viên: {" "}
                  {
                    isExchange ? (
                      <span style={{color: '#ff0000'}}>
                        {

                        }
                      {detailOrder.price_discount_percent ? (
                        <React.Fragment>
                          {`${symbol} ${Number((Math.round((Number(Number(Number(detailOrder.provisional_exchange) 
                            * Number(detailOrder.price_discount_percent / 100)))) * 100) / 100).toFixed(2))}`}
                          {` (${Number(detailOrder.price_discount_percent)}%)`}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                           {`${symbol} ${Number((Math.round(Number(Number(detailOrder.price_discount)*Number(rate)) * 100) / 100).toFixed(2))}`}
                        </React.Fragment>
                      )}
                    </span>
                    ) : (
                      <span style={{color: '#ff0000'}}>
                      {`${Number(detailOrder.price_discount)} ${CURRENCY}`}
                      {detailOrder.price_discount_percent && (` (${Number(detailOrder.price_discount_percent)}%)`)}
                    </span>
                    )
                  }
                </p>
              ) : (<React.Fragment></React.Fragment>)
            }
          <p>
            Phí vận chuyển:{" "}
            {
              isExchange ? (
                <React.Fragment>
                  {detailOrder.shipment_fee_discount_percent ?
                      (<React.Fragment>
                       <NumberFormat
                      value={
                        Number((Math.round((Number(detailOrder.shipment_fee_exchange) 
                        - (Number(detailOrder.shipment_fee_exchange) 
                        * (detailOrder.shipment_fee_discount_percent / 100))) * 100) / 100).toFixed(2))
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={`${symbol} `}
                    /> 
                      {(` (-${Number(detailOrder.shipment_fee_discount_percent)}%)`)}
                      </React.Fragment>)
                     :
                    (<React.Fragment>
                      <NumberFormat
                      value={
                        Number((Math.round(((Number(detailOrder.shipment_fee_exchange) * 10
                        - Number(detailOrder.shipment_fee_discount_exchange) * 10)/10) * 100) / 100).toFixed(2))
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={`${symbol} `}
                    /> 
                    { detailOrder.shipment_fee_discount ? (<React.Fragment>{(` (${symbol} -${Number((Math.round((Number(detailOrder.shipment_fee_discount)*Number(rate))* 100) /100).toFixed(2))})`)}</React.Fragment>) : (<React.Fragment></React.Fragment>)}
                    </React.Fragment>)
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <NumberFormat
                    value={Number((Math.round(Number(Number(detailOrder.shipment_fee)
                      - (Number(detailOrder.shipment_fee_discount) > 0 ?
                        Number(detailOrder.shipment_fee_discount) : 0)) * 100) / 100).toFixed(2))}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={` ${CURRENCY}`}
                  />
                  {
                    detailOrder.shipment_fee_discount_percent || detailOrder.shipment_fee_discount ? (<React.Fragment>
                      {
                        detailOrder.shipment_fee_discount_percent ?
                        (` (-${Number(detailOrder.shipment_fee_discount_percent)}%)`) :
                        (` (-${Number(detailOrder.shipment_fee_discount)} ${CURRENCY})`)
                      }
                    </React.Fragment>) : (<React.Fragment></React.Fragment>)
                  }
                </React.Fragment>
              )
            }
          </p>
          <p>
            Tổng cộng:{" "}
            <NumberFormat
              value={detailOrder.total_payment_eur ? Number(detailOrder.total_payment_eur) : Number(detailOrder.total_payment)}
              displayType={"text"}
              thousandSeparator={true}
              suffix={!detailOrder.total_payment_eur ? ` ${CURRENCY}` : " "}
              prefix={detailOrder.total_payment_eur ? `${detailOrder.symbol} `: ""}
            />
             {
               detailOrder.total_payment_eur && (
                <React.Fragment>(<NumberFormat value={Number((Math.round((Number(detailOrder.total_payment_eur)/Number(detailOrder.rate)) * 100) / 100).toFixed(2))}
                displayType={'text'}
                thousandSeparator={true}
                suffix={` Kč`}
                />)
                </React.Fragment>
               )
             }
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(Detail)));
