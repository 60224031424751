import {
  Container,
  Grid,
  Snackbar,
  CircularProgress as Loading,
  Button,
} from "@material-ui/core";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
import LeftSidebar from "../components/LeftSidebar";
import NumberFormat from "react-number-format";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";

import { createUserVoucher, getUserVoucher, getVoucherSetting } from "../../../models/user";
import PaginationUI from "../../../components/UI/PaginationUI";
import { CURRENCY } from "../../../utills/constant";
import DesktopPoint from "./DesktopPoint";
import MobilePoint from "./MobilePoint";
import { apiDomain } from "../../../utills/config";



const BoxTotalPoint = styled.div`
  padding: 10px;
  text-align: center;
  border: 1px solid #eee;
`;

const BoxChangeVoucher = styled.div`
  padding: 10px;
  border: 1px solid #eee;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 130px;
    & .span-block {
    display: block;
    margin-bottom: 5px;
    }
    & button {
      margin: 0px;
    }
  }
`;

class MyAccumulatedPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      sbColor: "error",
      sbMessage: "",
      isSubmited: false,
      colorValueError: false,
      point: "",
      isSkeleton: false,
      page: 1,
      page_size: 10,
      pageCount: 0,
      listResult: [],
      dividend: 0,
      member_point_used: 0,
      member_point: 0,
      valueVoucher: 0
    };
  }

  componentDidMount() {
    this.getSetting();
    this.getListHistory();
  }

  getSetting = () => {
    getVoucherSetting({}).then((res)=> {
      if(res.success) {
        if(res.setting && res.setting.exchange_rate && Number(res.setting.exchange_rate) > 0) {
          this.setState({dividend: res.setting.exchange_rate})
        }
      }
    })
  }
  
  getListHistory = () => {
    this.setState({isSkeleton: true});
    const { page, page_size} = this.state;
    let params = {
      page,
      page_size,
    };
    getUserVoucher(params).then(async (res) => {
      if(res.success) {
        let vouchers = res.user_voucher ? res.user_voucher : [];
        let rs = [];
        if(vouchers && vouchers.length > 0) {
          vouchers.forEach((v) => {
            if(v.exchange_rate) {
              if(v.margin_type === "increase") {
                v.value_exchange = Number((Math.round((v.value*v.exchange_rate
                  + v.margin/100*(v.value*v.exchange_rate))* 100) /100)).toFixed(2);
              }else{
                v.value_exchange = Number((Math.round((v.value*v.exchange_rate
                  - v.margin/100*(v.value*v.exchange_rate))* 100) /100)).toFixed(2);
              }
            }
            rs.push(v);
          })
        }
        this.setState({
          listResult: vouchers,
          isSkeleton: false,
          pageCount: res.page_count,
          member_point: res.member_point && Number(res.member_point) > 0 ? Number(res.member_point) : 0,
          member_point_used: res.member_point_used && Number(res.member_point_used) > 0 ? Number(res.member_point_used) : 0,
        })
      }
    })
  }

  handlePointChange = (e) => {
    if (e.target.value.length < 7) {
      this.setState({
        point: e.target.value,
        colorValueError: false,
        valueVoucher: Number((Math.round((Number(e.target.value) / Number(this.state.dividend)) * 100) / 100).toFixed(2))});
    }
  };

  checkValidatePoint = (value) => {
    if (value.toString().length === 0) {
      this.setState({ colorValueError: true, isSubmited: false });
      return false;
    }
    return true;
  };

  onSave = () => {
    this.setState({ isSubmited: true });
    const { point, dividend } = this.state;

    let isValidPoint = this.checkValidatePoint(point);
    if (!isValidPoint) return;
    if(point % 100 !== 0 || point / dividend < 100){
      this.setState({
        isOpen: true,
        sbMessage: `Điểm số đổi thưởng phải là bội của 100 và Giá trị voucher phải lớn hơn 100 ${CURRENCY}`,
        sbColor: 'error',
        isSubmited: false});
        return
    }
    createUserVoucher({type: 'exchange', point: point}).then((res) => {
      if(res.success) {
        this.setState({
          isOpen: true,
          sbMessage: `Đổi điểm voucher thành công, mã voucher ${res.detail.code} trị giá ${res.detail.value} ${CURRENCY}`,
          sbColor: 'success',
          isSubmited: false,
          point: ''
          });
        this.getListHistory();
      }else{
          this.setState({
            isOpen: true,
            sbMessage: res.error_message && res.error_message.point
              && res.error_message.point.inValid ?
              'Điểm không hợp lệ, vui lòng kiểm tra lại' :
              (res.error_message && res.error_message.point && res.error_message.point.isExceed ?
                'Giá trị đổi thưởng đã vượt quá số lượng điểm thưởng hiện có' :
                'Có lỗi xảy ra, vui lòng nhập lại'),
            sbColor: 'error',
            isSubmited: false});
      }
    })
  };

  handlePaginationChange = (event, value) => {
    this.setState({ page: value });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getListHistory();
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpen: false });
  };

  render() {
    const {
      isOpen,
      sbColor,
      sbMessage,
      isSubmited,
      colorValueError,
      point,
      isSkeleton,
      listResult,
      page,
      pageCount,
      member_point,
      member_point_used,
      valueVoucher
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Điểm tích lũy</title>
          <meta property="og:title" content={"Điểm tích lũy"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Điểm tích lũy"}></meta>
          <meta name="description" content={"Điểm tích lũy"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={10000}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <section id="my-address" className={classes.MyAccumulatedPoints}>
          <Container>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <LeftSidebar />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={10}>
                <h2>Điểm tích lũy</h2>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    {!isSkeleton ? (<BoxTotalPoint>
                      <p style={{ marginBottom: "5px" }}>
                        Tổng điểm tích lũy:{" "}
                        <span style={{ fontWeight: "bold" }}>
                        <NumberFormat
                          value={Number(member_point)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        </span>
                      </p>
                      <p style={{ marginBottom: "5px" }}>
                        Tổng điểm khả dụng:{" "}
                        <span style={{ fontWeight: "bold" }}>
                        <NumberFormat
                          value={Number(member_point) - Number(member_point_used)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        </span>
                      </p>
                      <p>
                        Điểm đã sử dụng:{" "}
                        <span style={{ fontWeight: "bold" }}>
                        <NumberFormat
                          value={Number(member_point_used)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        </span>
                      </p>
                    </BoxTotalPoint>) : (
                      <BoxTotalPoint style={{overflow: 'hidden', padding: '20px'}}>
                      <p style={{ marginBottom: "5px" }}>
                      <Skeleton variant="text" width={180} style={{margin: '0px auto'}} />
                      </p>
                      <p style={{ marginBottom: "5px" }}>
                      <Skeleton variant="text" width={180} style={{margin: '0px auto'}} />
                      </p>
                      <p>
                      <Skeleton variant="text" width={180} style={{margin: '0px auto'}}/>
                      </p>
                    </BoxTotalPoint>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                      {!isSkeleton ? (
                        <BoxChangeVoucher>
                        <ValidatorForm
                          style={{ display: "inline-block" }}
                          ref="form"
                          onSubmit={this.onSave}
                          onError={() => {
                            this.checkValidatePoint(point);
                          }}
                        >
                          <p
                            style={{
                              display: "inline-block",
                              position: "relative",
                            }}
                          >
                            <span>Đổi điểm lấy voucher:{" "}</span>
                            <span style={{display: 'inline-block', position: 'relative'}}>
                            <NumberFormat
                              style={{
                                border: colorValueError
                                  ? "1px solid #f44336"
                                  : "",
                              }}
                              className={classes.inputPoint}
                              name="point"
                              decimalScale={""}
                              format={"######"}
                              mask={""}
                              value={point}
                              onChange={(e) => this.handlePointChange(e)}
                            />{" "}
                            {colorValueError && (
                              <span className={classes.requiredPoint}>
                                Bắt buộc
                              </span>
                            )}
                            </span>
                           <span className="span-block"> điểm =  <NumberFormat
                            value={Number(valueVoucher)}
                            displayType={"text"}
                            thousandSeparator={true}
                          /> Kč</span>
                          </p>
                          <Button
                            className={classes.btnPoint}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {isSubmited ? (
                              <Loading
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  width: 20,
                                  height: 20,
                                  marginTop: -12,
                                  marginLeft: -5,
                                }}
                              />
                            ) : (
                              <React.Fragment>Lấy voucher</React.Fragment>
                            )}
                          </Button>
                        </ValidatorForm>
                      </BoxChangeVoucher>
                      ) : (
                        <BoxChangeVoucher>
                           <p style={{ marginBottom: "5px" }}>
                      <Skeleton variant="text" width={500} />
                      </p>
                    </BoxChangeVoucher>
                      )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} xl={12}>
                    <div className={classes.desktopTemplate}>
                    <DesktopPoint
                          isSkeleton={isSkeleton}
                          listResult={listResult}
                           />
                    </div>
                      <MobilePoint
                    isSkeleton={isSkeleton}
                    listResult={listResult}
                      />


                {!isSkeleton ? (
                  <React.Fragment>
                    {
                      pageCount > 0 ? (
                        <PaginationUI
                        currentPage={page}
                        pageCount={pageCount}
                        handlePaginationChange={(e, number) =>
                          this.handlePaginationChange(e, number)
                        }
                      />
                      ) : (<React.Fragment></React.Fragment>)
                    }
                  </React.Fragment>
                ) : (
                    <div style={{display: 'flex', justifyContent: 'center', margin: '20px auto'}}>
                    {[1,2,3,4,5].map((val) => (
                      <Skeleton key={val} variant="rect" width={30} height={30} style={{marginRight: '10px'}} />
                    ))}
                    </div>
                )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(MyAccumulatedPoints));
