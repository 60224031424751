import React, { Component } from 'react';
import styled from 'styled-components';
import { getContent } from '../models/content';
const Box = styled.div`
    padding: 5px 10px;
    background-color: #f2c123;
    color: #fff;
    text-align: center;
    position: relative;
`;

export default class NotificationHeader extends Component {
    constructor(props){
        super(props);
        this.state = {
            content: ""
        }
    }

    componentDidMount() {
        let params = {
            type: "notification",
          };
      
          getContent(params).then((res) => {
            if (res._embedded && res._embedded.content.length > 0) {
              this.setState({
                ...this.state,
                content: res._embedded.content[0].description,
              });
            }
          });
    }
    
    render() {
        const {content} = this.state;
        return (
           <React.Fragment>
            {
                content && (
                    <Box dangerouslySetInnerHTML={{__html: content}} />
                )
            }
           </React.Fragment>
        )
    }
}
