import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import style from "./../style";
import { compose } from "redux";
import Slider from "react-slick";
import Skeleton from "@material-ui/lab/Skeleton";

const settings1 = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  focusOnSelect: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
      },
    },
  ],
};
const settings2 = {
  dots: false,
  infinite: true,
  vertical: true,
  speed: 500,
  slidesToScroll: 1,
  focusOnSelect: true,
  arrows: false,
};

class LoadingSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      image: [],
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  componentDidUpdate(prevProps, prevStates) {
    if (prevProps.image !== this.props.image) {
      this.setState({
        ...this.state,
        image: this.props.image,
      });
    }
    if (prevStates.image !== this.state.image) {
      this.setState({
        ...this.state,
      });
    }
  }
  render() {
    const { classes } = this.props;
    const { nav1, nav2 } = this.state;
    return (
      <React.Fragment>
        <div className="boxSlider">
          <Slider
            className={classes.sliderMain}
            {...settings1}
            asNavFor={nav2}
            ref={(slider) => (this.slider1 = slider)}
          >
            {[1, 2, 3].map((value, index) => (
              <div className="container-thumb" key={index}>
                <Skeleton variant="rect" width={507} height={507} />
              </div>
            ))}
          </Slider>
          <Slider
            className={classes.sliderThumb}
            {...settings2}
            slidesToShow={3}
            asNavFor={nav1}
            ref={(slider) => (this.slider2 = slider)}
          >
            {[1, 2, 3].map((value, index) => (
              <div className="container-thumb" key={index}>
                <Skeleton variant="rect" width={135} height={135} />
              </div>
            ))}
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(injectIntl(LoadingSlider));
