const style = (theme) => ({
  productDetail: {
    padding: "30px 0px",
  },
  infoProduct: {
    marginBottom: "30px",
  },
  descriptionProduct: {
    padding: "30px 0px",
  },
  subTitle: {
    fontSize: "1.5rem",
    fontWeight: "500",
    lineHeight: "28px",
    paddingBottom: "14px",
    textTransform: "uppercase",
    borderBottom: "1px solid #e8e8e1",
    marginBottom: "35px",
  },
  relatedProduct: {
    padding: "20px 0px",
    "& .item": {
      padding: "10px",
      "& .thumb": {
        overflow: "hidden",
        width: "100%",
        textAlign: "center",
      },
      "& .info": {
        textAlign: "center",
        "& p": {
          margin: "0px",
        },
      },
    },
  },
  pdMain: {
    paddingTop: '15px',
    "& .pdName": {
      fontSize: "2.125rem",
      fontWeight: "500",
      lineHeight: "39px",
      marginBottom: "8px",
      // textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .rating": {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    "& .price": {
      fontSize: "1.5rem",
      lineHeight: "28px",
      marginTop: "20px",
      paddingBottom: "26px",
      display: 'block',
      borderBottom: "1px solid #e8e8e1",
      marginBottom: '10px',
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
      "& .price-sale": {
      fontSize: "1.5rem",
      lineHeight: "28px",
      marginTop: "20px",
      display: 'block',
      marginBottom: '10px',
      },
      "& .item-price": {
        marginRight: "30px",
      },
      "& .percent-promotion": {
        color: "#ff0000",
        marginRight: "0px",
      },
    },
    "& .promotion": {
      paddingTop: "18px",
      paddingBottom: "56px",
      fontSize: "1rem",
      lineHeight: "18px",
      "& p": {
        marginBottom: "12px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        lineHeight: "17px",
        height: "17px",
        "& span": {
          display: "inline-block",
          marginRight: "10px",
        },
      },
    },
    "& .fpVariation": {
      paddingTop: '20px',
      paddingBottom: "40px",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: 'center'
      },
      "& .error": {
        position: "absolute",
        color: "#f44336",
        bottom: "-22px",
        left: "110px",
        fontSize: "14px"
      },
      "& label": {
        margin: "5px 20px 5px 0px",
        fontSize: "1rem",
        fontWeight: "bold",
        // textTransform: 'capitalize',
        width: '90px',
        overflow: 'hidden'
      },
      "& .MuiFormControl-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        marginBottom: "30px"
      },
      "& .MuiOutlinedInput-input": {
        padding: "10px",
        maxWidth: "120px",
        width: "140px",
        border: "1px solid #e8e8e1",
        display: "flex",
        flexDirection: "row",
      },
    },
    "& .btnGroup": {
      fontSize: "0.875rem",
      "& .btnAddToCart": {
        background: "#fff",
        color: "#f2c123",
        textTransform: "uppercase",
        border: "1px solid #f2c123",
        marginBottom: "12px",
        borderRadius: "22px",
        height: "48px",
        "& span": {
          fontSize: "0.875rem",
          fontWeight: "500",
        },
        // "&:hover": {
        //   background: "#f2c123",
        //   color: "#fff",
        // },
        "&:disabled": {
          opacity: '0.5'
        }
      },
      "& .btnCheckout": {
        background: "#f2c123",
        height: "48px",
        textTransform: "uppercase",
        borderRadius: "30px",
        color: "#fff",
        border: "1px solid #f2c123",
        marginBottom: "12px",
        "& span": {
          fontSize: "0.875rem",
          fontWeight: "500",
        },
        // "&:hover": {
        //   background: "#fff",
        //   color: "#f2c123",
        // },
        "&:disabled": {
          opacity: '0.5'
        }
      },
    },
  },
  sliderThumb: {
    float: "left",
    width: "25%",
    padding: "15px",
    maxHeight: "550px",
    overflow: 'hidden',
    "& .container-thumb": {
      padding: "5px",
      width: "100%",
      overflow: "hidden",
      aspectRatio: '1',
      '& img':{
        width: '100%',
        height: '100%',
        objectFit: 'scale-down'
      },
    },
    "& .slick-current": {
      "& .container-thumb img": {
        border: "1px solid #f2c123",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none!important",
    },
  },
  removePaddingMobile: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  sliderMain: {
    width: "75%",
    float: "right",
    overflow: "hidden",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      float: "none",
    },
    "& .slick-slider": {
      border: "1px solid #eee",
      [theme.breakpoints.down("md")]: {
        border: "none",
        padding: '0px'
      },
    },
    "& .container-thumb":{
      aspectRatio: '1',
      overflow: 'hidden',
      position: 'relative',
      '& img':{
        width: '100%',
        height: '100%',
        objectFit: 'scale-down'
      },
    },
    "& .slick-dots": {
      [theme.breakpoints.down("md")]: {
        bottom: "0px",
        left: "0px"
      },
    },
  },
  formControl: {
    marginBottom: '20px',
    minWidth: 120,
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e8e8e1',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(70 69 69 / 87%)'
    },
    '& .btnQuantity': {
      width: '42px',
      height: '42px',
      minWidth: '42px',
      fontSize: '28px',
      lineHeight: '42px',
      border: '1px solid rgb(231, 232, 224)',
      background: '#fff',
      borderRight: 'none',
      borderRadius: '0px',
      '&.btnPlus': {
        borderLeft: 'none',
        borderRight: '1px solid rgb(231, 232, 224)',
      },
      '&:hover': {
        backgroundColor: 'rgb(236, 236, 236)',
        cursor: 'pointer'
      },
      '&:disabled': {
        cursor: 'auto',
        color: 'rgb(189 189 189)',
        background: '#fff'
      }
    }
  },
  menuItem: {
    borderBottom: '1px solid #eee',
    '&:hover': {
      background: '#eee'
    }
  },
  sliderRelated: {
    '& .slick-dots':{
      display: 'flex!important',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  fpGrid:{
    padding: '0px 10px',
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      padding: '0px 5px'
    }
  },
boxSlider: {
  "&:after": {
    content: "",
    clear: 'both',
    display: 'table',
  },
}

});

export default style;
