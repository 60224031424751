import React, { Component } from "react";
import { withStyles, Button, Typography, CircularProgress } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import NumberFormat from "react-number-format";
import style from "./style";
import { convertSlugSeo, CURRENCY } from "../../utills/constant";
import { addCart, getListCart, updateCart } from "../../models/cart";
import LocalStorageService from "../../services/LocalStorageService";
import DefaultImage from './../../assets/images/default.jpg';
import styled from "styled-components";
import LazyImage from "../LazyImage";
import { Plus, Minus, Heart, ShoppingCart } from "react-feather";
import ReactDOM from "react-dom";
import {Favorite} from '@material-ui/icons';

const localStorageService = LocalStorageService.getService();

const LinkDetail = styled(Link)`
  position: absolute;
  top: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0px;
  outline: none;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &::focus,&::visited, &:active{
    outline: none;
    border: none;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
`;

const SmallItemCart = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  color: #f2c123;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #f2c123;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  width: 150px;
  border-radius: 22px;
  height: 36px;
  position: relative;
  z-index: 10;
  @media (max-width: 768px) {
    width: 120px;
  };
  @media (max-width: 360px) {
    width: 110px;
  };
`;

const ButtonAdd = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    padding: 10px 25px 10px 10px;
    cursor: pointer;
    z-index: 10;
    position: relative;
    @media (max-width: 768px) {
      padding: 7px 15px 7px 7px;
    };
    & span {
      color: #f2c123;
      font-weight: bold;
    };
`;

const ButtonDiscount = styled.button`
    border: none;
    background-color: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    padding: 10px 10px 10px 25px;
    cursor: pointer;
    z-index: 10;
    position: relative;
    @media (max-width: 768px) {
      padding: 7px 7px 7px 15px;
    };
    & span {
      color: #f2c123;
      font-weight: bold;
    };
`;

const ButtonCart = styled(Button)`
  @media (max-width: 360px) {
    width: 110px!important;
  }
`;

const BottomTile = styled.div`
    flex-direction: row;
  @media (max-width: 320px){
    flex-direction: column;
  }
`;

const SoldOut = styled.div`
    width: 80px;
    height: 80px;
    background: #000000a1;
    border-radius: 50%;
    color: #fff;
    line-height: 80px;
    font-size: 17px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
`;

class ProductTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isLoading: false,
      sbMessage: "",
      sbColor: "error",
      isHasCart: false,
      quantityInCart: 0,
      reloadCart: false,
      cart_id: '',
    };
  }

  async componentDidMount() {
    let name = ReactDOM.findDOMNode(this.refs.cpDev1);
    if(name){
      this.setHeightEqualWidth(name);
    }

    let token = localStorageService.getAccessToken();
    if (typeof token === "string") {
      await this.setState({ isAuth: true });
    }
 
    this.handleReloadCart();
  }

  setHeightEqualWidth = (name) => {
    name.style.height = `${ Number(name.clientWidth) > 150 ? name.clientWidth : 150 }px`;
  }

  handleReloadCart = async() => {
    let Jproducts = localStorageService.getCart();
    let products = JSON.parse(Jproducts);

    let check = false;
    if(products && Array.isArray(products) && products.length > 0) {
      products.forEach(async(x) => {
        if(Number(x.product_id) === Number(this.props.value.id)) {
          check = true;
          await this.setState({quantityInCart: x.quantity, cart_id: x.cart_id})
        }
      });
    }
    await this.setState({reloadCart: false, isHasCart: check});
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.reloadCart !== this.state.reloadCart) {
      if(this.state.reloadCart) {
        this.handleReloadCart();
        this.props.reloadListProduct();
      }
    }
    if(prevProps.isChangeCart !== this.props.isChangeCart){
      this.handleReloadCart();
    }
  }

  handleAddToCart = async (value) => {
    this.setState({isLoading: true});
    if (value.attribute.length > 0 && value.attribute_detail.length > 0) {
      if(value.name && value.id) {
        this.props.history.push(
          `/product/${convertSlugSeo(value.name)}.id=${value.id}`
        );
      }
      return false;
    }
    let object = {};
    object.product_id = Number(value.id);
    object.product_name = value.name;
    object.image =
      (value.image_resize
      && Array.isArray(value.image_resize)
      && value.image_resize.length > 0
      && value.image_resize[0].length > 0
      && value.image_resize[0].includes('http'))
        ? value.image_resize[0]
          : (value.image
            && Array.isArray(value.image)
            && value.image.length > 0
            && value.image[0]);
    object.price = Number(value.price);
    object.discount_price = Number(value.discount_price);
    object.quantity = 1;
    object.inventory = Number(value.inventory);
    object.sku_code = value.sku_code;
    object.product_code = value.product_code;

    if(value.isExchange){
      object.isExchange = value.isExchange;
      object.rate = value.rate;
      object.symbol = value.symbol;
      object.margin = value.margin;
      object.margin_type = value.margin_type;
        if(value.margin_type === "increase") {
          object.discount_price_exchange = (value.discount_price &&
            typeof Number(value.discount_price) === "number"
            && !isNaN(value.discount_price)
            && Number(value.discount_price) > 0) ?
            Number((Math.round((value.discount_price*value.rate + value.margin/100*(value.discount_price*value.rate))* 100) /100)).toFixed(2) : 0;
            object.price_exchange = (value.price
            && typeof Number(value.price) === "number"
            && !isNaN(value.price)
            && Number(value.price) > 0) ?
            Number((Math.round((value.price*value.rate + value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2) : 0;
        }else{
          object.discount_price_exchange = (value.discount_price &&
            typeof Number(value.discount_price) === "number"
            && !isNaN(value.discount_price)
            && Number(value.discount_price) > 0) ?
            Number((Math.round((value.discount_price*value.rate - value.margin/100*(value.discount_price*value.rate))* 100) /100)).toFixed(2) : 0;
            object.price_exchange = (value.price
            && typeof Number(value.price) === "number"
            && !isNaN(value.price)
            && Number(value.price) > 0) ?
            Number((Math.round((value.price*value.rate - value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2) : 0;
        }
    }
    let token = localStorageService.getAccessToken();

    let Jproducts = localStorageService.getCart();
    let products = JSON.parse(Jproducts);
    let checked = false;
    if(products && Array.isArray(products) && products.length > 0){
      products.forEach((x) => {
        if(x.product_name === value.name && x.sku_code == value.sku_code && x.product_code == value.product_code){
          if(Number(value.inventory) <= Number(x.quantity)){
            checked = true;
          }
        }
      })
    }
    if(checked){
      this.props.showMessage({open: true, sbMessage: 'Đã hết hàng tồn kho, vui lòng thử lại sau', sbColor: 'error' })
      this.setState({ isLoading: false });
      return;
    }
    if (token) {
      await addCart({ type: 1, carts: [object] }).then(async (res) => {
        if (res.success) {
          await getListCart({ type: 1 }).then((res) => {
            if (res.success) {
              this.props.clearListCart();
              if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
                res.cart.forEach((value) => {
                  let item = {
                    attribute_name1: value.attribute_name1,
                    attribute_name2: value.attribute_name2,
                    attribute_name3: value.attribute_name3,
                    attribute_name4: value.attribute_name4,
                    attribute_name5: value.attribute_name5,
                    attribute_value1: value.attribute_value1,
                    attribute_value2: value.attribute_value2,
                    attribute_value3: value.attribute_value3,
                    attribute_value4: value.attribute_value4,
                    attribute_value5: value.attribute_value5,
                    cart_id: value.cart_id,
                    inventory: Number(value.inventory),
                    image: (value.image_resize
                      && Array.isArray(value.image_resize)
                      && value.image_resize.length > 0
                      && value.image_resize[0].length > 0
                      && value.image_resize[0].includes('http'))
                        ? value.image_resize[0]
                          : (value.image
                            && Array.isArray(value.image)
                            && value.image.length > 0
                            && value.image[0]),
                    product_attribute_value_id1: value.product_attribute_value_id1,
                    product_attribute_value_id2: value.product_attribute_value_id2,
                    product_attribute_value_id3: value.product_attribute_value_id3,
                    product_attribute_value_id4: value.product_attribute_value_id4,
                    product_attribute_value_id5: value.product_attribute_value_id5,
                    product_code: value.product_code,
                    product_id: Number(value.product_id),
                    product_name: value.product_name,
                    quantity: Number(value.quantity),
                    sku_code: value.sku_code,
                    discount_price: (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number(value.old_discount_price) : 0,
                    price: (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number(value.old_price) : 0,
                  }
                  if(Number(value.inventory) < Number(value.quantity)){
                    item.error_inventory = true;
                  }
                  if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
                    let exchange = res.exchange_rate[0];
                    if(exchange) {
                      item.isExchange = true;
                      item.symbol = exchange.symbol;
                      item.margin = exchange.margin;
                      item.rate = exchange.rate;
                      item.margin_type = exchange.margin_type;
                      if(exchange.margin_type === "increase") {
                        item.discount_price_exchange = (value.old_discount_price &&
                          typeof Number(value.old_discount_price) === "number"
                          && !isNaN(value.old_discount_price)
                          && Number(value.old_discount_price) > 0) ?
                          Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                        item.price_exchange = (value.old_price
                          && typeof Number(value.old_price) === "number"
                          && !isNaN(value.old_price)
                          && Number(value.old_price) > 0) ?
                          Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      }else{
                        item.discount_price_exchange = (value.old_discount_price &&
                          typeof Number(value.old_discount_price) === "number"
                          && !isNaN(value.old_discount_price)
                          && Number(value.old_discount_price) > 0) ?
                          Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                        item.price_exchange = (value.old_price
                          && typeof Number(value.old_price) === "number"
                          && !isNaN(value.old_price)
                          && Number(value.old_price) > 0) ?
                          Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      }
                    }
                  }
                  this.props.addToCart(item);
                });
              }
              this.props.showMessage({open: true, sbMessage: 'Thêm giỏ vào hàng thành công', sbColor: 'success' })
              this.setState({
                isLoading: false,
                reloadCart: true
              });
            }
          });
        }
      });
    } else {
      this.props.addToCart(object);
      this.props.showMessage({open: true, sbMessage: 'Thêm giỏ vào hàng thành công', sbColor: 'success' })
      this.setState({
        isLoading: false,
        reloadCart: true
      });
    }
  }

  handleChangeQuantityProduct = async (e) => {
    this.setState({isLoading: true});
    const { id, value } = e.target;
    const item = { cart_id: id, value };

    if(Number(item.value) === 0){
      if(item.cart_id){
        if (this.state.isAuth) {
          let params = {
            id: item.cart_id,
            type: 1,
            quantity: 0,
          };
          await updateCart(params).then(() => {});
        }
        this.props.deleteItemCart(item.cart_id);
        await this.setState({reloadCart: true, isLoading: false})
      }
      return;
    }

    let Jproducts = localStorageService.getCart();
    let products = JSON.parse(Jproducts);
    let checked = false;
    if(products && Array.isArray(products) && products.length > 0){
      products.forEach((x) => {
        if(x.cart_id === item.cart_id){
          if(Number(x.inventory) < Number(value)){
            checked = true;
          }
        }
      })
    }
    if(checked){
      this.props.showMessage({open: true, sbMessage: 'Đã hết hàng tồn kho, vui lòng thử lại sau', sbColor: 'error' })
      this.setState({ isLoading: false });
      return;
    }

    this.props.changeQuantityItemCart(item);
    if (this.state.isAuth && Number(item.value) > 0) {
      let params = {
        type: 1,
        id: item.cart_id,
        quantity: Number(item.value),
      };
      await updateCart(params).then(() => {});
    }
    await this.setState({reloadCart: true, isLoading: false })
  };

  render() {
    const { classes, value, type } = this.props;
    const { isLoading, isHasCart, quantityInCart, cart_id } = this.state;
    return (
      <React.Fragment>
        <div className={classes.fpItem} >
          <div className="fpThumb" ref="cpDev1" >
            {
              Number(value.inventory) === 0 && (
                <SoldOut>
                    <span>Hết hàng</span>
                </SoldOut>
              )
            }
            {value.discount_price ||
            (value.multiDiscountPrice &&
              Array.isArray(value.multiDiscountPrice) &&
              !value.multiDiscountPrice.every((value) => value === 0)) ||
            value.isNew ? (
              <div
                className="label-sale-off"
                style={{ backgroundColor: type === "new" ? (value.isNew ? "#f2c123" : "#ff2323") : (value.discount_price ||
                (value.multiDiscountPrice &&
                  Array.isArray(value.multiDiscountPrice) &&
                  !value.multiDiscountPrice.every((value) => value === 0)) ? "#ff2323" : "#f2c123")}}
              >
                {
                  type === "new" ? (<span>{value.isNew ? `Mới` : `${value.discount_percent}%`}</span>) 
                  : (<span>{value.discount_price ||
                    (value.multiDiscountPrice &&
                      Array.isArray(value.multiDiscountPrice) &&
                      !value.multiDiscountPrice.every((value) => value === 0)) ? `${Number((Math.round(Number(value.discount_percent) * 100) / 100).toFixed(0))}%` : `Mới`}</span>)
                }
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
              <LazyImage
                width={`100%`}
                height={`auto`}
                effect={"opacity"}
                src={ (value.image_resize && Array.isArray(value.image_resize) && value.image_resize.length > 0 && value.image_resize[0].length > 0 && value.image_resize[0].includes('http')) 
                  ? value.image_resize[0]
                  : (value.image && Array.isArray(value.image) && value.image.length > 0
                    ? value.image[0]
                    : DefaultImage) }
                alt={value.name}
                heightSke={231}
              />
              
            <div className="fpBoxBtn fpOverlay">
            <LinkDetail style={{cursor: isLoading && 'auto'}} to={value.name && value.id && `/product/${convertSlugSeo(value.name)}.id=${value.id}`}></LinkDetail>
            </div>
           
          </div>
          <div className="fpInfo">
            <p className="fpName">
              <Link
                to={value.name && value.id && `/product/${convertSlugSeo(value.name)}.id=${value.id}`}
              >
                {value.name}
              </Link>
            </p>
            <p className="fpPrice">
              {value.isExchange ? (
                <React.Fragment>
                  {
                    value.margin_type === "increase" ? (
                      <React.Fragment>
                         {value.multiPrice && value.multiPrice.length === 2 ? (
                  <React.Fragment>
                    {value.multiDiscountPrice &&
                    value.multiDiscountPrice.length === 2 &&
                    value.multiDiscountPrice.every((value) => value !== 0) ? (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.multiDiscountPrice[0]*value.rate + value.margin/100*(value.multiDiscountPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        <NumberFormat
                          value={Number((Math.round((value.multiDiscountPrice[1]*value.rate + value.margin/100*(value.multiDiscountPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` - ${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        <Typography
                          style={{ display: "block" }}
                          component={"span"}
                          variant={"body2"}
                        >
                          <del className="txtDanger">
                            <NumberFormat
                              value={Number((Math.round((value.multiPrice[0]*value.rate + value.margin/100*(value.multiPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${value.symbol} `}
                            />
                          </del>
                          <span className="txtDanger">{` - `}</span>
                          <del className="txtDanger">
                            <NumberFormat
                              value={Number((Math.round((value.multiPrice[1]*value.rate + value.margin/100*(value.multiPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${value.symbol} `}
                            />
                          </del>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.multiPrice[0]*value.rate + value.margin/100*(value.multiPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        {` - `}
                        <NumberFormat
                          value={Number((Math.round((value.multiPrice[1]*value.rate + value.margin/100*(value.multiPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {value.discount_price ? (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.discount_price*value.rate + value.margin/100*(value.discount_price*value.rate))* 100) /100)).toFixed(2)}
                          style={{ fontWeight: "bold" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                        />
                        <del className="txtDanger" style={{ marginLeft: "10px" }}>
                          <NumberFormat
                            value={Number((Math.round((value.price*value.rate + value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${value.symbol} `}
                          />
                        </del>
                      </React.Fragment>
                    ) : (
                      <NumberFormat
                        value={Number((Math.round((value.price*value.rate + value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={` ${value.symbol} `}
                        style={{ fontWeight: "bold" }}
                      />
                    )}
                  </React.Fragment>
                )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                          {value.multiPrice && value.multiPrice.length === 2 ? (
                  <React.Fragment>
                    {value.multiDiscountPrice &&
                    value.multiDiscountPrice.length === 2 &&
                    value.multiDiscountPrice.every((value) => value !== 0) ? (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.multiDiscountPrice[0]*value.rate - value.margin/100*(value.multiDiscountPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        <NumberFormat
                          value={Number((Math.round((value.multiDiscountPrice[1]*value.rate - value.margin/100*(value.multiDiscountPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` - ${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        <Typography
                          style={{ display: "block" }}
                          component={"span"}
                          variant={"body2"}
                        >
                          <del className="txtDanger">
                            <NumberFormat
                              value={Number((Math.round((value.multiPrice[0]*value.rate - value.margin/100*(value.multiPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${value.symbol} `}
                            />
                          </del>
                          <span className="txtDanger">{` - `}</span>
                          <del className="txtDanger">
                            <NumberFormat
                              value={Number((Math.round((value.multiPrice[1]*value.rate - value.margin/100*(value.multiPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${value.symbol} `}
                            />
                          </del>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.multiPrice[0]*value.rate - value.margin/100*(value.multiPrice[0]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                        {` - `}
                        <NumberFormat
                          value={Number((Math.round((value.multiPrice[1]*value.rate - value.margin/100*(value.multiPrice[1]*value.rate))* 100) /100)).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                          style={{ fontWeight: "bold" }}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {value.discount_price ? (
                      <React.Fragment>
                        <NumberFormat
                          value={Number((Math.round((value.discount_price*value.rate - value.margin/100*(value.discount_price*value.rate))* 100) /100)).toFixed(2)}
                          style={{ fontWeight: "bold" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`${value.symbol} `}
                        />
                        <del className="txtDanger" style={{ marginLeft: "10px" }}>
                          <NumberFormat
                            value={Number((Math.round((value.price*value.rate - value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${value.symbol} `}
                          />
                        </del>
                      </React.Fragment>
                    ) : (
                      <NumberFormat
                        value={Number((Math.round((value.price*value.rate - value.margin/100*(value.price*value.rate))* 100) /100)).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={` ${value.symbol} `}
                        style={{ fontWeight: "bold" }}
                      />
                    )}
                  </React.Fragment>
                )}
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              ) : (
                <React.Fragment>
                {value.multiPrice && value.multiPrice.length === 2 ? (
                  <React.Fragment>
                    {value.multiDiscountPrice &&
                    value.multiDiscountPrice.length === 2 &&
                    value.multiDiscountPrice.every((value) => value !== 0) ? (
                      <React.Fragment>
                        <NumberFormat
                          value={value.multiDiscountPrice[0]}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` ${CURRENCY}`}
                          style={{ fontWeight: "bold" }}
                        />
                        <NumberFormat
                          value={value.multiDiscountPrice[1]}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` - `}
                          suffix={` ${CURRENCY}`}
                          style={{ fontWeight: "bold" }}
                        />
                        <Typography
                          style={{ display: "block" }}
                          component={"span"}
                          variant={"body2"}
                        >
                          <del className="txtDanger">
                            <NumberFormat
                              value={value.multiPrice[0]}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          </del>
                          <span className="txtDanger">{` - `}</span>
                          <del className="txtDanger">
                            <NumberFormat
                              value={value.multiPrice[1]}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          </del>
                        </Typography>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <NumberFormat
                          value={value.multiPrice[0]}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` ${CURRENCY}`}
                          style={{ fontWeight: "bold" }}
                        />
                        {` - `}
                        <NumberFormat
                          value={value.multiPrice[1]}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` ${CURRENCY}`}
                          style={{ fontWeight: "bold" }}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {value.discount_price ? (
                      <React.Fragment>
                        <NumberFormat
                          value={value.discount_price}
                          style={{ fontWeight: "bold" }}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={` ${CURRENCY}`}
                        />
                        <del className="txtDanger" style={{ marginLeft: "10px" }}>
                          <NumberFormat
                            value={value.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` ${CURRENCY}`}
                          />
                        </del>
                      </React.Fragment>
                    ) : (
                      <NumberFormat
                        value={value.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={` `}
                        suffix={` ${CURRENCY}`}
                        style={{ fontWeight: "bold" }}
                      />
                    )}
                  </React.Fragment>
                )}
                </React.Fragment>
              )}
            </p>
            <BottomTile className={classes.bottomTile}>
                {value.isFavorite
                  ? (<span className="span-favorite" onClick={() => this.props.handleToggleFavorite(value.id, "remove")}><Favorite fontSize="small" style={{ color: '#ff2323' }}></Favorite></span>)
                  : (<span className="span-favorite" onClick={() => this.props.handleToggleFavorite(value.id, "add")}><Heart size={18} color={"#969696"}></Heart></span>)}
              {
              isHasCart
                && (!value.attribute
                  || (value.attribute && typeof value.attribute === "object"
                    && Array.isArray(value.attribute)
                    && value.attribute.length === 0)) ? (
                      <div>
                        <SmallItemCart>
                  {!isLoading ? (
                    <React.Fragment>
                       <ButtonDiscount
                    onClick={() =>
                          this.handleChangeQuantityProduct({
                            target: {
                              id: cart_id,
                              value: Number(quantityInCart) - 1,
                            },
                          })
                        }><Minus size={14} color="#f2c123" /></ButtonDiscount>
                  <span>
                    {quantityInCart}
                  </span>
                  <ButtonAdd
                    onClick={() =>
                      this.handleChangeQuantityProduct({
                        target: {
                          id: cart_id,
                          value: Number(quantityInCart) + 1,
                        },
                      })
                    }><Plus size={14} color="#f2c123" /></ButtonAdd>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div></div><CircularProgress size={18} color="inherit" /><div></div>
                    </React.Fragment>
                  )}
                </SmallItemCart>
                      </div>
              ) : (
                <ButtonCart
                    disabled={isLoading || Number(value.inventory) === 0 ? true: false}
                    onClick={() => this.handleAddToCart(value)}
                    className="fpBtn btn"
                  >
                    {!isLoading ? (<span className="textButtonCart"><ShoppingCart size={16}></ShoppingCart> <span className="label-btn-buy">Mua ngay</span></span>) : (<CircularProgress size={18} color="primary" />)}
                  </ButtonCart>
              )
            }
            </BottomTile>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    changeQuantityItemCart: (payload) => {
      dispatch({ type: "CHANGE_QUANTITY_ITEM", payload });
    },
    deleteItemCart: (payload) => {
      dispatch({ type: "DELETE_ITEM_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ProductTile)));
