import * as types from '../constants';

export function setUserInfoAction(value) {
  return {
    type: types.SET_USER_INFO,
    payload: value
  }
}

export function setUserAvatarAction(value) {
  return {
    type: types.SET_USER_AVATAR,
    payload: value
  }
}

