import React, { Component } from "react";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
class AlertMessage extends Component {
  render() {
    const { open, severity, handleAlertClose, message } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleAlertClose}
        key={"top right"}
        autoHideDuration={2000}
      >
        <Alert onClose={handleAlertClose} severity={severity}>{message}</Alert>
      </Snackbar>
    );
  }
}

export default AlertMessage;
