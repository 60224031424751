import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import { withStyles, Container, Grid, CircularProgress } from "@material-ui/core";
import Breadcrumb from "./../../../components/Breadcrumb";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from 'react-helmet';
import styled from "styled-components";
import CategoryProduct from "../../../components/CategoryProduct";
import InputSelect from "../../../components/InputSelect";
import NotFoundProduct from "../../../components/NotFoundProduct";
import ProductTile from "../../../components/ProductTile";
import AlertMessage from "../../../components/UI/AlertMessage";
import ProductTileLoading from "../../../components/UI/ProductTileLoading";
import { getProductList, productFavorite, addProductFavorite } from "../../../models/product";
import LocalStorageService from "../../../services/LocalStorageService";
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();

const options = [
  { value: "name asc", label: "Tên (A - Z)" },
  { value: "name desc", label: "Tên (Z - A)" },
  { value: "price asc", label: "Giá (Thấp - Cao)" },
  { value: "price desc", label: "Giá (Cao - Thấp)" },
];

const Progress = styled(CircularProgress)`
  color: #c7c7c7 !important;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

class ListTypeProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "",
      listProducts: [],
      page: 1,
      page_size: 10,
      page_count: 0,
      breadcrumb: [],
      hasMore: true,
      isFetch: false,
      current_datetime: '',
      isChangeCart: false,
      open: false,
      sbColor: 'error',
      sbMessage: '',
      isAuth: false,
      listFavorite: []
    };
  }

  async componentDidMount() {
    this.setState({
      breadcrumb: [{ link: '/san-pham-khuyen-mai', name: "Sản phẩm khuyến mãi" }],
    });
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }

    let token = localStorageService.getAccessToken();
    if(typeof token === "string") {
      await this.setState({isAuth: true});
    }

    const { page, page_size } = this.state;
    let params = {
      page,
      page_size,
      is_first: 1,
    };
    this.getListFavorite();
    this.handleGetListProduct(params);
  }

  getListFavorite = async () => {
    if(this.state.isAuth){
      await productFavorite({}).then(async(rsFavorite) => {
        if(rsFavorite.success) {
          await this.setState({listFavorite: rsFavorite.products ? rsFavorite.products : []});
        }
      })
    }
  }

  handleGetListProduct(params) {
    this.setState({ isFetch: false });
    const { page, page_count, sort, listProducts, current_datetime } = this.state;
    if (page > page_count && page_count !== 0) {
      this.setState({ hasMore: false });
      return;
    } else {
      let sortProducts = sort.split(" ");
      if (sortProducts.length > 0) {
        params.sort_name = sortProducts[0];
        params.sort_type = sortProducts[1];
      }
      if(current_datetime){
        params.created_at = current_datetime;
      }else{
        params.is_first = 1;
      }
      params.bestdiscount = 1;
      getProductList(params).then((res) => {
        if (res.success) {

          if(res.current_datetime){
            this.setState({current_datetime: res.current_datetime});
          }

          const products = res.products;
          const exchange = res.exchange_rate;
          const list_products = [...listProducts];
          const result = products.map((value) => {
            if (value.created_at) {
              let created_at = value.created_at.split(" ");
              let created_at_date = created_at[0].split("/");
              let created_at_format = `${created_at_date[2]}-${created_at_date[1]}-${created_at_date[0]}`;
              if (moment().diff(created_at_format, "days") === 0) {
                value.isNew = true;
              }
            }

            let isFavorite = false;
            this.state.listFavorite.forEach((f) => {
              if(f.id === value.id){
                isFavorite = true;
              }
            });
            value.isFavorite = isFavorite;

            if(exchange && typeof exchange === "object" && Object.keys(exchange).length > 0) {
              value.isExchange = true;
              value.symbol = exchange.symbol;
              value.margin = exchange.margin;
              value.margin_type = exchange.margin_type;
              value.rate = exchange.rate;
            }

            if (value && value.attribute_detail.length > 0) {
              value.attribute_detail.sort(function (a, b) {
                return Number(a.price) - Number(b.price);
              });
              let lengthAttr = value.attribute_detail.length;
              value.multiPrice = [
                Number(value.attribute_detail[0].price),
                Number(value.attribute_detail[lengthAttr - 1].price),
              ];
              value.multiDiscountPrice = [
                Number(value.attribute_detail[0].discount_price),
                Number(value.attribute_detail[lengthAttr - 1].discount_price),
              ];
            }
            return value;
          });
          let products_tmp = [...list_products, ...result];
          this.setState({
            ...this.state,
            listProducts: products_tmp,
            page_count: res.page_count,
            page: page <= res.page_count ? page + 1 : page,
          });
          if (res.total_items === 0 || res.products.length === res.total_items  ||
            products_tmp.length === res.total_items) {
            this.setState({ hasMore: false });
          }
          this.setState({ isFetch: true });
        }
      });
    }
  }

  handleFilterChange = async (event) => {
    const { page_size } = this.state;
    await this.setState({
      listProducts: [],
      page: 1,
      page_count: 0,
      sort: event.target.value,
      hasMore: true,
      current_datetime: ''
    });
    this.getListFavorite();
    this.handleGetListProduct({ page: 1, page_size});
  };

  showLoading = () => {
    let { page_size } = this.state;
    let xhtml = [];
    for (let i = 0; i < page_size; i++) {
      xhtml.push(
        <div key={i} className="fpGrid">
          <ProductTileLoading />
        </div>
      );
    }
    return xhtml;
  };

  nextData = () => {
    const { page, page_size } = this.state;
    let params = {
      page,
      page_size,
    };
    this.handleGetListProduct(params);
  };

  reloadListProduct = () => {
    this.setState(prevState => ({
      isChangeCart: !prevState.isChangeCart
    }));
  }

  showMessage = (info) => {
    this.setState({open: info.open, sbMessage: info.sbMessage, sbColor: info.sbColor})
  }

  handleAlertClose = () => {
    this.setState({ open: false });
  };

  handleToggleFavorite = (id, type) => {
    if(!this.state.isAuth){
      this.props.history.push('/auth/sign-in');
      return;
    }
    let listProducts = [...this.state.listProducts];

    let newList = listProducts.map((value) => {
      if(Number(value.id) === Number(id)){
        value.isFavorite = type === "remove" ? false : true;
      }
      return value;
    });
    addProductFavorite({product_id: id, favorite: type === "remove" ? 0 : 1}).then((res) => {
      if(res.success){
        this.setState({
          open: true,
          sbColor: 'success',
          sbMessage: type === "remove" ? 'Đã xoá khỏi danh sách sản phẩm yêu thích' : 'Đã thêm vào danh sách sản phẩm yêu thích'})
      }
    });
    this.setState({
      listProducts: newList,
    });
  }

  render() {
    const {
      sort,
      listProducts,
      isFetch,
      breadcrumb,
      open,
      isChangeCart,
      hasMore,
      sbColor,
      sbMessage
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
       <Helmet>
          <title>Sản phẩm khuyến mãi</title>
          <meta property="og:title" content="Sản phẩm khuyến mãi"></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content="Sản phẩm khuyến mãi"></meta>
          <meta name="description" content="Sản phẩm khuyến mãi"></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <CategoryProduct />
        <Breadcrumb breadcrumb={breadcrumb} />
        <section id="list-product-page" className={classes.listProductPage}>
          <Container maxWidth="xl">
            <InputSelect
              label={"Sắp xếp"}
              options={options}
              sort={sort}
              handleFilterChange={(e) => this.handleFilterChange(e)}
            />
            <div className={classes.fp}>
              <div className={classes.fpMain}>
                <InfiniteScroll
                  dataLength={listProducts.length}
                  next={this.nextData}
                  hasMore={hasMore}
                  className={classes.infinite}
                  loader={
                    <React.Fragment>
                      <GridContainer>
                        {this.showLoading()}
                      </GridContainer>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          style={{ textAlign: "center" }}
                        >
                          <h3>
                            <Progress size={30} color="primary" />
                          </h3>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                  scrollThreshold={0.5}
                >
                  <GridContainer>
                    {listProducts && Array.isArray(listProducts) && listProducts.length > 0 && (
                      <React.Fragment>
                        {listProducts.map((value, index) => (
                          <div key={index} className="fpGrid">
                            <ProductTile
                              value={value}
                              key={index}
                              reloadListProduct={() => this.reloadListProduct()}
                              isChangeCart={isChangeCart}
                              showMessage={(info) => this.showMessage(info)}
                              handleToggleFavorite={(id, type) => this.handleToggleFavorite(id, type)}
                              />
                          </div>
                        ))}
                      </React.Fragment>
                    )}
                  </GridContainer>
                </InfiniteScroll>
                <React.Fragment>
                  {isFetch && listProducts.length === 0 && (
                    <Container>
                      <Grid container>
                        <Grid item xs={12} xl={12}>
                           <div style={{marginBottom: '20px'}}>
                           <NotFoundProduct />
                           </div>
                        </Grid>
                      </Grid>
                    </Container>
                  )}
                </React.Fragment>
              </div>
            </div>
          </Container>
        </section>
        <AlertMessage
          open={open}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(ListTypeProduct)));
