import * as types from "../constants";
var auth = {
  logout: false,
  token: localStorage.getItem("access_token") || null,
};

export default function reducer(state = auth, actions) {
  switch (actions.type) {
    case types.SET_LOGOUT:
      document.cookie = "listCart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      return {
        ...state,
        logout: actions.payload,
      };
    case types.SET_AUTH:
      return {
        ...state,
        token: actions.payload,
      };

    default:
      return state;
  }
}
