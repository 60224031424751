import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Paper,
  Typography,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import LocalStorageService from "../../services/LocalStorageService";
import { injectIntl } from 'react-intl';
import style from "./style";
import { compose } from "redux";
import { setAuthAction } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { addCart, getListCart } from "../../models/cart";
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../utills/config";

const queryString = require('query-string');

const Wrapper = styled(Paper)`
  padding: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;
const localStorageService = LocalStorageService.getService();

const TextLink = styled(Link)`
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
`;

class HybridSignIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accces_token: '',
      refesh_token: '',
      isOpen: false,
      sbColor: 'error',
      sbMessage: '',
      classes: '',
      loading: false,
    };
  }

  async componentDidMount() {
    if (localStorageService.getAccessToken()) {
      this.props.history.push('/')
    }

    this.setState({ loading: true });

    const dataToken = queryString.parse(this.props.location.search);

    if (this.props.location.search && dataToken.access_token && dataToken.refresh_token ) {
      localStorageService.setToken({
        "access_token": dataToken.access_token,
        "expires_in": 0,
        "token_type": "Bearer",
        "scope": null,
        "refresh_token": dataToken.refresh_token
      });

		  this.props.setAuth(dataToken.access_token);

      this.handleAddToCart();

    } else {
      this.props.history.push('/auth/sign-in')
    }

  }


  handleAddToCart = async () => {
    if(localStorageService.getCart() && JSON.parse(localStorageService.getCart()).length > 0){
      await addCart({ type: 1, carts: JSON.parse(localStorageService.getCart()) }).then(async(res) => {
        await getListCart({ type: 1 }).then((res) => {
          if (res.success) {
            this.props.clearListCart();
            localStorageService.clearCart();
				    localStorageService.clearVoucher();
				    localStorageService.clearDeliveryInfo();
            localStorageService.clearVoucherPoint();
            if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
              res.cart.forEach((value) => {
                let item = {
                  attribute_name1: value.attribute_name1,
                  attribute_name2: value.attribute_name2,
                  attribute_name3: value.attribute_name3,
                  attribute_name4: value.attribute_name4,
                  attribute_name5: value.attribute_name5,
                  attribute_value1: value.attribute_value1,
                  attribute_value2: value.attribute_value2,
                  attribute_value3: value.attribute_value3,
                  attribute_value4: value.attribute_value4,
                  attribute_value5: value.attribute_value5,
                  cart_id: value.cart_id,
                  inventory: Number(value.inventory),
                  image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                  product_attribute_value_id1: value.product_attribute_value_id1,
                  product_attribute_value_id2: value.product_attribute_value_id2,
                  product_attribute_value_id3: value.product_attribute_value_id3,
                  product_attribute_value_id4: value.product_attribute_value_id4,
                  product_attribute_value_id5: value.product_attribute_value_id5,
                  product_code: value.product_code,
                  product_id: value.product_id,
                  product_name: value.product_name,
                  quantity: Number(value.quantity),
                  sku_code: value.sku_code,
                  discount_price: (value.old_discount_price &&
                    typeof Number(value.old_discount_price) === "number"
                    && !isNaN(value.old_discount_price)
                    && Number(value.old_discount_price) > 0) ?
                    Number(value.old_discount_price) : 0,
                  price: (value.old_price
                    && typeof Number(value.old_price) === "number"
                    && !isNaN(value.old_price)
                    && Number(value.old_price) > 0) ?
                    Number(value.old_price) : 0,
                }
                if(Number(value.inventory) < Number(value.quantity)){
                  item.error_inventory = true;
                }

                if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
                  let exchange = res.exchange_rate[0];
                  if(exchange) {
                    item.isExchange = true;
                    item.symbol = exchange.symbol;
                    item.margin = exchange.margin;
                    item.rate = exchange.rate;
                    item.margin_type = exchange.margin_type;
                    if(exchange.margin_type === "increase") {
                      item.discount_price_exchange = (value.old_discount_price &&
                        typeof Number(value.old_discount_price) === "number"
                        && !isNaN(value.old_discount_price)
                        && Number(value.old_discount_price) > 0) ?
                        Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      item.price_exchange = (value.old_price
                        && typeof Number(value.old_price) === "number"
                        && !isNaN(value.old_price)
                        && Number(value.old_price) > 0) ?
                        Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    }else{
                      item.discount_price_exchange = (value.old_discount_price &&
                        typeof Number(value.old_discount_price) === "number"
                        && !isNaN(value.old_discount_price)
                        && Number(value.old_discount_price) > 0) ?
                        Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      item.price_exchange = (value.old_price
                        && typeof Number(value.old_price) === "number"
                        && !isNaN(value.old_price)
                        && Number(value.old_price) > 0) ?
                        Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    }
                  }
                }

                this.props.addToCart(item);
              });
            }
          }
        });
      let that = this;
      that.setState({
        isOpen: false,
        sbMessage: "",
        loading: false,
      });
      that.props.history.push(localStorageService.getLocation());
    });
    }else{
      await getListCart({ type: 1 }).then((res) => {
        if (res.success) {
          this.props.clearListCart();
          localStorageService.clearCart();
				  localStorageService.clearVoucher();
				  localStorageService.clearDeliveryInfo();
          localStorageService.clearVoucherPoint();
          if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
            res.cart.forEach((value) => {
              let item = {
                attribute_name1: value.attribute_name1,
                attribute_name2: value.attribute_name2,
                attribute_name3: value.attribute_name3,
                attribute_name4: value.attribute_name4,
                attribute_name5: value.attribute_name5,
                attribute_value1: value.attribute_value1,
                attribute_value2: value.attribute_value2,
                attribute_value3: value.attribute_value3,
                attribute_value4: value.attribute_value4,
                attribute_value5: value.attribute_value5,
                cart_id: value.cart_id,
                inventory: Number(value.inventory),
                image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                product_attribute_value_id1: value.product_attribute_value_id1,
                product_attribute_value_id2: value.product_attribute_value_id2,
                product_attribute_value_id3: value.product_attribute_value_id3,
                product_attribute_value_id4: value.product_attribute_value_id4,
                product_attribute_value_id5: value.product_attribute_value_id5,
                product_code: value.product_code,
                product_id: value.product_id,
                product_name: value.product_name,
                quantity: Number(value.quantity),
                sku_code: value.sku_code,
                discount_price: (value.old_discount_price &&
                  typeof Number(value.old_discount_price) === "number"
                  && !isNaN(value.old_discount_price)
                  && Number(value.old_discount_price) > 0) ?
                  Number(value.old_discount_price) : 0,
                price: (value.old_price
                  && typeof Number(value.old_price) === "number"
                  && !isNaN(value.old_price)
                  && Number(value.old_price) > 0) ?
                  Number(value.old_price) : 0,
              }
              if(Number(value.inventory) < Number(value.quantity)){
                item.error_inventory = true;
              }

              if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
                let exchange = res.exchange_rate[0];
                if(exchange) {
                  item.isExchange = true;
                  item.symbol = exchange.symbol;
                  item.margin = exchange.margin;
                  item.rate = exchange.rate;
                  item.margin_type = exchange.margin_type;
                  if(exchange.margin_type === "increase") {
                    item.discount_price_exchange = (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    item.price_exchange = (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  }else{
                    item.discount_price_exchange = (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                    item.price_exchange = (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  }
                }
              }

              this.props.addToCart(item);
            });
          }
        }
      });
    let that = this;
    that.setState({
      isOpen: false,
      sbMessage: "",
      loading: false,
    });
    that.props.history.push(localStorageService.getLocation());
    }
  };

  render() {
    const { classes: clsName} = this.props;
    const { loading } = this.state;

    return (
     <React.Fragment>
       <Helmet>
          <title>Đang chuyển trang...</title>
          <meta property="og:title" content={"Đang chuyển trang..."}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đang chuyển trang..."}></meta>
          <meta name="description" content={"Đang chuyển trang..."}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
      <Wrapper>
        <Typography
          component="h1"
          variant="h1"
          align="center"
          gutterBottom
          className={clsName.textTransform}
        >
          {!loading ? (<TextLink to='/'>Taphoa.cz</TextLink>) : (<CircularProgress />)}
        </Typography>
      </Wrapper >
     </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
	return {
		token: store.authReducer.token,
	}
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		setAuth: (token) => {
			dispatch(setAuthAction(token));
		},
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
	};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStyles(style),withConnect)(injectIntl(HybridSignIn)) ;