const style = (theme) => ({
	pageTitle: {
		fontWeight: 500,
		color: '#212529',
	},
	breadcrumbs: {
		marginTop: '30px',
		'& .linkBc': {
			fontSize: '1rem',
			position: 'relative',
			display: 'block',
			marginBottom: '5px',
			'&:after': {
				background: '#000',
				content: '""',
				position: 'absolute',
				bottom: '1px',
				left: '0px',
				height: '1px',
				width: '100%'
			}
		}
	},
});

export default style;
