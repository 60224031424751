const style = (theme) => ({
  cartPage: {
    padding: "20px 0px",
    '& .MuiTableCell-root': {
      borderTop: '1px solid #f3f3ef',
      borderBottom: 'none',
    },
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        borderTop: 'none',
      },
    },
    "& .table-cart": {
      "& .tableCell": {
        fontFamily: '"Tinos", "Aleo", "Lobster", "Montserrat" , sans-serif',
        fontSize: "16px",
        '& del': {
          marginLeft: '10px'
        },
        '& .btnQuantity': {
          width: '42px',
          height: '42px',
          fontSize: '28px',
          lineHeight: '42px',
          border: '1px solid rgb(231, 232, 224)',
          background: '#fff',
          borderRight: 'none',
          '&.btnPlus': {
            borderLeft: 'none',
            borderRight: '1px solid rgb(231, 232, 224)',
          },
          '&:hover': {
            backgroundColor: 'rgb(236, 236, 236)',
            cursor: 'pointer'
          },
          '&:disabled': {
            cursor: 'auto',
            color: 'rgb(189 189 189)',
            background: '#fff'
          }
        }
      },
    },
    "& .authority": {
      fontSize: "1rem",
      padding: "20px 0px",
      "& > span": {
        display: "inline-block",
        marginRight: "15px",
      },
      "& a": {
        display: "inline-block",
        padding: "5px 10px",
        color: "#f2c123",
        border: "1px solid #f2c123",
        // "&:hover": {
        //   color: "#fff",
        //   background: "#f2c123",
        // },
      },
    },
    "& .field-name": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .thumb": {
        width: "120px",
        height: "120px",
        overflow: "hidden",
        minWidth: "120px",
        background: "#fff"
      },
      "& .info-product": {
        paddingLeft: "20px",
        "& .name-product": {
          marginBottom: '15px',
          "& a": {
            fontSize: "1rem",
            textTransform: "uppercase",
            lineHeight: "18px",
            display: "block",
            maxWidth: "550px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "0.875rem",
            },
          },
        },
        "& .attribute-product": {
          fontSize: "16px",
          lineHeight: "16px",
          marginBottom: "12px",
        },
      },
    },
    "& .totalPrice, & .priceVoucher, .priceVoucherPoint": {
      textAlign: "right",
      textTransform: "uppercase",
      fontSize: "15px",
      marginBottom: '7px'
    },
    "& .priceVoucher, .priceVoucherPoint": {
      textTransform: 'none',
      fontSize: "16px",
    },
    "& .priceVoucherPoint ": {
      "& span": {
        fontWeight: 'bold',
        color: '#f2c123'
      }
    },
    "& .boxFooterTable": {
      float: "right",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        float: 'inherit',
        '& a, button':{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '0px',
          marginBottom: '20px'
        }
      },
    },
    "& .btn-secondary": {
      display: "inline-block",
      padding: "0px 25px",
      fontSize: "0.875rem",
      lineHeight: "48px",
      height: "48px",
      borderRadius: '30px',
      textTransform: "uppercase",
      // "&:hover": {
      //   background: "#f2c123",
      //   color: "#fff",
      // },
    },
  },
  btnXoa: {
    marginTop: "10px",
    border: "1px solid #f2c123",
    color: "#f2c123",
    background: "#fff",
    fontSize: "0.875rem",
    borderRadius: "22px",
    textTransform: "uppercase",
    fontWeight: "500",
    height: "35px",
    "&:hover": {
      background: "#fff",
      color: "#f2c123!important",
    },
    "& span": {
      fontSize: "0.875rem",
      display: "block",
    },
  },
  btnCheckout: {
    color: "#fff!important",
    border: "1px solid #f2c123!important",
    width: "182px",
    background: "#f2c123!important",
    fontWeight: "500",
    height: "48px",
    marginLeft: "10px",
    borderRadius: "30px!important",
    textTransform: "uppercase",
    "&:hover": {
      background: '#f2c123',
      "& span": {
        color: "#fff!important",
      }
    },
    "&:disabled":{
      opacity: '50%',
      "& span": {
        color: "#fff!important",
      }
    }
    
  },
  btnLogin: {
    color: "#fff",
    border: "1px solid #f2c123",
    padding: "0px 32px",
    background: "#f2c123",
    fontWeight: "500",
    height: "38px",
    marginLeft: "10px",
    borderRadius: "22px",
    textTransform: "uppercase",
    "&:hover": {
      background: '#f2c123',
      "& span": {
        color: "#fff!important",
      }
    },
    "&:disabled":{
      opacity: '50%',
      "& span": {
        color: "#fff!important",
      }
    }
    
  },
  mobileCart: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "& .mobileCartItem": {
      marginBottom: "26px",
      paddingBottom: "26px",
      borderBottom: '1px solid #f3f3ef',
      "& .mobileCartBody": {
        display: "flex",
        alignItems: "flex-start",
        paddingTop: "20px",
        justifyContent: "space-between",
        "& .mobileCartInfo": {
          "& p": {
            marginBottom: "10px",
          },
          '& .btnQuantity': {
            width: '32px',
            height: '32px',
            fontSize: '16px',
            lineHeight: '30px',
            border: '1px solid rgb(231, 232, 224)',
            background: '#fff',
            borderRight: 'none',
            borderRadius: '5px 0px 0px 5px',
            '&.btnPlus': {
              borderLeft: 'none',
              borderRight: '1px solid rgb(231, 232, 224)',
              borderRadius: '0px 5px 5px 0px'
            },
            '&:hover': {
              backgroundColor: 'rgb(236, 236, 236)',
              cursor: 'pointer'
            },
            '&:disabled': {
              cursor: 'auto',
              color: 'rgb(189 189 189)',
              background: '#fff'
            }
          }
        },
      },
    },
  },
  desktopCart: {
    padding: '1px',
    boxShadow: '-1px 3px 10px 5px #fbfbfb',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inputFormat: {
    "&:focus": {
      outline: "none",
    },
  },
  btnReAdd: {
    background: '#f2c123',
    display: 'inline-flex',
    width: '88px',
    height: '32px',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #f2c123',
    color: '#fff',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inventoryError: {
    background: '#fff6f6',
    borderRight: '1px solid #ff7d7d!important',
    borderLeft: '1px solid #ff7d7d!important',
    "& .MuiTableCell-root": {
      borderBottom: '1px solid #ff7d7d!important',
      borderTop: '1px solid #ff7d7d!important'
    }
  },
  inventoryNotError: {
    background: '#fff',
    borderRight: '1px solid #fff0!important',
    borderLeft: '1px solid #fff0!important',
  },
  addVoucher: {
    width: '102px',
    height: '35px',
    fontSize: '13px',
    color: '#fff',
    borderRadius: '22px',
    textTransform: 'uppercase',
    background: '#ff9304',
    border: '1px solid #ff9304',
    textAlign: 'center',
    lineHeight: '35px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputVoucher: {
    border: '1px dotted #ff9304',
    height: '35px',
    width: '206px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '15px',
    '& .MuiInput-underline:after': {
      display: 'none'
    },
    '& .MuiInput-underline:before':{
      display: 'none'
    },
    '& .MuiInputBase-input': {
      fontSize: '15px',
      padding: '9px 0 7px'
    }
  }
});

export default style;
