const style = (theme) => ({
    pagination: {
        margin: '20px 0px',
        display: 'flex',
        justifyContent: 'center'
    },
         selected: {
            backgroundColor: '#fff!important',
            color:'#0000006b!important',
            '&:hover':{
                cursor: 'initial!important'
            }
           },
});

export default style;
