import iconUpload from './../../../../assets/images/upload_icon.png';

const style = (theme) => ({
	avatar: {
		width: '86px',
		height: '86px',
		display: 'flex',
		'-webkit-box-pack': 'center',
		'-webkit-justify-content': 'center',
		'-ms-flex-pack': 'center',
		justifyContent: 'center',
		border: '1px dashed #9b9b9b',
		borderRadius: '50%',
		backgroundColor: '#f7f7f7',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		overflow: 'hidden',
		backgroundSize: '60% 60%',
		backgroundImage: `url(${iconUpload})`,
		marginBottom: '48px',
		[theme.breakpoints.up('sm')]: {
			width: '86px',
			height: '86px',
		},
	},
	imgageLoading: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		zIndex: 4,
		opacity: 0.6,
	},
	imgagePreview: {
		height: 'auto',
		width: '100%',
		borderRadius: '50%',
		zIndex: 1,
		objectFit: 'cover',
	},
	inputUpload: {
		width: '116px',
		height: '26px',
		zIndex: '101',
		opacity: 0,
		position: 'absolute',
		top: '0px',
		background: '#353535',
		right: '0px',
		'&:hover':{
			cursor: 'pointer'
		}
	},
	listNav: {
		'& .MuiListItemIcon-root': {
			minWidth: '40px',
		},
		'& .MuiListItemText-root': {
			'& a': {
				textDecoration: 'none',
				color: '#4c5152',
				fontSize: '16px',
			},
		},
		'& .active': {
			'& .MuiListItemText-root': {
				'& a': {
					color: '#3e643e',
				},
			},
		},
		'& .logout': {
			'& .MuiTypography-root': {
				textDecoration: 'none',
				color: '#4c5152',
				fontSize: '16px',
				cursor: 'pointer',
			},
		},
	},
	userName: {
		color: '#212529',
		fontWeight: 500,
	},
	userTitle: {
		color: '#212529',
		fontWeight: 400,
		marginBottom: '50px',
	},
});

export default style;
