import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  Grid,
  Button,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import styled from "styled-components";

import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import { Link } from "react-router-dom";
import { CURRENCY } from "../../../../../utills/constant";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";

const GreenRadio = styled(Radio)`
  color: #f2c123!important;
  background-color: #fff0 !important;
`;

const TelPhone = styled.a`
  color: #f2c123;
  display: block;
`;

class DeliveryService extends Component {
  render() {
    const {
      isService,
      classes,
      price,
      delivery_service,
      shipment,
      delivery_date,
      delivery_time,
      delivery_schedules,
      note,
      handleDateChange,
      handleNoteChange,
      handleOrder,
      isSkeleton,
      handleOptionServiceChange,
      isOptional,
      errorCheckout,
      isExchange,
      symbol,
      rate,
      margin,
      margin_type,
      price_exchange,
      rank,
      price_discount
    } = this.props;
    return (
      <div className="service-delivery">
        {!isSkeleton ? (
          <React.Fragment>
            <h2>Dịch vụ giao hàng</h2>
            {errorCheckout.length > 0 && (<p style={{ color: "#d02e2e", marginBottom: "10px" }}>{errorCheckout}</p>)}
            {!isService ? (
              <p style={{ color: "#d02e2e", marginBottom: "10px" }}>
                Vui lòng xác nhận địa chỉ giao hàng ở mục trên
              </p>
            ) : (
              <div className={classes.serviceMain}>
                <Grid container>
                  <Grid item xl={4} md={4} sm={12}>
                    <div className={classes.serviceItem}>
                      <div className="service-options">
                        <FormControlLabel
                          style={{display: isOptional ? 'none' : 'flex'}}
                          value="end"
                          control={
                            <GreenRadio
                              checked={delivery_service === "1"}
                              onChange={(e) =>
                                handleOptionServiceChange(e)
                              }
                              value="1"
                              name="options-service"
                            />
                          }
                          label={
                            <p>
                              Giao hàng tận nhà{" "}
                              <span style={{display: 'inline-block', marginLeft: '20px'}}>
                                {isExchange ? (
                                  <React.Fragment>
                                      {(price_discount === 0) ? (
                                        <React.Fragment>
                                            {`Miễn phí (${symbol} -${Number(price_exchange)})`}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                        {
                                          (rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0) ? (
                                            <React.Fragment>
                                            {rank.shipment_fee_discount_type === "percent" ? 
                                              (` ${symbol} ${Number((Math.round((Number(price_exchange) - ((Number(rank.shipment_fee_discount) / 100) * Number(price_exchange))) *100) / 100).toFixed(2))}`) : 
                                              (<React.Fragment>
                                                {
                                                  Number(Number(price_exchange) - Number(rank.shipment_fee_discount*rate)) > 0 ?
                                                  `${symbol} ${Number((Math.round(Number(Number(price_exchange) - Number(rank.shipment_fee_discount*rate)) * 100) / 100).toFixed(2))}` :
                                                  `Miễn phí`
                                                }
                                              </React.Fragment>)}
                                            </React.Fragment>
                                            ) : (<React.Fragment> 
                                              {` ${symbol} ${Number(price_exchange)}`}
                                              </React.Fragment>)
                                              }
                                          {
                                            rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0
                                            ? (
                                              <React.Fragment>
                                                {
                                                  rank.shipment_fee_discount_type === "percent" ?
                                                  (` (-${Number(rank.shipment_fee_discount)}%)`) :
                                                  (` (${symbol} -${Number((Math.round((rank.shipment_fee_discount*rate)* 100) /100).toFixed(2))})`)
                                                }
                                              </React.Fragment>
                                            ) : (<React.Fragment></React.Fragment>)
                                          }
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                      {(price_discount === 0) ? (
                                        <React.Fragment>
                                          {`Miễn phí (-${Number(price)} ${CURRENCY})`}
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                        {
                                          rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0 ? (
                                            <React.Fragment>
                                            {
                                              rank.shipment_fee_discount_type === "percent" ?
                                              (`${
                                                Number((Math.round((Number(price)
                                                - ((Number(rank.shipment_fee_discount) / 100) * Number(price))) *100) / 100).toFixed(2))
                                              }
                                              ${CURRENCY}`) : (`${
                                                Number(Number(price) - Number(rank.shipment_fee_discount)) > 0 ?
                                                Number((Math.round((Number(price) - Number(rank.shipment_fee_discount)) * 100) / 100).toFixed(2)) + ' ' + CURRENCY : `Miễn phí`
                                            }`)
                                            }
                                            </React.Fragment>
                                          ) : (<React.Fragment>{`${Number(price)} ${CURRENCY}`}</React.Fragment>)
                                        }
                                        {
                                          rank && rank.shipment_fee_discount && Number(rank.shipment_fee_discount) > 0
                                          ? (
                                            <React.Fragment>
                                              {
                                                rank.shipment_fee_discount_type === "percent" ?
                                                (` (-${Number(rank.shipment_fee_discount)}%)`) : (` (-${Number(rank.shipment_fee_discount)} ${CURRENCY})`)
                                              }
                                            </React.Fragment>
                                          ) : (<React.Fragment></React.Fragment>)
                                        }
                                        </React.Fragment>
                                      )}
                                  </React.Fragment>
                                )
                                }
                              </span>
                            </p>
                          }
                        />
                        <FormControlLabel
                          value="end"
                          control={
                            <GreenRadio
                              checked={delivery_service === "2"}
                              onChange={(e) =>
                                handleOptionServiceChange(e)
                              }
                              value="2"
                              name="options-service"
                            />
                          }
                          label={
                            <p>
                              Lấy hàng tại shop <span style={{display: 'inline-block', marginLeft: '20px'}}>Miễn phí</span>
                            </p>
                          }
                        />
                      </div>
                      <p>
                        Quý khách có thể xem thêm thông tin vận chuyển tại mục{" "}
                        <Link to="/">Chính sách vận chuyển</Link>{" "}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12}>
                    <div className={classes.serviceItem}>
                      <h3>Thời gian nhận hàng</h3>
                      {shipment && Array.isArray(shipment) && shipment.length > 0 && shipment.length > 0 && delivery_service === "1" && (
                        <div className={classes.serviceSchedule}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label-1">
                              Ngày
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label-1"
                              id="demo-simple-select-outlined-1"
                              name="delivery_date"
                              value={delivery_date}
                              onChange={(e) => handleDateChange(e)}
                              label="Ngày"
                            >
                              {shipment.map((value, index) => (
                                <MenuItem value={value.date} key={index}>
                                  {`${moment(`${value.date}T00:00:00`).format(
                                "DD/MM/YYYY"
                              )}`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel id="demo-simple-select-outlined-label-1">
                              Khung giờ
                            </InputLabel>
                            <Select
                              name="delivery_time"
                              labelId="demo-simple-select-outlined-label-2"
                              id="demo-simple-select-outlined-2"
                              value={delivery_time}
                              onChange={(e) => handleDateChange(e)}
                              label="Khung giờ"
                            >
                              {delivery_schedules && delivery_schedules.list_time && Array.isArray(delivery_schedules.list_time) && delivery_schedules.list_time.length > 0 && delivery_schedules.list_time.map(
                                (value, index) => (
                                  <MenuItem value={value.id} key={index}>
                                    {value.time_from + " - " + value.time_to}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                      <p>
                        Cần hỗ trợ về thời gian giao hàng vui lòng liên hệ{" "}
                        <TelPhone href="tel:+420732886666">+420 732886666</TelPhone>
                      </p>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={4}
                    md={4}
                    sm={12}
                    className={classes.fullWidthMobile}
                  >
                    <div
                      className={classes.serviceItem}
                      style={{ border: "none" }}
                    >
                      <h3>Ghi chú giao hàng</h3>
                      <textarea
                        className="field-note"
                        value={note}
                        onChange={(e) => handleNoteChange(e)}
                        placeholder="Vui lòng gọi trước khi giao"
                      ></textarea>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            <Button
              className="btn-checkout"
              onClick={() => handleOrder()}
              disabled={!isService ? true : false}
            >
              MUA HÀNG
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>
              <Skeleton variant="text" />
            </h2>
            <div className={classes.serviceMain}>
              <Grid container>
                <Grid item xl={4} md={4} sm={12}>
                  <div className={classes.serviceItem}>
                    <div className="service-options">
                    <p style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                    <Skeleton variant="rect" width={25} height={25} style={{marginRight: '15px'}} />
                    <Skeleton variant="text" width={150} height={20}  />
                    </p>
                    <p style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                    <Skeleton variant="rect" width={25} height={25} style={{marginRight: '15px'}} />
                    <Skeleton variant="text" width={150} height={20}  />
                    </p>
                    </div>
                    <p>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    </p>
                  </div>
                </Grid>
                <Grid item xl={4} md={4} sm={12}>
                  <div className={classes.serviceItem}>
                    <h3> <Skeleton variant="text" /></h3>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                    <Skeleton variant="rect" width={100} height={48} style={{marginRight: '50px'}} />
                    <Skeleton variant="rect" width={100} height={48} />
                    </div>
                    <p>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xl={4}
                  md={4}
                  sm={12}
                  className={classes.fullWidthMobile}
                >
                  <div
                    className={classes.serviceItem}
                    style={{ border: "none" }}
                  >
                    <h3> <Skeleton variant="text" />
                    <Skeleton variant="text" /></h3>
                    <Skeleton variant="rect" width={210} height={118} />
                  </div>
                </Grid>
              </Grid>
            </div>
            <Skeleton variant="rect" style={{marginTop: '20px'}} width={150} height={48} />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DeliveryService)));
