import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";


class SkeletonLoading extends Component {
  render() {
    const {
      classes,
    } = this.props;
    return (
      <TableContainer component={Paper} className={classes.desktopCart}>
        <Table className="table-cart">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell"><Skeleton variant="text" /></TableCell>
              <TableCell className="tableCell"><Skeleton variant="text" /></TableCell>
              <TableCell className="tableCell"><Skeleton variant="text" /></TableCell>
              <TableCell className="tableCell"><Skeleton variant="text" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" className="tableCell">
                  <div className="field-name">
                    <div className="thumb">
                      <Skeleton variant="rect" width={210} height={118} />
                    </div>
                    <div className="info-product">
                      <p className="name-product">
                        <Skeleton variant="text" />
                      </p>
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="rect" width={64} height={35} />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell className="tableCell">
                  <Skeleton variant="rect" width={82} height={42} />
                </TableCell>
                <TableCell className="tableCell">
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
          </TableBody>
          <tfoot>
            <tr>
              <td></td>
              <td colSpan="3" style={{ padding: "10px" }}>
                    <p className="totalPrice" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Skeleton variant="text" width={60} style={{ marginRight: "10px" }} />
                      <Skeleton variant="text" width={60} />
                    </p>
                    <div className="boxFooterTable">
                      <Skeleton
                        variant="rect"
                        width={165}
                        height={48}
                        style={{ marginRight: "10px" }}
                      />
                      <Skeleton variant="rect" width={165} height={48} />
                    </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownDispatch) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(SkeletonLoading)));
