const style = (theme) => ({
    orderPage: {
        padding: '30px 0px',
        '& .txtSuccess': {
            color: '#f2c123',
            fontSize: '15px',
            lineHeight: '17px',
        },
        '& h2': {
            fontSize: '1.375rem',
            fontWeight: '500',
            marginBottom: '16px'
        },
        '& .contact-info':{
            padding: '15px',
            width: '100%',
            height: '100%',
            border: '1px solid #f3f3ef',
            '& p': {
                fontSize: '15px',
                lineHeight: '17px',
                marginBottom: '10px'
            },
            '& .contact': {
                fontSize: '18px',
                lineHeight: '24px',
                position: 'relative',
                '& span': {
                    textDecoration: 'underline',
                    position: 'absolute',
                    top: '0px',
                    right: '0px'
                }
            }
        },
        '& .cart':{
            padding: '15px',
            width: '100%',
            height: '100%',
            border: '1px solid #f3f3ef',
            '& p': {
                fontSize: '15px',
                lineHeight: '17px',
                marginBottom: '10px'
            },
            '& .contact': {
                fontSize: '18px',
                lineHeight: '24px',
                position: 'relative',
                '& .btn': {
                    textDecoration: 'underline',
                    marginLeft: '16px',
                },
                '& .price-cart, & .delivery-cart': {
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    [theme.breakpoints.down("xs")]: {
                        display: 'block',
                        position: 'initial'
                      },
                },
                // '& .delivery-cart': {
                //     color: '#ff0000'
                // },
            },
            '& .box-total':{
                padding: ' 25px 0px 0px',
                '& p': {
                    fontSize: '1.125rem',
                    fontWeight: 'bold',
                    position: 'relative',
                    '& span': {
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px'
                    }
                }
            }
        }
    },
    paymentMethod: {
        display: 'flex',
        flexDirection: 'column',
        justityContent: 'flex-start',
        "& .MuiRadio-colorSecondary.Mui-checked": {
          color : "#f2c123"
        }
    },
    totalPayment: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        margin: '0px 15px 10px 0px',
        position: 'relative',
        '& span': {
            float: 'right',
        }
    },
    btnCheckout: {
        width: '200px',
        height: '48px',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '500',
        background: '#f2c123',
        borderRadius: '30px',
        color: '#fff',
        border: '1px solid #f2c123',
        '&:hover': {
            background: '#f2c123',
            cursor: 'pointer'
        },
        '&:hover .progress-icon':{
            color: '#fff'
        },
        '&:hover span': {
            color: '#fff'
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
          },
    },
    detailOrder: {
        "& .priceVoucherPoint ": {
          "& span": {
            fontWeight: 'bold',
            color: '#f2c123'
          }
        },
        "& .contact-info": {
          marginBottom: "20px",
          "& .info-item": {
            border: "1px solid #eee",
            padding: "10px",
            [theme.breakpoints.down("md")]: {
              paddingLeft: "5px",
            },
            "& h3": {
              marginBottom: "10px",
              // textAlign: 'center'
            },
          },
          "& .contact-info:first-child": {
            borderRight: "none",
          },
        },
        "& .detail-product": {
          marginBottom: "20px",
        },
        "& .total-price": {
          textAlign: "right",
          "& p": {
            marginBottom: "10px",
            fontWeight: "bold",
          },
        },
        "& .table-mobile": {
          display: "none",
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
          "& .item-detail-mb": {
            paddingBottom: "10px",
            "& p": {
              fontWeight: "bold",
              marginBottom: "5px",
              "&.attribute-product": {
                fontWeight: "500",
              },
              "& span": {
                fontWeight: "500",
              },
            },
          },
          '& .MuiDialogContent-dividers':{
            padding: '14px 12px'
          }
        },
        "& .table-desktop": {
          display: "block",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },
      },
      btnCloseModal: {
        position: "absolute",
        top: "0px",
        right: "0px",
      },
      stepper: {
        marginBottom: '30px',
        '& .MuiPaper-root' : {
          marginTop: '0px!important'
        }
      }
})

export default style;