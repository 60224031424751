const style = (theme) => ({
    stepperCustom: {
        '& .MuiStepIcon-root.MuiStepIcon-active' : {
            color: '#f2c123'
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed' : {
            color: '#f2c123'
        }
    }
  });
  
  export default style;
  