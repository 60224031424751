import React, { Component } from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import styled from 'styled-components';
import { domain } from '../../../../utills/config';
import DefaultImage from './../../../../assets/images/default.jpg';

const BannerThumb = styled.div`
    padding: 0px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
`;

class BannerProductMobile extends Component {
    render() {
        let { banner, isSke, title } = this.props;
        return (
            <React.Fragment>
                {
                    isSke ? (
                        <BannerThumb>
                            <Skeleton variant="text" height={196} style={{transform: 'scale(1)'}} />
                        </BannerThumb>
                    ) : (
                        <BannerThumb>
                            <a style={{display: 'block'}} href={banner.link ? banner.link : domain}>
                                <img style={{display: 'block', width: '100%', objectFit: 'cover'}} onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={banner.image ? banner.image : DefaultImage} alt={title} />
                            </a>
                     </BannerThumb>
                    )
                }
            </React.Fragment>
        );
    }
}

export default BannerProductMobile;