import * as types from "../constants";
import LocalStorageService from "../../services/LocalStorageService";
import { uid } from "uid";

const localStorageService = LocalStorageService.getService();
var cart = {
  listProduct: [],
};

function saveCartToLocalStorage(listItem) {
  let cartInfo = JSON.stringify(listItem);
  localStorageService.setCart(cartInfo);
}

function calculatorProvisional(listItem) {
  let list = listItem.map((value) => {
      if(typeof Number(value.discount_price) === "number" && !isNaN(value.discount_price) && Number(value.discount_price) > 0){
        value.payment = Number((Math.round((Number(value.quantity) * Number(value.discount_price)) * 100) / 100).toFixed(2));
        if(value.isExchange) {
          value.payment_exchange = Number((Math.round((Number(value.quantity) * Number(value.discount_price_exchange)) * 100) / 100).toFixed(2));
        }
      }else{
        value.payment = Number((Math.round((Number(value.quantity) * Number(value.price)) * 100) / 100).toFixed(2));
        if(value.isExchange) {
          value.payment_exchange = Number((Math.round((Number(value.quantity) * Number(value.price_exchange)) * 100) / 100).toFixed(2));
        }
      }
      value.buying = Number((Math.round((Number(value.quantity) * Number(value.price)) * 100) / 100).toFixed(2));
      if(value.isExchange) {
        value.buying_exchange = Number((Math.round((Number(value.quantity) * Number(value.price_exchange)) * 100) / 100).toFixed(2));
      }
      return value;
  });
  return list;
}

function handleChangeQuantity(items, payload) {
  payload.value = payload.value.toString().replace(/,/g, "");
  let listItems = items.map((value) => {
    value.notifyMessage = "";
    if (value.cart_id == payload.cart_id) {
      if(Number(value.inventory) < Number(payload.value)){
        value.quantity = Number(value.inventory);
        value.notifyMessage = "Bạn đã chọn hết số lượng tồn kho hiện có."
      }else{
        value.notifyMessage = ""
        if(payload.value === ""){
          value.quantity = "";
        }else{
            value.quantity = Number(payload.value);
        }
      }
    }
    return value;
  });
  return listItems;
}

export default function reducer(state = cart, actions) {
  let token = LocalStorageService.getAccessToken();
  switch (actions.type) {
    case types.ADD_TO_CART:
      let checkExist = false;
      let listItem = state.listProduct;
      let newProduct = actions.payload;
      listItem = listItem.map((value) => {
          value.notifyMessage = "";
        if (
          (value && typeof value.sku_code === "string" && 
          value.sku_code === newProduct.sku_code) || 
          (value && !value.sku_code && !newProduct.sku_code && typeof value.product_code === "string" && value.product_code === newProduct.product_code)
        ) {
          // if(Number(newProduct.inventory) === 0){
          //   value.errorMessage = "Sản phẩm đã hết hàng.";
          // }
          // if(Number(value.inventory) <= Number(newProduct.quantity)){
          //   value.quantity = Number(value.inventory);
          // }else{
            // if(Number(value.inventory) <= (Number(newProduct.quantity) + value.quantity) ){
            //   value.quantity = Number(value.inventory);
            // }else{
              value.quantity += Number(newProduct.quantity);
            // }
          // }
          checkExist = true;
        }
        return value;
      });
      if (!checkExist){
        if(!token && !newProduct.cart_id){
          newProduct.cart_id = uid();
        }
        // if(Number(newProduct.inventory) < Number(newProduct.quantity)){
        //   newProduct.quantity = newProduct.inventory;
        // }
        // if(Number(newProduct.inventory) === 0){
        //   newProduct.errorMessage = "Sản phẩm đã hết hàng.";
        // }
        listItem.push(newProduct);
      }
      listItem = calculatorProvisional(listItem);

      // if (!token) {
        saveCartToLocalStorage(listItem);
      // }
      return {
        ...state,
        listProduct: listItem,
      };
    case types.CLEAR_LIST_CART:
      const listEmpty = [];
      return {
        ...state,
        listProduct: listEmpty,
      };
    case types.DELETE_ITEM_CART:
      const listItemCart = state.listProduct;
      let newListItemCart = listItemCart.filter((value) => {
        return value.cart_id !== actions.payload;
      });
      newListItemCart = calculatorProvisional(newListItemCart);
      // if (!token) {
        saveCartToLocalStorage(newListItemCart);
      // }
      return {
        ...state,
        listProduct: newListItemCart,
      };
    case types.CHANGE_QUANTITY_ITEM:
      const items = state.listProduct;
      let newLists = handleChangeQuantity(items, actions.payload);
      newLists = calculatorProvisional(newLists);
      // if (!token) {
        saveCartToLocalStorage(newLists);
      // }
      return {
        ...state,
        listProduct: newLists,
      };
    case types.UPDATE_INVENTORY_ITEM:
      const itemsCart = state.listProduct;
      let newCartLists = itemsCart.map((value) => {
        if(value.cart_id == actions.payload.cart_id){
          value.inventory = actions.payload.inventory;
        }
        return value;
      });
      saveCartToLocalStorage(newCartLists);
      return {
        ...state,
        listProduct: newCartLists,
      };
    default:
      return state;
  }
}
