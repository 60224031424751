const style = (theme) => ({
    infinite: {
        overflow: 'unset !important',
        '& .MuiCardMedia-img': {
          cursor: 'pointer',
          objectFit: 'scale-down',
        },
      },
      listProductPage: {
        padding: '20px 0px'
      },
        fp: {
          background: "#fff",
          padding: "20px 0px",
          "& .fpGrid": {
            padding: "10px",
            [theme.breakpoints.down("sm")]: {
              padding: '5px'
            }
          },
        },
      });
      export default style;