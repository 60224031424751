import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@material-ui/core";
  import React, { Component } from "react";
  import { FormattedMessage } from "react-intl";
  import CustomButton from "../../UI/Button";
  
  class DeleteDeliveryAddress extends Component {
    render() {
      const { delete_id, loading, handleDeleteDeliveryAddress, isOpenModal, onClose } = this.props;
      return (
        <Dialog
          maxWidth="xs"
          open={isOpenModal}
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="Logout" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Bạn có chắc muốn xóa địa chỉ này không?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => onClose()} btnType="success">
              <FormattedMessage id="No" />
            </CustomButton>
            <CustomButton
              customStyle={{width: '80px', height: '38px'}}
              onClick={() => handleDeleteDeliveryAddress(delete_id)}
              btnType="danger"
              loading={loading}
              autoFocus
            >
              <FormattedMessage id="Yes" />
            </CustomButton>
          </DialogActions>
        </Dialog>
      );
    }
  }
  
export default DeleteDeliveryAddress
  