const style = (theme) => ({
	changePassword: {
		paddingTop: '40px',
		paddingBottom: '40px',
		borderTop: '1px solid #f3f3ef',
		'& h2': {
			fontSize: '1.75rem',
			marginBottom: '18px',
			fontWeight: '500',
			paddingTop: '8px',
			textAlign: 'center'
		  },
	},
	sectionTitle: {
		fontWeight: 400,
		color: '#212529',
		marginBottom: theme.spacing(5),
	},
	txtControl: {
		marginTop: '0',
		marginBottom: '30px',
		'& label': {
			marginBottom: '12px'
		},
		'& input': {
			maxWidth: '440px',
			width: '440px',
			padding: '13px 15px',
			fontSize: '15px'
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderRadius: '0px'
		},
	},
	validateForm: {
		maxWidth: '440px',
		margin: '0px auto'
	},
	btnSubmit: {
		borderRadius: '30px',
		textTransform: 'uppercase',
		marginTop: '10px',
		backgroundColor: '#f2c123!important',
		border: '1px solid #f2c123!important'
	},
	formControl: {
		width: '100%',
		margin: theme.spacing(1),
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
});

export default style;
