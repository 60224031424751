import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  withStyles,
  Container,
  Grid,
  Radio,
  CircularProgress,
  FormControlLabel,
  Dialog,
  IconButton,
  Paper
} from "@material-ui/core";
import { injectIntl } from "react-intl";
import { convertImageTobase64, CURRENCY, formatTwoDecimals } from "../../utills/constant";
import styled from "styled-components";
import Breadcrumb from "../../components/Breadcrumb";
import { checkExchangeRate, createOrder, getListCart, updateCart } from "../../models/cart";
import moment from "moment";
import AlertMessage from "../../components/UI/AlertMessage";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import DetailCart from "./DetailCart";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import LocalStorageService from "../../services/LocalStorageService";
import { Helmet } from 'react-helmet';
import Card from './../../assets/images/credit-bedit-card.png';
// import ReactDOM from "react-dom";
import { queryUserInfo } from "../../models";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PreventTransitionPrompt from "../../components/PreventTransitionPromt/PreventTransitionPrompt";
import HorizontalLinearStepper from "../../components/HorizontalLinearStepper";
import { apiDomain } from "../../utills/config";
// import { Prompt } from 'react-router';


// const PayPalButton = window && window.paypal_sdk ? window.paypal_sdk.Buttons.driver("react", { React, ReactDOM }) : styled.div``;

const localStorageService = LocalStorageService.getService();

const GreenRadio = styled(Radio)`
  color: #f2c123;
`;

const ButtonSpan = styled.span`
  color: #f2c123;
  text-decoration: underline;
  font-size: 15px;
  line-height: 17px;
  &:hover {
    cursor: pointer;
  }
`;

const DialogDetailOrder = styled(Dialog)`
  & .MuiDialog-scrollPaper {
    display: flex;
    align-items: flex-start;
  }
  & .MuiDialog-paperWidthSm {
    max-width: 1170px;
  }
`;

const DialogTitle = styled(MuiDialogTitle)`
  position: relative;
  text-align: center;
`;

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery_person_name: "",
      delivery_address: "",
      delivery_address_id: "",
      phone: "",
      postcode: "",
      country_id: 0,
      city: "",
      delivery_date: "",
      delivery_time: "",
      payment_method: "1",
      country_name: "",
      email: "",
      listCart: [],
      totalQuantity: 0,
      totalBuying: 0,
      totalCost: 0,
      totalCart: 0,
      totalPayment: 0,
      delivery_service: "",
      note: "",
      isAlert: false,
      sbColor: "error",
      sbMessage: "",
      shipment_fee: 0,
      isLoading: false,
      isSkeleton: false,
      isEditAddressModalOpen: false,
      listCountry: [],
      listCountry1: [],
      country_selected: null,
      isDetailCartModalOpen: false,
      isAuth: false,
      contact_information: {},
      delivery_prefix: "",
      colorError: false,
      messageDetail: "",
      infoVoucher: null,
      order_no: "",
      order_id: "",
      isExchange: false,
      symbol: "",
      rate: 0,
      margin: 0,
      margin_type: "",
      totalPaymentExchange: 0,
      totalCartExchange: 0,
      totalCostExchange: 0,
      totalBuyingExchange: 0,
      shipment_fee_exchange: 0,
      isSubmited: false,
      rank: null,
      shipment_fee_discount: 0,
      shipment_fee_exchange_discount: 0,
      voucherPoint: null,
      isBlocking: false,
      imageCard: "",
      shipment_discount: ""
    };
  }
  async componentDidMount() {
    // console.log(localStorageService.getDeliveryInfo());
    // console.log(localStorageService.getCart());
    if (
      !localStorageService.getDeliveryInfo() ||
      typeof localStorageService.getDeliveryInfo() !== "string" ||
      typeof JSON.parse(localStorageService.getDeliveryInfo()) !== "object" ||
      !localStorageService.getCart() ||
      (localStorageService.getCart() &&
        JSON.parse(localStorageService.getCart()).length === 0)
    ) {
      this.props.history.push("/");
      return false;
    }

    this.setState({ isSkeleton: true, isBlocking: true });

    if (localStorageService.getAccessToken()) {
      await this.setState({ isAuth: true});
      await queryUserInfo({ id: 1 }).then(async(res) => {
        if (res.success) {
          let exchange = null;
          if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
            exchange = res.exchange_rate[0];
            await this.setState({
              isExchange: true,
              rate: exchange.rate,
              symbol: exchange.symbol,
              margin: exchange.margin,
              margin_type: exchange.margin_type
            });
            if(res.rank && typeof res.rank === "object" && Object.keys(res.rank).length > 0){
              res.rank.price_discount_exchange =
              Number((Math.round((Number(res.rank.price_discount)*Number(exchange.rate)) * 100) / 100).toFixed(2));
            }
          }
          await this.setState({
            rank: res.rank ? res.rank : null
          });
        }
      });
    }else{
      await checkExchangeRate({check_location: 1}).then(async (res) => {
        if(res.success) {
          if(res._embedded
            && res._embedded.exchange_rate
            && Array.isArray(res._embedded.exchange_rate)
            && res._embedded.exchange_rate.length === 1){
              let exchange = res._embedded.exchange_rate[0];
              await this.setState({
                isExchange: true,
                rate: exchange.rate,
                symbol: exchange.symbol,
                margin: exchange.margin,
                margin_type: exchange.margin_type
              })
            }
        }
      })
    }


    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      let order_info = JSON.parse(localStorageService.getOrderInfo());
      await this.setState({
        payment_method: "2",
        order_no: order_info.order_no ? order_info.order_no : "",
        order_id: order_info.order_id ? order_info.order_id : "",
      });
    }

     convertImageTobase64(Card, async(link) => {
      await this.setState({imageCard: link})
    })

    this.handleGetInfoPurchasing();
  }

  handleGetInfoPurchasing = async () => {
    let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
    let cartInfo = JSON.parse(localStorageService.getCart());
      let isExchange = false;
      if(this.state.isAuth) {
        if(cartInfo[0].isExchange) {
          isExchange = true;
          await this.setState({
            isExchange: true,
            symbol: cartInfo[0].symbol,
            margin: cartInfo[0].margin,
            rate: cartInfo[0].rate,
            margin_type: cartInfo[0].margin_type
          });
        }
        else{
          isExchange = false;
          await this.setState({
            isExchange: false,
            symbol: '',
            margin: 0,
            rate: 0,
            margin_type: ''
          });
        }
      }else{
        isExchange = this.state.isExchange;
      }

    let contact = {
      delivery_person_name: deliveryInfo && deliveryInfo.delivery_person_name ? deliveryInfo.delivery_person_name : "",
      delivery_address: deliveryInfo && deliveryInfo.delivery_address ? deliveryInfo.delivery_address : "",
      delivery_address_id: deliveryInfo && deliveryInfo.delivery_address_id ? deliveryInfo.delivery_address_id : "",
      phone: deliveryInfo && deliveryInfo.phone ? deliveryInfo.phone : "",
      postcode: deliveryInfo && deliveryInfo.postcode ? deliveryInfo.postcode : "",
      country_id: deliveryInfo && deliveryInfo.country_id ? Number(deliveryInfo.country_id) : 0,
      country_selected: deliveryInfo && deliveryInfo.country_selected ? deliveryInfo.country_selected : null,
      city: deliveryInfo && deliveryInfo.city ? deliveryInfo.city : "",
      country_name: deliveryInfo && deliveryInfo.country_name ? deliveryInfo.country_name : "",
      shipment_fee: deliveryInfo && deliveryInfo.shipment_fee && Number(deliveryInfo.shipment_fee) > 0 ? Number(deliveryInfo.shipment_fee) : 0,
      shipment_fee_discount: deliveryInfo && (deliveryInfo.shipment_discount === 0) ? deliveryInfo.shipment_discount : deliveryInfo && deliveryInfo.shipment_fee_discount && Number(deliveryInfo.shipment_fee_discount) > 0 ? Number(deliveryInfo.shipment_fee_discount) : 0,
      shipment_discount: deliveryInfo && (deliveryInfo.shipment_discount === 0) ? deliveryInfo.shipment_discount : "",
      delivery_service: deliveryInfo && deliveryInfo.delivery_service ? deliveryInfo.delivery_service : "",
      note: deliveryInfo && deliveryInfo.note ? deliveryInfo.note : "",
      delivery_date: deliveryInfo && deliveryInfo.delivery_date ? deliveryInfo.delivery_date : "",
      delivery_time: deliveryInfo && deliveryInfo.delivery_time ? deliveryInfo.delivery_time : "",
      email: deliveryInfo && deliveryInfo.email ? deliveryInfo.email : "",
      delivery_prefix: deliveryInfo && deliveryInfo.delivery_prefix ? deliveryInfo.delivery_prefix : "",
      shipment_fee_exchange: deliveryInfo && deliveryInfo.shipment_fee_exchange && Number(deliveryInfo.shipment_fee_exchange) > 0 ? Number(deliveryInfo.shipment_fee_exchange) : 0,
      shipment_fee_exchange_discount: deliveryInfo && deliveryInfo.shipment_fee_exchange_discount && Number(deliveryInfo.shipment_fee_exchange_discount) > 0 ? Number(deliveryInfo.shipment_fee_exchange_discount) : 0,
    };

    let voucher = null;
    if(localStorageService.getVoucher() &&
    typeof JSON.parse(localStorageService.getVoucher())  === "object" &&
    Object.keys(JSON.parse(localStorageService.getVoucher())).length > 0){
        voucher = JSON.parse(localStorageService.getVoucher());
        await this.setState({infoVoucher: voucher});
    }

    let countQuantity = 0;
    let countBuying = 0;
    let countBuyingExchange = 0;
    let countPayment = 0;
    let countPaymentExchange = 0;

    let listCart = [];
    if(this.state.isAuth) {
      if(cartInfo && Array.isArray(cartInfo) && cartInfo.length > 0) {
        cartInfo.forEach((value) => {
          countQuantity += Number(value.quantity);
          countBuying = (countBuying * 10 + Number(value.buying) * 10) / 10;
          countPayment = (countPayment * 10 + Number(value.payment) * 10) / 10;
          if(isExchange && value.buying_exchange && Number(value.buying_exchange) > 0) {
            countBuyingExchange = (countBuyingExchange * 10 + Number(value.buying_exchange) * 10) / 10;
          }
          if(isExchange && value.payment_exchange && Number(value.payment_exchange) > 0) {
            countPaymentExchange = (countPaymentExchange * 10 + Number(value.payment_exchange) * 10) / 10;
          }
          let item = {
            cart_id: value.cart_id,
            product_name: value.product_name,
            product_id: value.product_id,
            product_attribute_value_id1: value.product_attribute_value_id1,
            product_attribute_value_id2: value.product_attribute_value_id2,
            product_attribute_value_id3: value.product_attribute_value_id3,
            product_attribute_value_id4: value.product_attribute_value_id4,
            product_attribute_value_id5: value.product_attribute_value_id5,
            attribute_name1: value.attribute_name1,
            attribute_name2: value.attribute_name2,
            attribute_name3: value.attribute_name3,
            attribute_name4: value.attribute_name4,
            attribute_name5: value.attribute_name5,
            attribute_value1: value.attribute_value1,
            attribute_value2: value.attribute_value2,
            attribute_value3: value.attribute_value3,
            attribute_value4: value.attribute_value4,
            attribute_value5: value.attribute_value5,
            sku_code: value.sku_code,
            product_code: value.product_code,
            quantity: value.quantity,
            discount: Number(value.discount_price),
            price: Number(value.price),
            provisional:
              typeof Number(value.discount_price) === "number" && !isNaN(value.discount_price) && Number(value.discount_price) > 0
                ? formatTwoDecimals((value.discount_price * value.quantity))
                : formatTwoDecimals((value.price * value.quantity)),
            discount_exchange: Number(value.discount_price_exchange),
            price_exchange: Number(value.price_exchange),
            provisional_exchange:
            typeof Number(value.discount_price) === "number" && !isNaN(value.discount_price) && Number(value.discount_price) > 0
              ? formatTwoDecimals((Number(value.discount_price_exchange) * value.quantity))
              : formatTwoDecimals((Number(value.price_exchange) * value.quantity)),
          };
          listCart.push(item);
        });
      }
    }else{
      if(cartInfo && Array.isArray(cartInfo) && cartInfo.length > 0) {
        let list = cartInfo.map((value) => {
          if(typeof Number(value.discount_price) === "number" 
            && !isNaN(value.discount_price) && Number(value.discount_price) > 0){
            value.payment = Number((Math.round((Number(value.quantity) 
              * Number(value.discount_price)) * 100) / 100).toFixed(2));
            if(isExchange) {
              if(this.state.margin_type === "increase") {
                value.discount_price_exchange = (value.discount_price &&
                  typeof Number(value.discount_price) === "number"
                  && !isNaN(value.discount_price)
                  && Number(value.discount_price) > 0) ?
                  Number((Math.round((value.discount_price*this.state.rate + this.state.margin/100*(value.discount_price*this.state.rate))* 100) /100)).toFixed(2) : 0;
                  value.price_exchange = (value.price
                    && typeof Number(value.price) === "number"
                    && !isNaN(value.price)
                    && Number(value.price) > 0) ?
                    Number((Math.round((value.price*this.state.rate 
                      + this.state.margin/100*(value.price*this.state.rate))* 100) /100)).toFixed(2) : 0;
                }else{
                value.discount_price_exchange = (value.discount_price &&
                  typeof Number(value.discount_price) === "number"
                  && !isNaN(value.discount_price)
                  && Number(value.discount_price) > 0) ?
                  Number((Math.round((value.discount_price*this.state.rate - this.state.margin/100*(value.discount_price*this.state.rate))* 100) /100)).toFixed(2) : 0;
                  value.price_exchange = (value.price
                    && typeof Number(value.price) === "number"
                    && !isNaN(value.price)
                    && Number(value.price) > 0) ?
                    Number((Math.round((value.price*this.state.rate - this.state.margin/100*(value.price*this.state.rate))* 100) /100)).toFixed(2) : 0;
                }
              value.payment_exchange = Number((Math.round((Number(value.quantity) 
              * Number(value.discount_price_exchange)) * 100) / 100).toFixed(2));
            }
          }else{
            if(isExchange){
              if(this.state.margin_type === "increase"){
                value.price_exchange = (value.price
                  && typeof Number(value.price) === "number"
                  && !isNaN(value.price)
                  && Number(value.price) > 0) ?
                  Number((Math.round((value.price*this.state.rate 
                    + this.state.margin/100*(value.price*this.state.rate))* 100) /100)).toFixed(2) : 0;
              }else{
                value.price_exchange = (value.price
                  && typeof Number(value.price) === "number"
                  && !isNaN(value.price)
                  && Number(value.price) > 0) ?
                  Number((Math.round((value.price*this.state.rate - this.state.margin/100*(value.price*this.state.rate))* 100) /100)).toFixed(2) : 0;
              }
            }
            value.payment = Number((Math.round((Number(value.quantity) * Number(value.price)) * 100) / 100).toFixed(2));
            if(isExchange) {
              value.payment_exchange = Number((Math.round((Number(value.quantity) 
              * Number(value.price_exchange)) * 100) / 100).toFixed(2));
            }
          }
          value.buying = Number((Math.round((Number(value.quantity) * Number(value.price)) * 100) / 100).toFixed(2));
          if(isExchange) {
            value.buying_exchange = Number((Math.round((Number(value.quantity) * Number(value.price_exchange)) * 100) / 100).toFixed(2));
          }
          return value;
      });
          list.forEach((value) => {
          countQuantity += Number(value.quantity);
          countBuying = (countBuying * 10 + Number(value.buying) * 10) / 10;
          countPayment = (countPayment * 10 + Number(value.payment) * 10) / 10;
          if(isExchange && value.buying_exchange && Number(value.buying_exchange) > 0) {
            countBuyingExchange = (countBuyingExchange * 10 + Number(value.buying_exchange) * 10) / 10;
          }
          if(isExchange && value.payment_exchange && Number(value.payment_exchange) > 0) {
            countPaymentExchange = (countPaymentExchange * 10 + Number(value.payment_exchange) * 10) / 10;
          }
          let item = {
            cart_id: value.cart_id,
            product_name: value.product_name,
            product_id: value.product_id,
            product_attribute_value_id1: value.product_attribute_value_id1,
            product_attribute_value_id2: value.product_attribute_value_id2,
            product_attribute_value_id3: value.product_attribute_value_id3,
            product_attribute_value_id4: value.product_attribute_value_id4,
            product_attribute_value_id5: value.product_attribute_value_id5,
            attribute_name1: value.attribute_name1,
            attribute_name2: value.attribute_name2,
            attribute_name3: value.attribute_name3,
            attribute_name4: value.attribute_name4,
            attribute_name5: value.attribute_name5,
            attribute_value1: value.attribute_value1,
            attribute_value2: value.attribute_value2,
            attribute_value3: value.attribute_value3,
            attribute_value4: value.attribute_value4,
            attribute_value5: value.attribute_value5,
            sku_code: value.sku_code,
            product_code: value.product_code,
            quantity: value.quantity,
            discount: Number(value.discount_price),
            price: Number(value.price),
            provisional:
              typeof Number(value.discount_price) === "number" && !isNaN(value.discount_price) && Number(value.discount_price) > 0
                ? formatTwoDecimals((value.discount_price * value.quantity))
                : formatTwoDecimals((value.price * value.quantity)),
            discount_exchange: Number(value.discount_price_exchange),
            price_exchange: Number(value.price_exchange),
            provisional_exchange:
            typeof Number(value.discount_price) === "number" && !isNaN(value.discount_price) && Number(value.discount_price) > 0
              ? formatTwoDecimals((Number(value.discount_price_exchange) * value.quantity))
              : formatTwoDecimals((Number(value.price_exchange) * value.quantity)),
          };
          listCart.push(item);
        });
      }
    }

    let countCart = countPayment;
    let countCartExchange = 0;
    if(isExchange) {
      countCartExchange = countPaymentExchange;
    }
    if(voucher && typeof voucher === "object" && Object.keys(voucher).length > 0){
      if(voucher.value_type === "fixed") {
        countPayment = (Number(countPayment) * 10 - Number(voucher.value) * 10)/10;
      }else {
        countPayment = (Number(countPayment) * 10
        - ((Number(voucher.value) / 100) * Number(countPayment)) * 10) / 10;
      }

      if(isExchange) {
        if(voucher.value_type === "fixed") {
          countPaymentExchange = (Number(countPaymentExchange) * 10
          - Number(voucher.value_exchange) * 10) / 10;
        }else {
          countPaymentExchange = (Number(countPaymentExchange) * 10
          - ((Number(voucher.value) / 100) * Number(countPaymentExchange)) * 10) / 10;
        }
      }
    }

    if(this.state.rank && typeof this.state.rank === "object" && Object.keys(this.state.rank).length > 0) {
      if(this.state.rank.price_discount_type === "percent"){
        countPayment = (((Number(countPayment) * 10)
        - (((Number(this.state.rank.price_discount) / 100) * Number(countCart)) * 10)) / 10);
      }else{
        countPayment = Number((countPayment * 10 - Number(this.state.rank.price_discount) * 10) / 10);
      }
      if(isExchange) {
        if(this.state.rank.price_discount_type === "percent") {
          countPaymentExchange = (Number(countPaymentExchange) * 10
          - (((Number(this.state.rank.price_discount) / 100) * Number(countCartExchange)) * 10)) / 10;
        }else{
          countPaymentExchange = (Number(countPaymentExchange) * 10
          - Number((Number(this.state.rank.price_discount) * Number(this.state.rate)) * 10)) / 10;
        }
      }
    }

    let voucherPoint = null;
    if(localStorageService.getVoucherPoint()
    && typeof JSON.parse(localStorageService.getVoucherPoint()) === "object"
    && Object.keys(JSON.parse(localStorageService.getVoucherPoint())).length > 0){
       voucherPoint = JSON.parse(localStorageService.getVoucherPoint());
    }

    this.setState({
      voucherPoint: voucherPoint ? voucherPoint : null,
      listCart: listCart,
      totalQuantity: Number(countQuantity),
      totalBuying: formatTwoDecimals(countBuying),
      totalBuyingExchange: formatTwoDecimals(countBuyingExchange),

      totalCost: formatTwoDecimals(countPayment) > 0
        ? formatTwoDecimals(countPayment): 0,
      totalCostExchange: isExchange && countPaymentExchange && Number(countPaymentExchange) > 0
        ? formatTwoDecimals(countPaymentExchange) : 0,

      totalCart: formatTwoDecimals(countCart) > 0
        ? formatTwoDecimals(countCart) : 0,
      totalCartExchange: isExchange && countCartExchange && Number(countCartExchange) > 0
        ? formatTwoDecimals(countCartExchange) : 0,

      totalPayment: formatTwoDecimals(((Number(countPayment) > 0 ? Number(countPayment) : 0)
      + Number(contact.shipment_fee_discount))) > 0
        ? formatTwoDecimals(((Number(countPayment) > 0 ? Number(countPayment) : 0)
        + Number(contact.shipment_fee_discount))) : 0,
      totalPaymentExchange: isExchange && countPaymentExchange && Number(countPaymentExchange) > 0 ?
        (formatTwoDecimals(((Number(countPaymentExchange) > 0 ? Number(countPaymentExchange) : 0)
        + (Number(contact.shipment_fee_exchange_discount)))) > 0 ?
        formatTwoDecimals(((Number(countPaymentExchange) > 0 ? Number(countPaymentExchange) : 0)
        + (Number(contact.shipment_fee_exchange_discount)))) : 0) : 0,
      contact_information: contact,
      ...contact,
    });

    if(cartInfo[cartInfo.length - 1].isLastItem){
      setTimeout(() => {
        this.setState({ isSkeleton: false });
      }, 500);
    }
  }

  handleOptionServiceChange = (e) => {
    this.setState({ payment_method: e.target.value });
  };

  handleAlertClose = () => {
    this.setState({ isAlert: false });
  };

  handleDetailCartModalClose = () => {
    this.setState({ isDetailCartModalOpen: false });
  };

  handleDetailCartModalOpen = () => {
    this.setState({ isDetailCartModalOpen: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.listCartStore !== this.props.listCartStore){
      this.handleGetInfoPurchasing();
    }
  }

  handleOrder = async () => {
    let {
      infoVoucher,
      totalPayment,
      delivery_service,
      note,
      totalBuying,
      payment_method,
      shipment_fee,
      contact_information,
      delivery_time,
      delivery_date,
      listCart,
      isAuth,
      order_id,
      order_no,
      isExchange,
      rate,
      margin,
      margin_type,
      symbol,
      totalPaymentExchange,
      voucherPoint,
      shipment_discount
    } = this.state;

    if(!localStorageService.getDeliveryInfo() ||
    typeof localStorageService.getDeliveryInfo() !== "string" ||
    typeof JSON.parse(localStorageService.getDeliveryInfo()) !== "object" ||
    !localStorageService.getCart() ||
    (localStorageService.getCart() &&
      JSON.parse(localStorageService.getCart()).length === 0)){
      this.setState({
        sbColor: 'error',
        sbMessage: 'Đơn hàng đã được thanh toán trước đó, vui lòng tạo đơn hàng mới.',
        isAlert: true,
        isBlocking: false
      });
      return;
    }

    await this.setState({ isLoading: true, isSubmited: true });

    let validCheckout = false;

    if(order_id && order_no && payment_method === "2") {
      validCheckout = true;
      return validCheckout;
    }

    let detail = listCart.map((value) => {
      value.sku_code = value.sku_code ? value.sku_code : value.product_code;
      return value;
    });

    let params_detail = [];
    listCart.forEach((value) => {
      let item = {
        product_id: value.product_id,
        product_attribute_value_id1: value.product_attribute_value_id1,
        product_attribute_value_id2: value.product_attribute_value_id2,
        product_attribute_value_id3: value.product_attribute_value_id3,
        product_attribute_value_id4: value.product_attribute_value_id4,
        product_attribute_value_id5: value.product_attribute_value_id5,
        sku_code: value.sku_code ? value.sku_code : value.product_code,
        quantity: value.quantity,
        discount: value.discount,
        price: value.price,
      }
      params_detail.push(item);
    });

    let params = {
      type: 2,
      delivery_service: delivery_service,
      note: note,
      total_buying: totalBuying,
      payment_method: payment_method,
      detail: params_detail,
      total_payment: totalPayment,
    };

    if(isExchange) {
      params.total_payment_eur = totalPaymentExchange;
      params.exchange_rate = {
        rate: rate,
        symbol: symbol,
        margin: margin,
        margin_type: margin_type
      }
    }

    if (infoVoucher &&
      typeof infoVoucher === "object" &&
      Object.keys(infoVoucher).length > 0) {
        params.voucher_id = infoVoucher.id;
        params.code = infoVoucher.code;
        params.value = infoVoucher.value;
        params.value_type = infoVoucher.value_type;
        params.voucher_type = infoVoucher.voucher_type;
    }

    params.invoice_name = contact_information.delivery_person_name;
    params.invoice_address = contact_information.delivery_address;
    params.invoice_postcode = contact_information.postcode;
    params.invoice_city = contact_information.city;
    params.invoice_country = contact_information.country_id;
    params.invoice_phone = contact_information.delivery_prefix ? contact_information.delivery_prefix + contact_information.phone : contact_information.phone;

    params.receiver_name = contact_information.delivery_person_name;
    params.delivery_address = contact_information.delivery_address;
    params.delivery_postcode = contact_information.postcode;
    params.delivery_city = contact_information.city;
    params.delivery_country = contact_information.country_id;
    params.receiver_phone = contact_information.delivery_prefix ? contact_information.delivery_prefix + contact_information.phone : contact_information.phone;

    if(!isAuth){
      params.email = contact_information.email;
    }else{
      params.user_invoice_address_id = contact_information.delivery_address_id;
      params.user_delivery_address_id = contact_information.delivery_address_id;
    }

    if (Number(params.delivery_service) === 1) {
      params.delivery_time = delivery_time;
      params.delivery_date = delivery_date;
      params.shipment_fee = Number(shipment_fee);
    }

    if(shipment_discount === 0){
      params.shipment_discount =  Number(shipment_fee);
    }

    if(voucherPoint) {
      params.voucher_point = {
        id: voucherPoint.id, 
        start_date: voucherPoint.start_date,
        end_date: voucherPoint.end_date,
        value: voucherPoint.value,
        value_type: voucherPoint.value_type
      }
    }

    await createOrder(params).then( async (res) => {
      if (res.success) {
        if(payment_method === "1") {
          let order_no = res.detail.order_no;
          await this.setState({
            isLoading: false,
            isSubmited: false,
            isBlocking: false
          });
          this.props.clearListCart();
          localStorageService.clearCart();
          localStorageService.clearVoucher();
          localStorageService.clearDeliveryInfo();
          localStorageService.clearVoucherPoint();
          this.props.history.push("/checkout-success", {
            order_no,
          });
        }else{
          await this.setState({
            isLoading: false,
            isSubmited: false,
            order_no: res.detail.order_no,
            order_id: res.detail.id,
            isBlocking: false
          });
        }
        validCheckout = true;
      } else {
        validCheckout = false;
        let message = "";
        let sbMessage = "";
        let listProductChange = [];
        if (res.error_message && res.error_message.detail) {
          Object.keys(res.error_message.detail).forEach((value) => {
            if (value === "isNotEnoughInventory") {
              res.error_message.detail.isNotEnoughInventory.forEach((value) => {
                value.errorCode = 'isNotEnoughInventory';
                let product = detail.find((pr) => {
                  if(pr.sku_code){
                    return pr.sku_code === value.sku_code;
                  }
                  return pr.product_code === value.sku_code;
                });
                if(product){
                  value.cart_id = product.cart_id;
                  value.product_id = product.product_id;
                  value.product_name = product.product_name;
                  value.product_code = product.product_code;
                  value.sku_code = product.sku_code;
                  value.quantity = product.quantity;
                  value.product_attribute_value_id1 = product.product_attribute_value_id1;
                  value.product_attribute_value_id2 = product.product_attribute_value_id2;
                  value.product_attribute_value_id3 = product.product_attribute_value_id3;
                  value.product_attribute_value_id4 = product.product_attribute_value_id4;
                  value.product_attribute_value_id5 = product.product_attribute_value_id5;
                }
                listProductChange.push(value);
              });
            } else if (value === "inValidPrice") {
              res.error_message.detail.inValidPrice.forEach((value) => {
                value.errorCode = 'inValidPrice';
                let product = detail.find((pr) => {
                  if(pr.sku_code){
                    return pr.sku_code === value.sku_code;
                  }
                  return pr.product_code === value.sku_code;
                });
                if(product){
                  value.cart_id = product.cart_id;
                  value.product_id = product.product_id;
                  value.product_name = product.product_name;
                  value.product_code = product.product_code;
                  value.sku_code = product.sku_code;
                  value.quantity = product.quantity;
                  value.product_attribute_value_id1 = product.product_attribute_value_id1;
                  value.product_attribute_value_id2 = product.product_attribute_value_id2;
                  value.product_attribute_value_id3 = product.product_attribute_value_id3;
                  value.product_attribute_value_id4 = product.product_attribute_value_id4;
                  value.product_attribute_value_id5 = product.product_attribute_value_id5;
                }
                listProductChange.push(value);
              })
            } else if (value === "inValidDiscount") {
              res.error_message.detail.inValidDiscount.forEach((value) => {
                value.errorCode = 'inValidDiscount';
                let product = detail.find((pr) => {
                  if(pr.sku_code){
                    return pr.sku_code === value.sku_code;
                  }
                  return pr.product_code === value.sku_code;
                });
                if(product){
                  value.cart_id = product.cart_id;
                  value.product_id = product.product_id;
                  value.product_name = product.product_name;
                  value.product_code = product.product_code;
                  value.sku_code = product.sku_code;
                  value.quantity = product.quantity;
                  value.product_attribute_value_id1 = product.product_attribute_value_id1;
                  value.product_attribute_value_id2 = product.product_attribute_value_id2;
                  value.product_attribute_value_id3 = product.product_attribute_value_id3;
                  value.product_attribute_value_id4 = product.product_attribute_value_id4;
                  value.product_attribute_value_id5 = product.product_attribute_value_id5;
                }
                listProductChange.push(value);
              })
            }
          });
          await this.setState({isBlocking: false});
          this.props.history.push("/cart", {errorCheckout: listProductChange});
          return;
        }else if(res.error_message && res.error_message.voucher_id){
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.voucher_id).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/cart", {errorCheckout: "inValid_voucher_id"});
              return;
            }
          });
        }
         else if (res.error_message && res.error_message.shipment_fee) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.shipment_fee).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/delivery-address", {error: "inValid_shipment_fee"});
              return;
            }
          });
        } else if (res.error_message && res.error_message.total_payment) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.total_payment).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/cart", {errorCheckout: "inValid_total_payment"});
              return;
            }
          });
        } else if (res.error_message && res.error_message.total_buying) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.total_buying).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/cart", {errorCheckout: "inValid_total_buying"});
              return;
            }
          });
        } else if (res.error_message && res.error_message.voucher_point) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.voucher_point).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/cart", {errorCheckout: "inValid_voucher_point"});
              return;
            }
          });
        } else if (res.error_message && res.error_message.delivery_time) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.delivery_time).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/delivery-address", {error: "inValid_delivery_time"});
              return;
            }
          });
        } else if (res.error_message && res.error_message.delivery_date) {
          await this.setState({isBlocking: false});
          Object.keys(res.error_message.delivery_date).forEach((value) => {
            if (value === "inValid") {
              this.props.history.push("/delivery-address", {error: "inValid_delivery_date"});
              return;
            }
          });
        }
         else {
          await this.setState({isBlocking: false});
          sbMessage = "Có lỗi xảy ra, vui lòng thử lại sau.";
        }

        this.setState({
          isAlert: true,
          sbMessage: sbMessage,
          messageDetail : message,
          sbColor: "error",
          isLoading: false,
          isSubmited: false
        });
        return false;
      }
    });
    return validCheckout;
  };


  handleCheckoutFailed = () => {
    let {order_no, order_id} = this.state;
    if(order_no && order_id) {
      let order = {
        order_no: order_no,
        order_id: order_id
      }
      let order_str = JSON.stringify(order);
      this.props.history.push("/checkout-failed", {order_str});
    }
  }

  handleUpdateStatusCheckout = async () => {
    let {order_id, order_no} = this.state;
    await updateCart({id: order_id, "payment_success": 1, "type": 5}).then(async(res) => {
      if(res.success){
          this.props.clearListCart();
          localStorageService.clearCart();
          localStorageService.clearVoucher();
          localStorageService.clearDeliveryInfo();
          localStorageService.clearOrderInfo();
          await this.setState({isSkeleton: false})
          this.props.history.push("/checkout-success", {
            order_no,
          });
      }
    })
  }

  handleErrorPaypal = (error) => {
    console.log(error);
    // this.setState({
    //   isAlert: true,
    //   sbColor: "error",
    //   sbMessage: "Thông tin không chính xác, vui lòng kiểm tra lại."
    // })
    this.handleCheckoutFailed();
  }

  async createOrder(data, actions) {
    let { isExchange, totalPaymentExchange, rate, totalPayment } = this.state;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: isExchange && Number(totalPaymentExchange) > 0 ? Number((Math.round((totalPaymentExchange/rate) * 100) / 100).toFixed(2)) : totalPayment,
            currency_code: 'CZK'
          },
        },
      ],
      application_context: { shipping_preference: 'NO_SHIPPING' },
      enableStandardCardFields: true,
    });
  }

  async onApprove(data, actions) {
    let validCheckout =  await this.handleOrder();
    if(!validCheckout){
      await this.setState({isSkeleton: false});
      return;
    }
    const order = await actions.order.capture();
    if(order && order.status === "COMPLETED"){
      this.setState({isSkeleton: true})
      this.handleUpdateStatusCheckout();
    }else{
      this.handleCheckoutFailed();
    }
  }

  successTransitionRoute = async (location) => {
    await this.setState({isBlocking: false});
    this.props.history.push(location)
  }

  render() {
    const {
      payment_method,
      delivery_service,
      delivery_date,
      delivery_time,
      shipment_fee,
      totalQuantity,
      totalCost,
      totalCart,
      totalPayment,
      isAlert,
      sbColor,
      sbMessage,
      isLoading,
      isSkeleton,
      listCart,
      isDetailCartModalOpen,
      contact_information,
      messageDetail,
      infoVoucher,
      order_id,
      isExchange,
      rate,
      symbol,
      margin,
      margin_type,
      totalPaymentExchange,
      totalCostExchange,
      totalCartExchange,
      shipment_fee_exchange,
      isSubmited,
      shipment_fee_discount,
      shipment_fee_exchange_discount,
      rank,
      isAuth,
      voucherPoint,
      isBlocking,
      imageCard,
      shipment_discount
    } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>Thanh toán</title>
          <meta property="og:title" content={"Thanh toán"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Thanh toán"}></meta>
          <meta name="description" content={"Thanh toán"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb
          breadcrumb={[
            { link: "/cart", name: "Giỏ hàng" },
            { link: "/cart", name: "Thanh toán" },
          ]}
        />
        
         {/* <Prompt message='Chưa hoàn tất thanh toán, bạn có thực sự muốn thoát?' /> */}
        <section id="order-page" className={classes.orderPage}>
        <Paper style={{padding: '10px 0px 40px'}}>
        <div className={classes.stepper}>
            <Container>
              <HorizontalLinearStepper activeStep={1} />
            </Container>
        </div>
        {!isSkeleton ? (
            <Container>
              <h2>Thông tin đơn hàng</h2>
              <Grid container spacing={4}>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <div className="contact-info">
                    <p className="contact">
                      {contact_information.delivery_person_name}{" "}
                    </p>
                    <p>{contact_information.delivery_address}</p>
                    <p>
                      {contact_information.city},{" "}
                      {contact_information.country_name},{" "}
                      {contact_information.postcode}
                    </p>
                    <p>
                      {contact_information.delivery_prefix ? (
                        <React.Fragment>{`${contact_information.delivery_prefix} ${contact_information.phone}`}</React.Fragment>
                      ) : (
                        contact_information.phone
                      )}
                    </p>
                    {contact_information.email && (
                      <p>{contact_information.email}</p>
                    )}
                  </div>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <div className="cart">
                    <p className="contact">
                      Đơn hàng ({totalQuantity} món){" "}
                      <ButtonSpan
                        className="btn"
                        onClick={() => this.handleDetailCartModalOpen()}
                      >
                        Xem chi tiết
                      </ButtonSpan>
                      {
                        isExchange ? (
                          <NumberFormat
                        className="price-cart"
                        value={totalCostExchange}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={`${symbol} `}
                      />
                        ) : (
                          <NumberFormat
                        className="price-cart"
                        value={totalCost}
                        displayType={"text"}
                        thousandSeparator={true}
                        suffix={` ${CURRENCY}`}
                      />
                        )
                      }
                    </p>
                    <p className="contact">
                      {delivery_service === "1" ? (
                        <React.Fragment>Giao hàng tận nhà</React.Fragment>
                      ) : (
                        <React.Fragment>Nhận hàng tại Shop</React.Fragment>
                      )}{" "}
                      {delivery_date && (
                        <span
                          style={{
                            display: "block",
                            textDecoration: "none",
                            margin: "0px",
                          }}
                        >
                          {`${moment(`${delivery_date}T00:00:00`).format(
                            "DD/MM/YYYY"
                          )}`}{" "}
                          {delivery_time && <React.Fragment>{`(${delivery_time})`}</React.Fragment>}
                        </span>
                      )}
                      {
                        isExchange ? (
                          <span className="delivery-cart">
                          {symbol} {Number(shipment_fee_exchange_discount)}
                      </span>
                        ) : (
                          <span className="delivery-cart">
                        {Number(shipment_fee_discount)} {CURRENCY}
                      </span>
                        )
                      }
                    </p>
                    <div className="box-total">
                      <p>
                        Tổng thanh toán:{" "}
                        {
                          isExchange ? (
                            <NumberFormat
                            value={totalPaymentExchange}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                          ) : (
                            <NumberFormat
                            value={totalPayment}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` ${CURRENCY}`}
                          />
                          )
                        }
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={6} xs={12}>
                <h2 style={{marginTop: '10px'}}>Phương thức thanh toán</h2>
                <div className={classes.paymentMethod}>
                    <FormControlLabel
                      value="end"
                      control={
                        <GreenRadio
                          checked={payment_method === "1"}
                          onChange={(e) => this.handleOptionServiceChange(e)}
                          value="1"
                          name="options-service"
                          disabled={isSubmited ? true : false}
                          size="small"
                        />
                      }
                      label="Thanh toán tiền mặt"
                    />
                    <FormControlLabel
                      style={contact_information.country_name === "Czechia" ? {display: 'none'} : {}}
                      value="end"
                      control={
                        <GreenRadio
                          disabled={isSubmited || (Number(totalPayment) === 0 && Number(totalPaymentExchange) === 0) ? true : false}
                          checked={payment_method === "2"}
                          onChange={(e) => this.handleOptionServiceChange(e)}
                          value="2"
                          name="options-service"
                          size="small"
                        />
                      }
                      label="Thanh toán online"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={6} xs={12}>
                      <div style={{margin: '15px 0px 20px'}}>
                      <p className={classes.totalPayment} >
                    Tổng tiền hàng ({totalQuantity} món){" "} 
                    {
                          isExchange ? (
                            <React.Fragment>
                             {
                            totalPaymentExchange && (
                             <span style={{marginLeft: '10px'}}>
                               <NumberFormat
                                value={
                                  Number((Math.round((totalPaymentExchange/rate) * 100) / 100).toFixed(2))
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={` (`}
                                suffix={` ${CURRENCY})`}
                                />
                             </span>
                            )
                          }
                              <NumberFormat
                            value={totalPaymentExchange}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                            suffix={` `}
                          />
                            </React.Fragment>
                          ) : (
                            <NumberFormat
                            value={totalPayment}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` ${CURRENCY}`}
                          />
                          )
                        }
                    </p>
                      </div>
                </Grid>
              </Grid>
              <Grid container>
                    {
                      payment_method === "2" ? (
                        <Grid item xl={6} xs={12}>
                        <div><img style={{width: '239px'}} src={imageCard} alt="card credit/bedit" /></div>
                        <PayPalScriptProvider options={{
                          "client-id": "AdwRaKTwqXPadzCuBKx0vAAaZjcPm5G5z5fqX4lLFYQh7Bmbo76IUoQNF56cj9H8NLhu1ATvZvY5A_K5",
                          // "client-id": "ASou92VqGvcqR-qxcU4U9oGVmTpJNLOO5DK0HTl6FOVIBSkxjzHltLpQdvv7ZKCECicxTBrrLmNnmeA7",
                          // "client-id": "ATP7zCbxk9gC_rURvLIUgLmDqLffpkI_x-0dS57aF78FRvlg32JuymmvyrkU3Il1UlfGmjtqTv1C7sBw",
                          "currency": "CZK",
                          "enable-funding": "card"}}>
                          <PayPalButtons
                              createOrder={(data, actions) => this.createOrder(data, actions)}
                              onApprove={(data, actions) => this.onApprove(data, actions)}
                              onError={error => this.handleErrorPaypal(error)}
                              onCancel={() => this.handleCheckoutFailed()}
                          />
                        </PayPalScriptProvider>
                        </Grid>
                      ) : (
                        <Grid item xl={12} xs={12}>
                        {messageDetail && (<p style={{marginBottom: '10px'}} className="txtDanger" dangerouslySetInnerHTML={{__html: messageDetail}} />)}
                      <button
                        className={classes.btnCheckout}
                        onClick={() => this.handleOrder()}
                        disabled={isLoading ? true : false}
                      >
                        {isLoading ? (
                          <CircularProgress className="progress-icon" size={20} />
                        ) : (
                          <React.Fragment>Xác nhận mua hàng</React.Fragment>
                        )}
                      </button>
                        </Grid>
                      )
                    }
              </Grid>
            </Container>
          ) : (
            <Container>
              <h2>
                <Skeleton variant="text" width={180} />
              </h2>
              <Grid container spacing={4}>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <div className="contact-info">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px",
                      }}
                    >
                      <Skeleton variant="text" width={180} />
                      <Skeleton variant="text" width={180} />
                    </p>
                    <p>
                      <Skeleton variant="text" width={180} />
                    </p>
                    <p>
                      <Skeleton variant="text" width={180} />
                    </p>
                    <p>
                      <Skeleton variant="text" width={180} />
                    </p>
                  </div>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <div className="cart">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px",
                      }}
                    >
                      <Skeleton variant="text" width={180} />
                      <Skeleton variant="text" width={180} />
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px",
                      }}
                    >
                      <Skeleton variant="text" width={180} />
                      <Skeleton variant="text" width={180} />
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "20px 0px",
                      }}
                    >
                      <Skeleton variant="text" width={180} />
                      <Skeleton variant="text" width={180} />
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={6} xs={12}>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "20px 0px",
                    }}
                  >
                    <Skeleton variant="text" width={180} />
                    <Skeleton variant="text" width={180} />
                  </p>
                  <Skeleton variant="rect" width={150} height={48} />
                </Grid>
              </Grid>
            </Container>
          )}
        </Paper>
        </section>
        <DialogDetailOrder
          onClose={() => this.handleDetailCartModalClose()}
          aria-labelledby="customized-dialog-title"
          open={isDetailCartModalOpen}
        >
          <DialogTitle disableTypography>
            <h2>Thông tin giỏ hàng</h2>
            <IconButton
              className={classes.btnCloseModal}
              aria-label="close"
              onClick={() => this.handleDetailCartModalClose()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <MuiDialogContent dividers>
            <DetailCart
              detailOrder={listCart}
              totalPayment={totalPayment}
              shipment_fee={shipment_fee}
              shipment_discount={shipment_discount}
              totalCost={totalCost}
              totalCart={totalCart}
              infoVoucher={infoVoucher}
              totalPaymentExchange={totalPaymentExchange}
              totalCostExchange={totalCostExchange}
              totalCartExchange={totalCartExchange}
              shipment_fee_exchange={shipment_fee_exchange}
              isExchange={isExchange}
              symbol={symbol}
              rate={rate}
              margin={margin}
              margin_type={margin_type}
              shipment_fee_discount={shipment_fee_discount}
              shipment_fee_exchange_discount={shipment_fee_exchange_discount}
              rank={rank}
              isAuth={isAuth}
              voucherPoint={voucherPoint}
            />
          </MuiDialogContent>
        </DialogDetailOrder>
        <AlertMessage
          open={isAlert}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
        <PreventTransitionPrompt
          when={isBlocking}
          title="Thông báo"
          message="Chưa hoàn thành thanh toán đơn hàng. Bạn có muốn thoát?"
          successTransitionRoute={(location) => this.successTransitionRoute(location)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    listCartStore: store.cartReducer.listProduct,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(Checkout)));
