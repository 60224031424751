const style = (theme) => ({
    MyAccumulatedPoints: {
        paddingTop: '40px',
		paddingBottom: '80px',
		borderTop: '1px solid #f3f3ef',
		'& h2': {
			fontSize: '1.75rem',
			marginBottom: '18px',
			fontWeight: '500',
			paddingTop: '8px'
		  },
        '& .css-1okebmr-indicatorSeparator, & .css-tlfecz-indicatorContainer': {
            display: 'none'
        },
        '& .css-yk16xz-control': {
            height: '44px',
            minWidth: '120px',
            borderRadius: "0px",
        },
        '& .MuiTableCell-root':{
            padding: '14px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            fontSize: '14px',
            minWidth: '92px'
        }
    },
    inputPoint: {
        font: 'inherit',
        fontSize: '16px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        height: '1.1875em',
        margin: '0',
        display: 'inline-block',
        padding: '6px 7px 7px',
        background: 'none',
        marginLeft: '5px',
        marginRight: '5px',
        width: '80px',
        boxSizing: 'content-box',
        outline: 'none',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid rgba(0, 0, 0, 0.23)'
        },
        '&:focus': {
            border: '1px solid #1565c0',
        }
    },
    requiredPoint: {
        margin: '0px',
        position: 'absolute',
        left: '5px',
        color: 'red',
        fontSize: '12px',
        display: 'block',
        paddingTop: '3px'
    },
    btnPoint: {
        background: '#f2c123',
        borderRadius: '22px',
        marginLeft: '30px',
        width: '115px',
        height: '36px',
        '&:hover': {
            background: '#f2c123',
            '& span': {
                color: '#fff'
            }
        },
        [theme.breakpoints.down("lg")]: {
            marginLeft: '0px',
            display: 'block',
            }
        },

        listMobile: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
              display: "block",
              marginTop: '10px',
              border: '1px solid #eee',
              borderBottom: 'none',
            },
            '& .item-order-mobile': {
                padding: '10px 10px 15px',
                borderBottom: '1px solid #eee',
                '& p': {
                  marginBottom: '5px',
                  display: 'flex',
                  '& .label': {
                      minWidth: '190px',
                      display: 'inline-block',
                      fontWeight: 'bold'
                  },
                  '& .list-name-product': {
                      display: 'flex',
                      flexDirection: 'column'
                  }
                }
            }
          },
          desktopTemplate: {
            display: 'block',
            [theme.breakpoints.down("sm")]: {
                display: "none",
              },
        },
  });
  
  export default style;