import * as types from '../constants';
var initialValues = {
	posts: []
};

export default function reducer(state = initialValues, actions) {
	switch (actions.type) {
		case types.FETCH_POSTS:
			return {
				...state,
				posts: actions.payload,
			};
		default:
			return state;
	}
}
