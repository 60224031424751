import * as types from '../constants';
var modal = {
    logout: false,
    confirm: false,
};

export default function reducer(state = modal, actions) {
	switch (actions.type) {
		case types.SET_MODAL_LOGOUT:
			return {
				...state,
				logout: actions.payload
			};

		default:
			return state;
	}
}
