import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import {
    Grid,
    Hidden,
    IconButton as MuiIconButton,
    withStyles,
  } from "@material-ui/core";
  import { injectIntl } from 'react-intl';
  import { connect } from 'react-redux';
import { compose } from 'redux';
import style from "./style";
import { X as XIcon } from "react-feather";
import styled, { withTheme } from "styled-components";
import { Menu as MenuIcon } from "@material-ui/icons";
const IconButton = styled(MuiIconButton)`
`;
 class DrawerMobile extends Component {
    render() {
      let { onDrawerToggle, classes, mobileOpen } = this.props;
        return (
            <Hidden mdUp>
                  <Grid item>
                    {!mobileOpen ? (
                      <IconButton
                        className={classes.hambuger}
                        aria-label="Open drawer"
                        onClick={onDrawerToggle}
                      >
                        <MenuIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        className={classes.hambuger}
                        aria-label="Open drawer"
                        onClick={onDrawerToggle}
                      >
                        <XIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Hidden>
        )
    }
}
const mapStateToProps = (store) => {
  return {
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  withStyles(style)
)(withTheme(withRouter(injectIntl(DrawerMobile))));