import React, { Component } from 'react'
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import style from "./style";
import { compose } from "redux";
import {
    Grid,
    Hidden,
    withStyles,
  } from "@material-ui/core";
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withTheme } from "styled-components";


  const NavigationItem = ({ label, to, activeOnlyWhenExact, clsName }) => {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact,
    });
  
    return (
      <li className={match ? "active" : ""}>
        <Link to={to}>{label}</Link>
      </li>
    );
  };

 class NavDesktop extends Component {
  
    render() {
        let { intl, classes } = this.props;
        return (
            <Hidden smDown>
            <Grid item>
              <ul className={classes.lstNav}>
                <NavigationItem
                  label={intl.formatMessage({
                    id: "Nav.Purchase",
                  })}
                  to="/chinh-sach-dat-hang"
                />
                <NavigationItem
                  label={intl.formatMessage({
                    id: "Nav.Promotion",
                  })}
                  to="/khuyen-mai"
                />
                <NavigationItem
                  label={intl.formatMessage({
                    id: "Nav.Menu",
                  })}
                  to="/thuc-don"
                />
                 <NavigationItem
                  label={"Blog"}
                  to="/blog"
                />
              </ul>
            </Grid>
          </Hidden>
        )
    }
}

const mapStateToProps = (store) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {
    };
  };
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
    withConnect,
    withStyles(style)
  )(withTheme(withRouter(injectIntl(NavDesktop))));