import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
  withStyles,
  Snackbar,
  Container,
  Grid,
} from "@material-ui/core";
import {
  createDeliveryAddress,
  deleteDeliveryAddress,
  getDeliveryAddress,
  getListDeliveryAddress,
  updateDeliveryAddress,
} from "../../../models/deliveryAddress";
import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
import { listCountry } from "../../../models";
import LeftSidebar from "../components/LeftSidebar";
import DesktopTemplate from "./components/DesktopTemplate";
import MobileTemplate from "./components/MobileTemplate";
import EditAddress from "../../checkout/DeliveryAddress/components/EditAddress";
import DeleteDeliveryAddress from "../../../components/Modal/DeleteDeliveryAddress";
import {
  geocodeByAddress,
} from "react-places-autocomplete";
import LocalStorageService from "../../../services/LocalStorageService";
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../../utills/config";

const localStorageService = LocalStorageService.getService();

class MyAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAddress: [],
      editIndx: -1,
      sbColor: "error",
      sbMessage: "",
      isOpen: false,
      listCountry: [],
      listCountry1: [],
      isNewAddress: false,
      delivery_address: "",
      delivery_person_name: "",
      postcode: "",
      phone: "",
      country_id: 0,
      country_name: "",
      city: "",
      isModalOpen: false,
      isLoading: false,
      isModalDeleteDelivery: false,
      delete_id: 0,
      isSkeleton: false,
      country_selected: null,
      delivery_address_id: 0,
      isAuth: false,
      colorError: false,
      delivery_prefix: ""
    };
  }




  componentDidMount() {
    if(localStorageService.getAccessToken()){
      this.setState({isAuth: true});
    }
    this.fetchListDeliveryAddress();
    this.fetchListCountry();
  }

  fetchListCountry() {
    listCountry({}).then((res) => {
      if (res.success) {
        let country = res._embedded.country;
        let result = [];
        country && Array.isArray(country) && country.length > 0 && country.forEach((value) => {
          let item = {
            value: value.id,
            label: value.name,
          };
          result.push(item);
        });

        this.setState({
          ...this.state,
          listCountry: country && Array.isArray(country) && country.length > 0 ? country : [],
          listCountry1: result
        });
      }
    });
  }

  fetchListDeliveryAddress() {
    this.setState({isSkeleton: true});
    getListDeliveryAddress({}).then((res) => {
      if (res.success) {
        let info = [];
        const addresses = res._embedded.user_delivery_address;
        addresses && Array.isArray(addresses) && addresses.length > 0 && addresses.forEach((element) => {
          let item = {};
          item.id = element.id;
          item.name = element.delivery_person_name
            ? element.delivery_person_name
            : "";
          item.address = element.delivery_address
            ? element.delivery_address
            : "";
          item.postcode = element.postcode ? element.postcode : "";
          item.city = element.city ? element.city : "";
          item.country_name = element.name ? element.name : "";
          item.country_id = element.country_id ? element.country_id : 0;
          item.prefix = element.prefix ? element.prefix : "";
          item.phone = element.phone ? element.phone : "";
          info.push(item);
        });
        this.setState({ ...this.state, listAddress: info, isSkeleton: false });
      }
    });
  }


  handlePlacesApiChange = (address) => {
    this.setState({ delivery_address: address });
  };

  handlePlacesApiSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      if (results && results[0]) {
        let postal_code = "";
        let city = "";
        let country = "";
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              if (results[0].address_components[i].types[j] === "postal_code") {
                postal_code = results[0].address_components[i].long_name;
              }
              if (
                results[0].address_components[i].types[j] ===
                "administrative_area_level_1"
              ) {
                city = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] === "country") {
                country = results[0].address_components[i].long_name;
              }
            }
          }
        }

        let countryFind = this.state.listCountry.find(
          (value) => value.name === country
        );

        let country_selected = this.state.country_selected;
        let country_id = this.state.country_id;
        let prefix = this.state.prefix;
        let country_name = this.state.country_name;
        if (countryFind) {
          country_selected = { value: countryFind.id, label: countryFind.name };
          country_id = countryFind.id;
          country_name = countryFind.name;
          prefix = countryFind.phone_code;
        }

        this.setState({
          delivery_address: results[0].formatted_address,
          postcode: postal_code,
          city: city,
          country_selected: country_selected,
          country_id: country_id,
          country_name: country_name,
          delivery_prefix: prefix,
          colorError: country_selected ? false : true,
        });
      }
    });
  };

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState({[name]: value});
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleAddNew = () => {
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
    } = this.state;
    const req = {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
    };
    createDeliveryAddress(req)
      .then((res) => {
        if (res.success) {
          this.setState({
            isOpen: true,
            sbMessage: "Tạo địa chỉ giao hàng thành công",
            sbColor: "success",
            isLoading: false,
          });
        } else {
          this.setState({
            isOpen: true,
            sbMessage: "Có lỗi xảy ra, vui lòng thử lại",
            sbColor: "error",
            isLoading: false,
          });
        }
      })
      .then(() => {
        this.resetForm();
        this.fetchListDeliveryAddress();
      });
  };

  resetForm = () => {
    this.setState({
      delivery_address: "",
      delivery_person_name: "",
      postcode: "",
      phone: "",
      country_name: "",
      city: "",
      country_id: 0,
      country_selected: null,
      delivery_address_id: 0,
      colorError: false,
      delivery_prefix: ""
    })
  }

  handleDeleteModalOpen = (id) => {
      this.setState({delete_id: id, isModalDeleteDelivery: true})
  }
  handleRemove = (id) => {
    this.setState({isLoading: true});
    deleteDeliveryAddress({id: id}).then((res) => {
      this.setState({
        isOpen: true,
        sbMessage: "Xóa địa chỉ giao hàng thành công",
        sbColor: "success",
        isLoading: false,
        isModalDeleteDelivery: false
      });
    }).then(() => {
      this.resetForm();
      this.fetchListDeliveryAddress();
    });
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
    this.resetForm();
  };

  handleSave = () => {
    if(!this.state.country_selected) {
      this.setState({colorError: true});
      return;
    }
    this.setState({ isLoading: true });
    if (this.state.delivery_address_id > 0) {
      this.updateAddress();
    } else {
      this.addNewAddress();
    }
  };

  updateAddress() {
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
      delivery_address_id,
      delivery_prefix
    } = this.state;
    const req = {
      delivery_person_name: delivery_person_name,
      delivery_address: delivery_address,
      postcode: postcode,
      phone: phone,
      country_id: country_id,
      city: city,
      id: delivery_address_id,
      prefix: delivery_prefix
    };
    updateDeliveryAddress(req)
      .then((res) => {
        if (res.success) {
          this.resetForm();
          this.setState({
            isOpen: true,
            sbMessage: "Tạo địa chỉ giao hàng thành công",
            sbColor: "success",
            isLoading: false,
            isModalOpen: false
          });
        } else {
          this.resetForm();
          this.setState({
            isOpen: true,
            sbMessage: "Có lỗi xảy ra, vui lòng thử lại",
            sbColor: "error",
            isLoading: false,
          });
        }
      })
      .then(() => {
        this.fetchListDeliveryAddress();
      });
  }

  addNewAddress() {
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
      delivery_prefix
    } = this.state;
    const req = {
      delivery_person_name: delivery_person_name,
      delivery_address: delivery_address,
      postcode: postcode,
      phone: phone,
      country_id: country_id,
      city: city,
      prefix: delivery_prefix
    };
    createDeliveryAddress(req)
      .then((res) => {
        if (res.success) {
          this.setState({
            isOpen: true,
            sbMessage: "Tạo địa chỉ giao hàng thành công",
            sbColor: "success",
            isLoading: false,
            isModalOpen: false
          });
        } else {
          this.setState({
            isOpen: true,
            sbMessage: "Có lỗi xảy ra, vui lòng thử lại",
            sbColor: "error",
            isLoading: false,
          });
        }
      })
      .then(() => {
        this.fetchListDeliveryAddress();
      });
  }

  handleEditModalOpen = (id) => {
    this.setState({isLoading: true });
    if (id) {
      this.fetchOneAddress(id);
    } else {
      this.setState({ isModalOpen: true, isLoading: false});
    }
  };

  fetchOneAddress(id) {
    getDeliveryAddress({ id })
      .then((res) => {
        if (res) {
          this.setState({
            delivery_person_name: res.delivery_person_name,
            delivery_address: res.delivery_address,
            postcode: res.postcode,
            delivery_prefix: res.prefix,
            phone: res.phone,
            country_id: res.country_id,
            country_selected: {value: res.country_id, label: res.name},
            delivery_address_id: res.id,
            city: res.city,
          });
        }
      })
      .then(() => {
        this.setState({ isModalOpen: true, isLoading: false});
      });
  }

  onDeleteModalClose = () => {
    this.resetForm();
    this.setState({ isModalDeleteDelivery: false });
  };

  handleCountryChange = (e) => {
    if (e) {
      let countrySelected = this.state.listCountry.find(
        (value) => Number(e.value) === Number(value.id)
      );
      this.setState({
        country_id: e.value,
        country_name: e.label,
        country_selected: { value: e.value, label: e.label },
        delivery_prefix: countrySelected.phone_code,
        colorError: false,
      });
    } else {
      this.setState({
        country_selected: e,
        country_id: 0,
        delivery_prefix: "",
      });
    }
  };

  handleColorErrorChange = () => {
    this.setState({colorError: true})
  }

  render() {
    const { classes } = this.props;
    const {
      listAddress,
      isOpen,
      sbColor,
      sbMessage,
      listCountry,
      delivery_address,
      delivery_person_name,
      postcode,
      phone,
      country_id,
      city,
      isModalOpen,
      isLoading,
      isModalDeleteDelivery,
      delete_id,
      isSkeleton,
      listCountry1,
      country_selected,
      isAuth,
      delivery_prefix,
      colorError
    } = this.state;
    return (
      <React.Fragment>
      <Helmet>
          <title>Địa chỉ giao hàng</title>
          <meta property="og:title" content={"Địa chỉ giao hàng"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Địa chỉ giao hàng"}></meta>
          <meta name="description" content={"Địa chỉ giao hàng"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={1500}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
        <section id="my-address" className={classes.myAddress}>
          <Container>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <LeftSidebar />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={10}>
                <h2>Địa chỉ giao hàng</h2>
                <div className={classes.desktopTemplate}>
                  <DesktopTemplate
                    listAddress={listAddress}
                    isSkeleton={isSkeleton}
                    handleRemove={(id) => this.handleDeleteModalOpen(id)}
                    handleModalOpen={(id) => this.handleEditModalOpen(id)}
                  />
                </div>
                <div>
                  <MobileTemplate
                   listAddress={listAddress}
                   isSkeleton={isSkeleton}
                   handleModalOpen={(id) => this.handleEditModalOpen(id)}
                   handleRemove={(id) => this.handleDeleteModalOpen(id)}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <DeleteDeliveryAddress   
            delete_id={delete_id}
            loading={isLoading}
            isOpenModal={isModalDeleteDelivery}
            handleDeleteDeliveryAddress={(id) => this.handleRemove(id)}
            onClose={() => this.onDeleteModalClose()} />
        <EditAddress
          isModalOpen={isModalOpen}
          isLoading={isLoading}
          delivery_person_name={delivery_person_name}
          postcode={postcode}
          city={city}
          country_id={country_id}
          listCountry={listCountry}
          delivery_address={delivery_address}
          phone={phone}
          isAuth={isAuth}
          listCountry1={listCountry1}
          country_selected={country_selected}
          colorError={colorError}
          handleColorErrorChange={() => this.handleColorErrorChange()}
          handleModalClose={() => this.handleModalClose()}
          handleInputChange={(e) => this.handleInputChange(e)}
          handleSave={() => this.handleSave()}
          handleCountryChange={(e) => this.handleCountryChange(e)}
          handlePlacesApiSelect={(address) => this.handlePlacesApiSelect(address)}
          handlePlacesApiChange = {(address) => this.handlePlacesApiChange(address)}
          delivery_prefix={delivery_prefix}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(MyAddress)));
