import APIFunction from './../services';
import LocalStorageService from "./../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

// login
export const login = (username, password) => {
    return APIFunction.login({ username: username, password: password }).then((result) => {
        if (result.success) {
            localStorageService.setToken(result);
        } else {
            localStorageService.clearToken();
        }
        return result;
    }).catch(error => {
        localStorageService.clearToken();
        return error;
    })
}

// logout
export const logout = (token) => {
    return APIFunction.logout(token).then((result) => {
        localStorageService.clearToken();
        return result;
    }).catch(error => {
        localStorageService.clearToken();
        return error;
    })
}