import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";
import { convertSlugSeo, CURRENCY } from "../../../../utills/constant";
import DefaultImage from "./../../../../assets/images/default.jpg";
// import LazyImage from "../../../../components/LazyImage";

class DesktopTemplate extends Component {
  render() {
    const {
      classes,
      listAddToCart,
      totalQuantity,
      totalCost,
      inventoryError,
      deleteItemCart,
      isLoading,
      idLoading,
      setCheckout,
      handleChangeQuantityProduct,
      handleBlurQuantityProduct,
      handleChangeInputVoucher,
      voucher,
      infoVoucher,
      handleGetVoucher,
      isExchange,
      symbol,
      totalCostExchange,
      rank,
      voucherPoint,
      isAuth,
      rate
    } = this.props;

    return (
      <TableContainer component={'div'} className={classes.desktopCart}>
        <Table className="table-cart">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Sản phẩm</TableCell>
              <TableCell className="tableCell">Giá</TableCell>
              <TableCell className="tableCell">Số lượng</TableCell>
              <TableCell className="tableCell">Tạm tính</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listAddToCart &&
              Array.isArray(listAddToCart) &&
              listAddToCart.length > 0 &&
              listAddToCart.map((value, index) => (
                <TableRow
                  key={index}
                  className={inventoryError &&
                    Array.isArray(inventoryError) &&
                    inventoryError.some(
                      (error) => error.cart_id === value.cart_id
                    ) ? classes.inventoryError : classes.inventoryNotError}
                >
                  <TableCell component="th" scope="row" className="tableCell">
                    <div className="field-name">
                      <div className="thumb">
                      <img onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = DefaultImage;
                }} src={value.image ? value.image : DefaultImage} alt={value.product_name} />
                      </div>
                      <div className="info-product">
                        <p className="name-product">
                          <Link
                            to={
                              value.product_name &&
                              value.product_id &&
                              `/product/${convertSlugSeo(
                                value.product_name
                              )}.id=${value.product_id}`
                            }
                          >
                            {value.product_name}
                          </Link>
                        </p>
                        {value.attribute_name1 && value.attribute_value1 && (
                          <p className="attribute-product">
                            {value.attribute_name1 +
                              " " +
                              value.attribute_value1}
                          </p>
                        )}
                        {value.attribute_name2 && value.attribute_value2 && (
                          <p className="attribute-product">
                            {value.attribute_name2 +
                              " " +
                              value.attribute_value2}
                          </p>
                        )}
                        {value.attribute_name3 && value.attribute_value3 && (
                          <p className="attribute-product">
                            {value.attribute_name3 +
                              " " +
                              value.attribute_value3}
                          </p>
                        )}
                        {value.attribute_name4 && value.attribute_value4 && (
                          <p className="attribute-product">
                            {value.attribute_name4 +
                              " " +
                              value.attribute_value4}
                          </p>
                        )}
                        {value.attribute_name5 && value.attribute_value5 && (
                          <p className="attribute-product">
                            {value.attribute_name5 +
                              " " +
                              value.attribute_value5}
                          </p>
                        )}
                        {typeof value.errorMessage === "string" &&
                          value.errorMessage.length > 0 && (
                            <p className="txtDanger">{value.errorMessage}</p>
                          )}
                           {inventoryError &&
                  Array.isArray(inventoryError) &&
                  inventoryError.length > 0 &&
                  inventoryError.map((error, index) => (
                    <React.Fragment key={index}>
                      {error.cart_id === value.cart_id && error.inventory &&
                      typeof Number(error.inventory) === "number" &&
                      !isNaN(error.inventory) &&
                      Number(error.inventory) > 0 ? (
                        <p style={{color: '#ff0000', marginBottom: '5px'}}>
                          <i>
                          Sản phẩm thay đổi số lượng tồn kho còn {error.inventory}.
                          </i>
                        </p>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  ))
                }
                        <Button
                          className={classes.btnXoa}
                          onClick={() =>
                            deleteItemCart(value.cart_id, value.product_name)
                          }
                        >
                          Xóa
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">
                      {
                        isExchange ? (
                          <React.Fragment>
                            {value.discount_price &&
                              typeof Number(value.discount_price) === "number" &&
                              !isNaN(value.discount_price) &&
                              Number(value.discount_price) > 0 ? (
                                <React.Fragment>
                                  <NumberFormat
                                    value={Number(value.discount_price_exchange)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />
                                  <del className="txtDanger">
                                    <NumberFormat
                                      value={Number(value.price_exchange)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${symbol} `}
                                    />
                                  </del>
                                </React.Fragment>
                              ) : (
                                <NumberFormat
                                  value={Number(value.price_exchange)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={`${symbol} `}
                                />
                              )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          {value.discount_price &&
                            typeof Number(value.discount_price) === "number" &&
                            !isNaN(value.discount_price) &&
                            Number(value.discount_price) > 0 ? (
                              <React.Fragment>
                                <NumberFormat
                                  value={Number(value.discount_price)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                                <del className="txtDanger">
                                  <NumberFormat
                                    value={Number(value.price)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={` ${CURRENCY}`}
                                  />
                                </del>
                              </React.Fragment>
                            ) : (
                              <NumberFormat
                                value={Number(value.price)}
                                displayType={"text"}
                                thousandSeparator={true}
                                suffix={` ${CURRENCY}`}
                              />
                            )}
                          </React.Fragment>
                        )
                      }
                  </TableCell>
                  <TableCell className="tableCell">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <button
                        disabled={Number(value.quantity) <= 1}
                        className="btnQuantity"
                        onClick={() =>
                          handleChangeQuantityProduct({
                            target: {
                              id: value.cart_id,
                              value: Number(value.quantity) - 1,
                            },
                          }, true)
                        }
                      >
                        -
                      </button>
                      <NumberFormat
                        className={classes.inputFormat}
                        style={{
                          width: "82px",
                          height: "42px",
                          border: "1px solid #e7e8e0",
                          textAlign: "center",
                          borderRadius: "0px",
                          outline: "none",
                          boxShadow: "none",
                        }}
                        id={value.cart_id}
                        value={value.quantity}
                        onChange={(e) => handleChangeQuantityProduct(e, true)}
                        onBlur={(e) => handleBlurQuantityProduct(e, true)}
                      />
                      <button
                        disabled={
                          Number(value.quantity) === Number(value.inventory)
                        }
                        className="btnQuantity btnPlus"
                        onClick={() =>
                          handleChangeQuantityProduct({
                            target: {
                              id: value.cart_id,
                              value: Number(value.quantity) + 1,
                            },
                          }, true)
                        }
                      >
                        +
                      </button>
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">
                        {
                          isExchange ? (
                            <NumberFormat
                            value={Number(value.payment_exchange)}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`${symbol} `}
                          />
                          ) : (
                            <NumberFormat
                            value={value.payment}
                            displayType={"text"}
                            thousandSeparator={true}
                            suffix={` ${CURRENCY}`}
                          />
                          )
                        }
                        </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <tfoot>
            <tr>
            <td colSpan="2" style={{ padding: "20px", borderTop: '1px solid #f2f2f0' }}>
              <TextField
                value={voucher}
                inputProps={{
                  maxLength: 20,
                }}
                placeholder="Nhập mã voucher"
                className={classes.inputVoucher}
                name="voucher"
                onChange={(e) => handleChangeInputVoucher(e.target.value)}/>
              <button className={classes.addVoucher} onClick={() => handleGetVoucher()}>
              {isLoading && idLoading === 'voucher' ? (<CircularProgress size={14} color="primary"></CircularProgress>) : (<React.Fragment>Áp dụng</React.Fragment>)}
              </button>
              {infoVoucher && typeof infoVoucher === "object" && Object.keys(infoVoucher).length > 0 && (
                <p style={{marginTop: '10px', fontSize: '14px'}}><i>Mã voucher <span style={{color: '#ff9304'}}>{infoVoucher.code}</span> áp dụng thành công</i></p>
              )}
              </td>
              <td colSpan="2" style={{ padding: "20px", borderTop: '1px solid #f2f2f0' }}>
                {infoVoucher && typeof infoVoucher === "object" && Object.keys(infoVoucher).length > 0 && (
                  <React.Fragment>
                    {
                      isExchange ? (
                          <React.Fragment>
                            {
                             infoVoucher.value_type === "fixed" ?
                             (
                             <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                               {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                               {` ${symbol} ${Number(infoVoucher.value_exchange)}`}</span></p>
                             ) : (
                              <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                                {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                                {` ${symbol} ${Number((Math.round(((Number(infoVoucher.value) / 100) * Number(totalCostExchange)) *100) / 100).toFixed(2))}`}
                                {` (${Number(infoVoucher.value)}%)`}</span></p>
                             )
                            }
                          </React.Fragment>
                      ) : (
                        <p className="priceVoucher">Mã khuyến mãi <span style={{color: '#ff9304'}}>
                          {infoVoucher.code}</span>: <span style={{color: '#ff0000'}}>
                            {
                              infoVoucher.value_type === "fixed" ? (`${Number(infoVoucher.value)} ${CURRENCY}`) : (
                                <React.Fragment>
                                  {`${Number((Math.round(((Number(infoVoucher.value) / 100) * Number(totalCost)) *100) / 100).toFixed(2))} ${CURRENCY}`}
                                  {` (${Number(infoVoucher.value)}%)`}
                                </React.Fragment>
                              )
                            }
                          </span></p>
                      )
                    }
                  </React.Fragment>
                )}
                <React.Fragment>
                {rank && rank.price_discount && Number(rank.price_discount) > 0 && (
                  <React.Fragment>
                     {
                        isExchange ? (
                          <React.Fragment>
                              {
                             rank.price_discount_type === "fixed" ?
                             (
                             <p className="priceVoucher">Khuyến mãi thành viên: <span style={{color: '#ff0000'}}>
                               {` ${symbol} ${Number(rank.price_discount_exchange)}`}</span></p>
                             ) : (
                              <p className="priceVoucher">Khuyến mãi thành viên: <span style={{color: '#ff0000'}}>
                                {` ${symbol} ${Number((Math.round(((Number(rank.price_discount) / 100) * Number(totalCostExchange)) *100) / 100).toFixed(2))}`}
                                {` (${Number(rank.price_discount)}%)`}</span></p>
                             )
                            }
                          </React.Fragment>
                        ) : (<React.Fragment>
                         <p className="priceVoucher">Khuyến mãi thành viên:
                          <span style={{color: '#ff0000'}}>
                           {rank.price_discount_type === "fixed" ? (
                            ` ${Number(rank.price_discount)} ${CURRENCY}`
                           ) : (<React.Fragment>
                            {` ${Number((Math.round(((Number(rank.price_discount) / 100) * Number(totalCost)) *100) / 100).toFixed(2))} ${CURRENCY}`}
                            {` (${Number(rank.price_discount)}%)`}
                           </React.Fragment>)}
                          </span></p>
                        </React.Fragment>)
                      }
                  </React.Fragment>
                )}
                </React.Fragment>
              <p className="totalPrice">
                  <span>
                    Tổng tiền hàng (
                    <NumberFormat
                      value={totalQuantity}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={` `}
                    />
                    món):{" "}
                  </span>
                  {
                    infoVoucher &&
                    typeof infoVoucher === "object"
                    && Object.keys(infoVoucher).length > 0
                    && rank && rank.price_discount
                    && Number(rank.price_discount) > 0 ? (
                        <React.Fragment>
                          {
                            isExchange ? (
                              <React.Fragment>
                                 {
                            infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2)) > 0 ?
                                Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                 infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                              <NumberFormat
                              value={
                                Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) > 0 ?
                                Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />) : (
                                    <React.Fragment>
                                      {
                                       infoVoucher.value_type === "percent" && rank.price_discount_type === "fixed" ? (
                                        <NumberFormat
                              value={
                                Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2)) > 0 ?
                                Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `} />) : (
                              <NumberFormat
                              value={
                                Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) > 0 ?
                                Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                { infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                                    <NumberFormat
                              value={
                                Number((Math.round((Number(totalCost)
                                - Number(infoVoucher.value)
                                - Number(rank.price_discount)) * 100) / 100).toFixed(2)) > 0 ?
                                Number((Math.round((Number(totalCost)
                                - Number(infoVoucher.value)
                                - Number(rank.price_discount)) * 100) / 100).toFixed(2)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                  infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                                     <NumberFormat
                                      value={
                                        Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) > 0 ?
                                        Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={` ${CURRENCY}`}
                                    />) : (<React.Fragment>
                                    {
                                     infoVoucher.value_type === "fixed" && rank.price_discount_type === "percent" ? (
                                     <NumberFormat
                                      value={
                                        Number((Math.round((Number(totalCost)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) * 100) / 100).toFixed(2)) > 0 ?
                                        Number((Math.round((Number(totalCost)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) * 100) / 100).toFixed(2)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={` ${CURRENCY}`}
                                    />) : (
                                      <NumberFormat
                                      value={
                                        Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2)) > 0 ?
                                        Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={` ${CURRENCY}`}
                                    />
                                    )
                                    }
                                  </React.Fragment>)
                                }
                              </React.Fragment>
                            )}
                              </React.Fragment>
                            )
                          }
                        </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                         (!infoVoucher ||
                         typeof infoVoucher !== "object"||
                         Object.keys(infoVoucher).length === 0)
                         && (!rank || !rank.price_discount ||
                         Number(rank.price_discount) === 0) ? (<React.Fragment>
                            {
                            isExchange ? (
                              <NumberFormat
                              value={Number(totalCostExchange)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />
                            ) : (
                              <NumberFormat
                              value={Number(totalCost)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                            )
                          }
                        </React.Fragment>) : (<React.Fragment>
                            {
                             (infoVoucher ||
                              typeof infoVoucher === "object"||
                              Object.keys(infoVoucher).length > 0)
                              && (!rank || !rank.price_discount ||
                              Number(rank.price_discount) === 0) ? (
                                <React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       infoVoucher.value_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.round((Number(totalCostExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) > 0 ?
                                      Number((Math.round((Number(totalCostExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />) : (
                                  <NumberFormat
                                    value={
                                      Number((Math.round((Number(totalCostExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2)) > 0 ?
                                      Number((Math.round((Number(totalCostExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        infoVoucher.value_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.round((Number(totalCost)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) > 0 ?
                                              Number((Math.round((Number(totalCost)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />) : (<NumberFormat
                                            value={
                                              Number((Math.round((Number(totalCost)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2)) > 0 ?
                                              Number((Math.round((Number(totalCost)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                              ) : (<React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       rank.price_discount_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.round((Number(totalCostExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) > 0 ?
                                      Number((Math.round((Number(totalCostExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />) : (
                                  <NumberFormat
                                    value={
                                      Number((Math.round((Number(totalCostExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2)) > 0 ?
                                      Number((Math.round((Number(totalCostExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        rank.price_discount_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.round((Number(totalCost)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) > 0 ?
                                              Number((Math.round((Number(totalCost)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />) : (
                                          <NumberFormat
                                            value={
                                              Number((Math.round((Number(totalCost)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2)) > 0 ?
                                              Number((Math.round((Number(totalCost)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>)
                            }
                        </React.Fragment>)
                        }
                      </React.Fragment>
                    )
                  }
                </p>
                {isAuth && (<p className="priceVoucherPoint">
                  Điểm tích lũy thành viên: {
                    infoVoucher &&
                    typeof infoVoucher === "object"
                    && Object.keys(infoVoucher).length > 0
                    && rank && rank.price_discount
                    && Number(rank.price_discount) > 0 ? (
                        <React.Fragment>
                          {
                            isExchange ? (
                          <React.Fragment>
                                 {
                            infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                 infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1 )) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1 )) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />) : (<React.Fragment>
                              {infoVoucher.value_type === "percent" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))
                                - Number(rank.price_discount_exchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`} />) : (
                              <NumberFormat
                              value={
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - (Number(rank.price_discount) / 100) * Number(totalCostExchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor((Math.floor(Number((Math.round((Number(totalCostExchange)
                                - Number(infoVoucher.value_exchange)
                                - (Number(rank.price_discount) / 100) * Number(totalCostExchange)) *100) / 100).toFixed(2))/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                          </React.Fragment>
                            ) : (<React.Fragment>{ 
                              infoVoucher.value_type === "fixed" && rank.price_discount_type === "fixed" ? (
                              <NumberFormat
                              value={
                                Number((Math.floor(Number((Math.round((Number(totalCost)
                                - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Number((Math.round((Number(totalCost)
                                - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <React.Fragment>
                                {
                                  infoVoucher.value_type === "percent" && rank.price_discount_type === "percent" ? (
                                     <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - Number(infoVoucher.value) - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />) : (<React.Fragment>
                                    {
                                     infoVoucher.value_type === "fixed" && rank.price_discount_type === "percent" ? ( <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - Number(infoVoucher.value)
                                        - ((Number(rank.price_discount) / 100) * Number(totalCost))) * 100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />) : (
                                      <NumberFormat
                                      value={
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                        Number((Math.floor(Number((Math.round((Number(totalCost)
                                        - ((Number(infoVoucher.value) / 100) * Number(totalCost))
                                        - Number(rank.price_discount)) *100) / 100).toFixed(2))/10))
                                        * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                      }
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`+`}
                                    />
                                    )
                                    }
                                  </React.Fragment>)
                                }
                              </React.Fragment>
                            )}
                              </React.Fragment>
                            )
                          }
                        </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {
                         (!infoVoucher ||
                         typeof infoVoucher !== "object"||
                         Object.keys(infoVoucher).length === 0)
                         && (!rank || !rank.price_discount ||
                         Number(rank.price_discount) === 0) ? (<React.Fragment>
                            {
                            isExchange ? (
                              <NumberFormat
                              value={
                                Number((Math.floor(Math.floor(Math.floor(Number(totalCostExchange)/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Math.floor(Math.floor(Number(totalCostExchange)/rate))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            ) : (
                              <NumberFormat
                              value={
                                Number((Math.floor(Math.floor(Number(totalCost))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                Number((Math.floor(Math.floor(Number(totalCost))/10))
                                * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`+`}
                            />
                            )
                          }
                        </React.Fragment>) : (<React.Fragment>
                            {
                             (infoVoucher ||
                              typeof infoVoucher === "object"||
                              Object.keys(infoVoucher).length > 0)
                              && (!rank || !rank.price_discount ||
                              Number(rank.price_discount) === 0) ? (
                                <React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       infoVoucher.value_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.floor((Number(Math.floor(Number((Math.round((Number(totalCostExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor((Number(Math.floor(Number((Math.round((Number(totalCostExchange)
                                      - ((Number(infoVoucher.value) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2)))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />) : (<NumberFormat
                                    value={
                                      Number((Math.floor((Number(Math.floor((Number((Math.round((Number(totalCostExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2))))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor((Number(Math.floor((Number((Math.round((Number(totalCostExchange)
                                      - Number(infoVoucher.value_exchange)) * 100) / 100).toFixed(2))))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        infoVoucher.value_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCost))) *100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - ((Number(infoVoucher.value) / 100) * Number(totalCost))) *100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />) : (<NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - Number(infoVoucher.value)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                              ) : (<React.Fragment>
                                {
                                  isExchange ? (
                                    <React.Fragment>
                                       {
                                       rank.price_discount_type === "percent" ? (
                                    <NumberFormat
                                    value={
                                      Number((Math.floor(Number(Math.floor(Number((Math.round((Number(totalCostExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor(Number(Math.floor(Number((Math.round((Number(totalCostExchange)
                                      - ((Number(rank.price_discount) / 100) * Number(totalCostExchange))) *100) / 100).toFixed(2))/rate))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />) : (<NumberFormat
                                    value={
                                      Number((Math.floor(Number(Math.floor(Number(Number((Math.round((Number(totalCostExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                      Number((Math.floor(Number(Math.floor(Number(Number((Math.round((Number(totalCostExchange)
                                      - Number(rank.price_discount_exchange)) * 100) / 100).toFixed(2))/rate)))/10))
                                      * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`+`}
                                  />)
                                      }
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                        rank.price_discount_type === "percent" ? (
                                           <NumberFormat
                                            value={
                                              Number((Math.floor((Number((Math.round((Number(totalCost)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor((Number((Math.round((Number(totalCost)
                                              - ((Number(rank.price_discount) / 100) * Number(totalCost))) *100) / 100).toFixed(2)))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />) : (<NumberFormat
                                            value={
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) > 0 ?
                                              Number((Math.floor(Number((Math.round((Number(totalCost)
                                              - Number(rank.price_discount)) * 100) / 100).toFixed(2))/10))
                                              * (voucherPoint && Number(voucherPoint.value) > 0 ? Number(voucherPoint.value) : 1)) : 0
                                            }
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`+`}
                                          />)
                                      }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>)
                            }
                        </React.Fragment>)
                        }
                      </React.Fragment>
                    )
                  }
                </p>)}
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{ padding: "20px", borderTop: '1px solid #f2f2f0' }}>
              <div className="boxFooterTable">
                  <Link to="/" className="btn-secondary">
                    Tiếp tục mua hàng
                  </Link>
                  <Button
                    onClick={() => setCheckout()}
                    className={classes.btnCheckout}
                  >
                   {isLoading && idLoading === 0 ? (<CircularProgress size={14} color="primary"></CircularProgress>) : (<React.Fragment>Đặt hàng</React.Fragment>)}
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownDispatch) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DesktopTemplate)));
