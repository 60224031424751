import React, { Component } from 'react';
import {  withStyles} from "@material-ui/core";
import style from '../../style';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import styled from 'styled-components';
import Skeleton from "@material-ui/lab/Skeleton";
import { CURRENCY } from '../../../../../utills/constant';
import NumberFormat from 'react-number-format';
const BtnMaDonHang = styled.span`
    background-color: #fff!important;
      color: #f2c123;
      font-weight: 500;
      &:hover{
        cursor: pointer;
      }
`;

class MobileTemplate extends Component {
    render() {
        const { listOrder, getDetailOrder, isSkeleton, handleReCheckout, symbol, classes } = this.props;
        return (
            <React.Fragment>
               {!isSkeleton ? (<React.Fragment>
                    {
                     listOrder && Array.isArray(listOrder) && listOrder.length > 0 ? (
                        <React.Fragment>
                           {listOrder.map((row) => (
                  <div className="item-order-mobile" key={row.order_no}>
                    <p>
                      <span className="label">Ngày đặt hàng:</span>{" "}
                      <span>{row.created_at}</span>
                    </p>
                    <p>
                      <span className="label">Mã đơn hàng:</span>{" "}
                      <BtnMaDonHang onClick={() => getDetailOrder(row)}>
                        {row.order_no}
                      </BtnMaDonHang>
                    </p>
                    <p>
                      <span className="label">Tên người nhận: </span>
                      <span>{row.receiver_name}</span>
                    </p>
                    <p>
                      <span className="label">Địa chỉ nhận hàng:</span>{" "}
                      {Number(row.delivery_service) === 2 ? ('Lấy hàng tại Shop') : (
                        <React.Fragment>
                        <span>{row.delivery_address}</span>
                        </React.Fragment>
                      ) }
                    </p>
                    <p>
                      <span className="label">Tổng tiền thanh toán: </span>
                      <span>
                      <NumberFormat
                          value={row.total_payment_eur ? Number(row.total_payment_eur) : Number(row.total_payment)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!row.symbol ? ` ${CURRENCY}` : ""}
                          prefix={row.symbol ? `${row.symbol} ` : ""}
                        />
                        </span>
                    </p>
                    <p>
                      <span className="label">Tổng sản phẩm: </span>
                      <span className="list-name-product">
                        {row.total_products} sản phẩm
                      </span>
                    </p>
                    <p>
                      <span className="label">Trạng thái:</span>{" "}
                      {row.status_name}
                      {/* {row.status_name && row.status === 6 ?
                    (<button className={classes.reCheckout} onClick={() => handleReCheckout(row)}>
                      {row.status_name}
                    </button>) : (
                      <React.Fragment>{row.status_name}</React.Fragment>
                    )} */}
                    </p>
                  </div>
                ))}
                        </React.Fragment>
                      ) : (
                        <h3>Hiện chưa có đơn hàng.</h3>
                      )
                    }
               </React.Fragment>) : (
                 <React.Fragment>
                   {[1,2,3,4,5,6,7,8,9,10].map((row) => (
                  <div className="item-order-mobile" key={row}>
                    {[1,2,3,4,5,6].map((value) => (
                       <p key={value}>
                       <Skeleton className="label" variant="text" width={155} style={{marginRight: '20px'}} />
                       <Skeleton variant="text" width={180} />
                     </p>
                    ))}
                  </div>
                ))}
                 </React.Fragment>
               )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch, props) => {
    return {};
  };
  
  const withConnect = connect(mapStateToProps, mapDispatchToProps);
  
  export default compose(
    withStyles(style),
    withConnect
  )(withRouter(injectIntl(MobileTemplate)));