import { Button, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import LocalStorageService from "./../../services/LocalStorageService";
import style from "./style";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container, Grid, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Breadcrumb from "../../components/Breadcrumb";
import NotFoundProduct from "../../components/NotFoundProduct";
import DesktopTemplate from "./components/DesktopTemplate";
import MobileTemplate from "./components/MobileTemplate";
import { addCart, checkProductCart, getListCart, getVoucherInfo, updateCart } from "../../models/cart";
import DeleteCart from "../../components/Modal/DeleteCart";
import SkeletonDesktop from "./components/SkeletonDesktop";
import SkeletonMobile from "./components/SkeletonMobile";
import AlertMessage from "../../components/UI/AlertMessage";
import { Helmet } from 'react-helmet';
import NumberFormat from "react-number-format";
import { convertSlugSeo, CURRENCY } from "../../utills/constant";
import { queryUserInfo } from "../../models";
import { apiDomain } from "../../utills/config";

const localStorageService = LocalStorageService.getService();

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSkeleton: true,
      isAuth: false,
      listAddToCart: [],
      totalQuantity: 0,
      totalCost: 0,
      totalDiscountPrice: 0,
      isOpenModal: false,
      cart_id: 0,
      product_name: "",
      isAlert: false,
      sbColor: "error",
      sbMessage: "",
      inventoryError: [],
      priceError: [],
      discountError: [],
      notFoundError: [],
      outOfStockError: [],
      isLoading: false,
      idLoading: 0,
      isStartCheckCart: false,
      voucher: "",
      infoVoucher: null,
      errorCheckout: "",
      isOpenDialog: false,
      isChangeQuantity: false,
      loadingBtnDelete: false,
      isCheckedCheckout: false,
      isExchange: false,
      symbol: "",
      rate: 0,
      margin: 0,
      margin_type: "",
      totalCostExchange: 0,
      rank: null,
      isReAddToCart: true
    };
  }

  async componentDidMount() {
    this.setState({ isSkeleton: true });
    localStorageService.clearVoucher();
    localStorageService.clearVoucherPoint();
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }
    let token = localStorageService.getAccessToken();
    if (typeof token === "string") {
      await this.setState({ isAuth: true });
      this.getVoucherPoint();
      await queryUserInfo({ id: 1 }).then(async(res) => {
        if (res.success) {
          let exchange = null;
          if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
            exchange = res.exchange_rate[0];
            await this.setState({
              isExchange: true,
              rate: exchange.rate,
              symbol: exchange.symbol,
              margin: exchange.margin,
              margin_type: exchange.margin_type
            });
            if(res.rank && typeof res.rank === "object" && Object.keys(res.rank).length > 0){
              res.rank.price_discount_exchange =
              Number((Math.round((Number(res.rank.price_discount)*Number(exchange.rate)) * 100) / 100).toFixed(2));
            }
          }
          await this.setState({
            rank: res.rank ? res.rank : null
          });
        }
      });
    }else{
      let listProducts = [];
      let listImages = [];

      let Jproducts = localStorageService.getCart();
      let products = JSON.parse(Jproducts);

      if(products && Array.isArray(products) && products.length > 0) {
        products.forEach((x) => {
          let item_img = {
            cart_id: x.cart_id,
            image: x.image
          }
          listImages.push(item_img);

          let item = {
            cart_id: x.cart_id,
            product_id: x.product_id,
            product_name: x.product_name,
            attribute_name1: x.attribute_name1,
            attribute_name2: x.attribute_name2,
            attribute_name3: x.attribute_name3,
            attribute_name4: x.attribute_name4,
            attribute_name5: x.attribute_name5,
            attribute_value1: x.attribute_value1,
            attribute_value2: x.attribute_value2,
            attribute_value3: x.attribute_value3,
            attribute_value4: x.attribute_value4,
            attribute_value5: x.attribute_value5,
            product_attribute_value_id1: x.product_attribute_value_id1,
            product_attribute_value_id2: x.product_attribute_value_id2,
            product_attribute_value_id3: x.product_attribute_value_id3,
            product_attribute_value_id4: x.product_attribute_value_id4,
            product_attribute_value_id5: x.product_attribute_value_id5,
            price: x.price,
            discount_price: x.discount_price,
            quantity: x.quantity,
            product_code: x.product_code,
            sku_code: x.sku_code,
            image: x.image
          }
          listProducts.push(item);
        });

        await checkProductCart({type: 4, products: listProducts}).then(async(res) => {
            if(res.success) {
              this.props.clearListCart();
              localStorageService.clearCart();
              let exchange = null;
              if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1){
                exchange = res.exchange_rate[0];
                await this.setState({
                  isExchange: true,
                  rate: exchange.rate,
                  symbol: exchange.symbol,
                  margin: exchange.margin,
                  margin_type: exchange.margin_type
                });
              }
              if(res.cart && Array.isArray(res.cart) && res.cart.length > 0){
                res.cart.forEach((value) => {
                  let item = {
                    cart_id: value.cart_id,
                    inventory: Number(value.inventory),
                    image: value.image,
                    attribute_name1: value.attribute_name1,
                    attribute_name2: value.attribute_name2,
                    attribute_name3: value.attribute_name3,
                    attribute_name4: value.attribute_name4,
                    attribute_name5: value.attribute_name5,
                    attribute_value1: value.attribute_value1,
                    attribute_value2: value.attribute_value2,
                    attribute_value3: value.attribute_value3,
                    attribute_value4: value.attribute_value4,
                    attribute_value5: value.attribute_value5,
                    product_attribute_value_id1: value.product_attribute_value_id1,
                    product_attribute_value_id2: value.product_attribute_value_id2,
                    product_attribute_value_id3: value.product_attribute_value_id3,
                    product_attribute_value_id4: value.product_attribute_value_id4,
                    product_attribute_value_id5: value.product_attribute_value_id5,
                    product_code: value.product_code,
                    product_id: value.product_id,
                    product_name: value.product_name,
                    quantity: Number(value.quantity),
                    sku_code: value.sku_code,
                    discount_price: (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number(value.old_discount_price) : 0,
                    price: (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number(value.old_price) : 0,
                  }
                  if(Number(value.inventory) < Number(value.quantity)){
                    item.error_inventory = true;
                  }
                  if(exchange && typeof exchange === "object") {
                      item.isExchange = true;
                      item.symbol = exchange.symbol;
                      item.margin = exchange.margin;
                      item.rate = exchange.rate;
                      item.margin_type = exchange.margin_type;
                      if(exchange.margin_type === "increase") {
                        item.discount_price_exchange = (value.old_discount_price &&
                          typeof Number(value.old_discount_price) === "number"
                          && !isNaN(value.old_discount_price)
                          && Number(value.old_discount_price) > 0) ?
                          Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                        item.price_exchange = (value.old_price
                          && typeof Number(value.old_price) === "number"
                          && !isNaN(value.old_price)
                          && Number(value.old_price) > 0) ?
                          Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      }else{
                        item.discount_price_exchange = (value.old_discount_price &&
                          typeof Number(value.old_discount_price) === "number"
                          && !isNaN(value.old_discount_price)
                          && Number(value.old_discount_price) > 0) ?
                          Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                        item.price_exchange = (value.old_price
                          && typeof Number(value.old_price) === "number"
                          && !isNaN(value.old_price)
                          && Number(value.old_price) > 0) ?
                          Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                      }
                  }
                  this.props.addToCart(item);
                });
              }
            }
        });
      }
    }
    this.getListProductCart();
  }

  getVoucherPoint = () => {
    getVoucherInfo({value_type: 'point'}).then((res) => {
      if(res.success) {
        if(res._embedded.voucher && Array.isArray(res._embedded.voucher) && res._embedded.voucher.length === 1) {
          let voucher = res._embedded.voucher[0];
          let voucherPoint = {
            id: voucher.id,
            name: voucher.name,
            start_date: voucher.start_date,
            end_date: voucher.end_date,
            value: voucher.value,
            value_type: voucher.value_type
          }
          this.setState({
            voucherPoint: voucherPoint
          });
          let cartInfo = JSON.stringify(voucherPoint);
          localStorageService.setVoucherPoint(cartInfo);
        }
      }
    })
  }

  getListProductCart = () => {
    let Jproducts = localStorageService.getCart();
    let products = JSON.parse(Jproducts);
    if(products && Array.isArray(products) && products.length > 0){
      this.handleCalculatorPrice(products);
    }else{
     if(products && Array.isArray(products) && products.length === 0){
      this.setState({
        listAddToCart: [],
        isSkeleton: false
      });
     }else{
      this.setState({isSkeleton: false})
     }
    }
  };

  handleCalculatorPrice = async (products) => {
    let {isChangeQuantity, listAddToCart} = this.state;
    let countQuantity = 0;
    let countTotalCost = 0;
    let countTotalCostExchange = 0;
    if(isChangeQuantity) {
      // if(listAddToCart.length > 0 && products.length > 0){
      let rs = [];
      let list_cart_id = listAddToCart.map((value) => (
          value.cart_id
      ));
      products.forEach((value) => {
          if(list_cart_id.includes((value.cart_id))){
            rs.push(value);
          }
      });
      rs.forEach(async (value, index) => {
        if(typeof value.notifyMessage === "string" && value.notifyMessage.length > 0){
          this.setState({isAlert: true, sbColor: "error", sbMessage: value.notifyMessage})
        }
        countQuantity += Number(value.quantity);
        countTotalCost += Number(value.payment);
        if(value.isExchange) {
          countTotalCostExchange += value.payment_exchange;
        }
        // if(index === (rs.length -1) && !this.state.isStartCheckCart){
        //   await this.setState({isStartCheckCart: true});
        // }
      });
      await this.setState({
        listAddToCart: rs,
        isChangeQuantity: false,
        totalQuantity: Number(countQuantity),
        totalCost: Number((Math.round(countTotalCost * 100) / 100).toFixed(2)),
        totalCostExchange: Number((Math.round(countTotalCostExchange * 100) / 100).toFixed(2)),
      });
    // }
    }else{
      let isCheckCart = false;
      products.forEach(async (value, index) => {
        if(typeof value.notifyMessage === "string" && value.notifyMessage.length > 0){
          this.setState({isAlert: true, sbColor: "error", sbMessage: value.notifyMessage})
        }
        countQuantity += Number(value.quantity);
        countTotalCost += Number(value.payment);
        if(value.isExchange) {
          countTotalCostExchange += value.payment_exchange;
        }
        if(index === (products.length -1) && !this.state.isStartCheckCart){
          isCheckCart = true;
        }
      });
      await this.setState({
        listAddToCart: products,
        totalQuantity: Number(countQuantity),
        totalCost: Number((Math.round(countTotalCost * 100) / 100).toFixed(2)),
        totalCostExchange: Number((Math.round(countTotalCostExchange * 100) / 100).toFixed(2)),
      });
      if(isCheckCart) {
        await this.setState({isStartCheckCart: true});
      }
    }
  }

  setCheckout = async () => {
    this.setState({idLoading: 0, isLoading: true});
    let { priceError, discountError } = this.state;
    let list_to_del = [];
    priceError.forEach((value) => {
      list_to_del.push(value.cart_id);
    });

    discountError.forEach((value) => {
      list_to_del.push(value.cart_id);
    });

    await this.setState({priceError: [], discountError: []});
    await list_to_del.forEach(async (value) => {
      this.props.deleteItemCart(value);
      if (this.state.isAuth) {
        let params = {
          id: value,
          type: 1,
          quantity: 0,
        };
        await updateCart(params).then(() => {});
      }
    });

    if(list_to_del.length > 0) {
      setTimeout(() => {
        this.handleCheckCartBeforeCheckout().then((res) => {
          if(res === true) {
            localStorageService.clearDeliveryInfo();
            this.props.history.push("/delivery-address");
          }
        });
      }, 1500);
    }else{
      this.handleCheckCartBeforeCheckout().then((res) => {
        if(res === true) {
          localStorageService.clearDeliveryInfo();
          this.props.history.push("/delivery-address");
        }
      });
    }
  }

  handleCheckCartBeforeCheckout = async () => {
    let isValid = false;
    this.setState({isLoading: true, idLoading: 0, isChangeQuantity: false});
    let { isAuth, isCheckedCheckout } = this.state;
    let listProducts = [];
    let listImages = [];
    JSON.parse(localStorageService.getCart()).forEach((x) => {
      let item_img = {
        cart_id: x.cart_id,
        image: x.image
      }
      listImages.push(item_img);

      let item = {
        cart_id: x.cart_id,
        product_id: x.product_id,
        product_name: x.product_name,
        product_attribute_value_id1: x.product_attribute_value_id1,
        product_attribute_value_id2: x.product_attribute_value_id2,
        product_attribute_value_id3: x.product_attribute_value_id3,
        product_attribute_value_id4: x.product_attribute_value_id4,
        product_attribute_value_id5: x.product_attribute_value_id5,
        attribute_name1 : x.attribute_name1,
        attribute_name2 : x.attribute_name2,
        attribute_name3 : x.attribute_name3,
        attribute_name4 : x.attribute_name4,
        attribute_name5 : x.attribute_name5,
        attribute_value1 : x.attribute_value1,
        attribute_value2 : x.attribute_value2,
        attribute_value3 : x.attribute_value3,
        attribute_value4 : x.attribute_value4,
        attribute_value5 : x.attribute_value5,
        price: x.price,
        discount_price: x.discount_price,
        quantity: x.quantity,
        product_code: x.product_code,
        sku_code: x.sku_code
      }
      listProducts.push(item);
    });
    const params = {
      type: 4,
      products: listProducts
    };

    if(isCheckedCheckout){
      await checkProductCart(params).then(async (res) => {
        if(res.success){
          let cart = res.cart;
          if(cart && Array.isArray(cart) && cart.length > 0){
            cart.forEach(async (value) => {
              listImages.forEach((img) => {
                if(img.cart_id === value.cart_id){
                  value.image = img.image
                }
              });
              if(value.error_inventory || (value.inventory && Number(value.inventory) === 0)){
                if(value.inventory && typeof Number(value.inventory) === "number" && !isNaN(value.inventory) && Number(value.inventory) > 0){
                  let target = {target: {id: value.cart_id, value: value.inventory}}
                  this.props.updateInventoryItemCart({cart_id: value.cart_id, inventory: value.inventory});
                  this.handleChangeQuantityProduct(target);
                }else{
                  if(value.cart_id){
                    if (isAuth) {
                      let params = {
                        id: value.cart_id,
                        type: 1,
                        quantity: 0,
                      };
                      await updateCart(params).then(() => {});
                    }
                    this.props.deleteItemCart(value.cart_id);
                  }
                }
              }else if(value.error_price) {
                  if(value.cart_id){
                    if (isAuth) {
                      let params = {
                        id: value.cart_id,
                        type: 1,
                        quantity: 0,
                      };
                      await updateCart(params).then(() => {});
                    }
                    this.props.deleteItemCart(value.cart_id);
                  }
              }else if(value.error_discount){
                if(value.cart_id){
                  if (isAuth) {
                    let params = {
                      id: value.cart_id,
                      type: 1,
                      quantity: 0,
                    };
                    await updateCart(params).then(() => {});
                  }
                  this.props.deleteItemCart(value.cart_id);
                }
              } else if(value.error) {
                if(value.cart_id){
                  if (isAuth) {
                    let params = {
                      id: value.cart_id,
                      type: 1,
                      quantity: 0,
                    };
                    await updateCart(params).then(() => {});
                  }
                  this.props.deleteItemCart(value.cart_id);
                }
              }else{
                this.props.updateInventoryItemCart({cart_id: value.cart_id, inventory: value.inventory});
              }
            });
          }
          await this.setState({
            isLoading: false,
            isSkeleton: false,
            isCheckedCheckout: true
          });
          isValid = true;
        }
      });
    } else {
      await checkProductCart(params).then(async (res) => {
        if(res.success){
          let cart = res.cart;
          let error_inventory = [];
          let error_price = [];
          let error_discount = [];
          let error_notfound = [];
          let error_out_of_stock = [];
          let list_error_price_cart_id = [];
          if(cart && Array.isArray(cart) && cart.length > 0){
            cart.forEach(async (value) => {
              listImages.forEach((img) => {
                if(img.cart_id === value.cart_id){
                  value.image = img.image
                }
              });
              if(value.error_inventory || (value.inventory && Number(value.inventory) === 0)){
                if(value.inventory && typeof Number(value.inventory) === "number" && !isNaN(value.inventory) && Number(value.inventory) > 0){
                  value.error_inventory = true;
                  error_inventory.push(value);
                  let target = {target: {id: value.cart_id, value: value.inventory}}
                  this.props.updateInventoryItemCart({cart_id: value.cart_id, inventory: value.inventory});
                  this.handleChangeQuantityProduct(target);
                }
                else{
                  error_out_of_stock.push({product_name: value.product_name});
                  list_error_price_cart_id.push(value.cart_id);
                  if(value.cart_id){
                    if (isAuth) {
                      let params = {
                        id: value.cart_id,
                        type: 1,
                        quantity: 0,
                      };
                      await updateCart(params).then(() => {});
                    }
                    this.props.deleteItemCart(value.cart_id);
                  }
                }
              }else if(value.error_price) {
                error_price.push(value);
                list_error_price_cart_id.push(value.cart_id);
              }else if(value.error_discount){
                error_discount.push(value);
                list_error_price_cart_id.push(value.cart_id);
              } else if(value.error) {
                error_notfound.push({product_name: value.product_name});
                list_error_price_cart_id.push(value.cart_id);
                if(value.cart_id){
                  if (isAuth) {
                    let params = {
                      id: value.cart_id,
                      type: 1,
                      quantity: 0,
                    };
                    await updateCart(params).then(() => {});
                  }
                  this.props.deleteItemCart(value.cart_id);
                }
              }
            });
  
            if(error_inventory.length > 1) {
              error_inventory = error_inventory.sort(function(a,b) {
                return Number(a.inventory) - Number(b.inventory);
              });
            }
          }
  
          let listCarts = [...this.state.listAddToCart];
          let carts = [];
          listCarts.forEach((c) => {
            if(!list_error_price_cart_id.includes(c.cart_id)){
              carts.push(c);
            }
          });
          this.handleCalculatorPrice(carts);
          await this.setState({
            listAddToCart: carts,
            inventoryError: error_inventory,
            priceError: error_price,
            discountError: error_discount,
            notFoundError: error_notfound,
            outOfStockError: error_out_of_stock,
            isLoading: false,
            isSkeleton: false,
            isCheckedCheckout: true
          });
  
          if(error_discount.length === 0 &&
            error_price.length === 0 &&
            error_inventory.length === 0 &&
            error_notfound.length === 0 &&
            error_out_of_stock.length === 0){
            isValid = true;
          }
        }
      });
    }
     return isValid;
  }

  handleCheckCart = async () => {
    // let isValid = false;
    this.setState({isLoading: true, idLoading: 0, isChangeQuantity: false, isReAddToCart: true});
    let { listAddToCart, isAuth } = this.state;
    let listProducts = [];
    let listImages = [];
    listAddToCart.forEach((x) => {
      let item_img = {
        cart_id: x.cart_id,
        image: x.image
      }
      listImages.push(item_img);

      let item = {
        cart_id: x.cart_id,
        product_id: x.product_id,
        product_name: x.product_name,
        product_attribute_value_id1: x.product_attribute_value_id1,
        product_attribute_value_id2: x.product_attribute_value_id2,
        product_attribute_value_id3: x.product_attribute_value_id3,
        product_attribute_value_id4: x.product_attribute_value_id4,
        product_attribute_value_id5: x.product_attribute_value_id5,
        attribute_name1 : x.attribute_name1,
        attribute_name2 : x.attribute_name2,
        attribute_name3 : x.attribute_name3,
        attribute_name4 : x.attribute_name4,
        attribute_name5 : x.attribute_name5,
        attribute_value1 : x.attribute_value1,
        attribute_value2 : x.attribute_value2,
        attribute_value3 : x.attribute_value3,
        attribute_value4 : x.attribute_value4,
        attribute_value5 : x.attribute_value5,
        price: x.price,
        discount_price: x.discount_price,
        quantity: x.quantity,
        product_code: x.product_code,
        sku_code: x.sku_code
      }
      listProducts.push(item);
    });
    const params = {
      type: 4,
      products: listProducts
    };
    await checkProductCart(params).then(async (res) => {
      if(res.success){
        let cart = res.cart;
        let error_inventory = [];
        let error_price = [];
        let error_discount = [];
        let error_notfound = [];
        let error_out_of_stock = [];
        let list_error_price_cart_id = [];
        if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1){
          let exchange = res.exchange_rate[0];
          await this.setState({
            isExchange: true,
            rate: exchange.rate,
            symbol: exchange.symbol,
            margin: exchange.margin,
            margin_type: exchange.margin_type
          });
        }
        if(cart && Array.isArray(cart) && cart.length > 0){
          cart.forEach(async (value) => {
            listImages.forEach((img) => {
              if(img.cart_id === value.cart_id){
                value.image = img.image
              }
            });
            if(value.error_inventory || (Number(value.inventory) === 0)){
              if(value.inventory && typeof Number(value.inventory) === "number" && !isNaN(value.inventory) && Number(value.inventory) > 0){
                value.error_inventory = true;
                error_inventory.push(value);
                let target = {target: {id: value.cart_id, value: value.inventory}}
                this.props.updateInventoryItemCart({cart_id: value.cart_id, inventory: value.inventory});
                this.handleChangeQuantityProduct(target);
              }else{
                error_out_of_stock.push({product_name: value.product_name});
                list_error_price_cart_id.push(value.cart_id);
                if(value.cart_id){
                  if (isAuth) {
                    let params = {
                      id: value.cart_id,
                      type: 1,
                      quantity: 0,
                    };
                    await updateCart(params).then(() => {});
                  }
                  this.props.deleteItemCart(value.cart_id);
                }
              }
            }else if(value.error_price) {
              error_price.push(value);
              list_error_price_cart_id.push(value.cart_id);
            }else if(value.error_discount){
              error_discount.push(value);
              list_error_price_cart_id.push(value.cart_id);
            } else if(value.error) {
              error_notfound.push({product_name: value.product_name});
              list_error_price_cart_id.push(value.cart_id);
              if(value.cart_id){
                if (isAuth) {
                  let params = {
                    id: value.cart_id,
                    type: 1,
                    quantity: 0,
                  };
                  await updateCart(params).then(() => {});
                }
                this.props.deleteItemCart(value.cart_id);
              }
            }else{
              this.props.updateInventoryItemCart({cart_id: value.cart_id, inventory: value.inventory});
            }
          });

          if(error_inventory.length > 1) {
            error_inventory = error_inventory.sort(function(a,b) {
              return Number(a.inventory) - Number(b.inventory);
            });
          }
        }

        let listCarts = [...this.state.listAddToCart];
        let carts = [];
          listCarts.forEach((c) => {
            if(!list_error_price_cart_id.includes(c.cart_id)){
              carts.push(c);
            }
          });
          this.handleCalculatorPrice(carts);
        await this.setState({
          listAddToCart: carts,
          inventoryError: error_inventory,
          priceError: error_price,
          discountError: error_discount,
          notFoundError: error_notfound,
          outOfStockError: error_out_of_stock,
          isLoading: false,
        });

        await setTimeout(async() => {
          await this.setState({
            isSkeleton: false,
            isReAddToCart: false
          });
        }, 500)

        // if(error_discount.length === 0 &&
        //   error_price.length === 0 &&
        //   error_inventory.length === 0 &&
        //   error_notfound.length === 0){
        //   isValid = true;
        // }
      }
    });
    // return isValid;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.listCartStore !== this.props.listCartStore) {
      // if(this.state.isChangeQuantity) {
        // let products = this.props.listCartStore;
        // this.handleCalculatorPrice(products);
      //}
      if(!this.state.isReAddToCart) {
        this.getListProductCart();
      }
    }
    if(prevState.isStartCheckCart !== this.state.isStartCheckCart){
      if(this.state.isStartCheckCart){
        if(this.props.location &&
          this.props.location.state &&
          this.props.location.state.errorCheckout &&
          typeof this.props.location.state.errorCheckout === "string" &&
          this.props.location.state.errorCheckout.length > 0){
            if(this.props.location.state.errorCheckout === "inValid_voucher_id") {
              await this.setState({errorCheckout: "Voucher đã được cập nhật, vui lòng nhập lại mã voucher."})
            }else if(this.props.location.state.errorCheckout === "inValid_total_payment" ||
              this.props.location.state.errorCheckout === "inValid_total_buying"){
              await this.setState({errorCheckout: "Tổng giá trị đơn hàng đã có thay đổi, vui lòng đặt hàng lại."})
            }else if(this.props.location.state.errorCheckout === "inValid_voucher_point"){
              await this.setState({errorCheckout: "Chương trình tích điểm đã được thay đổi, Vui lòng xem lại số điểm sẽ được cộng."})
            }
          }
        this.handleCheckCart();
      }
    }
  }

  deleteItemCart(cart_id, product_name) {
    this.setState({
      cart_id: cart_id,
      product_name: product_name,
      isOpenModal: true,
    });
  }

  handleChangeQuantityProduct = async (e, isOnclick = false) => {
    const { id, value } = e.target;
    const item = { cart_id: id, value };
    let newQuantity = typeof Number(item.value) === "number" && !isNaN(item.value) && Number(item.value) > 0 ? Number(item.value) : 1

    await this.setState({isChangeQuantity: true});
    this.props.changeQuantityItemCart(item);
    if (this.state.isAuth) {
      let params = {
        type: 1,
        id: item.cart_id,
        quantity: newQuantity,
      };
      await updateCart(params).then(() => {});
    }

    if(isOnclick) {
      let inventoryError = [...this.state.inventoryError];
      let newInventoryError = inventoryError.filter((err) => (
        ((err.cart_id !== item.cart_id)) || ((newQuantity > Number(err.inventory)) && (err.cart_id === item.cart_id))
        ));
      await this.setState({inventoryError: newInventoryError});
    }
  };

  handleRedirect = () => {
    this.props.history.push("/auth/sign-in", {
      location: this.props.location.pathname,
    });
  };

  onClose = () => {
    this.setState({ isOpenModal: false, loadingBtnDelete: false  });
  };

  handleAlertClose = () => {
    this.setState({isAlert: false});
  };

  handleBlurQuantityProduct = async (e) => {
    const { id, value } = e.target;
    const item = {
      cart_id: id,
      value: typeof Number(value) === "number"
        && !isNaN(value) && Number(value) > 0 ? value : 1
      };
    await this.setState({isChangeQuantity: true});
    this.props.changeQuantityItemCart(item);
    if (this.state.isAuth) {
      let params = {
        type: 1,
        id: item.cart_id,
        quantity: item.value,
      };
      await updateCart(params).then(() => {});
    }
  }

  handleReAddToCart = async (value, idLoading) => {
      await this.setState({
        isLoading: true,
        idLoading: idLoading,
        isStartCheckCart: false,
        isChangeQuantity: false,
        isSkeleton: true,
        isReAddToCart: true
      })
    // if(value.isErrorCheckout) {
      if(value.cart_id){
        if (this.state.isAuth) {
          let params = {
            id: value.cart_id,
            type: 1,
            quantity: 0,
          };
          await updateCart(params).then(() => {});
        }
        this.props.deleteItemCart(value.cart_id);
      }
    // }
    const { priceError, discountError, isExchange, rate, margin, margin_type, symbol } = this.state;
    let object = {};
    object.product_id = value.product_id;
    object.product_name = value.product_name;
    object.image = value.image;
    object.price = value.new_price;
    object.discount_price = value.new_discount_price;
    object.quantity = value.quantity;
    object.inventory = value.inventory;
    object.sku_code = value.sku_code;
    object.product_code = value.product_code;
    if (value.product_attribute_value_id1 ||
        value.product_attribute_value_id2 ||
        value.product_attribute_value_id3 ||
        value.product_attribute_value_id4 ||
        value.product_attribute_value_id5) {
          object.attribute_name1 = value.attribute_name1;
          object.attribute_name2 = value.attribute_name2;
          object.attribute_name3 = value.attribute_name3;
          object.attribute_name4 = value.attribute_name4;
          object.attribute_name5 = value.attribute_name5;
          object.attribute_value1 = value.attribute_value1;
          object.attribute_value2 = value.attribute_value2;
          object.attribute_value3 = value.attribute_value3;
          object.attribute_value4 = value.attribute_value4;
          object.attribute_value5 = value.attribute_value5;
          object.product_attribute_value_id1 = value.product_attribute_value_id1;
          object.product_attribute_value_id2 = value.product_attribute_value_id2;
          object.product_attribute_value_id3 = value.product_attribute_value_id3;
          object.product_attribute_value_id4 = value.product_attribute_value_id4;
          object.product_attribute_value_id5 = value.product_attribute_value_id5;
    }
    if (this.state.isAuth) {
      await addCart({ type: 1, carts: [object] }).then(async (res) => {
        if (res.success) {
          await getListCart({ type: 1 }).then(async (res) => {
            if (res.success) {
              this.props.clearListCart();
              if(res.cart && Array.isArray(res.cart) && res.cart.length > 0) {
                res.cart.forEach(async (value) => {
                  let item = {
                    attribute_name1: value.attribute_name1,
                    attribute_name2: value.attribute_name2,
                    attribute_name3: value.attribute_name3,
                    attribute_name4: value.attribute_name4,
                    attribute_name5: value.attribute_name5,
                    attribute_value1: value.attribute_value1,
                    attribute_value2: value.attribute_value2,
                    attribute_value3: value.attribute_value3,
                    attribute_value4: value.attribute_value4,
                    attribute_value5: value.attribute_value5,
                    cart_id: value.cart_id,
                    inventory: Number(value.inventory),
                    image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                    product_attribute_value_id1: value.product_attribute_value_id1,
                    product_attribute_value_id2: value.product_attribute_value_id2,
                    product_attribute_value_id3: value.product_attribute_value_id3,
                    product_attribute_value_id4: value.product_attribute_value_id4,
                    product_attribute_value_id5: value.product_attribute_value_id5,
                    product_code: value.product_code,
                    product_id: value.product_id,
                    product_name: value.product_name,
                    quantity: Number(value.quantity),
                    sku_code: value.sku_code,
                    discount_price: (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number(value.old_discount_price) : 0,
                    price: (value.old_price
                      && typeof Number(value.old_price) === "number"
                      && !isNaN(value.old_price)
                      && Number(value.old_price) > 0) ?
                      Number(value.old_price) : 0,
                  }

                  if(isExchange) {
                    item.isExchange = true;
                    item.symbol = symbol;
                    item.margin = margin;
                    item.rate = rate;
                    item.margin_type = margin_type;
                    item.discount_price_exchange = (value.old_discount_price &&
                      typeof Number(value.old_discount_price) === "number"
                      && !isNaN(value.old_discount_price)
                      && Number(value.old_discount_price) > 0) ?
                      Number((Math.round((value.old_discount_price*rate + margin/100*(value.old_discount_price*rate))* 100) /100)).toFixed(2) : 0;
                    item.price_exchange = (value.new_price
                      && typeof Number(value.new_price) === "number"
                      && !isNaN(value.new_price)
                      && Number(value.new_price) > 0) ?
                      Number((Math.round((value.new_price*rate + margin/100*(value.new_price*rate))* 100) /100)).toFixed(2) : 0;
                  }
  
                  this.props.addToCart(item);
                  await this.setState({
                    isLoading: false,
                    priceError: priceError.filter((e) => (e.product_id !== item.product_id)),
                    discountError: discountError.filter((e) => (e.product_id !== item.product_id)),
                  });
                  let products = this.props.listCartStore;
                  this.handleCalculatorPrice(products);
                });
              }
            }
          });
        }
      });
    } else {

      if(isExchange) {
        object.isExchange = true;
        object.symbol = symbol;
        object.margin = margin;
        object.rate = rate;
        object.margin_type = margin_type;
        object.discount_price_exchange = (value.new_discount_price &&
          typeof Number(value.new_discount_price) === "number"
          && !isNaN(value.new_discount_price)
          && Number(value.new_discount_price) > 0) ?
          Number((Math.round((value.new_discount_price*rate + margin/100*(value.new_discount_price*rate))* 100) /100)).toFixed(2) : 0;
        object.price_exchange = (value.old_price
          && typeof Number(value.old_price) === "number"
          && !isNaN(value.old_price)
          && Number(value.old_price) > 0) ?
          Number((Math.round((value.old_price*rate + margin/100*(value.old_price*rate))* 100) /100)).toFixed(2) : 0;
      }

      this.props.addToCart(object);
      await this.setState({
        isLoading: false,
        priceError: priceError.filter((e) => (e.cart_id !== value.cart_id)),
        discountError: discountError.filter((e) => (e.cart_id !== value.cart_id)),
      });
      let products = this.props.listCartStore;
      this.handleCalculatorPrice(products);
    }
  }

  handleChangeInputVoucher = (value) => {
    this.setState({voucher: value});
  }

  handleGetVoucher = () => {
    let { voucher, isExchange, rate, isAuth } = this.state;
    if(voucher && voucher.trim().length > 0) {
      if(!isAuth) {
        this.setState({
          isAlert: true,
          sbColor: "error",
          sbMessage: "Vui lòng đăng nhập để sử dụng voucher"});
        return;
      }
      this.setState({idLoading: 'voucher', isLoading: true})
      getVoucherInfo({get_by_code: 1, code: voucher}).then((res) => {
        if(res.success){
          let listVoucher = res._embedded.voucher;
          if(listVoucher &&
            Array.isArray(listVoucher) &&
            listVoucher.length === 1 &&
            listVoucher[0] &&
            typeof listVoucher[0] === "object" &&
            Object.keys(listVoucher[0]).length > 0){
             let itemVoucher = {
              id: listVoucher[0].id,
              code: listVoucher[0].code,
              value: listVoucher[0].value,
              value_type: listVoucher[0].value_type,
              voucher_type: listVoucher[0].voucher_type,
             }
             if(isExchange && listVoucher[0].value_type === "fixed") {
              itemVoucher.value_exchange = Number((Math.round((Number(listVoucher[0].value)*Number(rate))* 100) /100)).toFixed(2)
             }
             this.setState({infoVoucher: itemVoucher, isLoading: false});
             let cartInfo = JSON.stringify(itemVoucher);
             localStorageService.setVoucher(cartInfo);
          }else{
            this.setState({
              infoVoucher: null,
              isLoading: false,
              isAlert: true,
              sbColor: 'error',
              sbMessage: 'Không tìm thấy mã giảm giá'});
            localStorageService.clearVoucher();
          }
        }
    })
    }
  }

  handleDeleteItemCart = async (cart_id, isAuth) => {
    this.setState({isChangeQuantity: true, loadingBtnDelete: true});
    if(cart_id){
      if (isAuth) {
        let params = {
          id: cart_id,
          type: 1,
          quantity: 0,
        };
        await updateCart(params).then(() => {});
      }
      this.props.deleteItemCart(cart_id);
    }
    this.onClose();
};

  render() {
    const { classes } = this.props;
    const {
      isAuth,
      listAddToCart,
      product_name,
      totalQuantity,
      totalCost,
      isSkeleton,
      isOpenModal,
      cart_id,
      isAlert,
      sbColor,
      sbMessage,
      inventoryError,
      priceError,
      discountError,
      notFoundError,
      outOfStockError,
      isLoading,
      idLoading,
      voucher,
      infoVoucher,
      errorCheckout,
      loadingBtnDelete,
      isExchange,
      symbol,
      rate,
      margin,
      margin_type,
      totalCostExchange,
      rank,
      voucherPoint
      // isOpenDialog
    } = this.state;
    return (
      <React.Fragment>
      <Helmet>
          <title>Giỏ hàng</title>
          <meta property="og:title" content={"Giỏ hàng"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Giỏ hàng"}></meta>
          <meta name="description" content={"Giỏ hàng"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb breadcrumb={[{ link: "/cart", name: "Giỏ hàng" }]} />
        <section id="cart-page" className={classes.cartPage}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xl={12} xs={12}>
                <div className="authority">
                  {!isAuth && (
                    <React.Fragment>
                      <span>Bạn chưa đăng nhập</span>
                      <Button
                        onClick={() => this.handleRedirect()}
                        className={classes.btnLogin}
                      >
                        Đăng nhập
                      </Button>
                    </React.Fragment>
                  )}
                </div>
                <div className="cart-main">
                  {!isSkeleton ? (
                    <React.Fragment>
                      {listAddToCart.length > 0 ? (
                        <React.Fragment>
                          <DesktopTemplate
                            listAddToCart={listAddToCart}
                            totalQuantity={totalQuantity}
                            totalCost={totalCost}
                            inventoryError={inventoryError}
                            isLoading={isLoading}
                            idLoading={idLoading}
                            handleChangeQuantityProduct={(e, isOnclick) =>
                              this.handleChangeQuantityProduct(e, isOnclick)
                            }
                            handleBlurQuantityProduct={(e) => 
                              this.handleBlurQuantityProduct(e)
                            }
                            setCheckout={() => this.setCheckout()}
                            deleteItemCart={(id, product_name) =>
                              this.deleteItemCart(id, product_name)
                            }
                            handleReAddToCart={(value, id) => this.handleReAddToCart(value, id)}
                            handleChangeInputVoucher={(value) => this.handleChangeInputVoucher(value)}
                            voucher={voucher}
                            infoVoucher={infoVoucher}
                            handleGetVoucher={() => this.handleGetVoucher()}
                            errorCheckout={errorCheckout}
                            isExchange={isExchange}
                            symbol={symbol}
                            margin={margin}
                            margin_type={margin_type}
                            rate={rate}
                            totalCostExchange={totalCostExchange}
                            rank={rank}
                            voucherPoint={voucherPoint}
                            isAuth={isAuth}
                          />
                          <MobileTemplate
                           listAddToCart={listAddToCart}
                           totalQuantity={totalQuantity}
                           totalCost={totalCost}
                           inventoryError={inventoryError}
                           isLoading={isLoading}
                           idLoading={idLoading}
                           handleChangeQuantityProduct={(e, isOnclick) =>
                             this.handleChangeQuantityProduct(e, isOnclick)
                           }
                           handleBlurQuantityProduct={(e) => 
                             this.handleBlurQuantityProduct(e)
                           }
                           setCheckout={() => this.setCheckout()}
                           deleteItemCart={(id, product_name) =>
                             this.deleteItemCart(id, product_name)
                           }
                           handleReAddToCart={(value, id) => this.handleReAddToCart(value, id)}
                           handleChangeInputVoucher={(value) => this.handleChangeInputVoucher(value)}
                           voucher={voucher}
                           infoVoucher={infoVoucher}
                           handleGetVoucher={() => this.handleGetVoucher()}
                           errorCheckout={errorCheckout}
                           isExchange={isExchange}
                           symbol={symbol}
                           margin={margin}
                           margin_type={margin_type}
                           rate={rate}
                           totalCostExchange={totalCostExchange}
                           rank={rank}
                           voucherPoint={voucherPoint}
                           isAuth={isAuth}
                          />
                        </React.Fragment>
                      ) : (
                        <NotFoundProduct />
                      )}
                       <div style={{marginTop: '20px'}}>
                       {errorCheckout && errorCheckout.length > 0 && (
                          <p style={{color: '#ff0000', marginBottom: '5px'}}>{errorCheckout}</p>
                        )}
                       {priceError &&
                  Array.isArray(priceError) &&
                  priceError.length > 0 &&
                  priceError.map((error, index) => (
                    <React.Fragment>
                      {
                        isExchange ? (<React.Fragment>
                          {
                            margin_type === "increase" ? (
                              <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                              <i>
                              Sản phẩm{" "}
                              <Link
                                style={{color: '#f2c123'}}
                                to={
                                  error.product_id &&
                                  error.product_name &&
                                  `/product/${convertSlugSeo(error.product_name)}.id=${
                                    error.product_id
                                  }`
                                }
                              >
                                {error.product_name}
                              </Link>{" "}
                              {
                                (error.old_discount_price
                                  && typeof Number(error.old_discount_price)
                                  && !isNaN(error.old_discount_price)
                                  && Number(error.old_discount_price) > 0
                                  && error.new_discount_price
                                  && typeof Number(error.new_discount_price)
                                  && !isNaN(error.new_discount_price)
                                  && Number(error.new_discount_price) > 0) ? (
                                  <React.Fragment>
                                  đã thay đổi giá từ <NumberFormat
                                  value={Number((Math.round((Number(error.old_discount_price)*rate + margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={`${symbol} `}
                                />
                              {" "}sang{" "}
                              <NumberFormat
                                      value={Number((Math.round((Number(error.new_discount_price)*rate + margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${symbol} `}
                                    />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {
                                     (typeof Number(error.old_discount_price)
                                      && !isNaN(error.old_discount_price)
                                      && Number(error.old_discount_price) === 0
                                      && typeof Number(error.new_discount_price)
                                      && !isNaN(error.new_discount_price)
                                      && Number(error.new_discount_price) === 0) ? (
                                        <React.Fragment>
                                          đã thay đổi giá từ <NumberFormat
                                            value={Number((Math.round((Number(error.old_price)*rate + margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`${symbol} `}
                                          />
                                        {" "}sang{" "}
                                        <NumberFormat
                                                value={Number((Math.round((Number(error.new_price)*rate + margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={`${symbol} `}
                                              />
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                        {
                                          (typeof Number(error.new_discount_price)
                                            && !isNaN(error.new_discount_price)
                                            && Number(error.new_discount_price) === 0) ? (
                                            <React.Fragment>
                                            đã thay đổi giá từ <NumberFormat
                                            value={Number((Math.round((Number(error.old_discount_price)*rate + margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`${symbol} `}
                                          />
                                        {" "}sang{" "}
                                        <NumberFormat
                                                value={Number((Math.round((Number(error.new_price)*rate + margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={`${symbol} `}
                                              />
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                      đã thay đổi giá từ <NumberFormat
                                      value={Number((Math.round((Number(error.old_price)*rate + margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${symbol} `}
                                    />
                                  {" "}sang{" "}
                                  <NumberFormat
                                          value={Number((Math.round((Number(error.new_discount_price)*rate + margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={`${symbol} `}
                                        />
                                      </React.Fragment>
                                          )
                                        }
                                        </React.Fragment>
                                      )
                                    }
                                  </React.Fragment>
                                )
                              }, bạn có muốn thêm lại vào giỏ hàng?{" "}
                              </i>
                              <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('priceError' + index + 1))}>{isLoading && idLoading === ('priceError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                            </p>
                            ) : (
                              <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                              <i>
                              Sản phẩm{" "}
                              <Link
                                style={{color: '#f2c123'}}
                                to={
                                  error.product_id &&
                                  error.product_name &&
                                  `/product/${convertSlugSeo(error.product_name)}.id=${
                                    error.product_id
                                  }`
                                }
                              >
                                {error.product_name}
                              </Link>{" "}
                              {
                                (error.old_discount_price
                                  && typeof Number(error.old_discount_price)
                                  && !isNaN(error.old_discount_price)
                                  && Number(error.old_discount_price) > 0
                                  && error.new_discount_price
                                  && typeof Number(error.new_discount_price)
                                  && !isNaN(error.new_discount_price)
                                  && Number(error.new_discount_price) > 0) ? (
                                  <React.Fragment>
                                  đã thay đổi giá từ <NumberFormat
                                  value={Number((Math.round((Number(error.old_discount_price)*rate - margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={`${symbol} `}
                                />
                              {" "}sang{" "}
                              <NumberFormat
                                      value={Number((Math.round((Number(error.new_discount_price)*rate - margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${symbol} `}
                                    />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {
                                     (typeof Number(error.old_discount_price)
                                      && !isNaN(error.old_discount_price)
                                      && Number(error.old_discount_price) === 0
                                      && typeof Number(error.new_discount_price)
                                      && !isNaN(error.new_discount_price)
                                      && Number(error.new_discount_price) === 0) ? (
                                        <React.Fragment>
                                          đã thay đổi giá từ <NumberFormat
                                            value={Number((Math.round((Number(error.old_price)*rate - margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`${symbol} `}
                                          />
                                        {" "}sang{" "}
                                        <NumberFormat
                                                value={Number((Math.round((Number(error.new_price)*rate - margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={`${symbol} `}
                                              />
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                        {
                                          (typeof Number(error.new_discount_price)
                                            && !isNaN(error.new_discount_price)
                                            && Number(error.new_discount_price) === 0) ? (
                                            <React.Fragment>
                                            đã thay đổi giá từ <NumberFormat
                                            value={Number((Math.round((Number(error.old_discount_price)*rate - margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={`${symbol} `}
                                          />
                                        {" "}sang{" "}
                                        <NumberFormat
                                                value={Number((Math.round((Number(error.new_price)*rate - margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={`${symbol} `}
                                              />
                                            </React.Fragment>
                                          ) : (
                                            <React.Fragment>
                                      đã thay đổi giá từ <NumberFormat
                                      value={Number((Math.round((Number(error.old_price)*rate - margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`${symbol} `}
                                    />
                                  {" "}sang{" "}
                                  <NumberFormat
                                          value={Number((Math.round((Number(error.new_discount_price)*rate - margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={`${symbol} `}
                                        />
                                      </React.Fragment>
                                          )
                                        }
                                        </React.Fragment>
                                      )
                                    }
                                  </React.Fragment>
                                )
                              }, bạn có muốn thêm lại vào giỏ hàng?{" "}
                              </i>
                              <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('priceError' + index + 1))}>{isLoading && idLoading === ('priceError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                            </p>
                            )
                          }
                        </React.Fragment>) : (
                          <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                          <i>
                          Sản phẩm{" "}
                          <Link
                            style={{color: '#f2c123'}}
                            to={
                              error.product_id &&
                              error.product_name &&
                              `/product/${convertSlugSeo(error.product_name)}.id=${
                                error.product_id
                              }`
                            }
                          >
                            {error.product_name}
                          </Link>{" "}
                          {
                            (error.old_discount_price
                              && typeof Number(error.old_discount_price)
                              && !isNaN(error.old_discount_price)
                              && Number(error.old_discount_price) > 0
                              && error.new_discount_price
                              && typeof Number(error.new_discount_price)
                              && !isNaN(error.new_discount_price)
                              && Number(error.new_discount_price) > 0) ? (
                              <React.Fragment>
                              đã thay đổi giá từ <NumberFormat
                              value={Number(error.old_discount_price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          {" "}sang{" "}
                          <NumberFormat
                                  value={Number(error.new_discount_price)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {
                                 (typeof Number(error.old_discount_price)
                                  && !isNaN(error.old_discount_price)
                                  && Number(error.old_discount_price) === 0
                                  && typeof Number(error.new_discount_price)
                                  && !isNaN(error.new_discount_price)
                                  && Number(error.new_discount_price) === 0) ? (
                                    <React.Fragment>
                                      đã thay đổi giá từ <NumberFormat
                                        value={Number(error.old_price)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={` ${CURRENCY}`}
                                      />
                                    {" "}sang{" "}
                                    <NumberFormat
                                            value={Number(error.new_price)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                    {
                                      (typeof Number(error.new_discount_price)
                                        && !isNaN(error.new_discount_price)
                                        && Number(error.new_discount_price) === 0) ? (
                                        <React.Fragment>
                                        đã thay đổi giá từ <NumberFormat
                                        value={Number(error.old_discount_price)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={` ${CURRENCY}`}
                                      />
                                    {" "}sang{" "}
                                    <NumberFormat
                                            value={Number(error.new_price)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            suffix={` ${CURRENCY}`}
                                          />
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                  đã thay đổi giá từ <NumberFormat
                                  value={Number(error.old_price)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                              {" "}sang{" "}
                              <NumberFormat
                                      value={Number(error.new_discount_price)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      suffix={` ${CURRENCY}`}
                                    />
                                  </React.Fragment>
                                      )
                                    }
                                    </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }, bạn có muốn thêm lại vào giỏ hàng?{" "}
                          </i>
                          <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('priceError' + index + 1))}>{isLoading && idLoading === ('priceError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                        </p>
                        )
                      }
                    </React.Fragment>
                  ))}
                  {discountError &&
                  Array.isArray(discountError) &&
                  discountError.length > 0 &&
                  discountError.map((error, index) => (
                    <React.Fragment>
                      {
                        isExchange ? (
                          <React.Fragment>
                            {
                              margin_type === "increase" ? (
                                <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                          <i>
                          Sản phẩm{" "}
                          <Link
                            style={{color: '#f2c123'}}
                            to={
                              error.product_id &&
                              error.product_name &&
                              `/product/${convertSlugSeo(error.product_name)}.id=${
                                error.product_id
                              }`
                            }
                          >
                            {error.product_name}
                          </Link>{" "}
                          {
                            (error.old_discount_price
                              && typeof Number(error.old_discount_price)
                              && !isNaN(error.old_discount_price)
                              && Number(error.old_discount_price) > 0
                              && error.new_discount_price
                              && typeof Number(error.new_discount_price)
                              && !isNaN(error.new_discount_price)
                              && Number(error.new_discount_price) > 0) ? (
                              <React.Fragment>
                              đã thay đổi giá từ <NumberFormat
                              value={Number((Math.round((Number(error.old_discount_price)*rate + margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />
                          {" "}sang{" "}
                          <NumberFormat
                                  value={Number((Math.round((Number(error.new_discount_price)*rate + margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={`${symbol} `}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {
                                  (error.new_discount_price
                                    && typeof Number(error.new_discount_price)
                                    && !isNaN(error.new_discount_price)
                                    && Number(error.new_discount_price) === 0) ? (
                                    <React.Fragment>
                                    đã thay đổi giá từ <NumberFormat
                                    value={Number((Math.round((Number(error.old_discount_price)*rate + margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />
                                {" "}sang{" "}
                                <NumberFormat
                                        value={Number((Math.round((Number(error.new_price)*rate + margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={`${symbol} `}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                              đã thay đổi giá từ <NumberFormat
                              value={Number((Math.round((Number(error.old_price)*rate + margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={`${symbol} `}
                            />
                          {" "}sang{" "}
                          <NumberFormat
                                  value={Number((Math.round((Number(error.new_discount_price)*rate + margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={`${symbol} `}
                                />
                              </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                          , bạn có muốn thêm lại vào giỏ hàng?{" "}
                          </i>
                          <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('discountError' + index + 1))}>{isLoading && idLoading === ('discountError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                        </p>
                              ) : (
                                <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                                <i>
                                Sản phẩm{" "}
                                <Link
                                  style={{color: '#f2c123'}}
                                  to={
                                    error.product_id &&
                                    error.product_name &&
                                    `/product/${convertSlugSeo(error.product_name)}.id=${
                                      error.product_id
                                    }`
                                  }
                                >
                                  {error.product_name}
                                </Link>{" "}
                                {
                                  (error.old_discount_price
                                    && typeof Number(error.old_discount_price)
                                    && !isNaN(error.old_discount_price)
                                    && Number(error.old_discount_price) > 0
                                    && error.new_discount_price
                                    && typeof Number(error.new_discount_price)
                                    && !isNaN(error.new_discount_price)
                                    && Number(error.new_discount_price) > 0) ? (
                                    <React.Fragment>
                                    đã thay đổi giá từ <NumberFormat
                                    value={Number((Math.round((Number(error.old_discount_price)*rate - margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />
                                {" "}sang{" "}
                                <NumberFormat
                                        value={Number((Math.round((Number(error.new_discount_price)*rate - margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={`${symbol} `}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {
                                        (error.new_discount_price
                                          && typeof Number(error.new_discount_price)
                                          && !isNaN(error.new_discount_price)
                                          && Number(error.new_discount_price) === 0) ? (
                                          <React.Fragment>
                                          đã thay đổi giá từ <NumberFormat
                                          value={Number((Math.round((Number(error.old_discount_price)*rate - margin/100*(Number(error.old_discount_price)*rate))* 100) /100)).toFixed(2)}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          prefix={`${symbol} `}
                                        />
                                      {" "}sang{" "}
                                      <NumberFormat
                                              value={Number((Math.round((Number(error.new_price)*rate - margin/100*(Number(error.new_price)*rate))* 100) /100)).toFixed(2)}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={`${symbol} `}
                                            />
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                    đã thay đổi giá từ <NumberFormat
                                    value={Number((Math.round((Number(error.old_price)*rate - margin/100*(Number(error.old_price)*rate))* 100) /100)).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={`${symbol} `}
                                  />
                                {" "}sang{" "}
                                <NumberFormat
                                        value={Number((Math.round((Number(error.new_discount_price)*rate - margin/100*(Number(error.new_discount_price)*rate))* 100) /100)).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={`${symbol} `}
                                      />
                                    </React.Fragment>
                                        )
                                      }
                                    </React.Fragment>
                                  )
                                }
                                , bạn có muốn thêm lại vào giỏ hàng?{" "}
                                </i>
                                <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('discountError' + index + 1))}>{isLoading && idLoading === ('discountError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                              </p>
                              )
                            }
                          </React.Fragment>
                        ) : (
                          <p style={{color: '#ff0000', marginBottom: '5px'}} key={(index + 1)}>
                          <i>
                          Sản phẩm{" "}
                          <Link
                            style={{color: '#f2c123'}}
                            to={
                              error.product_id &&
                              error.product_name &&
                              `/product/${convertSlugSeo(error.product_name)}.id=${
                                error.product_id
                              }`
                            }
                          >
                            {error.product_name}
                          </Link>{" "}
                          {
                            (error.old_discount_price
                              && typeof Number(error.old_discount_price)
                              && !isNaN(error.old_discount_price)
                              && Number(error.old_discount_price) > 0
                              && error.new_discount_price
                              && typeof Number(error.new_discount_price)
                              && !isNaN(error.new_discount_price)
                              && Number(error.new_discount_price) > 0) ? (
                              <React.Fragment>
                              đã thay đổi giá từ <NumberFormat
                              value={Number(error.old_discount_price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          {" "}sang{" "}
                          <NumberFormat
                                  value={Number(error.new_discount_price)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {
                                  (error.new_discount_price
                                    && typeof Number(error.new_discount_price)
                                    && !isNaN(error.new_discount_price)
                                    && Number(error.new_discount_price) === 0) ? (
                                    <React.Fragment>
                                    đã thay đổi giá từ <NumberFormat
                                    value={Number(error.old_discount_price)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    suffix={` ${CURRENCY}`}
                                  />
                                {" "}sang{" "}
                                <NumberFormat
                                        value={Number(error.new_price)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        suffix={` ${CURRENCY}`}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                              đã thay đổi giá từ <NumberFormat
                              value={Number(error.old_price)}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={` ${CURRENCY}`}
                            />
                          {" "}sang{" "}
                          <NumberFormat
                                  value={Number(error.new_discount_price)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  suffix={` ${CURRENCY}`}
                                />
                              </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          }
                          , bạn có muốn thêm lại vào giỏ hàng?{" "}
                          </i>
                          <button className={classes.btnReAdd} onClick={() => this.handleReAddToCart(error, ('discountError' + index + 1))}>{isLoading && idLoading === ('discountError' + index + 1) ? (<CircularProgress size={12} color="primary"></CircularProgress>) : (<React.Fragment>Xác nhận</React.Fragment>)}</button>
                        </p>
                        )
                      }
                    </React.Fragment>
                  ))}
                  {notFoundError &&
                  Array.isArray(notFoundError) &&
                  notFoundError.length > 0 &&
                  notFoundError.map((error, key) => (
                    <p style={{color: '#ff0000', marginBottom: '5px'}} key={key}>
                      <i>Sản phẩm <span style={{color: '#f2c123'}}>{error.product_name}</span> không tồn tại hoặc đã bị xóa.</i>
                    </p>
                  ))}
                  {outOfStockError &&
                  Array.isArray(outOfStockError) &&
                  outOfStockError.length > 0 &&
                  outOfStockError.map((error, key) => (
                    <p style={{color: '#ff0000', marginBottom: '5px'}} key={key}>
                      <i>Sản phẩm <span style={{color: '#f2c123'}}>{error.product_name}</span> đã hết hàng.</i>
                    </p>
                  ))}
                       </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <SkeletonDesktop />
                      <SkeletonMobile />
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <DeleteCart
          cart_id={cart_id}
          product_name={product_name}
          isAuth={isAuth}
          isOpenModal={isOpenModal}
          loadingBtnDelete={loadingBtnDelete}
          onClose={() => this.onClose()}
          handleDeleteItemCart={(cart_id, isAuth) => this.handleDeleteItemCart(cart_id, isAuth)}
        />
         <AlertMessage
          open={isAlert}
          severity={sbColor}
          message={sbMessage}
          handleAlertClose={() => this.handleAlertClose()}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    listCartStore: store.cartReducer.listProduct,
  };
};

const mapDispatchToProps = (dispatch, ownDispatch) => {
  return {
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    deleteItemCart: (payload) => {
      dispatch({ type: "DELETE_ITEM_CART", payload });
    },
    changeQuantityItemCart: (payload) => {
      dispatch({ type: "CHANGE_QUANTITY_ITEM", payload });
    },
    updateInventoryItemCart: (payload) => {
      dispatch({type: "UPDATE_INVENTORY_ITEM", payload});
    }
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(Cart)));
