import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import { apiDomain } from "../../utills/config";

const Session = styled.section`
  padding: 12px 0px;
  text-align: center;
`;
const ThumbIcon = styled.div`
  padding: 8px 16px;
`;
const IconCustom = styled(DoneIcon)`
  font-size: 50px;
  color: #f2c123;
`;
const Heading = styled.h1`
    font-size: 1.875rem;
    margin-bottom: 15px;
    /* font-family: "Aleo"; */
  @media (max-width: 768px) {
    font-size: 1.675rem;
    margin-bottom: 15px;
  }
`;
const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 26px;
  margin-bottom: 0px;
  /* font-family: "Aleo"; */
  color: #000;
  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ButtonCustom = styled(Link)`
    width: 235px;
    height: 45px;
    margin-top: 40px;
    line-height: 45px;
    text-align: center;
    background: #f2c123;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    /* font-family: "Aleo"; */
`;

class SignUpSuccess extends Component {
  render() {
    return (
        <React.Fragment>
        <Helmet>
          <title>Đăng ký thành công</title>
          <meta property="og:title" content={"Đăng ký thành công"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Đăng ký thành công"}></meta>
          <meta name="description" content={"Đăng ký thành công"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
          <Session>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xl={12} xs={12}>
              <ThumbIcon>
                <IconCustom />
              </ThumbIcon>
              <Heading>Đăng ký tài khoản thành công</Heading>
              <Paragraph>
                Chúc mừng bạn đã đăng ký thành công, vui lòng kiểm tra email để kích hoạt tài khoản.
              </Paragraph>
              <Paragraph>
                Nếu không thấy email kích hoạt vui lòng kiểm tra thư mục Thư rác (Spam) hoặc liên hệ email: <b>lienhe@taphoa.cz</b> để được hỗ trợ.
              </Paragraph>
              <ButtonCustom to="/auth/sign-in">Trở về trang đăng nhập</ButtonCustom>
            </Grid>
          </Grid>
        </Container>
      </Session>
        </React.Fragment>
    );
  }
}

export default SignUpSuccess;
