import React, { Component } from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
  withStyles,
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { CURRENCY } from "../../../../utills/constant";
import NumberFormat from "react-number-format";
import style from "../style";

class MobilePoint extends Component {
  render() {
    const { classes, listResult, isSkeleton } = this.props;
    return (
      <div className={classes.listMobile}>
        {!isSkeleton ? (<React.Fragment>
         
              { listResult && Array.isArray(listResult) && listResult.length > 0 ? (
                <React.Fragment>
                   {listResult.map((row, i) => (
          <div className="item-order-mobile" key={i}>
            <p>
              <span className="label">Ngày cập nhật:</span>{" "}
              <span>{`${row.created_at} (UTC +2)`}</span>
            </p>
            <p>
              <span className="label">Mã đơn hàng/Mã voucher:</span>{" "}
              <span>{row.code}</span>
            </p>
            <p>
              <span className="label">Giao dịch: </span>
              <span>{row.type === "exchange" ? `Đổi điểm` : `Mua hàng`}</span>
            </p>
            <p>
              <span className="label">Tổng tiền thanh toán: </span>
              <span><NumberFormat
                        value={row.exchange_rate
                        && row.type !== "exchange" ?
                        `${Number(row.value_exchange)} ` : `${Number(row.value)}`}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={row.exchange_rate && row.type !== "exchange" ? `${row.symbol} ` : ``}
                        suffix={!row.exchange_rate ? ` ${CURRENCY}` : ``}
                    /></span>
            </p>
            <p>
              <span className="label">Điểm thưởng: </span>
              <span> {Number(row.point) > 0 && (
                    <NumberFormat
                    value={Number(row.point)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={row.type === "exchange" ? `-` : `+`}
                />
                )} </span>
            </p>
          </div>
        ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                   <h3 style={{marginBottom: '15px'}}>Hiện chưa giao dịch.</h3>
                </React.Fragment>
              )}
        
        </React.Fragment>) : (<React.Fragment>
                   {[1,2,3,4,5,6,7,8,9,10].map((row) => (
                  <div className="item-order-mobile" key={row}>
                    {[1,2,3,4,5,6].map((value) => (
                       <p key={value}>
                       <Skeleton className="label" variant="text" width={155} style={{marginRight: '20px'}} />
                       <Skeleton variant="text" width={180} />
                     </p>
                    ))}
                  </div>
                ))}
        </React.Fragment>)}
        
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(MobilePoint)));
