import { darken } from '@material-ui/core';

const style = (theme) => ({
    btn: {
        position: 'relative',
		fontWeight: 400,
		height: '38px',
		color: '#fff',
        '&:hover': {
			color: '#fff!important',
		},
		"& *": {
			color: '#fff',
			textDecoration: 'none',
		}
    },
	btnSuccess: {
		backgroundColor: '#f2c123!important',
		border: '1px solid #f2c123!important',
		borderRadius: '22px',
		textDecoration: 'uppercase',
		minWidth: '80px',
		color: '#fff!important',
		"& span": {
			color: '#fff!important',
			textDecoration: 'uppercase',
		},
	},
	btnDanger: {
		backgroundColor: '#e74c3c!important',
		borderRadius: '22px',
		textDecoration: 'uppercase',
		border: '1px solid #e74c3c!important',
		color: '#fff',
	},
	btnWarning: {
		backgroundColor: '#f39c12!important',
		border: '1px solid #f39c12!important',
		borderRadius: '0px',
		textDecoration: 'uppercase',
		color: '#fff',
		height: '48px',
		minWidth: '120px'
	},
	btnDefault: {
		'& > * ': {
			color: '#000',
		}
	},
	btnLoading: {
		color: '#3e643e',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

export default style;
