import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import breadcrumbReducer from './breadcrumbReducers';
import languageReducer from './languageReducers';
import authReducer from './authReducers';
import modalReducer from './modalReducers';
import userReducer from './userReducers';
import postReducer from './postReducers';
import cartReducer from './cartReducers';


export default combineReducers({
	themeReducer,
	breadcrumbReducer,
	languageReducer,
	authReducer,
	modalReducer,
	userReducer,
	postReducer,
	cartReducer
});
