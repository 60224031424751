import React, { Component } from "react";
import style from "./../../style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { injectIntl } from "react-intl";
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  withStyles,
} from "@material-ui/core";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";
import NumberFormat from "react-number-format";
import { CURRENCY } from "../../../../../utills/constant";

const TableHeadCus = styled(TableHead)`
  background: #f1f8f2;
`;

const TableCellCus = styled(TableCell)`
  font-family: 'Tinos';
  font-size: 16px;
`;

const BtnMaDonHang = styled.span`
  background-color: #fff !important;
  color: #f2c123;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`;

class DesktopTemplate extends Component {
  render() {
    const { isSkeleton, listOrder, classes, getDetailOrder, handleReCheckout } = this.props;
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          {!isSkeleton ? (
            <React.Fragment>
              <TableHeadCus>
                <TableRow>
                  <TableCellCus >Ngày đặt hàng</TableCellCus>
                  <TableCellCus >Mã đơn hàng</TableCellCus>
                  <TableCellCus >Tên người nhận</TableCellCus>
                  <TableCellCus >Địa chỉ nhận hàng</TableCellCus>
                  <TableCellCus >Tổng tiền thanh toán</TableCellCus>
                  <TableCellCus >Tổng sản phẩm</TableCellCus>
                  <TableCellCus >Trạng thái</TableCellCus>
                </TableRow>
              </TableHeadCus>
              <TableBody>
                {
                 listOrder && Array.isArray(listOrder) && listOrder.length > 0 ? (
                    <React.Fragment>
                      {listOrder.map((row) => (
                  <TableRow key={row.order_no}>
                    <TableCellCus component="th" scope="row">
                      {row.created_at}
                    </TableCellCus>
                    <TableCellCus>
                      <BtnMaDonHang onClick={() => getDetailOrder(row)}>
                        {row.order_no}
                      </BtnMaDonHang>
                    </TableCellCus>
                    <TableCellCus>{row.receiver_name}</TableCellCus>
                    <TableCellCus>
                      {Number(row.delivery_service) === 2 ? ('Lấy hàng tại Shop') : (
                        <React.Fragment>
                        <p>{row.delivery_address}</p>
                        <p>{row.city}</p>
                        <p>{row.country_name}</p>
                        </React.Fragment>
                      ) }
                    </TableCellCus>
                    <TableCellCus>
                    <NumberFormat
                          value={row.total_payment_eur ? Number(row.total_payment_eur) : Number(row.total_payment)}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={!row.symbol ? ` ${CURRENCY}` : ""}
                          prefix={row.symbol ? `${row.symbol} ` : ""}
                        /></TableCellCus>
                    <TableCellCus>
                        {row.total_products} sản phẩm
                    </TableCellCus>
                    <TableCellCus>
                    {/* {row.status_name && row.status === 6 ?
                    (<button className={classes.reCheckout} onClick={() => handleReCheckout(row)}>
                      {row.status_name}
                    </button>) : (
                      <React.Fragment>{row.status_name}</React.Fragment>
                    )} */}
                    {row.status_name}
                    </TableCellCus>
                  </TableRow>
                ))}
                    </React.Fragment>
                  ) : (
                    <h3 style={{marginTop: '10px'}}>Hiện chưa có đơn hàng.</h3>
                  )
                }
              </TableBody>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TableHeadCus>
                <TableRow>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                  <TableCell><Skeleton variant="text" width={80} /></TableCell>
                </TableRow>
              </TableHeadCus>
              <TableBody>
                {[1,2,3,4,5,6,7,8,9,10].map((row) => (
                  <TableRow key={row}>
                    <TableCell component="th" scope="row">
                    <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell >
                    <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                    <TableCell >
                      <p><Skeleton variant="text" width={80} /></p>
                      <p><Skeleton variant="text" width={80} /></p>
                      <p><Skeleton variant="text" width={80} /></p>
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                    <TableCell >
                    <Skeleton variant="text" width={80} />
                    <Skeleton variant="text" width={80} />
                    </TableCell>
                    <TableCell ><Skeleton variant="text" width={80} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </React.Fragment>
          )}
        </Table>
      </TableContainer>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DesktopTemplate)));
