const style = (theme) => ({
    myAddress: {
        paddingTop: '40px',
		paddingBottom: '80px',
		borderTop: '1px solid #f3f3ef',
		'& h2': {
			fontSize: '1.75rem',
			marginBottom: '18px',
			fontWeight: '500',
			paddingTop: '8px'
		  },
        '& .css-1okebmr-indicatorSeparator, & .css-tlfecz-indicatorContainer': {
            display: 'none'
        },
        '& .css-yk16xz-control': {
            height: '44px',
            minWidth: '120px',
            borderRadius: "0px",
        },
        '& .MuiTableCell-root':{
            padding: '14px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            fontSize: '14px',
            minWidth: '92px'
        }
    },
    desktopTemplate: {
        display: 'block',
        [theme.breakpoints.down("sm")]: {
            display: "none",
          },
    },
    listMobile: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "block",
        },
        '& .item-order-mobile': {
            padding: '10px 0px 15px',
            borderBottom: '1px solid #eee',
            '& p': {
              marginBottom: '5px',
              display: 'flex',
              '& .label': {
                  minWidth: '155px',
                  display: 'inline-block',
                  fontWeight: 'bold'
              },
              '& .list-name-product': {
                  display: 'flex',
                  flexDirection: 'column'
              }
            }
        }
      },
   
});

export default style;