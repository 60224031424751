import React, { Component } from "react";
import style from "./style";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { withStyles, Container, Snackbar, Paper } from "@material-ui/core";
import { injectIntl } from "react-intl";
import Breadcrumb from "../../../components/Breadcrumb";
import { listCountry, queryUserInfo } from "../../../models";
import {
  createDeliveryAddress,
  getDeliveryAddress,
  getListDeliveryAddress,
  updateDeliveryAddress,
} from "../../../models/deliveryAddress";
import { MySnackbarContentWrapper } from "../../../components/MySnackbarContentWrapper";
import { getDeliveryService } from "../../../models/cart";
import DeliveryService from "./components/DeliveryService";
import ListAddress from "./components/ListAddress";
import EditAddress from "./components/EditAddress";
import LocalStorageService from "../../../services/LocalStorageService";
import { geocodeByAddress } from "react-places-autocomplete";
import { uid } from "uid";
import { Helmet } from "react-helmet";
import HorizontalLinearStepper from "../../../components/HorizontalLinearStepper";
import { apiDomain } from "../../../utills/config";
import { formatTwoDecimals } from "../../../utills/constant";

const localStorageService = LocalStorageService.getService();

class DeliveryAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      isModalOpen: false,
      delivery_address: "",
      delivery_person_name: "",
      postcode: "",
      phone: "",
      country_id: 0,
      listAddress: [],
      city: "",
      listCountry: [],
      listCountry1: [],
      sbMessage: "",
      sbColor: "success",
      isOpen: false,
      isLoading: false,
      idxLoading: 0,
      delivery_address_id: 0,
      isService: false,
      delivery_service: "1",
      shipment: [],
      delivery_date: "",
      delivery_time: "",
      delivery_time_id: "",
      price: 0,
      note: "",
      delivery_schedules: {},
      contact_information: {},
      country_selected: null,
      totalOrder: 0,
      isSkeleton1: false,
      isSkeleton2: false,
      isOptional: false,
      isAuth: false,
      delivery_prefix: "",
      email: "",
      colorError: false,
      errorCheckout: "",
      errorCode: "",
      isRollback: false,
      id_address_edit: 0,
      products: [],
      isExchange: false,
      symbol: "",
      rate: 0,
      margin: 0,
      margin_type: "",
      price_exchange: 0,
      rank: null,
      totalCost: 0,
      price_discount: ""
    };
  }

  async componentDidMount() {
    if(localStorageService.getOrderInfo()
    && typeof JSON.parse(localStorageService.getOrderInfo()) === "object"
    && Object.keys(JSON.parse(localStorageService.getOrderInfo())).length > 0) {
      this.props.clearListCart();
      localStorageService.clearCart();
      localStorageService.clearVoucher();
      localStorageService.clearOrderInfo();
      localStorageService.clearDeliveryInfo();
      localStorageService.clearVoucherPoint();
    }
    if (
      !localStorageService.getCart() ||
      (localStorageService.getCart() &&
        JSON.parse(localStorageService.getCart()).length === 0)
    ) {
      this.props.history.push("/");
      return false;
    }

    this.setState({
      isSkeleton1: true,
      isSkeleton2: true,
      products: JSON.parse(localStorageService.getCart())
    });

    if(localStorageService.getAccessToken()){
      await this.setState({ isAuth: true });
      await queryUserInfo({ id: 1 }).then(async(res) => {
        if (res.success) {
          await this.setState({
            rank: res.rank ? res.rank : null
          });
        }
      });
    }
    this.handleGetInfoPurchasing();
    this.fetchListCountry();

    if (localStorageService.getAccessToken()) {
      if (localStorageService.getDeliveryInfo()){
        await this.setState({isRollback: true});
        if(this.props.location &&
          this.props.location.state &&
          this.props.location.state.error &&
          typeof this.props.location.state.error === "string" &&
          this.props.location.state.error.length > 0) {
            await this.getErrorRollback(this.props.location.state.error);
        }
      }
      this.getMyListDeliveryAddress();
    } else {
      if (localStorageService.getDeliveryInfo()) {
        let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
        await this.setState({
          delivery_person_name: deliveryInfo && deliveryInfo.delivery_person_name ? deliveryInfo.delivery_person_name : "",
          delivery_address: deliveryInfo && deliveryInfo.delivery_address ? deliveryInfo.delivery_address : "",
          postcode: deliveryInfo && deliveryInfo.postcode ? deliveryInfo.postcode : "",
          city: deliveryInfo && deliveryInfo.city ? deliveryInfo.city : "",
          country_id: deliveryInfo && deliveryInfo.country_id ? Number(deliveryInfo.country_id) : 0,
          country_name: deliveryInfo && deliveryInfo.country_name ? deliveryInfo.country_name : "",
          country_selected: deliveryInfo && deliveryInfo.country_selected ? deliveryInfo.country_selected : null,
          phone: deliveryInfo && deliveryInfo.phone ? deliveryInfo.phone : "",
          delivery_prefix: deliveryInfo && deliveryInfo.delivery_prefix ? deliveryInfo.delivery_prefix : "",
          email: deliveryInfo && deliveryInfo.email ? deliveryInfo.email : "",
          note: deliveryInfo && deliveryInfo.note ? deliveryInfo.note : "",
          isRollback: true,
        });

        if(this.props.location &&
          this.props.location.state &&
          this.props.location.state.error &&
          typeof this.props.location.state.error === "string" &&
          this.props.location.state.error.length > 0) {
            await this.getErrorRollback(this.props.location.state.error);
        }
        this.saveNotAuth(false);
      } else {
        this.setState({
          isModalOpen: true,
          isSkeleton1: false,
          isSkeleton2: false,
        });
      }
    }

  }

  handleGetInfoPurchasing = async () => {
    let cartInfo = JSON.parse(localStorageService.getCart());
    let voucher = null;
    if(localStorageService.getVoucher() &&
    typeof JSON.parse(localStorageService.getVoucher())  === "object" &&
    Object.keys(JSON.parse(localStorageService.getVoucher())).length > 0){
        voucher = JSON.parse(localStorageService.getVoucher());
    }
    let countPayment = 0;

    if(this.state.isAuth) {
      if(cartInfo && Array.isArray(cartInfo) && cartInfo.length > 0) {
        cartInfo.forEach((value) => {
          countPayment = (countPayment * 10 + Number(value.payment) * 10) / 10;
        });
      }
    }else{
      if(cartInfo && Array.isArray(cartInfo) && cartInfo.length > 0) {
        let list = cartInfo.map((value) => {
          if(typeof Number(value.discount_price) === "number" 
            && !isNaN(value.discount_price) && Number(value.discount_price) > 0){
            value.payment = Number((Math.round((Number(value.quantity) 
              * Number(value.discount_price)) * 100) / 100).toFixed(2));
          }else{
            value.payment = Number((Math.round((Number(value.quantity) * Number(value.price)) * 100) / 100).toFixed(2));
          }
          return value;
      });
          list.forEach((value) => {
          countPayment = (countPayment * 10 + Number(value.payment) * 10) / 10;
        });
      }
    }

    let countCart = countPayment;
   
    if(voucher && typeof voucher === "object" && Object.keys(voucher).length > 0){
      if(voucher.value_type === "fixed") {
        countPayment = (Number(countPayment) * 10 - Number(voucher.value) * 10)/10;
      }else {
        countPayment = (Number(countPayment) * 10
        - ((Number(voucher.value) / 100) * Number(countPayment)) * 10) / 10;
      }
    }

    if(this.state.rank && typeof this.state.rank === "object" && Object.keys(this.state.rank).length > 0) {
      if(this.state.rank.price_discount_type === "percent"){
        countPayment = (((Number(countPayment) * 10)
        - (((Number(this.state.rank.price_discount) / 100) * Number(countCart)) * 10)) / 10);
      }else{
        countPayment = Number((countPayment * 10 - Number(this.state.rank.price_discount) * 10) / 10);
      }
    }

    this.setState({
      totalCost: formatTwoDecimals(countPayment) > 0
        ? formatTwoDecimals(countPayment): 0,
    });
  }

  getErrorRollback = async (error) => {
    switch (error) {
      case "inValid_shipment_fee":
        await this.setState({
          errorCheckout:
            "Phí giao hàng đã được thay đổi, vui lòng thanh toán lại.",
            errorCode: "inValid_shipment_fee"
        });
        break;
      case "inValid_delivery_time":
        await this.setState({
          errorCheckout:
            "Thời gian giao hàng đã được thay đổi, vui lòng chọn lại.",
            errorCode: "inValid_delivery_time"
        });
        break;
      case "inValid_delivery_date":
        await this.setState({
          errorCheckout:
            "Ngày giao hàng đã được thay đổi, vui lòng chọn lại.",
          errorCode: "inValid_delivery_date"
        });
        break;
      default:
        break;
    }
  }

  fetchListCountry() {
    listCountry({}).then((res) => {
      if (res.success) {
        let result = [];
        const country = res._embedded.country;
        country &&
          Array.isArray(country) &&
          country.length > 0 &&
          country.forEach((value) => {
            let item = {
              value: value.id,
              label: value.name,
            };
            result.push(item);
          });

        this.setState({
          ...this.state,
          listCountry:
            country && Array.isArray(country) && country.length > 0
              ? country
              : [],
          listCountry1: result,
        });
      }
    });
  }

  getMyListDeliveryAddress() {
    getListDeliveryAddress({})
      .then((res) => {
        if (res.success) {
          let info = [];
          if (res._embedded.user_delivery_address.length === 0) {
            this.setState({ isModalOpen: true });
            return;
          }
          res._embedded.user_delivery_address.forEach((element) => {
            let item = {};
            item.delivery_address_id = element.id;
            item.name = element.delivery_person_name
              ? element.delivery_person_name
              : "";
            item.address = element.delivery_address
              ? element.delivery_address
              : "";
            item.postcode = element.postcode ? element.postcode : "";
            item.city = element.city ? element.city : "";
            item.country_id = element.country_id ? element.country_id : 0;
            item.country_name = element.name ? element.name : "";
            item.phone = element.phone ? element.phone : "";
            item.delivery_prefix = element.prefix ? element.prefix : "";
            info.push(item);
          });
          this.setState({
            ...this.state,
            listAddress: info,
            isModalOpen: false,
            isLoading: false,
          });
          return info;
        }
      })
      .then((info) => {
        if(this.state.isRollback){
          let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
          let item = {};
          item.delivery_address_id = deliveryInfo && deliveryInfo.delivery_address_id ? deliveryInfo.delivery_address_id : "";
          item.name = deliveryInfo && deliveryInfo.delivery_person_name ? deliveryInfo.delivery_person_name : "";
          item.address = deliveryInfo && deliveryInfo.delivery_address ? deliveryInfo.delivery_address : "";
          item.postcode = deliveryInfo && deliveryInfo.postcode ? deliveryInfo.postcode : "";
          item.city = deliveryInfo && deliveryInfo.city ? deliveryInfo.city : "";
          item.country_id = deliveryInfo && deliveryInfo.country_id ? Number(deliveryInfo.country_id) : 0;
          item.country_name = deliveryInfo && deliveryInfo.country_name ? deliveryInfo.country_name : "";
          item.phone = deliveryInfo && deliveryInfo.phone ? deliveryInfo.phone : "";
          item.delivery_prefix = deliveryInfo && deliveryInfo.delivery_prefix ? deliveryInfo.delivery_prefix : "";
          this.handleChooseAddress(item);
        }else{
          if (info && Array.isArray(info) && info.length === 1) {
            this.handleChooseAddress(info[0]);
          }else if(info && Array.isArray(info) && info.length > 1 && this.state.delivery_address_id){
            if(this.state.delivery_address_id === this.state.id_address_edit) {
              let addressChose = info.find((value) => (
                value.delivery_address_id === this.state.delivery_address_id
                ));
              if(addressChose){
                this.handleChooseAddress(addressChose);
              }
            }else{
              setTimeout(() => {
                this.resetForm();
                this.setState({ isSkeleton1: false, isSkeleton2: false });
              }, 500);
            }
          }else{
            setTimeout(() => {
              this.resetForm();
              this.setState({ isSkeleton1: false, isSkeleton2: false });
            }, 500);
          }
        }
      });
  }

  handleCheckboxChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  handleModalClose = () => {
    if (this.state.isAuth) {
      this.setState({ isModalOpen: false });
      this.resetForm();
    } else {
      if (this.state.listAddress && this.state.listAddress[0]) {
        let contact = this.state.listAddress[0];
        this.setState({
          delivery_address_id: contact.delivery_address_id,
          delivery_person_name: contact.name,
          delivery_address: contact.address,
          postcode: contact.postcode,
          city: contact.city,
          country_id: contact.country_id,
          country_name: contact.name,
          country_selected: contact.country_selected,
          phone: contact.phone,
          delivery_prefix: contact.delivery_prefix
            ? contact.delivery_prefix
            : "",
          email: contact.email ? contact.email : "",
          isModalOpen: false,
          isLoading: false,
          colorError: false,
        });
      } else {
        this.setState({
          delivery_person_name: "",
          delivery_address: "",
          postcode: "",
          phone: "",
          id: 0,
          city: "",
          isOptional: false,
          delivery_address_id: 0,
          delivery_service: "1",
          isService: false,
          country_selected: null,
          country_name: "",
          country_id: 0,
          isModalOpen: false,
          isLoading: false,
          colorError: false,
          delivery_prefix: ""
        });
      }
    }
  };

  handleModalOpen = (id) => {
    this.setState({ idxLoading: id, isLoading: true });
    if (id && this.state.isAuth) {
      this.fetchOneAddress(id);
    } else {
      if (this.state.isAuth) {
        this.resetForm();
      }
      this.setState({ isModalOpen: true, isLoading: false, idxLoading: 0 });
    }
  };

  fetchOneAddress(id) {
    getDeliveryAddress({ id })
      .then((res) => {
        if (res) {
          this.setState({
            delivery_person_name: res.delivery_person_name,
            delivery_address: res.delivery_address,
            postcode: res.postcode,
            phone: res.phone,
            country_id: res.country_id,
            country_selected: { value: res.country_id, label: res.name },
            id_address_edit: res.id,
            city: res.city,
            delivery_prefix: res.prefix
          });
        }
      })
      .then(() => {
        this.setState({ isModalOpen: true, isLoading: false, idxLoading: 0 });
      });
  }

  handleSave = () => {
    if (!this.state.country_selected) {
      this.setState({ colorError: true });
      return;
    }
    this.setState({ isLoading: true, isSkeleton1: true, isSkeleton2: true });
    if (this.state.isAuth) {
      if (this.state.id_address_edit > 0) {
        this.updateAddress();
      } else {
        this.addNewAddress();
      }
    } else {
      this.saveNotAuth(true);
    }
  };

  saveNotAuth = (isNew) => {
    let info = [];
    const id = uid();
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      city,
      country_id,
      country_name,
      country_selected,
      phone,
      delivery_prefix,
      email,
    } = this.state;

    let isUpdate = false;
    if (Object.keys(this.state.contact_information).length > 0) {
      isUpdate = true;
    }

    let item = {
      delivery_address_id: id,
      name: delivery_person_name,
      address: delivery_address,
      postcode,
      city,
      country_id,
      country_name,
      country_selected,
      phone,
      delivery_prefix,
      email,
      isUpdate,
      isNew
    };
    info.push(item);
    this.getListDeliveryService(item);
    this.setState({
      contact_information: item,
      listAddress: info,
      delivery_address_id: id,
    });
  };

  updateAddress() {
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
      id_address_edit,
      delivery_prefix
    } = this.state;
    const req = {
      delivery_person_name: delivery_person_name,
      delivery_address: delivery_address,
      postcode: postcode,
      phone: phone,
      country_id: country_id,
      city: city,
      id: id_address_edit,
      prefix: delivery_prefix
    };
    updateDeliveryAddress(req)
      .then((res) => {
        if (res.success) {
          this.setState({
            isOpen: true,
            sbMessage: "Sửa địa chỉ giao hàng thành công",
            sbColor: "success",
          });
        } else {
          this.setState({
            isOpen: true,
            sbMessage: "Có lỗi xảy ra, vui lòng thử lại",
            sbColor: "error",
          });
        }
      })
      .then(() => {
        this.getMyListDeliveryAddress();
      });
  }

  resetForm() {
    if (this.state.isAuth) {
      this.setState({
        id_address_edit: 0,
        delivery_person_name: "",
        delivery_address: "",
        postcode: "",
        city: "",
        country_id: 0,
        country_name: "",
        country_selected: null,
        phone: "",
        colorError: false,
        delivery_prefix: ""
      });
    }
  }

  addNewAddress() {
    const {
      delivery_person_name,
      delivery_address,
      postcode,
      phone,
      country_id,
      city,
      delivery_prefix
    } = this.state;
    const req = {
      delivery_person_name: delivery_person_name,
      delivery_address: delivery_address,
      postcode: postcode,
      phone: phone,
      country_id: country_id,
      city: city,
      prefix: delivery_prefix
    };
    createDeliveryAddress(req)
      .then((res) => {
        if (res.success) {
          this.setState({
            isOpen: true,
            sbMessage: "Tạo địa chỉ giao hàng thành công",
            sbColor: "success",
          });
        } else {
          this.setState({
            isOpen: true,
            sbMessage: "Có lỗi xảy ra, vui lòng thử lại",
            sbColor: "error",
          });
        }
      })
      .then(() => {
        this.getMyListDeliveryAddress();
      });
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleChooseAddress = (value) => {
    if (!this.state.isAuth) return;
    value.country_selected = {
      value: value.country_id,
      label: value.country_name,
    };
    this.setState({ isSkeleton2: true });
    this.setState({
      delivery_address_id: value.delivery_address_id,
      contact_information: value,
      country_selected: { value: value.country_id, label: value.country_name },
    });
    this.getListDeliveryService(value);
  };

  getListDeliveryService = (value) => {
    let products = this.state.products.map((value) => {
      let item = { id: value.product_id, quantity: value.quantity };
      return item;
    });

    let params = {
      type: 3,
      postcode: value.postcode,
      products: products,
      country_id: value.country_id,
      total_payment: this.state.totalCost
    };

    getDeliveryService(params).then(async (res) => {
      if (res.success) {
        if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
            await this.setState({
              isExchange: true,
              rate: res.exchange_rate[0].rate,
              symbol: res.exchange_rate[0].symbol,
              margin: res.exchange_rate[0].margin,
              margin_type: res.exchange_rate[0].margin_type,
            });
        }
        let { isExchange, rate, margin, margin_type } = this.state;
        if (
          res.shipment &&
          Array.isArray(res.shipment) &&
          res.shipment.length > 0
        ) {
          let shipments = res.shipment.filter(
            (value) => value.list_time && value.list_time.length > 0
          );
          if (Object.keys(shipments).length > 0) {
            let shipment_sort = shipments.map((value) => {
              value.list_time.sort(function (a, b) {
                return a.time_from.localeCompare(b.time_from);
              });
              return value;
            });

            if(isExchange) {
              if(margin_type === "increase" ) {
              shipment_sort[0].list_time[0].price_exchange = Number((Math.round((shipment_sort[0].list_time[0].price*rate + margin/100*(shipment_sort[0].list_time[0].price*rate))* 100) /100)).toFixed(2);
              }else{
              shipment_sort[0].list_time[0].price_exchange = Number((Math.round((shipment_sort[0].list_time[0].price*rate - margin/100*(shipment_sort[0].list_time[0].price*rate))* 100) /100)).toFixed(2);
              }
            }

            this.setState({
              shipment: shipment_sort,
              delivery_schedules: shipment_sort[0],
              delivery_date: shipment_sort[0].date,
              delivery_time: shipment_sort[0].list_time[0].id,
              delivery_time_id: shipment_sort[0].list_time[0].id,
              price: shipment_sort[0].list_time[0].price,
              price_discount: (shipment_sort[0].list_time[0].price_discount === 0) ? shipment_sort[0].list_time[0].price_discount : "",
              price_exchange: shipment_sort[0].list_time[0].price_exchange,
              isService: true,
              isOptional: false,
              delivery_service: "1",
            });
            if((this.state.isRollback && this.state.errorCode === "inValid_shipment_fee") || (this.state.isRollback && !this.state.errorCode)){
              let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
              deliveryInfo && deliveryInfo.delivery_service && this.handleOptionServiceChange({target: {value: deliveryInfo.delivery_service}});
              deliveryInfo && deliveryInfo.delivery_date && this.handleDateChange({target: {value: deliveryInfo.delivery_date , name: 'delivery_date'}});
              deliveryInfo && deliveryInfo.delivery_time_id && this.handleDateChange({target: {value: deliveryInfo.delivery_time_id , name: 'delivery_time'}});
            }
            this.setState({
              isSkeleton1: false,
              isSkeleton2: false,
              isRollback: false
            });
          } else {
            this.setState({
              isOptional: true,
              delivery_schedules: {},
              delivery_date: "",
              delivery_time: "",
              delivery_time_id: "",
              shipment: [],
              isService: true,
              delivery_service: "2",
            });
            if(this.state.isRollback){
              let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
              deliveryInfo && deliveryInfo.delivery_service && this.handleOptionServiceChange({target: {value: deliveryInfo.delivery_service}});
            }
            this.setState({
              isSkeleton1: false,
              isSkeleton2: false,
              isRollback: false
            });
          }
        } else if (res.shipment && Object.keys(res.shipment).length > 0) {
          let price_exchange = 0;
          if(isExchange) {
            if(margin_type === "increase") {
              price_exchange = Number((Math.round((res.shipment.price*rate + margin/100*(res.shipment.price*rate))* 100) /100)).toFixed(2);
            }else{
              price_exchange = Number((Math.round((res.shipment.price*rate - margin/100*(res.shipment.price*rate))* 100) /100)).toFixed(2);
            }
          }
          this.setState({
            price: res.shipment.price,
            price_exchange: price_exchange,
            delivery_schedules: {},
            delivery_date: "",
            delivery_time: "",
            delivery_time_id: "",
            shipment: [],
            isService: true,
            isOptional: false,
            delivery_service: "1",
          });
          if(this.state.isRollback){
            let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
            deliveryInfo && deliveryInfo.delivery_service && this.handleOptionServiceChange({target: {value: deliveryInfo.delivery_service}});
          }
          this.setState({
            isSkeleton1: false,
            isSkeleton2: false,
            isRollback: false
          });
        } else {
          this.setState({
            isOptional: true,
            delivery_schedules: {},
            delivery_date: "",
            delivery_time: "",
            delivery_time_id: "",
            shipment: [],
            isService: true,
            delivery_service: "2",
          });
          if(this.state.isRollback){
            let deliveryInfo = JSON.parse(localStorageService.getDeliveryInfo());
            deliveryInfo && deliveryInfo.delivery_service && this.handleOptionServiceChange({target: {value: deliveryInfo.delivery_service}});
          }
          this.setState({
            isSkeleton1: false,
            isSkeleton2: false,
            isRollback: false
          });
        }
        if (!this.state.isAuth) {
          this.setState({
            isOpen: value.isNew ? true : false,
            sbMessage: value.isUpdate
              ? "Sửa địa chỉ giao hàng thành công"
              : "Tạo địa chỉ giao hàng thành công",
            sbColor: "success",
            isModalOpen: false,
            isLoading: false,
            isService: true,
            isSkeleton1: false,
            isSkeleton2: false,
          });
        }
      }
    });
  };

  handleOptionServiceChange = (e) => {
    this.setState({ delivery_service: e.target.value });
  };

  handleDateChange = async (e) => {
    let { value, name } = e.target;
    let { isExchange, rate, margin, margin_type } = this.state;
    if (name === "delivery_date") {
      let schedules = this.state.shipment.find(
        (element) => element.date === e.target.value
      );
      if(schedules){
        let price_exchange = 0;
        if(isExchange) {
          if(margin_type === "increase") {
            price_exchange = Number((Math.round((schedules.list_time[0].price*rate + margin/100*(schedules.list_time[0].price*rate))* 100) /100)).toFixed(2);
          }else{
            price_exchange = Number((Math.round((schedules.list_time[0].price*rate - margin/100*(schedules.list_time[0].price*rate))* 100) /100)).toFixed(2);
          }
        }
        await this.setState({
          ...this.state,
          delivery_schedules: schedules,
          delivery_date: value,
          delivery_time: schedules.list_time[0].id,
          delivery_time_id: schedules.list_time[0].id,
          price: schedules.list_time[0].price,
          price_exchange: price_exchange
        });
      }
    } else {
      let schedules = this.state.shipment.find((element) => {
        return element.date === this.state.delivery_date;
      });
      if(schedules){
        let itemTime = schedules.list_time.find(
          (element) => element.id === value
        );
        if(itemTime){
          let price_exchange = 0;
          if(isExchange) {
            if(margin_type === "increase") {
              price_exchange = Number((Math.round((itemTime.price*rate + margin/100*(itemTime.price*rate))* 100) /100)).toFixed(2);
            }else{
              price_exchange = Number((Math.round((itemTime.price*rate - margin/100*(itemTime.price*rate))* 100) /100)).toFixed(2);
            }
          }
          this.setState({
            ...this.state,
            delivery_time: value,
            delivery_time_id: value,
            price: itemTime.price,
            price_exchange: price_exchange
          });
        }
      }
    }
  };

  handleOrder = () => {
    let params = {
      note: this.state.note,
      delivery_person_name: this.state.contact_information.name,
      delivery_address: this.state.contact_information.address,
      delivery_address_id: this.state.contact_information.delivery_address_id,
      phone: this.state.contact_information.phone,
      postcode: this.state.contact_information.postcode,
      country_id: this.state.contact_information.country_id,
      country_name: this.state.contact_information.country_name,
      country_selected: this.state.contact_information.country_selected,
      city: this.state.contact_information.city,
      shipment_fee: this.state.delivery_service === "1" ? Number(this.state.price) : 0,
      shipment_discount: (this.state.price_discount === 0) ? this.state.price_discount : "",
      shipment_fee_exchange: this.state.delivery_service === "1" ? Number(this.state.price_exchange) : 0,
      delivery_service: this.state.delivery_service,
      delivery_prefix: this.state.contact_information.delivery_prefix
    };

    if (!this.state.isAuth) {
      params.email = this.state.contact_information.email;
    }

    if (
      this.state.delivery_service === "1" &&
      this.state.shipment.length > 0 &&
      this.state.delivery_schedules &&
      this.state.delivery_time &&
      this.state.delivery_time_id &&
      this.state.delivery_date
    ) {
      let times = this.state.delivery_schedules.list_time.find(
        (value) => value.id === this.state.delivery_time
      );
      params.delivery_time = times.time_from + " - " + times.time_to;
      params.delivery_time_id = this.state.delivery_time_id;
      params.delivery_date = this.state.delivery_date;
    }

    if(this.state.delivery_service === "1") {
      if(this.state.rank && this.state.rank.shipment_fee_discount && Number(this.state.rank.shipment_fee_discount) > 0){
        if(this.state.rank.shipment_fee_discount_type === "percent") {
          params.shipment_fee_discount = Number((Math.round((Number(this.state.price)
          - ((Number(this.state.rank.shipment_fee_discount) / 100)
          * Number(this.state.price))) *100) / 100).toFixed(2));
        }else{
          params.shipment_fee_discount = Number(Number(this.state.price)
          - Number(this.state.rank.shipment_fee_discount)) > 0 ?
          Number((Math.round(Number(Number(this.state.price)
          - Number(this.state.rank.shipment_fee_discount)) * 100) / 100).toFixed(2)) : 0;
        }
      }else{
        params.shipment_fee_discount = Number(this.state.price);
      }
    }else{
      params.shipment_fee_discount = 0;
    }
    if(this.state.delivery_service === "1" && this.state.isExchange) {
      if(this.state.rank && this.state.rank.shipment_fee_discount && Number(this.state.rank.shipment_fee_discount) > 0) {
        if(this.state.rank.shipment_fee_discount_type === "percent") {
          params.shipment_fee_exchange_discount = Number((Math.round((Number(this.state.price_exchange) - ((Number(this.state.rank.shipment_fee_discount) / 100) * Number(this.state.price_exchange))) *100) / 100).toFixed(2))
        }else{
          params.shipment_fee_exchange_discount = Number(Number(this.state.price_exchange) - Number(Number(this.state.rank.shipment_fee_discount)*Number(this.state.rate))) > 0 ? Number((Math.round(Number(Number(this.state.price_exchange) - Number(Number(this.state.rank.shipment_fee_discount)*Number(this.state.rate))) * 100) / 100).toFixed(2)) : 0
        }
      }else{
        params.shipment_fee_exchange_discount = Number(this.state.price_exchange);
      }
    }else{
      params.shipment_fee_exchange_discount = 0;
    }

    let deliveryInfo = JSON.stringify(params);
    localStorageService.setDeliveryInfo(deliveryInfo);
    this.props.history.push("/checkout");
  };

  handleNoteChange = (e) => {
    this.setState({ note: e.target.value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCountryChange = (e) => {
    if (e) {
      let countrySelected = this.state.listCountry.find(
        (value) => Number(e.value) === Number(value.id)
      );
      this.setState({
        country_id: e.value,
        country_name: e.label,
        country_selected: { value: e.value, label: e.label },
        delivery_prefix: countrySelected.phone_code,
        colorError: false,
      });
    } else {
      this.setState({
        country_selected: e,
        country_id: 0,
        delivery_prefix: "",
      });
    }
  };

  handlePlacesApiChange = (address) => {
    this.setState({ delivery_address: address });
  };

  handlePlacesApiSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      if (results && results[0]) {
        let postal_code = "";
        let city = "";
        let country = "";
        if (results[0].address_components) {
          for (let i = 0; i < results[0].address_components.length; i++) {
            for (
              let j = 0;
              j < results[0].address_components[i].types.length;
              j++
            ) {
              if (results[0].address_components[i].types[j] === "postal_code") {
                postal_code = results[0].address_components[i].long_name;
              }
              if (
                results[0].address_components[i].types[j] ===
                "administrative_area_level_1"
              ) {
                city = results[0].address_components[i].long_name;
              }
              if (results[0].address_components[i].types[j] === "country") {
                country = results[0].address_components[i].long_name;
              }
            }
          }
        }

        let countryFind = this.state.listCountry.find(
          (value) => value.name === country
        );

        let country_selected = this.state.country_selected;
        let country_id = this.state.country_id;
        let prefix = this.state.prefix;
        let country_name = this.state.country_name;
        if (countryFind) {
          country_selected = { value: countryFind.id, label: countryFind.name };
          country_id = countryFind.id;
          country_name = countryFind.name;
          prefix = countryFind.phone_code;
        }

        this.setState({
          delivery_address: results[0].formatted_address,
          postcode: postal_code,
          city: city,
          country_selected: country_selected,
          country_id: country_id,
          country_name: country_name,
          delivery_prefix: prefix,
          colorError: country_selected ? false : true,
        });
      }
    });
  };

  handleColorErrorChange = () => {
    this.setState({ colorError: true });
  };

  render() {
    const { classes } = this.props;
    const {
      checked,
      isModalOpen,
      delivery_address,
      delivery_person_name,
      postcode,
      note,
      phone,
      listAddress,
      country_id,
      listCountry,
      listCountry1,
      city,
      sbColor,
      sbMessage,
      isOpen,
      idxLoading,
      isLoading,
      delivery_address_id,
      delivery_service,
      isService,
      delivery_date,
      delivery_time,
      shipment,
      delivery_schedules,
      price,
      isSkeleton1,
      isSkeleton2,
      isOptional,
      country_selected,
      isAuth,
      delivery_prefix,
      email,
      colorError,
      errorCheckout,
      isExchange,
      rate,
      symbol,
      margin,
      margin_type,
      price_exchange,
      rank,
      price_discount
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Địa chỉ giao hàng</title>
          <meta property="og:title" content={"Địa chỉ giao hàng"}></meta>
          <meta property="og:image" content={`${apiDomain}/upload/og-image-default2.png`} ></meta>
          <meta property="og:description" content={"Địa chỉ giao hàng"}></meta>
          <meta name="description" content={"Địa chỉ giao hàng"}></meta>
          <meta property="og:url" content={window.location.href}></meta>
        </Helmet>
        <Breadcrumb
          breadcrumb={[
            { link: "/delivery-address", name: "Địa chỉ giao hàng" },
          ]}
        />
        <section id="delivery-address" className={classes.deliveryAddress}>
          <Paper style={{padding: '10px 0px 40px'}}>
          <div className={classes.stepper}>
            <Container>
              <HorizontalLinearStepper activeStep={0} />
            </Container>
        </div>
          <Container>
            <ListAddress
              isAuth={isAuth}
              isSkeleton={isSkeleton1}
              listAddress={listAddress}
              isService={isService}
              delivery_address_id={delivery_address_id}
              idxLoading={idxLoading}
              checked={checked}
              handleChooseAddress={(value) => this.handleChooseAddress(value)}
              handleModalOpen={(delivery_address_id) =>
                this.handleModalOpen(delivery_address_id)
              }
              handleCheckboxChange={(e) => this.handleCheckboxChange(e)}
            />
            <DeliveryService
              isSkeleton={isSkeleton2}
              delivery_service={delivery_service}
              isService={isService}
              price={price}
              shipment={shipment}
              delivery_date={delivery_date}
              delivery_time={delivery_time}
              delivery_schedules={delivery_schedules}
              note={note}
              isOptional={isOptional}
              errorCheckout={errorCheckout}
              handleDateChange={(e) => this.handleDateChange(e)}
              handleNoteChange={(e) => this.handleNoteChange(e)}
              handleOrder={() => this.handleOrder()}
              handleOptionServiceChange={(e) =>
                this.handleOptionServiceChange(e)
              }
              price_exchange={price_exchange}
              isExchange={isExchange}
              rate={rate}
              margin={margin}
              margin_type={margin_type}
              symbol={symbol}
              rank={rank}
              price_discount={price_discount}
            />
          </Container>
          </Paper>
        </section>
        <EditAddress
          isAuth={isAuth}
          isModalOpen={isModalOpen}
          isLoading={isLoading}
          delivery_person_name={delivery_person_name}
          postcode={postcode}
          city={city}
          country_id={country_id}
          listCountry={listCountry}
          listCountry1={listCountry1}
          delivery_address={delivery_address}
          country_selected={country_selected}
          colorError={colorError}
          phone={phone}
          email={email}
          delivery_prefix={delivery_prefix}
          handleColorErrorChange={() => this.handleColorErrorChange()}
          handleModalClose={() => this.handleModalClose()}
          handleInputChange={(e) => this.handleInputChange(e)}
          handleCountryChange={(e) => this.handleCountryChange(e)}
          handlePlacesApiSelect={(address) =>
            this.handlePlacesApiSelect(address)
          }
          handlePlacesApiChange={(address) =>
            this.handlePlacesApiChange(address)
          }
          handleSave={() => this.handleSave()}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={2000}
          open={isOpen}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            className={classes.snackbar}
            onClose={this.handleClose}
            variant={sbColor}
            message={sbMessage}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    listCartStore: store.cartReducer.listProduct,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(DeliveryAddress)));
