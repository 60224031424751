const style = (theme) => ({
  header: {
    background: "#fff!important",
  },
  headerTop: {
    height: '40px',
    background: '#f8f9fa',
    '&.MuiToolbar-regular': {
      minHeight: '40px',
      backgroundColor: '#f8f9fa!important',
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    '& .header-top': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& .contact': {
      display: 'flex',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '18px',
        color: '#686868',
        '& span': {
          display: 'inline-block',
          marginLeft: '7px'
        }
      }
    },
    '& .account': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& span': {
        color: '#686868',
        cursor: 'pointer'
      },
      '& .account-span': {
        display: 'inline-block',
        margin: '0px 14px',
      }
    }
  },
  headerMain: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 0px",
    "& .logoText": {
      "& a": {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85px',
        width: '85px',
          [theme.breakpoints.down("sm")]: {
            width: '74px',
            height: '74px'
          },
          '& img': {
            position: 'relative',
            top: '0px'
          },
        [theme.breakpoints.down("xs")]: {
            maxWidth: '132px',
            '& img': {
              position: 'relative',
              top: '0px'
            },
        },
      },
    },
    "& .headerRight": {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  hambuger: {
    color: "#000",
  },
  search: {
    position: "relative",
    backgroundColor: "#fff",
    marginRight: "50px",
    marginLeft: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginRight: "10px",
    },
  },
  inputRoot: {
    color: "inherit",
  },

  inputSearchMobile: {
    border: "1px solid #f2c123",
    zIndex: 100,
    background: "#fff",
    borderRadius: "22px",
    height: '40px',
    transition: theme.transitions.create("width"),
    "& .MuiOutlinedInput-root": {
      border: "none",
      paddingRight: "6px!important",
      flexDirection: "row-reverse",
    },
    "& input": {
      padding: "0px 12px!important",
      fontSize: "15px",
      height: '26px'
    },
      [theme.breakpoints.between("xs", "1115")]: {
        width: "100%",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
  },
  inputSearchDesktop: {
    border: "1px solid #f2c123",
    background: "#fff",
    borderRadius: "22px",
    position: 'relative',
    height: '40px',
    transition: theme.transitions.create("width"),
    "& .MuiOutlinedInput-root": {
      border: "none",
      paddingRight: "6px!important",
      flexDirection: "row-reverse",
    },
    "& input": {
      padding: "5px 12px!important",
      fontSize: "15px",
    },
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
    [theme.breakpoints.between("1004", "1066")]: {
      width: "35ch",
    },
    [theme.breakpoints.between("sm", "1004")]: {
      width: "13ch",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .MuiAutocomplete-clearIndicator':{
      display: 'none'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#bdbdbd",
  },
  lstNav: {
    "& li": {
      listStyleType: "none",
      display: "inline-block",
      "&.active": {
        "& a": {
          color: "#f2c123",
          textDecoration: "none",
        },
      },
      "& a": {
        color: "#000",
        fontSize: "0.8215rem",
        textTransform: "uppercase",
        textDecoration: "none",
        marginTop: '6px',
        height: "40px",
        lineHeight: '40px',
        display: "block",
        "&:hover": {
          color: "#ff0000",
        },
        "&:focus": {
          color: "#ff0000",
          textDecoration: "none",
        },
      },
    },
    "& li:not(:last-child)": {
      marginRight: "40px",
    },
  },

  lstNavMobile: {
    position: "absolute",
    top: "70%",
    zIndex: '100',
    right: "12px",
    backgroundColor: "#fff",
    margin: 0,
    padding: "25px 35px",
    boxShadow: "1px 2px 11px #8073737d",
    "& li": {
      listStyleType: "none",
      "&.active": {
        "& a": {
          color: "#ff0000",
          textDecoration: "none",
        },
      },
      "& a": {
        color: "#000",
        textDecoration: "none",
      },
    },
    "& li:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  headerAction: {
    position: "fixed",
    width: "100%",
    top: "64px",
    left: 0,
    margin: 0,
    padding: "0 8px",
    zIndex: "999",
    backgroundColor: "#fff",
  },
  searchIconMobile: {
    [theme.breakpoints.down("sm")]: {
      color: "#111",
      marginLeft: "8px",
    },
  },
  searchInputMobile: {
    padding: "8px",
    backgroundColor: "#fff",
    position: "fixed",
    width: "100%",
    left: 0,
    top: "110px",
    zIndex: 1000,
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
    },
  },
  btnLogin: {
    borderRadius: 50,
    background: "#fff",
    minWidth: "50px",
    boxShadow: 'none',
    display: "none",
    "&:hover": {
      background: "#fff",
    boxShadow: 'none',

    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  btnUser: {
    alignItems: "flex-start",
    padding: "6px 16px",
    display: "none",
    "&:hover": {
      background: "#fff",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  btnSearch: {
    display: 'none'
  },
  btnSearchMobile: {
    display: 'none',
    alignItems: "flex-start",
    padding: "12px",
    color: "#000",
    background: "#fff",
    "&:hover": {
      background: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  btnCart: {
    background: "#fff",
    padding: '8px 13px 8px 12px',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    [theme.breakpoints.down("sm")]:{
      padding: '2px 13px 8px 12px',
    }
  },

  myCart: {
    position: "relative",
    "& .quantityItemCart": {
      position: "absolute",
      display: "inline-block",
      color: "#fff",
      backgroundColor: "#f2c123",
      top: "0px",
      right: "0px",
      lineHeight: "20px",
      fontSize: "0.6875rem",
      width: "20px",
      height: "20px",
      textAlign: "center",
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        top: '-3px'
      }
    },
  },
  features: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  featuresItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  featuresThumb: {
    marginRight: "20px",
  },
  featuresDescription: {
    "& p": {
      lineHeight: "28px",
    },
  },
  featuresTitle: {
    margin: 0,
  },
  featuresSubtitle: {
    margin: 0,
  },
  navMainCategory: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: "0px",
    "& .navMainCategoryItem": {
      listStyleType: "none",
      position: "relative",
      "& a": {
        padding: "15px",
        color: "#000",
        display: "block",
      },
      "& .subNavMain": {
        border: "1px solid #e8e8e1",
        display: "none",
        position: "absolute",
        top: "100%",
        left: "0px",
        background: "#fff",
        padding: "0px",
        textAlign: "center",
        gridTemplateColumns: "150px 150px 150px",
      },
      "&:hover": {
        background: "#eee",
      },
      "&:hover .subNavMain": {
        display: "grid",
      },
    },
  },

  btnLogout: {
    padding: "0px",
    display: "block",
    fontSize: "16px",
    fontFamily: "Tinos",
    color: "#000",
    height: "20px",
    minHeight: "0px",
    marginBottom: "10px",
  },

  drawerNav:{
    width: '300px',
    '& .MuiDrawer-paper': {
      width: '300px',
      lineHeight: 1.5,
      "& .logoText": {
          display: 'block',
          height: '52px',
          maxWidth: '132px',
          '& img': {
            position: 'relative',
            top: '-2px'
          }
      },
      "& .navListItem": {
        fontSize: '17px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderBottom: '1px solid #eee',
        paddingRight: '40px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& li, & ul': {
          width: '100%',
          "&.active": {
            "& a": {
              color: "#f2c123",
              textDecoration: "none",
            },
          },
        },
      },
      '& .navListItemSpecial':{
        marginTop: '15px',
        fontSize: '17px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderBottom: '1px solid #eee',
        paddingRight: '40px',
        '& li, & ul': {
          width: '100%',
          "&.active": {
            "& a": {
              color: "#f2c123",
              textDecoration: "none",
            },
          },
        },
      }
    }
  },
  subItemCat: {
    paddingBottom: '10px'
  },
  toggleIcon: {
    position: 'absolute',
    right: '20px',
    top: '10px'
  },
  iconSearch: {
    position: 'absolute',
    background: '#f2c123'
  }
});

export default style;
