import React, { Component } from "react";
import {
  AppBar as MuiAppBar,
  Box,
  Container,
  Grid,
  Hidden,
  Toolbar as MuiToolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { parse } from "date-fns";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import styled, { withTheme } from "styled-components";
import { setLanguageAction } from "../../redux/actions/languageAction";
import { setUserInfoAction } from "../../redux/actions/userActions";
import LocalStorageService from "./../../services/LocalStorageService";
import { dateFormat } from "./../../utills/config";
import style from "./style";
import Button from "./../UI/Button";
import UserMenu from "./UserMenu";
import NavMobile from "./NavMobile";
import NavDesktop from "./NavDesktop";
import Search from "./Search";
import DrawerMobile from "./DrawerMobile";
import { getProductList } from "../../models/product";
import BagIcon from "./../../assets/images/bag-icon.png";
import UserIcon from "./../../assets/images/user-icon.png";
import { getListCart } from "../../models/cart";
import SearchMobile from "./SearchMobile";
import { Search as SearchIcon } from "react-feather";
import { convertSlugSeo } from "../../utills/constant";
import { queryCategory } from "../../models/category";
import LogoImage from './../../assets/images/logo2.png';
import { Phone } from "react-feather";
import { Mail } from "react-feather";
import { setModalLogoutAction } from "../../redux/actions/modalActions";


const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
  top: -1px!important;
`;

const Toolbar = styled(MuiToolbar)`
  box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.05);
`;

const localStorageService = LocalStorageService.getService();
class Header extends Component {
  state = {
    searchInput: "",
    options: [],
    value: null,
    toggleSearch: false,
    loading: false,
    quantity: 0,
    categories: [],
    isOpenSubCat: false,
    idOpenSubCat: 0
  };

  async componentDidMount() {
    const token = localStorageService.getAccessToken();

    this.fetchCategories();

    if (token) {
      this.setState({ ...this.state, isAuth: true });
      await getListCart({type: 1}).then(async (res) => {
        if(res.success){
          this.props.clearListCart();
          let exchange = {};
          let isExchange = false;
          if(res.exchange_rate && Array.isArray(res.exchange_rate) && res.exchange_rate.length === 1) {
            isExchange = true;
            exchange = res.exchange_rate[0];
          }
          if(res.cart && Array.isArray(res.cart) && res.cart.length > 0) {
            let lengthItem = res.cart.length - 1;
            res.cart.forEach((value, index) => {
              let item = {
                attribute_name1: value.attribute_name1,
                attribute_name2: value.attribute_name2,
                attribute_name3: value.attribute_name3,
                attribute_name4: value.attribute_name4,
                attribute_name5: value.attribute_name5,
                attribute_value1: value.attribute_value1,
                attribute_value2: value.attribute_value2,
                attribute_value3: value.attribute_value3,
                attribute_value4: value.attribute_value4,
                attribute_value5: value.attribute_value5,
                cart_id: value.cart_id,
                inventory: Number(value.inventory),
                image: value.image && Array.isArray(value.image) && value.image.length > 0 && value.image[0],
                product_attribute_value_id1: value.product_attribute_value_id1,
                product_attribute_value_id2: value.product_attribute_value_id2,
                product_attribute_value_id3: value.product_attribute_value_id3,
                product_attribute_value_id4: value.product_attribute_value_id4,
                product_attribute_value_id5: value.product_attribute_value_id5,
                product_code: value.product_code,
                product_id: value.product_id,
                product_name: value.product_name,
                quantity: Number(value.quantity),
                sku_code: value.sku_code,
                discount_price: (value.old_discount_price &&
                  typeof Number(value.old_discount_price) === "number"
                  && !isNaN(value.old_discount_price)
                  && Number(value.old_discount_price) > 0) ?
                  Number(value.old_discount_price) : 0,
                price: (value.old_price
                  && typeof Number(value.old_price) === "number"
                  && !isNaN(value.old_price)
                  && Number(value.old_price) > 0) ?
                  Number(value.old_price) : 0,
                }

              if(isExchange) {
                item.isExchange = true;
                item.symbol = exchange.symbol;
                item.margin = exchange.margin;
                item.rate = exchange.rate;
                item.margin_type = exchange.margin_type;
                if(exchange.margin_type === "increase") {
                  item.discount_price_exchange = (value.old_discount_price &&
                    typeof Number(value.old_discount_price) === "number"
                    && !isNaN(value.old_discount_price)
                    && Number(value.old_discount_price) > 0) ?
                    Number((Math.round((value.old_discount_price*exchange.rate + exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  item.price_exchange = (value.old_price
                    && typeof Number(value.old_price) === "number"
                    && !isNaN(value.old_price)
                    && Number(value.old_price) > 0) ?
                    Number((Math.round((value.old_price*exchange.rate + exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                }else{
                  item.discount_price_exchange = (value.old_discount_price &&
                    typeof Number(value.old_discount_price) === "number"
                    && !isNaN(value.old_discount_price)
                    && Number(value.old_discount_price) > 0) ?
                    Number((Math.round((value.old_discount_price*exchange.rate - exchange.margin/100*(value.old_discount_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                  item.price_exchange = (value.old_price
                    && typeof Number(value.old_price) === "number"
                    && !isNaN(value.old_price)
                    && Number(value.old_price) > 0) ?
                    Number((Math.round((value.old_price*exchange.rate - exchange.margin/100*(value.old_price*exchange.rate))* 100) /100)).toFixed(2) : 0;
                }
              }

              if(Number(value.inventory) < Number(value.quantity)){
                item.error_inventory = true;
              }
              item.isLastItem = index === lengthItem ? true : false;
              this.props.addToCart(item);
            });
          }else{
            this.props.clearListCart();
            localStorageService.clearCart();
            localStorageService.clearVoucher();
            localStorageService.clearDeliveryInfo();
            localStorageService.clearVoucherPoint();
          }
        }
      })
    } else {
      // document.cookie = "listCart=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const cart = localStorageService.getCart();
      if (typeof cart === "string" && cart.length > 0) {
        let cartInfo = JSON.parse(cart);
        if (cartInfo && Array.isArray(cartInfo) && cartInfo.length > 0) {
          //clear state list item redux to add to cart from cookie
          this.props.clearListCart();
          let lengthItem = cartInfo.length - 1;
          cartInfo.forEach((value, index) => {
            value.isLastItem = index === lengthItem ? true : false
            this.props.addToCart(value);
          });
        }else{
          this.props.clearListCart();
          localStorageService.clearCart();
          localStorageService.clearVoucher();
          localStorageService.clearDeliveryInfo();
          localStorageService.clearVoucherPoint();
        }
      }else{
        this.props.clearListCart();
        localStorageService.clearCart();
        localStorageService.clearVoucher();
        localStorageService.clearDeliveryInfo();
        localStorageService.clearVoucherPoint();
      }
    }
  }

  filterProduct = (query) => {
    let that = this;
    this.setState({ loading: true });
    getProductList({ search: query, page: 1, page_size: 8 }).then((res) => {
      if (res.success) {
        this.setState({ ...this.state, options: res.products });
      }
      that.setState({ loading: false });
    });
  };

  toggleSearch = () => {
    this.setState({
      toggleSearch: !this.state.toggleSearch,
    });
  };

  viewSearch = (item) => {
    if (item && typeof item === "string") {
      let item_replace = item.replace(/\//g,'--')
      this.props.history.replace("/search/search=" + item_replace);
    } else if (item && item.id && item.name) {
      this.props.history.push(`/product/${convertSlugSeo(item.name)}.id=${item.id}`);
    }
  };

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.listCartStore !== this.props.listCartStore) {
        let countQuantity = 0;
        this.props.listCartStore.forEach((value) => {
          countQuantity += Number(value.quantity);
        });
        this.setState({
          quantity: countQuantity,
        });
      }
  }

  handleChangeOpenInput = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  }

  fetchCategories = () => {
    let that = this;
    queryCategory().then((res) => {
      if (res.success) {
        that.setState({
          categories: res.category,
        });
      }
    });
  };

  handleToggleSubCat = (value, id) => {
    this.setState((prevState) => ({isOpenSubCat: value, idOpenSubCat: id === prevState.prevState ? 0 : id}));
  }

  render() {
    let { onDrawerToggle, classes, mobileOpen } = this.props;
    const { options, value, toggleSearch, loading, quantity, categories, isOpenSubCat, idOpenSubCat } = this.state;
    const token = localStorageService.getAccessToken();
    return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar className={classes.headerTop}>
            <Container maxWidth="xl">
                      <div className="header-top">
                      <div className="contact">
                      <a href="tel:+420732886666">
                        <Phone size={18}></Phone><span>+420 732886666</span>
                      </a>
                      <a href="mailto:lienhe@taphoa.cz">
                        <Mail size={18}></Mail><span>lienhe@taphoa.cz</span>
                      </a>
                    </div>
                    {token ? (
                    <div className="account">
                      <Link className="account-span" to="/user/account"><span>Cá nhân</span></Link>
                      <span style={{marginBottom: '5px', display: 'inline-block'}}>|</span>
                      <span className="account-span" onClick={() => {this.props.setModalLogout(true)}}>Đăng xuất</span>
                    </div>
                  ) : (
                    <div className="account">
                      <Link className="account-span" to="/auth/sign-in"><span>Đăng nhập</span></Link>
                      <span style={{marginBottom: '5px', display: 'inline-block'}}>|</span>
                      <Link className="account-span" to="/auth/sign-up"><span>Đăng ký</span></Link>
                    </div>
                  )}
                      </div>
            </Container>
          </Toolbar>
          <Toolbar>
            <Container maxWidth="xl">
              <Grid
                container
                alignItems="center"
                className={classes.headerMain}
              >
                <Grid item>
                  <Typography variant="h1" className="logoText">
                    <Link to="/"><img src={LogoImage} alt="logo" /></Link>
                  </Typography>
                </Grid>
                <NavDesktop />
                <div className="headerRight">
                  <Search
                  isMobile={false}
                    options={options}
                    value={value}
                    loading={loading}
                    viewSearch={(newValue) => this.viewSearch(newValue)}
                    filterProduct={(value) => this.filterProduct(value)}
                  />
                <button className={classes.btnSearchMobile} onClick={() => this.toggleSearch()}>
                    <SearchIcon />
                    </button>
                  <DrawerMobile onDrawerToggle={onDrawerToggle} />
                  <div className={classes.myCart}>
                      <Link to="/cart" className={classes.btnCart}>
                        <img src={BagIcon} alt="Giỏ hàng taphoa.cz" />
                      </Link>
                    {quantity > 0 && (
                      <span className="quantityItemCart">{quantity}</span>
                    )}
                  </div>
                </div>
              </Grid>
            </Container>
            <NavMobile
              mobileOpen={mobileOpen}
              isAuth={token}
              onDrawerToggle={(e) => onDrawerToggle(e)}
              categories={categories}
              isOpenSubCat={isOpenSubCat}
              logout={() => this.logout()}
              handleToggleSubCat={(value, id) => this.handleToggleSubCat(value, id)}
              idOpenSubCat={idOpenSubCat}
            />
          </Toolbar>
          <div style={{display: toggleSearch ? 'block' : 'none',
              padding: '10px 8px',
              background: 'rgb(255, 255, 255)',
              borderTop: '1px solid rgb(248 248 248)'}}>
            <SearchMobile isMobile={true}   options={options}
                    value={value}
                    loading={loading}
                    viewSearch={(newValue) => this.viewSearch(newValue)}
                    filterProduct={(value) => this.filterProduct(value)} />
          </div>
        </AppBar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumb: store.breadcrumbReducer.currentBreadcrumb,
    language: store.languageReducer.language,
    listCartStore: store.cartReducer.listProduct,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setLanguage: (header) => {
      dispatch(setLanguageAction(header));
    },
    setUserInfo: (user) => {
      dispatch(setUserInfoAction(user));
    },
    addToCart: (payload) => {
      dispatch({ type: "ADD_TO_CART", payload });
    },
    clearListCart: (payload = null) => {
      dispatch({ type: "CLEAR_LIST_CART", payload });
    },
    setModalLogout: (logout) => {
      dispatch(setModalLogoutAction(logout));
    },
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  withStyles(style)
)(withTheme(withRouter(injectIntl(Header))));
