import style from "./../../style";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Container, Grid, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import ProductTile from "../../../../components/ProductTile";
import ProductTileLoading from "../../../../components/UI/ProductTileLoading";
import { SLIDER_SKELETON } from "../../../../utills/constant";
import Skeleton from "@material-ui/lab/Skeleton";
import BannerProduct from "../BannerProduct";
import BannerProductMobile from "../BannerProductMobile";
import styled from "styled-components";

const GridItem = styled(Grid)`
  @media (max-width: 375px){
    padding: 2px!important;
  }
`;

class FeaturedProduct extends Component {
  render() {
    const {
      classes,
      title,
      listProducts,
      isLoading,
      type,
      total_items,
      reloadListProduct,
      isChangeCart,
      showMessage,
      handleToggleFavorite,
      banner,
      bannerMobile,
      positionBanner
    } = this.props;
    return (
      <React.Fragment>
        {!isLoading ? (
          <React.Fragment>
            {listProducts && Array.isArray(listProducts) && listProducts.length > 0 && (
              <section id="fp" className={classes.fp}>
                <Container maxWidth="xl">
                <Grid container>
                  <Grid xl={12} xs={12} item>
                    <div className={classes.titleHome}>
                      <h2><span>{title}</span></h2>
                      <div className="title-sp"></div>
                    </div>
                  </Grid>
                </Grid>
                </Container>
                <div className={classes.fpMain}>
                  <div className="for-mobile">
                    <div className="fpItemProduct">
                          <div className="banner-product">
                              <BannerProductMobile banner={bannerMobile} title={title} />
                          </div>
                          <div className="list-product">
                              <Container maxWidth="xl">
                              <Grid container spacing={2}>
                          {listProducts.map((value, index) => (
                            <GridItem
                              item
                              md={3}
                              lg={3}
                              xs={6}
                              sm={4}
                              key={index}
                              className="fpGrid"
                            >
                              <ProductTile
                                type={type}
                                value={value}
                                reloadListProduct={() => reloadListProduct()}
                                isChangeCart={isChangeCart}
                                showMessage={(info) => showMessage(info)}
                                handleToggleFavorite={(id, type) => handleToggleFavorite(id, type)}
                              />
                            </GridItem>
                          ))}
                        </Grid>
                              </Container>
                          </div>
                      </div>
                      {total_items && total_items > 8 && type && type === "promotion" && ( <Grid item xs={12}>
                          <Link to="/san-pham-khuyen-mai" className={classes.btnViewAll}>Xem thêm</Link>
                      </Grid>)}
                  </div>
                  <Container maxWidth="xl" className="for-desktop">
                    <div className="fpItemProduct">
                        {
                          positionBanner === "left" && (
                            <div className="banner-product">
                            <Grid container>
                                <Grid item xl={12}>
                                    <BannerProduct banner={banner} title={title} />
                                </Grid>
                              </Grid>
                          </div>
                          )
                        }
                        <div className="list-product">
                          <Grid container spacing={2}>
                        {listProducts.map((value, index) => (
                          <Grid
                            item
                            md={3}
                            lg={3}
                            xs={6}
                            sm={4}
                            key={index}
                            className="fpGrid"
                          >
                            <ProductTile
                              type={type}
                              value={value}
                              reloadListProduct={() => reloadListProduct()}
                              isChangeCart={isChangeCart}
                              showMessage={(info) => showMessage(info)}
                              handleToggleFavorite={(id, type) => handleToggleFavorite(id, type)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                        </div>
                        {
                          positionBanner === "right" && (
                            <div className="banner-product">
                            <Grid container>
                                <Grid item xl={12}>
                                    <BannerProduct banner={banner} title={title} />
                                </Grid>
                              </Grid>
                          </div>
                          )
                        }
                    </div>
                    {total_items && total_items > 8 && type && type === "promotion" && ( <Grid item xs={12}>
                        <Link to="/san-pham-khuyen-mai" className={classes.btnViewAll}>Xem thêm</Link>
                    </Grid>)}
                  </Container>
                </div>
              </section>
            )}
          </React.Fragment>
        ) : (
          <section id="fp" className={classes.fp}>
           <Container maxWidth="xl">
                <Grid container>
                  <Grid xl={12} xs={12} item>
                    <div className={classes.titleHome}>
                      <h2><span>{title}</span></h2>
                      <div className="title-sp"></div>
                    </div>
                  </Grid>
                </Grid>
                </Container>
            <div className={classes.fpMain}>
            <div className="for-mobile">
                    <div className="fpItemProduct">
                          <div className="banner-product">
                              <BannerProductMobile banner={bannerMobile} title={title} isSke={true} />
                          </div>
                          <div className="list-product">
                              <Container maxWidth="xl">
                              <Grid container spacing={2}>
                              {SLIDER_SKELETON.map((value, index) => (
                              <Grid item md={3} lg={3} xs={6} sm={4} className="fpGrid" key={index}>
                                <ProductTileLoading />
                              </Grid>
                          ))}
                        </Grid>
                              </Container>
                          </div>
                      </div>
                  </div>
              <Container maxWidth="xl" className="for-desktop">
              <div className="fpItemProduct">
                        <div className="banner-product">
                            <BannerProduct banner={banner} isSke={true}  />
                        </div>
                        <div className="list-product">
                        <Grid container spacing={2}>
                          {SLIDER_SKELETON.map((value, index) => (
                            <Grid item md={3} lg={3} xs={6} sm={4} className="fpGrid" key={index}>
                              <ProductTileLoading />
                            </Grid>
                        ))}
                </Grid>
                        </div>
              </div>
              
              </Container>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withStyles(style),
  withConnect
)(withRouter(injectIntl(FeaturedProduct)));
